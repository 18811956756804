import { Button } from "@equinor/eds-core-react";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import { Fieldset } from "components/form/Form";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { closeEditSheet } from "features/sheets/commands";
import { SaveVDSTextBlockDescription } from "features/sheets/types";
import useSaveVDSTextBlockDescription from "queries/useSaveVDSTextBlockDescription";
import useSheet from "queries/useSheet";
import { useEffect, useMemo } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { confirmNavigation } from "utils/confirmNavigation";
import { successMessage } from "utils/successMessage";

export function EditVDSTextBlockDescription({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const { isConfirmed } = useConfirm();

  const { data, status, error, isRefetching, isRefetchError } = useSheet({
    name,
    revision,
    sheetType: "vds-textblocks",
  });

  const open = useMemo(
    () => !!name && !!revision && tab === "description",
    [name, revision, tab]
  );

  const {
    mutate: saveVDSTextBlockDescription,
    status: saveVDSTextBlockDescriptionStatus,
    error: saveVDSTextBlockDescriptionError,
    reset: saveVDSTextBlockDescriptionReset,
    data: saveVDSTextBlockDescriptionData,
  } = useSaveVDSTextBlockDescription();

  const onSubmit: SubmitHandler<SaveVDSTextBlockDescription> = async (
    formData
  ) => {
    (await isConfirmed(
      <>
        VDS Description will be updated on all VDS revisions referencing this
        Text Block. Are you sure you want to continue?
      </>
    )) &&
      saveVDSTextBlockDescription({
        name,
        revision,
        content: formData,
      });
  };

  const methods = useForm<SaveVDSTextBlockDescription>({
    shouldUnregister: true,
    mode: "all",
  });
  const { reset, formState, handleSubmit } = methods;
  const { isDirty } = formState;

  const closeModal = async () => {
    (!isDirty || (await confirmNavigation({ isConfirmed }))) &&
      closeEditSheet({ history });
  };

  useEffect(() => {
    !isRefetching && !isRefetchError && reset(undefined, { keepDirty: false });
  }, [isRefetching, isRefetchError, data, reset]);

  return (
    <>
      <ModalWindow
        isOpen={open}
        closeModal={closeModal}
        title={`Update VDS Description for TextBlock ${name} rev. ${revision}`}
        status={status}
        error={error}
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalSideMargin>
                <ModalWindowContentDefault style={{ minWidth: 500 }}>
                  <Fieldset>
                    <Field
                      prop="Description"
                      value={data?.VDSDescription}
                      title="Description"
                      type="text"
                    />
                  </Fieldset>
                </ModalWindowContentDefault>
              </ModalSideMargin>
              <ModalWindowButtonContainer>
                <Button type="submit">Save</Button>
                <Button variant="outlined" onClick={closeModal}>
                  Close
                </Button>
              </ModalWindowButtonContainer>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      <StatusModal
        status={saveVDSTextBlockDescriptionStatus}
        error={saveVDSTextBlockDescriptionError}
        onSettledClose={saveVDSTextBlockDescriptionReset}
        successMessage={successMessage(saveVDSTextBlockDescriptionData)}
      />
    </>
  );
}
