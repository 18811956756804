import { useMutation, useQueryClient } from "react-query";
import { PlantProps } from "features/plant/types";
import axios from "axios";
import config from "app/config";

type IssuesCommandParams = {
  create: {
    plant: PlantProps;
  };
  delete: {
    IssueRevision: string;
    plant: PlantProps;
  };
  "user-protection": {
    SetUserProtectIndicator: "Y" | "N";
    UserName: string;
    plant: PlantProps;
  };
};

type IssuesCommands = keyof IssuesCommandParams;

const issueMethods = {
  create: "post",
  delete: "delete",
  "user-protection": "put",
} as const;

export default function useIssuesCommand<T extends IssuesCommands>() {
  const queryClient = useQueryClient();

  async function issueCommand({
    command,
    ...rest
  }: { command: T } & IssuesCommandParams[T]) {
    const { plant, ...body } = rest;
    return await axios({
      url:
        config.restApiBaseUrl +
        `plants/${plant.PlantID}/issues${
          command === "user-protection" ? "/user-protection" : ""
        }`,
      method: issueMethods[command],
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: body,
    });
  }

  return useMutation(issueCommand, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["issues", variables.plant.PlantID]);
      queryClient.invalidateQueries(["codelists", "plants"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type IssuesCommandType = ReturnType<typeof useIssuesCommand>;
