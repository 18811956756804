import React, { useRef, useState } from "react";
import { Button, Menu, Tooltip } from "@equinor/eds-core-react";
import styled from "styled-components";
import { ConditionalWrapper } from "./Components";
import { generateId } from "features/sheets/util";

const StyledMenu = styled(Menu)`
  // this fixes the curious case of shrinking menus
  white-space: nowrap;
`;

const ListMenu = ({
  buttonContent,
  children,
  disabled,
  placement,
  menuOpen,
  setMenuOpen,
  tooltipTitle,
}: {
  buttonContent: React.ReactNode;
  children?: React.ReactNode;
  disabled?: boolean;
  placement?: any;
  menuOpen?: boolean; // use this to define open state as a prop instead of using internal open state
  setMenuOpen?: React.Dispatch<boolean>; // and this is the setState of that external prop
  tooltipTitle?: string;
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { current: ID } = useRef(generateId());

  const openMenu = () => {
    setMenuOpen ? setMenuOpen(true) : setIsOpen(true);
  };

  const closeMenu = () => {
    setMenuOpen ? setMenuOpen(false) : setIsOpen(false);
  };

  const actualOpen = typeof menuOpen !== "undefined" ? menuOpen : isOpen;

  return (
    <>
      <ConditionalWrapper
        condition={!!tooltipTitle && !actualOpen}
        wrapperProps={{ title: tooltipTitle }}
        Wrapper={Tooltip}
      >
        <Button
          disabled={disabled}
          ref={setAnchorEl}
          id={`anchor-${ID}`}
          aria-haspopup="true"
          aria-expanded={actualOpen}
          aria-controls={`menu-${ID}`}
          variant="ghost_icon"
          onClick={() => (actualOpen ? closeMenu() : openMenu())}
        >
          {buttonContent}
        </Button>
      </ConditionalWrapper>
      <StyledMenu
        open={actualOpen}
        id={`menu-${ID}`}
        aria-labelledby={`anchor-${ID}`}
        onClose={closeMenu}
        anchorEl={anchorEl}
        placement={placement ? placement : "bottom-end"}
      >
        {children}
      </StyledMenu>
    </>
  );
};

export default ListMenu;
