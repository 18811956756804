import axios, { AxiosResponse } from "axios";
import { StatusResponseWithInfo } from "components/StatusModal";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "./queryUtil";

export default function useCopyVDSTextBlock() {
  const queryClient = useQueryClient();

  async function copyVDSTextBlocks({
    textblocks,
  }: {
    textblocks: { textBlockId: string; revision: string }[];
  }): Promise<AxiosResponse<StatusResponseWithInfo>> {
    return await axios(getSheetApiUrl({ sheetType: "vds-textblocks" }), {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: {
        Operation: "Copy",
        ItemSection: "",
        ItemID: "",
        textblocks: textblocks.map((e) => ({
          TextBlockID: e.textBlockId,
          Revision: e.revision,
        })),
      },
    });
  }

  return useMutation(copyVDSTextBlocks, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sheets", "vds-textblocks"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type CopyVDSTextBlockQuery = ReturnType<typeof useCopyVDSTextBlock>;
