import { Button, Icon } from "@equinor/eds-core-react";
import { edit, file, visibility } from "@equinor/eds-icons";
import useSaveSetting from "queries/settings/useSaveSetting";
import { useSomeSettings } from "queries/settings/useSomeSettings";
import useCopyVDSTextBlock from "queries/useCopyVDSTextBlock";
import useCreateEDS from "queries/useCreateEDS";
import useSetMultipleSheetStatus from "queries/useSetMultipleSheetStatus";
import {
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import { selectUIState } from "uiSlice";
import { useMemoOne as useMemo } from "use-memo-one";
import { getSetting, getSettingsObject, settingKey } from "utils/settings";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  AbsoluteFlexContainer,
  BottomButtonContainer,
  ContentMessage,
  FlexContainer,
  FlexContentElement,
  FlexHeaderElement,
  StatusChip,
  ValveTypeCodeContainer,
} from "../../components/Components";
import StatusModal from "../../components/StatusModal";
import useConfirm from "../../components/confirm/useConfirm";
import { FilterProps, filtersReducer } from "../../components/table/Filters";
import Table from "../../components/table/Table";
import useTable from "../../components/table/useTable";
import { combineQueryStatuses } from "../../queries/queryUtil";
import { useCodelist } from "../../queries/useCodelist";
import useCopySheets from "../../queries/useCopySheets";
import useDeleteSheets from "../../queries/useDeleteSheets";
import useNewSheetRevision from "../../queries/useNewSheetRevision";
import useNewSheetRevisions from "../../queries/useNewSheetRevisions";
import useSetSheetStatus from "../../queries/useSetSheetStatus";
import useSheets, {
  sheetsExtraParams,
  useSheetsNames,
} from "../../queries/useSheets";
import { filterItemsByName } from "../../utils/filterItemsByName";
import { filterItemsByProps } from "../../utils/filterItemsByProps";
import { successMessage } from "../../utils/successMessage";
import { areArraysOfObjectsIdentical } from "../../utils/util";
import {
  selectMainPlant,
  selectSourcePlant,
  unsetPlantState,
} from "../plant/plantSlice";
import { PlantProps } from "../plant/types";
import ItemMenu from "./ItemMenu";
import { SheetControlHeader } from "./SheetControlHeader";
import { SheetPane } from "./SheetPane";
import { VDSTaskMonitor } from "./VDSTaskMonitor";
import {
  copySelected,
  deleteSelected,
  deleteSingle,
  editSheet,
  previewInPane,
} from "./commands";
import {
  ListSheetsDisplayTypes,
  SheetTypes,
  getElementNameSingular,
  minTableWidth,
  plantDependentSheets,
  sheetDocumentWidth,
  sheetProperties,
  sheetTypeNames,
  textualSheets,
  pcsWorkbenchCommands,
  vdsWorkbenchCommands,
} from "./config/sheetConfig";
import {
  MaybeSheetStatus,
  SheetStatus,
  sheetStatusConfig,
} from "./config/statusConfig";
import { NewNameInput } from "./modals/CopySheets";
import { CreateEDS, NewEDSOperation } from "./modals/CreateEDS";
import EditHTMLModal from "./modals/EditHTML";
import { SetStatus } from "./modals/SetStatus";
import EditPCSModal from "./modals/pcs/EditPCS";
import { VDSSubsegmentPropertiesModal } from "./modals/vds-subsegments/VDSSubsegmentProperties";
import { EditVDSTextBlockDescription } from "./modals/vds-textblocks/EditDescription";
import { EditVDSTextBlock } from "./modals/vds-textblocks/EditVDSTextBlock";
import { NewVDSTextBlock } from "./modals/vds-textblocks/NewVDSTextBlock";
import { VDSTextBlockPropertiesModal } from "./modals/vds-textblocks/VDSTextBlockProperties";
import PipeElementsPanel from "./panels/PipeElementsPanel";
import PropertiesPanel from "./panels/PropertiesPanel";
import ReferencePanel from "./panels/ReferencePanel";
import StandardNotesPanel from "./panels/StandardNotesPanel";
import {
  getSheetState,
  hideSheetCopyModal,
  setModalMutationStatuses,
  setMutationStatuses,
  setSheetType,
} from "./sheetSlice";
import { SheetCommands, VDSTextBlock } from "./types";
import { VDSPanel } from "./panels/VDSPanel";

export function PreviewInPaneCommandButton({
  isCopyModal,
  dispatch,
  plant,
  sheetType,
  item,
}: {
  isCopyModal: boolean;
  dispatch: Function;
  plant: PlantProps;
  sheetType: SheetTypes;
  item: any;
}) {
  const { previewInEdit } = sheetProperties[sheetType];
  return previewInEdit ? (
    <></>
  ) : (
    <Button
      variant="ghost_icon"
      title="Preview in pane"
      onClick={() => {
        previewInPane({
          location: isCopyModal ? "copyModal" : "main",
          dispatch,
          plant,
          sheetType,
          item,
        });
      }}
    >
      <Icon data={visibility} />
    </Button>
  );
}

export function EditCommandButton({
  isCopyModal,
  plant,
  sheetType,
  item,
}: {
  isCopyModal: boolean;
  plant: PlantProps;
  sheetType: SheetTypes;
  item: any;
}) {
  const elementName = getElementNameSingular(sheetType);
  const history = useHistory();
  const { previewInEdit } = sheetProperties[sheetType];
  return isCopyModal ||
    !(
      item.Status === "W" ||
      (item.Status === "I" && sheetType === "general") ||
      previewInEdit
    ) ? (
    // placeholder
    <Button variant="ghost_icon" style={{ visibility: "hidden" }}>
      <Icon data={edit} />
    </Button>
  ) : (
    <Button
      variant="ghost_icon"
      title={
        item.Status !== "W" && previewInEdit
          ? `View ${elementName}`
          : `Edit ${elementName}`
      }
      onClick={() => editSheet({ item, history, sheetType })}
    >
      {item.Status !== "W" && previewInEdit ? (
        <Icon data={file} />
      ) : (
        <Icon data={edit} />
      )}
    </Button>
  );
}

export type SheetProps = {
  name: string;
  revision: string;
};

export default function Sheets({
  plant,
  sheetType,
  displayType,
  newRevision,
  sheetCommand,
}: {
  plant: PlantProps;
  sheetType: SheetTypes;
  displayType: ListSheetsDisplayTypes;
  newRevision?: string;
  sheetCommand?: SheetCommands;
}) {
  return (
    <SheetsDisplay
      plant={plant}
      sheetType={sheetType}
      displayType={displayType}
      sheetCommand={sheetCommand}
      newRevision={newRevision}
      // Remount on change
      key={sheetType + plant.PlantID + displayType + sheetCommand}
    />
  );
}

function SheetsDisplay({
  plant,
  sheetType,
  displayType,
  newRevision,
  sheetCommand,
}: {
  plant: PlantProps;
  sheetType: SheetTypes;
  displayType: ListSheetsDisplayTypes;
  newRevision?: string;
  sheetCommand?: SheetCommands;
}) {
  const isPCSWorkbenchCommand = useMemo(
    () =>
      !!(sheetCommand && pcsWorkbenchCommands.some((w) => w === sheetCommand)),
    [sheetCommand]
  );
  const isVDSWorkbenchCommand = useMemo(
    () =>
      !!(sheetCommand && vdsWorkbenchCommands.some((w) => w === sheetCommand)),
    [sheetCommand]
  );

  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  const {
    mutationStatuses,
    modalMutationStatuses,
    pipeElementFilter,
    noteFilter,
  } = useAppSelector(getSheetState);
  const { paneModes, showVDSTaskMonitor } = useAppSelector(selectUIState);

  const isCopyModal = displayType === "copyModal";
  const hasName = sheetType !== "general";
  const sheetTypeName = sheetTypeNames[sheetType];

  const {
    statuses: sheetStatuses,
    filterNameProperty: definedFilternameProperty,
    nameProperty,
    filterPlaceholder,
  } = sheetProperties[sheetType];
  const filterNameProperty = definedFilternameProperty
    ? definedFilternameProperty
    : nameProperty;

  const columns = useMemo(
    () => [
      ...(isCopyModal && sheetType !== "general"
        ? [
            {
              type: "with-context",
              key: "NewName",
              title: "New Name",
              Component: NewNameInput,
            } as const,
          ]
        : []),
      ...sheetProperties[sheetType].columns.filter(
        (col) =>
          !col?.display ||
          (isPCSWorkbenchCommand && col?.display === "workbench")
      ),
    ],
    [isCopyModal, sheetType, isPCSWorkbenchCommand]
  );

  const basedOnSubsegmentFilterKey = settingKey([
    "sheets",
    "vds",
    "filters",
    "basedOnSubsegment",
  ]);

  const [basedOnSubsegmentFilter, setBasedOnSubsegmentFilter] = useState<
    "Y" | "N"
  >("Y");

  useEffect(() => {
    setBasedOnSubsegmentFilter(
      (getSetting({
        settings,
        settingKey: basedOnSubsegmentFilterKey,
      }) as "Y" | "N") ?? "Y"
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterKey = useMemo(
    () =>
      settingKey([
        "sheets",
        sheetType,
        "filters",
        {
          ...(plantDependentSheets.includes(sheetType) ? { isCopyModal } : {}),
          ...(sheetType === "pcs"
            ? { isPCSWorkbenchCommand: isPCSWorkbenchCommand }
            : {}),
          ...(sheetType === "vds"
            ? { basedOnSubsegmentFilter, isVDSWorkbenchCommand }
            : {}),
        },
      ]),
    [
      sheetType,
      isCopyModal,
      isPCSWorkbenchCommand,
      isVDSWorkbenchCommand,
      basedOnSubsegmentFilter,
    ]
  );

  const filterKeySheetType = useMemo(
    () => settingKey(["sheets", sheetType, "filters"]),
    [sheetType]
  );

  const settingsKeys = useMemo(
    () => [
      basedOnSubsegmentFilterKey,
      settingKey([filterKeySheetType, "nameFilter"]),
    ],
    [basedOnSubsegmentFilterKey, filterKeySheetType]
  );

  const settingsResults = useSomeSettings({ keys: settingsKeys });
  const settings = useMemo(
    () => getSettingsObject({ settingsKeys, settingsResults }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settingsKeys]
  );

  const { mutate: saveSetting } = useSaveSetting();

  useEffect(() => {
    if (sheetType === "vds") {
      saveSetting({
        key: basedOnSubsegmentFilterKey,
        content: basedOnSubsegmentFilter,
      });
    }
  }, [
    basedOnSubsegmentFilter,
    basedOnSubsegmentFilterKey,
    saveSetting,
    sheetType,
  ]);

  const dispatch = useAppDispatch();
  const sourcePlant = useAppSelector(selectSourcePlant);
  const mainPlant = useAppSelector(selectMainPlant);

  const [containerRef, containerSize] = useMeasure();

  const history = useHistory();

  // availableStatuses contains the statuses for this sheetType and displayType
  const availableStatuses = useMemo<SheetStatus[]>(
    () =>
      isPCSWorkbenchCommand
        ? ["W"]
        : isVDSWorkbenchCommand
        ? ["O", "W"]
        : isCopyModal
        ? sheetType === "sc"
          ? ["O"]
          : ["O", "W", "I"]
        : sheetStatuses,
    [
      isPCSWorkbenchCommand,
      isVDSWorkbenchCommand,
      isCopyModal,
      sheetType,
      sheetStatuses,
    ]
  );

  const defaultStatusFilter = useMemo<SheetStatus[]>(
    () =>
      availableStatuses.includes("I")
        ? ["O", "W", "I"]
        : availableStatuses.includes("O")
        ? ["O", "W"]
        : ["W"],
    [availableStatuses]
  );

  const [statusFilter, setStatusFilter] = useState(defaultStatusFilter);

  const extraParams: sheetsExtraParams = useMemo(
    () => ({
      statuses: sheetType === "vds" ? statusFilter : availableStatuses,
      ...(isCopyModal && ["sc", "vsm"].includes(sheetType)
        ? { freeformatFilter: "F" as "F" }
        : {}),
      ...(sheetType === "vds"
        ? { basedOnSubsegment: basedOnSubsegmentFilter }
        : {}),
      ...(pipeElementFilter !== 0 ? { pipeElementFilter } : {}),
      ...(noteFilter !== 0 ? { noteFilter } : {}),
    }),
    [
      statusFilter,
      availableStatuses,
      isCopyModal,
      sheetType,
      basedOnSubsegmentFilter,
      pipeElementFilter,
      noteFilter,
    ]
  );

  const {
    status: mainItemsStatus,
    data: mainItems,
    isSuccess,
    isRefetching,
    error: mainItemsError,
    refetch,
  } = useSheets({
    plant,
    sheetType,
    extraParams,
    disabled: sheetType === "vds" && statusFilter.length === 0,
  });

  const {
    data: mainItemsNames,
    status: mainItemsNamesStatus,
    error: mainItemsNamesError,
  } = useSheetsNames({
    plant: mainPlant,
    sheetType,
    isCopyModal,
  });

  useLayoutEffect(() => {
    filtersDispatch({ type: "reset" });
  }, [basedOnSubsegmentFilter]);

  const [showCreateNew, setShowCreateNew] = useState(false);
  const [newEDSOperation, setNewEDSOperation] =
    useState<NewEDSOperation>(false);
  const [newEDSName, setNewEDSName] = useState("");
  const [newEDSRevision, setNewEDSRevision] = useState("0");
  const [sourceEDSName, setSourceEDSName] = useState("");
  const [sourceEDSRevision, setSourceEDSRevision] = useState("");

  const [selectedItems, setSelectedItems] = useState([]);
  const [nameFilter, setNameFilter] = useState(
    getSetting({
      settings,
      settingKey: settingKey([filterKeySheetType, "nameFilter"]),
    }) ?? ""
  );

  const [displayItems, setDisplayItems] = useState([] as any[]);
  const [modifiedItems, setModifiedItems] = useState([] as any[]);
  const [itemsCopy, setItemsCopy] = useState([] as any);
  const [newNames, setNewNames] = useState({} as any);
  const [currentPlant, setCurrentPlant] = useState(
    unsetPlantState as PlantProps
  );
  const [currentSheetType, setCurrentSheetType] = useState("");
  const [wantToSetStatus, setWantToSetStatus] = useState(
    undefined as MaybeSheetStatus
  );
  const [selectedSheet, setSelectedSheet] = useState({
    name: "",
    revision: "",
  } as SheetProps);
  const { isConfirmed } = useConfirm();

  /*** Filters ***/
  const [filters, filtersDispatch] = useReducer(
    filtersReducer,
    [] as FilterProps[]
  );

  const filterCodelists = filters.map((filter) => filter.codelist);

  const { data: valveTypeCodelist } = useCodelist({
    codelist: "valve-types",
    enabled: filterCodelists.includes("valve-types"),
  });
  const { data: vdsSubsegmentGroupCodelist } = useCodelist({
    codelist: "vds-subsegment-groups",
    enabled: filterCodelists.includes("vds-subsegment-groups"),
  });
  const { data: materialGroupsCodelist } = useCodelist({
    codelist: "material-groups",
    enabled: filterCodelists.includes("material-groups"),
  });
  const { data: endConnectionCodelist } = useCodelist({
    codelist: "end-connections",
    enabled: filterCodelists.includes("end-connections"),
  });
  const { data: ratingClassCodelist } = useCodelist({
    codelist: "rating-classes",
    enabled: filterCodelists.includes("rating-classes"),
  });
  const { data: boreCodelist } = useCodelist({
    codelist: "bores",
    enabled: filterCodelists.includes("bores"),
  });
  const { data: vdsSizeCodelist } = useCodelist({
    codelist: "vds-sizes",
    enabled:
      filterCodelists.includes("vds-sizes") || sheetType === "vds-subsegments",
  });
  const { data: specialRequirementCodelist } = useCodelist({
    codelist: "special-requirements",
    enabled: filterCodelists.includes("special-requirements"),
  });

  const defaultAllSelected = "";
  const { current: defaultEmptyArray } = useRef([]);
  const [groupFilter, setGroupFilter] = useState(defaultAllSelected);
  const [ratingClassFilter, setRatingClassFilter] =
    useState(defaultAllSelected);
  const [materialGroupFilter, setMaterialGroupFilter] =
    useState(defaultAllSelected);
  const [endConnectionFilter, setEndConnectionFilter] =
    useState(defaultAllSelected);
  const [singleValveTypeFilter, setSingleValveTypeFilter] =
    useState(defaultAllSelected);
  const [multiValveTypeFilter, setMultiValveTypeFilter] = useState([]);
  const [boreFilter, setBoreFilter] = useState(defaultAllSelected);
  const [vdsSizeFilter, setVdsSizeFilter] = useState(defaultAllSelected);
  const [specialRequirementFilter, setSpecialRequirementFilter] =
    useState(defaultAllSelected);
  const [itemFilter, setItemFilter] = useState(defaultAllSelected);
  const [sectionFilter, setSectionFilter] = useState(defaultAllSelected);
  const [userFilter, setUserFilter] = useState(defaultAllSelected);

  const valveTypeCodelistFilterItems = useMemo(
    () =>
      valveTypeCodelist
        ? valveTypeCodelist.map((i: any) => ({
            key: i.ValveTypeID,
            title: (
              <>
                <ValveTypeCodeContainer>{i.VDSCode}</ValveTypeCodeContainer>{" "}
                {i.Description}
              </>
            ),
          }))
        : [],
    [valveTypeCodelist]
  );

  const sectionIds = useMemo(
    () =>
      sheetType === "vds-textblocks" && mainItems
        ? (Array.from(
            new Set(mainItems.sheets.map((e: VDSTextBlock) => e.ItemSection))
          ) as string[])
        : [],
    [mainItems, sheetType]
  );

  const itemIds = useMemo(
    () =>
      sheetType === "vds-textblocks" && mainItems
        ? (
            Array.from(
              new Set(
                mainItems.sheets.map((e: VDSTextBlock) => String(e.ItemID))
              )
            ) as string[]
          ).sort((a, b) => a.localeCompare(b, undefined, { numeric: true }))
        : [],
    [mainItems, sheetType]
  );

  const vdsSubsegmentGroupOptions = useMemo(
    () =>
      vdsSubsegmentGroupCodelist
        ? vdsSubsegmentGroupCodelist.map((i: any) => ({
            key: i.GroupID,
            title: i.Description,
          }))
        : [],
    [vdsSubsegmentGroupCodelist]
  );

  const ratingClassOptions = useMemo(
    () =>
      ratingClassCodelist
        ? ratingClassCodelist.map((i: any) => ({
            key: i.RatingClassID,
            title: i.Description,
          }))
        : [],
    [ratingClassCodelist]
  );

  const materialGroupOptions = useMemo(
    () =>
      materialGroupsCodelist
        ? materialGroupsCodelist.map((i: any) => ({
            key: i.MaterialGroupID,
            title: i.MaterialGroup,
          }))
        : [],
    [materialGroupsCodelist]
  );

  const endConnectionOptions = useMemo(
    () =>
      endConnectionCodelist
        ? endConnectionCodelist.map((i: any) => ({
            key: i.EndConnectionID,
            title: `${i.VDSCode} - ${i.Description}`,
          }))
        : [],
    [endConnectionCodelist]
  );

  const valveTypeOptions = useMemo(
    () =>
      valveTypeCodelist
        ? valveTypeCodelist.map((i: any) => ({
            key: i.ValveTypeID,
            title: `${i.VDSCode} - ${i.Description}`,
          }))
        : [],
    [valveTypeCodelist]
  );

  const boreOptions = useMemo(
    () =>
      boreCodelist
        ? boreCodelist.map((i: any) => ({
            key: i.BoreID,
            title: i.Description,
          }))
        : [],
    [boreCodelist]
  );

  const vdsSizeOptions = useMemo(
    () =>
      vdsSizeCodelist
        ? vdsSizeCodelist.map((i: any) => ({
            key: i.VDSSizeID,
            title: i.Description,
          }))
        : [],
    [vdsSizeCodelist]
  );

  const specialRequirementOptions = useMemo(
    () =>
      specialRequirementCodelist
        ? specialRequirementCodelist.map((i: any) => ({
            key: i.SpecialRequirementID,
            title: i.Description,
          }))
        : [],
    [specialRequirementCodelist]
  );

  const userOptions = useMemo(
    () =>
      mainItems &&
      mainItems.sheets
        .map((e: any) => e.LastUpdateBy)
        .reduce(
          (a: string[], c: string) => (a.includes(c) ? a : [...a, c]),
          []
        ),
    [mainItems]
  );

  useEffect(() => {
    if (["vds-subsegments", "vds-textblocks"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Group",
          prop: "GroupID",
          filterOptions: vdsSubsegmentGroupOptions,
          filterState: groupFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setGroupFilter,
          commaSeparated: false,
          group: "Selections",
          codelist: "vds-subsegment-groups",
        },
      });
    }

    if (["vds-textblocks"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Section",
          prop: "ItemSection",
          filterOptions: sectionIds,
          filterState: sectionFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setSectionFilter,
          commaSeparated: false,
          group: "Selections",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Item",
          prop: "ItemID",
          filterOptions: itemIds,
          filterState: itemFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setItemFilter,
          commaSeparated: false,
          group: "Selections",
        },
      });
    }

    if (["vds-subsegments"].includes(sheetType)) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Rating Class",
          prop: "RatingClass",
          filterOptions: ratingClassOptions,
          filterState: ratingClassFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setRatingClassFilter,
          commaSeparated: true,
          group: "Selections",
          codelist: "rating-classes",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Material Group",
          prop: "MaterialGroup",
          filterOptions: materialGroupOptions,
          filterState: materialGroupFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setMaterialGroupFilter,
          commaSeparated: true,
          group: "Selections",
          codelist: "material-groups",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "End Connection",
          prop: "EndConnection",
          filterOptions: endConnectionOptions,
          filterState: endConnectionFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setEndConnectionFilter,
          commaSeparated: true,
          group: "Selections",
          codelist: "end-connections",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "multiselect",
          title: "Valve Types",
          prop: "ValveType",
          filterOptions: valveTypeCodelistFilterItems,
          filterState: multiValveTypeFilter,
          defaultFilterState: defaultEmptyArray,
          setFilterState: setMultiValveTypeFilter,
          commaSeparated: true,
          group: "Valve Types",
          codelist: "valve-types",

          isArray: true,
        },
      });
    }

    if (sheetType === "vds" && basedOnSubsegmentFilter === "Y") {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Valve Type",
          prop: "ValveTypeID",
          filterOptions: valveTypeOptions,
          filterState: singleValveTypeFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setSingleValveTypeFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "valve-types",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Rating Class",
          prop: "RatingClassID",
          filterOptions: ratingClassOptions,
          filterState: ratingClassFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setRatingClassFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "rating-classes",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Material Group",
          prop: "MaterialGroupID",
          filterOptions: materialGroupOptions,
          filterState: materialGroupFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setMaterialGroupFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "material-groups",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "End Connection",
          prop: "EndConnectionID",
          filterOptions: endConnectionOptions,
          filterState: endConnectionFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setEndConnectionFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "end-connections",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Bore",
          prop: "BoreID",
          filterOptions: boreOptions,
          filterState: boreFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setBoreFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "bores",
        },
      });

      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Size",
          prop: "VDSSizeID",
          filterOptions: vdsSizeOptions,
          filterState: vdsSizeFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setVdsSizeFilter,
          commaSeparated: false,
          group: "Valve Characteristics",
          codelist: "vds-sizes",
        },
      });
    }

    if (
      (sheetType === "vds" && basedOnSubsegmentFilter === "Y") ||
      (sheetType === "pcs" && isPCSWorkbenchCommand)
    ) {
      filtersDispatch({
        type: "update",
        payload: {
          type: "select",
          title: "Special Requirement",
          prop: "SpecialReqID",
          filterOptions: specialRequirementOptions,
          filterState: specialRequirementFilter,
          defaultFilterState: defaultAllSelected,
          setFilterState: setSpecialRequirementFilter,
          commaSeparated: false,
          group: "Priority",
          codelist: "special-requirements",
        },
      });
    }
  }, [
    mainItems,
    groupFilter,
    ratingClassFilter,
    materialGroupFilter,
    endConnectionFilter,
    singleValveTypeFilter,
    multiValveTypeFilter,
    boreFilter,
    vdsSubsegmentGroupOptions,
    ratingClassOptions,
    materialGroupOptions,
    valveTypeOptions,
    endConnectionOptions,
    boreOptions,
    vdsSizeFilter,
    vdsSizeOptions,
    specialRequirementOptions,
    specialRequirementFilter,
    basedOnSubsegmentFilter,
    valveTypeCodelistFilterItems,
    isPCSWorkbenchCommand,
    itemIds,
    itemFilter,
    sectionIds,
    sectionFilter,
    defaultEmptyArray,
    sheetType,
  ]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "checkboxes",
        title: "Statuses",
        prop: "Status",
        filterOptions: availableStatuses.map((status) => {
          return {
            key: status,
            title: (
              <FlexContainer>
                <StatusChip>{status}</StatusChip>{" "}
                <span style={{ marginLeft: ".6em" }}>
                  {sheetStatusConfig[status]}
                </span>
              </FlexContainer>
            ),
          };
        }),
        filterState: statusFilter,
        defaultFilterState: defaultStatusFilter,
        setFilterState: setStatusFilter,
        commaSeparated: false,
        group: "Statuses",
        isArray: true,
      },
    });
  }, [
    statusFilter,
    defaultStatusFilter,
    availableStatuses,
    basedOnSubsegmentFilter,
    sheetType,
    settings,
    isCopyModal,
    isPCSWorkbenchCommand,
  ]);

  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Last Update By",
        prop: "LastUpdateBy",
        filterOptions: userOptions,
        filterState: userFilter,
        defaultFilterState: defaultAllSelected,
        setFilterState: setUserFilter,
        commaSeparated: false,
        group: "Users",
      },
    });
  }, [
    availableStatuses,
    basedOnSubsegmentFilter,
    sheetType,
    settings,
    isCopyModal,
    isPCSWorkbenchCommand,
    userFilter,
    userOptions,
  ]);

  const { selectionMode, selection, selectionDispatch } = useTable({
    selectionMode: "multi",
  });

  useEffect(() => {
    // this ensures that filtering only runs when the new states are set including filtersDispatch
    if (sheetType === currentSheetType) {
      setDisplayItems(
        filterItemsByProps({
          filters: filters,
          alwaysPresent: { idProp: "itemID", ids: selection },
          items: filterItemsByName({
            nameFilter,
            nameProperty: filterNameProperty,
            items: mainItems && mainItems.sheets,
            alwaysPresent: { idProp: "itemID", ids: selection },
          }),
        })
      );
    }
    // selection omitted on purpose.
    // eslint-disable-next-line
  }, [
    mainItems,
    filters,
    nameFilter,
    filterNameProperty,
    sheetType,
    currentSheetType,
  ]);

  const { register, formState, trigger } = useForm({
    shouldUnregister: true,
    mode: "all",
  });

  useEffect(() => {
    if (isCopyModal) {
      setTimeout(() => trigger());
    }
  }, [selection, trigger, isCopyModal]);

  const {
    mutate: copySheets,
    status: copySheetsStatus,
    reset: copySheetsReset,
    error: copySheetsError,
    data: copySheetsData,
  } = useCopySheets();
  const {
    mutate: deleteSheets,
    status: deleteSheetsStatus,
    reset: deleteSheetsReset,
    error: deleteSheetsError,
    data: deleteSheetsData,
  } = useDeleteSheets();
  const {
    mutate: newSheetRevision,
    status: newSheetRevisionStatus,
    reset: newSheetRevisionReset,
    error: newSheetRevisionError,
    data: newSheetRevisionData,
  } = useNewSheetRevision();
  const {
    mutate: newSheetRevisions,
    status: newSheetRevisionsStatus,
    reset: newSheetRevisionsReset,
    error: newSheetRevisionsError,
    data: newSheetRevisionsData,
  } = useNewSheetRevisions();
  const {
    mutate: setSheetStatus,
    status: setSheetStatusStatus,
    reset: setSheetStatusReset,
    error: setSheetStatusError,
    data: setSheetStatusData,
  } = useSetSheetStatus();
  const {
    mutate: setMultipleSheetStatus,
    status: setMultipleSheetStatusStatus,
    reset: setMultipleSheetStatusReset,
    error: setMultipleSheetStatusError,
    data: setMultipleSheetStatusData,
  } = useSetMultipleSheetStatus();
  const {
    mutate: createEDS,
    status: createEDSStatus,
    reset: createEDSReset,
    error: createEDSError,
    data: createEDSData,
  } = useCreateEDS();
  const {
    mutate: copyVDSTextBlock,
    status: copyVDSTextBlockStatus,
    reset: copyVDSTextBlockReset,
    error: copyVDSTextBlockError,
    data: copyVDSTextBlockData,
  } = useCopyVDSTextBlock();

  useEffect(() => {
    const allLocalMutationStatuses = [
      copySheetsStatus,
      deleteSheetsStatus,
      newSheetRevisionStatus,
      newSheetRevisionsStatus,
      setSheetStatusStatus,
      createEDSStatus,
      setMultipleSheetStatusStatus,
      copyVDSTextBlockStatus,
    ];
    if (isCopyModal) {
      dispatch(setModalMutationStatuses(allLocalMutationStatuses));
    } else {
      dispatch(setMutationStatuses(allLocalMutationStatuses));
    }
    return () => {
      if (isCopyModal) {
        dispatch(setModalMutationStatuses([]));
      } else {
        dispatch(setMutationStatuses([]));
      }
    };
  }, [
    copySheetsStatus,
    deleteSheetsStatus,
    newSheetRevisionStatus,
    newSheetRevisionsStatus,
    setSheetStatusStatus,
    createEDSStatus,
    dispatch,
    isCopyModal,
    setMultipleSheetStatusStatus,
    copyVDSTextBlockStatus,
  ]);

  useEffect(() => {
    setSelectedItems(
      mainItems
        ? mainItems.sheets.filter((i: any) => selection.includes(i.itemID))
        : []
    );
  }, [selection, mainItems]);

  useEffect(() => {
    dispatch(setSheetType(sheetType));
    setCurrentSheetType(sheetType);
  }, [sheetType, dispatch]);

  useEffect(() => {
    // when the plant is changed, cleanup is needed
    if (currentPlant.PlantID !== plant.PlantID && mainItems) {
      // reset new names
      if (isCopyModal) {
        setNewNames(
          mainItems.sheets.reduce(
            (acc: any, curr: any) => ({
              ...acc,
              [curr.itemID]: curr[sheetTypeName],
            }),
            {}
          )
        );
      }
      // set plant finally after settings reset
      setCurrentPlant(plant);
    }
  }, [isCopyModal, plant, isSuccess, mainItems, sheetTypeName, currentPlant]);

  useEffect(() => {
    setNameFilter(
      getSetting({
        settings,
        settingKey: settingKey([filterKeySheetType, "nameFilter"]),
      }) ?? ""
    );
  }, [filterKeySheetType, settings, sheetType]);

  const allMutationStatuses = useMemo(
    () => [...mutationStatuses, ...modalMutationStatuses],
    [modalMutationStatuses, mutationStatuses]
  );
  const isAfterMutation = allMutationStatuses.includes("success");
  const isAllMutationIdle =
    allMutationStatuses.includes("idle") &&
    new Set(allMutationStatuses).size === 1;

  const [updatedItemsExpected, setUpdatedItemsExpected] = useState(false);

  useEffect(() => {
    isAfterMutation && setUpdatedItemsExpected(true);
    !isAfterMutation && !isRefetching && setUpdatedItemsExpected(false);
  }, [isAfterMutation, isRefetching]);

  useEffect(() => {
    if (updatedItemsExpected) {
      if (
        mainItems &&
        Array.isArray(mainItems.sheets) &&
        !areArraysOfObjectsIdentical(mainItems.sheets, itemsCopy.sheets)
      ) {
        const newLines = mainItems.sheets
          .map((mi) => mi.itemID)
          .filter(
            (mid) => !itemsCopy.sheets.map((ic: any) => ic.itemID).includes(mid)
          );
        const newStatuses = mainItems.sheets
          .filter(
            (mi) =>
              itemsCopy.sheets.filter((ic: any) => ic.itemID === mi.itemID)
                .length > 0 &&
              itemsCopy.sheets.find((ic: any) => ic.itemID === mi.itemID)
                ?.Status !== mi?.Status
          )
          .map((i) => i.itemID);
        setModifiedItems([
          ...modifiedItems,
          ...newLines.map((i) => {
            return { itemID: i, element: "_line" };
          }),
          ...newStatuses.map((i) => {
            return { itemID: i, element: "Status" };
          }),
        ]);
      }
    }
    if (mainItemsStatus === "success") {
      setItemsCopy(mainItems);
    }
  }, [
    mainItemsStatus,
    mainItems,
    modifiedItems,
    updatedItemsExpected,
    itemsCopy,
  ]);

  useEffect(() => {
    if (isAllMutationIdle && modifiedItems.length > 0) {
      const nowModified = [...modifiedItems];
      const timeout = setTimeout(() => {
        setModifiedItems(
          modifiedItems.filter(
            (i) =>
              !nowModified.map(
                (nm) => nm.itemID === i.itemID && nm.element === i.element
              )
          )
        );
      }, 5000);
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [modifiedItems, isAllMutationIdle]);

  useEffect(
    () => {
      selectionDispatch({ type: "reset", payload: [] });
    },
    // eslint-disable-next-line
    [plant]
  );

  const [panelRef, panelSize] = useMeasure();

  const codelists = useMemo(
    () => ({
      "vds-section": { Y: "New Layout", N: "Old Layout" },
      "tube-pcs": { M: "Metric", I: "Imperal" },
      "vds-subsegment-groups": vdsSubsegmentGroupCodelist
        ? Object.fromEntries(
            vdsSubsegmentGroupCodelist.map((i) => [i.GroupID, i.Description])
          )
        : {},
      "valve-types": valveTypeCodelist
        ? Object.fromEntries(
            valveTypeCodelist.map((i) => [i.ValveTypeID, i.VDSCode])
          )
        : {},
      "material-groups": materialGroupsCodelist
        ? Object.fromEntries(
            materialGroupsCodelist.map((i) => [i.MaterialGroupID, i.VDSCode1])
          )
        : {},
      "end-connections": endConnectionCodelist
        ? Object.fromEntries(
            endConnectionCodelist.map((i) => [i.EndConnectionID, i.VDSCode])
          )
        : {},
      "rating-classes": ratingClassCodelist
        ? Object.fromEntries(
            ratingClassCodelist.map((i) => [i.RatingClassID, i.Description])
          )
        : {},
      bores: boreCodelist
        ? Object.fromEntries(boreCodelist.map((i) => [i.BoreID, i.Description]))
        : {},
      "vds-sizes": vdsSizeCodelist
        ? Object.fromEntries(
            vdsSizeCodelist.map((i) => [i.VDSSizeID, i.Description])
          )
        : {},
      "special-requirements": specialRequirementCodelist
        ? Object.fromEntries(
            specialRequirementCodelist.map((i) => [
              i.SpecialRequirementID,
              i.Description,
            ])
          )
        : {},
    }),
    [
      boreCodelist,
      endConnectionCodelist,
      materialGroupsCodelist,
      ratingClassCodelist,
      specialRequirementCodelist,
      valveTypeCodelist,
      vdsSizeCodelist,
      vdsSubsegmentGroupCodelist,
    ]
  );

  console.log(isVDSWorkbenchCommand, sheetCommand, panelSize);

  return plantDependentSheets.includes(sheetType) && mainPlant.PlantID === 0 ? (
    <ContentMessage>Select a plant.</ContentMessage>
  ) : (
    <AbsoluteFlexContainer ref={containerRef}>
      <FlexContentElement>
        <Space.Fill>
          <Space.Fill>
            {isPCSWorkbenchCommand && (
              <Space.Top size={panelSize.height}>
                {sheetCommand === "references" && (
                  <ReferencePanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "pipe-elements" && (
                  <PipeElementsPanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "standard-notes" && (
                  <StandardNotesPanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
                {sheetCommand === "properties" && (
                  <PropertiesPanel
                    ref={panelRef}
                    selectedItems={selectedItems}
                  />
                )}
              </Space.Top>
            )}
            {isVDSWorkbenchCommand && sheetCommand && (
              <Space.Top size={panelSize.height}>
                <VDSPanel
                  ref={panelRef}
                  selectedItems={selectedItems}
                  sheetCommand={sheetCommand}
                />
              </Space.Top>
            )}
            <Space.Fill>
              <Table
                error={mainItemsError || mainItemsNamesError}
                totalCount={
                  sheetType === "vds" && mainItems
                    ? mainItems.totalCount
                    : mainItems
                    ? mainItems.sheets.length
                    : 0
                }
                infoline={true}
                items={displayItems}
                itemIdProp="itemID"
                controlHeader={
                  <SheetControlHeader
                    plant={plant}
                    mainPlant={mainPlant}
                    isCopyModal={isCopyModal}
                    sheetType={sheetType}
                    selectedItems={selectedItems}
                    deleteSelected={deleteSelected}
                    deleteSheets={deleteSheets}
                    hasName={hasName}
                    sheetTypeName={sheetTypeName}
                    nameProperty={nameProperty}
                    nameFilter={nameFilter}
                    setNameFilter={setNameFilter}
                    isConfirmed={isConfirmed}
                    newSheetRevisions={newSheetRevisions}
                    setMultipleSheetStatus={setMultipleSheetStatus}
                    filters={filters}
                    mainItemsSheets={mainItems ? mainItems.sheets : []}
                    filteredItems={displayItems}
                    columns={columns}
                    refetch={refetch}
                    isRefetching={isRefetching}
                    dispatch={dispatch}
                    filterPlaceholder={
                      filterPlaceholder
                        ? filterPlaceholder
                        : `${sheetTypeName} filter`
                    }
                    containerSize={containerSize}
                    basedOnSubsegmentFilter={basedOnSubsegmentFilter}
                    setBasedOnSubsegmentFilter={setBasedOnSubsegmentFilter}
                    setShowCreateNew={setShowCreateNew}
                    setNewEDSOperation={setNewEDSOperation}
                    isWorkbenchCommand={isPCSWorkbenchCommand}
                    copyVDSTextBlock={copyVDSTextBlock}
                    filterKey={filterKey}
                    filterKeySheetType={filterKeySheetType}
                  />
                }
                columns={columns}
                codelists={codelists}
                contextData={{
                  plant,
                  dispatch,
                  sheetType,
                  isCopyModal,
                  deleteSingle,
                  newSheetRevision,
                  newSheetRevisions,
                  setSheetStatus,
                  setMultipleSheetStatus,
                  extraParams,
                  hasName,
                  sheetTypeName,
                  nameProperty,
                  mainPlant,
                  deleteSheets,
                  isConfirmed,
                  newNames,
                  setNewNames,
                  trigger,
                  mainItemsNames,
                  register,
                  formState,
                  selectedItems,
                  setWantToSetStatus,
                  setSelectedSheet,
                  copyVDSTextBlock,
                  setNewEDSOperation,
                  setSourceEDSName,
                  setSourceEDSRevision,
                }}
                status={combineQueryStatuses(
                  mainItemsStatus,
                  mainItemsNamesStatus
                )}
                loadingString={`Loading ${sheetTypeName}s...`}
                selectionMode={selectionMode}
                selection={selection}
                selectionDispatch={selectionDispatch}
                sortable={true}
                fullRowSelect={false}
                resizable={true}
                RowMenu={ItemMenu}
                Commands={[EditCommandButton, PreviewInPaneCommandButton]}
                itemsClassNames={modifiedItems.map((e) => ({
                  ...e,
                  className: "_newData",
                }))}
                refetch={refetch}
              />
              <StatusModal
                status={deleteSheetsStatus}
                successMessage={successMessage(deleteSheetsData)}
                onSettledClose={deleteSheetsReset}
                error={deleteSheetsError}
              />
              <StatusModal
                status={newSheetRevisionStatus}
                successMessage={successMessage(newSheetRevisionData)}
                onSettledClose={newSheetRevisionReset}
                error={newSheetRevisionError}
              />
              <StatusModal
                status={newSheetRevisionsStatus}
                successMessage={successMessage(newSheetRevisionsData)}
                onSettledClose={newSheetRevisionsReset}
                error={newSheetRevisionsError}
              />
              <StatusModal
                status={setSheetStatusStatus}
                successMessage={successMessage(setSheetStatusData)}
                onSettledClose={setSheetStatusReset}
                error={setSheetStatusError}
              />
              <StatusModal
                status={setMultipleSheetStatusStatus}
                successMessage={successMessage(setMultipleSheetStatusData)}
                onSettledClose={setMultipleSheetStatusReset}
                error={setMultipleSheetStatusError}
              />
              <StatusModal
                status={copySheetsStatus}
                successMessage={successMessage(copySheetsData)}
                onSettledClose={copySheetsReset}
                onSuccessClose={() => {
                  dispatch(hideSheetCopyModal());
                }}
                error={copySheetsError}
              />
              <StatusModal
                status={createEDSStatus}
                successMessage={successMessage(createEDSData)}
                onSettledClose={createEDSReset}
                onSuccess={() => {
                  createEDSReset();
                  editSheet({
                    item: { EDS: newEDSName, Revision: newEDSRevision },
                    history,
                    sheetType: "eds",
                  });
                }}
                error={createEDSError}
              />
              <StatusModal
                status={copyVDSTextBlockStatus}
                successMessage={successMessage(copyVDSTextBlockData)}
                onSettledClose={() => {
                  copyVDSTextBlockReset();
                  copyVDSTextBlockData &&
                    editSheet({
                      item: {
                        TextBlockID: copyVDSTextBlockData?.data.AdditionalInfo,
                        Revision: 0,
                      },
                      history,
                      sheetType: "vds-textblocks",
                    });
                }}
                error={copyVDSTextBlockError}
              />
              {parsedSearch.get("edit") &&
                ((textualSheets.includes(sheetType) && (
                  <EditHTMLModal
                    name={parsedSearch.get("edit") || ""}
                    revision={parsedSearch.get("rev") || ""}
                    sheetType={sheetType}
                  />
                )) ||
                  (sheetType === "pcs" && (
                    <EditPCSModal
                      name={parsedSearch.get("edit") || ""}
                      revision={parsedSearch.get("rev") || ""}
                      sheetType={sheetType}
                    />
                  )) ||
                  (sheetType === "vds-textblocks" && (
                    <>
                      <EditVDSTextBlock
                        name={parsedSearch.get("edit") || ""}
                        revision={parsedSearch.get("rev") || ""}
                        tab={parsedSearch.get("tab") || ""}
                      />
                      <VDSTextBlockPropertiesModal
                        name={parsedSearch.get("edit") || ""}
                        revision={parsedSearch.get("rev") || ""}
                        tab={parsedSearch.get("tab") || ""}
                      />
                      <EditVDSTextBlockDescription
                        name={parsedSearch.get("edit") || ""}
                        revision={parsedSearch.get("rev") || ""}
                        tab={parsedSearch.get("tab") || ""}
                      />
                    </>
                  )) ||
                  (sheetType === "vds-subsegments" && (
                    <>
                      <VDSSubsegmentPropertiesModal
                        name={parsedSearch.get("edit") || ""}
                        revision={parsedSearch.get("rev") || ""}
                        tab={parsedSearch.get("tab") || ""}
                      />
                    </>
                  )))}
              {wantToSetStatus && (
                <SetStatus
                  name={selectedSheet.name}
                  revision={selectedSheet.revision}
                  wantToSetStatus={wantToSetStatus}
                  setWantToSetStatus={setWantToSetStatus}
                  setSheetStatus={setSheetStatus}
                  sheetType={sheetType}
                />
              )}
            </Space.Fill>
          </Space.Fill>
          {showVDSTaskMonitor &&
            (
              ["vds", "vds-textblocks", "vds-subsegments"] as SheetTypes[]
            ).includes(sheetType) && (
              <Space.BottomResizable size={200}>
                <VDSTaskMonitor />
              </Space.BottomResizable>
            )}
          {(isCopyModal ? paneModes.copyModal : paneModes.main) && (
            <Space.RightResizable
              size={
                containerSize.width > sheetDocumentWidth + minTableWidth
                  ? sheetDocumentWidth
                  : "50%"
              }
              maximumSize={containerSize.width - minTableWidth}
            >
              <SheetPane location={isCopyModal ? "copyModal" : "main"} />
            </Space.RightResizable>
          )}
        </Space.Fill>
      </FlexContentElement>
      {isCopyModal ? (
        <FlexHeaderElement
          style={{
            textAlign: "center",
            borderTop: "1px solid var(--borderDefault)",
          }}
        >
          <BottomButtonContainer>
            <Button
              disabled={
                selection.length === 0 ||
                Object.keys(formState.errors).length > 0
              }
              onClick={() =>
                copySelected({
                  selectedItems,
                  newRevision,
                  hasName,
                  sheetTypeName,
                  newNames,
                  sourcePlant,
                  mainPlant,
                  copySheets,
                  sheetType,
                  isConfirmed,
                })
              }
            >
              Copy selected data sheets
            </Button>
          </BottomButtonContainer>
        </FlexHeaderElement>
      ) : (
        <></>
      )}
      {sheetType === "eds" && (
        <CreateEDS
          newEDSOperation={newEDSOperation}
          setNewEDSOperation={setNewEDSOperation}
          createEDS={createEDS}
          newEDSName={newEDSName}
          setNewEDSName={setNewEDSName}
          newEDSRevision={newEDSRevision}
          setNewEDSRevision={setNewEDSRevision}
          sourceEDSName={sourceEDSName}
          sourceEDSRevision={sourceEDSRevision}
        />
      )}
      {sheetType === "vds-textblocks" && (
        <NewVDSTextBlock
          showCreateNew={showCreateNew}
          setShowCreateNew={setShowCreateNew}
          groupFilter={groupFilter}
        />
      )}
    </AbsoluteFlexContainer>
  );
}
