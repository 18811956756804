// mostly code from reactjs.org/docs/error-boundaries.html
import {
  AppTitle,
  AppTitleFullContainer,
} from "components/AppDisplayComponents";
import { FullPageContainer } from "components/Components";
import { Component, ErrorInfo, ReactNode } from "react";

interface StateType {
  hasError: boolean;
  errorMessage: string;
}

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: "",
  };

  static getDerivedStateFromError(): StateType {
    return {
      hasError: true,
      errorMessage: "",
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error("ErrorBoundary caught an error", error, info);
    this.setState({ errorMessage: error.message });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <FullPageContainer>
          <AppTitleFullContainer>
            <AppTitle />
          </AppTitleFullContainer>
          <h2>Sorry. There's an error.</h2>
          <p>{this.state.errorMessage}</p>
        </FullPageContainer>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
