import { sheetProperties } from "./../features/sheets/config/sheetConfig";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "./queryUtil";
import {
  plantDependentSheets,
  SheetTypes,
} from "../features/sheets/config/sheetConfig";
import { SheetStatus } from "../features/sheets/config/statusConfig";
import { PlantProps } from "../features/plant/types";

export default function useSetMultipleSheetStatus() {
  const queryClient = useQueryClient();

  async function setMultipleSheetStatus({
    plant,
    sheetType,
    status,
    sheets,
  }: {
    plant?: PlantProps;
    sheetType: SheetTypes;
    status: SheetStatus;
    sheets: { name: string; revision: string }[];
  }) {
    const { sheetListProperty, nameProperty } = sheetProperties[sheetType];
    return await axios(
      getSheetApiUrl({ plant, sheetType, postfix: "status" }),
      {
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: {
          Status: status,
          [sheetListProperty ? sheetListProperty : "sheets"]: sheets.map(
            (e) => ({
              [nameProperty]: e.name,
              Revision: e.revision,
            })
          ),
        },
      }
    );
  }

  return useMutation(setMultipleSheetStatus, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType) &&
        variables.plant
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type SetMultipleSheetStatus = ReturnType<
  typeof useSetMultipleSheetStatus
>;
