import { useLocation } from "react-router-dom";
import { ReportFormHeader } from "../ReportFormHeader";
import { VDSTextblockSearchData } from "./vdsTextblockSearchConfig";
import { useMemo } from "react";
import { NativeSelect, TextField, Typography } from "@equinor/eds-core-react";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../ReportComponents";
import { FlexContainer, FlexElement } from "components/Components";

export const vdsTextblockSearchColumns: {
  key: keyof VDSTextblockSearchData[0];
  title: string;
}[] = [
  { key: "VDS", title: "VDS" },

  { key: "Revision", title: "Revision" },
  { key: "Status", title: "Status" },
];

export function VDSTextblockSearchReport({
  data,
}: {
  data: VDSTextblockSearchData | undefined;
}) {
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);

  return (
    <>
      <ReportFormHeader>
        <FlexContainer style={{ justifyContent: "flex-start", gap: 16 }}>
          <FlexElement>
            <TextField
              defaultValue={parsedSearch.get("textBlockID") || ""}
              label="Textblock ID"
              id="textBlockID"
            />
          </FlexElement>
          <FlexElement>
            <NativeSelect
              id="section"
              label="Section"
              defaultValue={parsedSearch.get("section") || ""}
            >
              <option value="D">Design</option>
              <option value="M">Material</option>
              <option value="R">Misc. Req.</option>
            </NativeSelect>
          </FlexElement>
        </FlexContainer>
      </ReportFormHeader>
      {data &&
        (data.length === 0 ? (
          <Typography>No result.</Typography>
        ) : (
          <ReportTable>
            <TableHead>
              <TableRow>
                {vdsTextblockSearchColumns.map((col) => (
                  <HeadCell key={col.key}>{col.title}</HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row) => (
                <TableRow key={JSON.stringify(row)}>
                  {vdsTextblockSearchColumns.map((col) => (
                    <ContentCell key={col.key}>{row[col.key]}</ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </ReportTable>
        ))}
    </>
  );
}
