import { MaterialRatingGroupsData } from "./materialRatingGroupsConfig";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../ReportComponents";

export const exportMaterialRatingGroups = (data: MaterialRatingGroupsData) => ({
  columns: [
    { title: "Material Group", key: "MaterialGroup" },
    { title: "Rating Group", key: "RatingGroup" },
  ],
  items: data,
});

export function MaterialRatingGroupsReport({
  data,
}: {
  data: MaterialRatingGroupsData;
}) {
  return (
    <ReportTable>
      <TableHead>
        <TableRow>
          <HeadCell>Material Group</HeadCell>
          <HeadCell>Rating Group</HeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((d) => (
          <TableRow key={d.MaterialGroup}>
            <ContentCell>{d.MaterialGroup}</ContentCell>
            <ContentCell>{d.RatingGroup}</ContentCell>
          </TableRow>
        ))}
      </TableBody>
    </ReportTable>
  );
}
