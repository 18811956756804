import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "./queryUtil";
import { PlantProps } from "../features/plant/types";

export default function useValidatePCS() {
  const queryClient = useQueryClient();

  async function validatePCS({
    plant,
    name,
    revision,
  }: {
    plant: PlantProps;
    name: string;
    revision: string;
  }) {
    return await axios(
      getSheetApiUrl({
        plant,
        sheetType: "pcs",
        name,
        revision,
        postfix: "validation-logs",
      }),
      {
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      }
    );
  }

  return useMutation(validatePCS, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheet-validation",
        variables.plant.PlantID,
        "pcs",
        variables.name,
        variables.revision,
        "validation-logs",
      ]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
