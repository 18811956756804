import { Editor } from "@tinymce/tinymce-react";
import config from "../../../app/config";
import { appProps } from "app/props";
import React from "react";
import { Editor as TinyMCEEditor } from "tinymce";

function prepareHtml(content: string) {
  return (
    content
      // remove conditional comments
      .replaceAll(/<!\[if !([a-zA-Z]*)\]>/g, "")
      .replaceAll("<![endif]>", "")
  );
}

export default function HTMLEditor({
  content,
  editorRef,
  setInitialContent,
}: {
  content: string;
  editorRef: React.MutableRefObject<TinyMCEEditor | null>;
  setInitialContent?: React.Dispatch<string>;
}) {
  return (
    <>
      <Editor
        apiKey={config.tinyApiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={prepareHtml(content)}
        init={{
          height: "100%",
          width: "100%",
          menubar: "edit insert view format table tools help",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | code | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          content_css: [
            appProps.appBaseUrl + appProps.appBasePath + "styles/sheet.css",
            "document",
          ],
          link_context_toolbar: true,
          document_base_url: config.documentBaseUrl,
          setup: function (editor) {
            editor.on("init", function () {
              setInitialContent && setInitialContent(editor.getContent());
            });
          },
          convert_urls: false,
          object_resizing: false,
        }}
      />
    </>
  );
}
