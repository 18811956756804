import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "./queryUtil";
import { PlantProps } from "../features/plant/types";
import {
  PCSReferenceSheetTypes,
  nameProperties,
} from "../features/sheets/config/sheetConfig";

export default function useChangePCSReference() {
  const queryClient = useQueryClient();

  async function changePCSReference({
    plant,
    sheets,
    referenceSheetType,
    referenceSheet,
    elementID,
    fromSize,
    toSize,
  }: {
    plant: PlantProps;
    sheets: any[];
    referenceSheetType: PCSReferenceSheetTypes;
    referenceSheet: string;
    elementID?: string;
    fromSize?: string;
    toSize?: string;
  }) {
    return await axios(
      getSheetApiUrl({
        plant,
        sheetType: "pcs",
        command: `-${referenceSheetType}`,
      }),
      {
        method: referenceSheetType === "vds" ? "post" : "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: {
          sheets,
          [nameProperties[referenceSheetType]]: referenceSheet,
          ...(elementID && { ElementID: elementID }),
          ...(fromSize && { FromSize: fromSize }),
          ...(toSize && { ToSize: toSize }),
        },
      }
    );
  }

  return useMutation(changePCSReference, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sheets", variables.plant.PlantID, "pcs"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
