import { useQuery, useQueryClient } from "react-query";
import axios, { AxiosError } from "axios";
import { getApiUrl } from "../queryUtil";

type SettingsReply = { getUserSettings: { Key: string; Value: string }[] };

export type Settings = { [k: string]: string | null };

export function useAllSettings() {
  const queryClient = useQueryClient();
  return useQuery<SettingsReply, AxiosError<Error>>(
    ["settings"],
    async () => {
      const { data } = await axios.get<SettingsReply>(
        getApiUrl("users/settings/key")
      );

      data.getUserSettings.forEach((e) => {
        queryClient.setQueryData(["settings", e.Key], e.Value);
      });
      return data;
    },
    {
      staleTime: Infinity,
    }
  );
}
