import React from "react";
import { SheetCommands } from "../types";

export const VDSPanel = React.forwardRef(
  (
    {
      selectedItems,
      sheetCommand,
    }: {
      selectedItems: any[];
      sheetCommand: SheetCommands;
    },
    ref: React.Ref<any>
  ) => {
    return <div ref={ref}>{sheetCommand}</div>;
  }
);
