import { QueryStatus } from "react-query";
import config from "../app/config";
import {
  SheetTypes,
  plantDependentSheets,
} from "../features/sheets/config/sheetConfig";
import { PlantProps } from "../features/plant/types";

export interface OperationReplyProps {
  success: boolean;
  UserMessage: string;
  SystemMessage: string;
}

export type EditMethods = "post" | "put" | "delete";

interface SheetAPIProps {
  plant?: PlantProps;
  sheetType: SheetTypes;
  name?: string;
  revision?: string;
  postfix?: string | string[];
  command?: string;
}

export function getSheetApiUrl({
  plant,
  sheetType,
  name,
  revision,
  postfix,
  command,
}: SheetAPIProps) {
  let url = config.restApiBaseUrl;
  if (plant && plantDependentSheets.includes(sheetType)) {
    url += `plants/${plant.PlantID}/${sheetType}`;
  } else {
    url += sheetType;
  }
  if (command) {
    url += command;
  }
  let urlParts = [];
  if (name) {
    if (sheetType !== "general") {
      urlParts.push(encodeURIComponent(name));
    }
  }
  if (revision) {
    urlParts.push("rev", revision);
  }
  if (postfix) {
    typeof postfix === "string"
      ? urlParts.push(postfix)
      : Array.isArray(postfix) && urlParts.push(...postfix);
  }
  if (urlParts.length) {
    url += `/${urlParts.join("/")}`;
  }
  return url;
}

export function getApiUrl(path: string) {
  return `${config.restApiBaseUrl}${path}`;
}

const statusOrder: QueryStatus[] = ["idle", "success", "loading", "error"];

export function combineQueryStatuses(...statuses: QueryStatus[]): QueryStatus {
  return statuses.reduce(
    (acc, curr) =>
      statusOrder.indexOf(curr) > statusOrder.indexOf(acc) ? curr : acc,
    "" as QueryStatus
  );
}
