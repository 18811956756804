export const confirmNavigation = async ({
  isConfirmed,
}: {
  isConfirmed: (prompt: React.ReactFragment) => Promise<boolean>;
}): Promise<boolean> => {
  return await isConfirmed(
    <>
      Unsaved changes will be lost when navigating away. Are you sure you want
      to leave?
    </>
  );
};
