import React, { useLayoutEffect, useState, useContext, useEffect } from "react";
import {
  Button,
  Icon,
  Input,
  Label,
  Tooltip,
  IconProps,
  EdsProvider,
  TextField,
  Radio,
  Table as EDSTable,
  Checkbox,
} from "@equinor/eds-core-react";
import {
  FlexContainer,
  FlexElement,
  RevisionMark,
  FlexFormContainer,
} from "../Components";
import { comment, comment_chat } from "@equinor/eds-icons";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { ModalButtonContainer, ModalContentContainer } from "../Modal";
import { ModalWindow } from "components/ModalWindow";
import useStandardNotes, {
  NoteSections,
} from "../../features/sheets/hooks/useStandardNotes";
import { useMemoOne as useMemo } from "use-memo-one";
import { FormRow, FormRowBlock } from "./Form";
import { useCodelist } from "queries/useCodelist";
import { FieldContext } from "./FieldContext";
import Table, { SmallTableContainer } from "components/table/Table";
import useTable from "components/table/useTable";
import { QueryStatus } from "react-query";

export type Pagebreak = "P" | "N";

export const createId = (label: string) => `id-${label.replace(" ", "-")}`;

export const RevCont = styled.div`
  align-self: flex-end;
  min-width: 30px;
  padding-bottom: 6px;
  padding-right: 1em;
`;

export const InpCont = styled.div`
  min-width: 50%;
  flex-grow: 1;
`;

export const NoteCont = styled.div`
  margin-left: auto;
  padding-left: 1em;
`;

export const SeriesContainer = styled.div`
  display: flex;
  > div {
    margin: 0 4px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

function NoteModal({
  note,
  noteProp,
  noteEditorOpen,
  setNoteEditorOpen,
  title,
  section,
}: {
  note: string;
  noteProp: string;
  noteEditorOpen: boolean;
  setNoteEditorOpen: Function;
  title?: string;
  section: NoteSections;
}) {
  const noteArr = useMemo(() => (note ? note.split(",") : []), [note]);
  const { setValue } = useFormContext();
  const { standardNotesContent, selection } = useStandardNotes({
    noteSelectionMode: "multi",
    section,
    defaultSelection: noteArr,
  });

  const closeModal = () => {
    setNoteEditorOpen(false);
  };

  return (
    <ModalWindow isOpen={noteEditorOpen} closeModal={closeModal} title={title}>
      <ModalContentContainer
        style={{ marginTop: 0, marginBottom: 0, minWidth: 600 }}
      >
        {standardNotesContent}
        <ModalButtonContainer>
          <EdsProvider density="comfortable">
            <Button
              onClick={() => {
                setValue(noteProp, selection.join(), { shouldDirty: true });
                setNoteEditorOpen(false);
              }}
            >
              Set
            </Button>
            <Button variant="outlined" onClick={() => setNoteEditorOpen(false)}>
              Close
            </Button>
          </EdsProvider>
        </ModalButtonContainer>
      </ModalContentContainer>
    </ModalWindow>
  );
}
function NoteButtonContent({
  noteProp,
  title,
  size,
  section,
}: {
  noteProp: string;
  title?: string;
  size?: IconProps["size"];
  section: NoteSections;
}) {
  const { getValues } = useFormContext();
  const [noteEditorOpen, setNoteEditorOpen] = useState(false);
  const currentNote = getValues(noteProp);
  const { data } = useCodelist({
    codelist: "pcs-standard-notes",
    extraParams: { notefilter: section },
  });

  return (
    <>
      <Button
        variant="ghost_icon"
        onClick={() => setNoteEditorOpen(true)}
        title={
          !currentNote
            ? "No notes."
            : currentNote
                .split(",")
                .map((noteId: string) => {
                  const noteElement = data?.find(
                    (note: any) => note.NoteID === Number(noteId)
                  );
                  return `${noteId}: ${
                    noteElement ? noteElement.NoteText : "(text?)"
                  }`;
                })
                .join("\n")
        }
      >
        <Icon
          data={currentNote && currentNote !== "0" ? comment_chat : comment}
          size={size}
        />
      </Button>
      {noteEditorOpen && (
        <NoteModal
          note={currentNote}
          noteProp={noteProp}
          title={title}
          noteEditorOpen={noteEditorOpen}
          setNoteEditorOpen={setNoteEditorOpen}
          section={section}
        />
      )}
    </>
  );
}

export function NoteButton({
  noteProp,
  note,
  title,
  size,
  section,
}: {
  noteProp: string;
  note?: string;
  title?: string;
  size?: IconProps["size"];
  section: NoteSections;
}) {
  const { register, setValue } = useFormContext();

  useLayoutEffect(() => {
    setValue(noteProp, note ? note : "");
  }, [noteProp, note, setValue]);

  return (
    <>
      <input type="hidden" {...register(noteProp)} />
      <NoteButtonContent
        noteProp={noteProp}
        title={title}
        size={size}
        section={section}
      />
    </>
  );
}

export function Note({
  noteProp,
  title,
  note,
}: {
  noteProp: string;
  title?: string;
  note?: string;
}) {
  return (
    <FlexContainer style={{ justifyContent: "flex-end" }}>
      <FlexElement>
        <Label label={`${title ? title : "Note"}`} style={{ marginRight: 2 }} />
      </FlexElement>
      <FlexElement>
        <NoteButton
          noteProp={noteProp}
          title={title}
          note={note}
          section="PCS"
        />
      </FlexElement>
    </FlexContainer>
  );
}

export function Matrix({
  props,
  values,
  titles,
  maxWidth,
  min,
  max,
  revMarks,
  changeIndicators,
}: {
  props: string[][];
  values: string[][];
  titles: string[];
  maxWidth: number;
  min: number[][];
  max: number[][];
  revMarks?: string[];
  changeIndicators?: boolean[][];
}) {
  const verticalProps = props[0].map((_, colIndex) =>
    props.map((row) => row[colIndex])
  );
  const { register, formState } = useFormContext();
  const { disabled } = useContext(FieldContext);

  return (
    <FormRow>
      <FlexContainer vertical>
        <FlexElement style={{ alignSelf: "flex-start", paddingTop: 1 }}>
          <FlexContainer>
            {revMarks && (
              <FlexElement>
                <RevCont />
              </FlexElement>
            )}
            <FlexElement>
              <Label label={titles.join(" / ")} />
            </FlexElement>
          </FlexContainer>
        </FlexElement>
        <FlexContainer style={{ flexWrap: "nowrap" }}>
          {revMarks && (
            <FlexContainer
              vertical
              style={{
                alignSelf: "stretch",
                alignContent: "flex-end",
              }}
            >
              {revMarks.map((revMark, i) => (
                <RevCont
                  key={i}
                  style={{
                    flexGrow: 1,
                    alignContent: "flex-end",
                    display: "flex",
                    alignItems: "flex-end",
                    marginBottom: 4,
                  }}
                >
                  <RevisionMark>{revMark}</RevisionMark>
                </RevCont>
              ))}
            </FlexContainer>
          )}
          <InpCont>
            <FlexContainer
              style={{
                flexWrap: "nowrap",
                maxWidth,
                margin: "-4px -4px 0 -4px",
              }}
            >
              {verticalProps.map((propCol, col) => (
                <FlexContainer vertical key={col}>
                  {propCol.map((prop, row) => (
                    <FlexElement style={{ margin: 4 }} key={row}>
                      <Tooltip
                        title={`${
                          changeIndicators && changeIndicators[row][col]
                            ? "Value changed since last revision."
                            : ""
                        }${
                          formState.errors.hasOwnProperty(prop)
                            ? `Value must be between ${min[row][col]} and ${max[row][col]}`
                            : ""
                        }`}
                        placement={row === 0 ? "top" : "bottom"}
                      >
                        <Input
                          defaultValue={values[row][col]}
                          style={{ minWidth: 55 }}
                          {...register(prop, {
                            min: min[row][col],
                            max: max[row][col],
                          })}
                          className={
                            changeIndicators && changeIndicators[row][col]
                              ? "value-changed"
                              : ""
                          }
                          variant={
                            formState.errors.hasOwnProperty(prop)
                              ? "error"
                              : undefined
                          }
                          disabled={disabled}
                        />
                      </Tooltip>
                    </FlexElement>
                  ))}
                </FlexContainer>
              ))}
            </FlexContainer>
          </InpCont>
        </FlexContainer>
      </FlexContainer>
    </FormRow>
  );
}

export function TextInputRow({
  label,
  value,
  setValue,
  width,
}: {
  label: string;
  value: string;
  setValue: React.Dispatch<string>;
  width?: number | string;
}) {
  const id = createId(label);
  return (
    <FormRow style={{ display: "block" }}>
      <TextField
        label={label}
        id={id}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        style={{ width: width ? width : 300 }}
      />
    </FormRow>
  );
}

export function PagebreakFormElement({
  pagebreak,
  setPagebreak,
}: {
  pagebreak: Pagebreak;
  setPagebreak: React.Dispatch<Pagebreak>;
}) {
  return (
    <FormRowBlock>
      <Label label="Page Break" />
      <FlexFormContainer>
        <FlexElement>
          <Radio
            name="pagebreak"
            label="No"
            checked={pagebreak === "N"}
            onChange={() => setPagebreak("N")}
          />
        </FlexElement>
        <FlexElement>
          <Radio
            name="pagebreak"
            label="Yes"
            checked={pagebreak === "P"}
            onChange={() => setPagebreak("P")}
          />
        </FlexElement>
      </FlexFormContainer>
    </FormRowBlock>
  );
}

export function MultiselectFieldFromCommaSeparated({
  prop,
  value,
  title,
  options,
  status,
  error,
  noneSelectedValue,
  noneSelectedString,
  showTableHeader,
  additionalColumns,
  titleTitle,
  isRefetching,
}: {
  prop: string;
  value: string;
  title: string;
  options?: {
    key: string;
    title: string | React.ReactFragment;
    [index: string]: string | React.ReactFragment;
  }[];
  status?: QueryStatus;
  error?: Error | null;
  noneSelectedValue?: string;
  noneSelectedString?: string;
  showTableHeader?: boolean;
  additionalColumns?: { key: string; title: string }[];
  titleTitle?: string;
  isRefetching?: boolean;
}) {
  const valueAsArray = useMemo(
    () => value.split(",").filter((e) => !!e),
    [value]
  );

  const { selectionDispatch, selection } = useTable({
    selectionMode: "multi",
    selection: valueAsArray,
  });

  // This makes sure that the selection is always updated when the value changes as an extra precaution.
  // In case isRefetching is provided to this component, the selection will be updated – even to its original value –
  // after the field's data is refetched, thus providing an updated value even if data inconsistenciy or hidden update failure occur.
  useEffect(() => {
    if (isRefetching === false) {
      selectionDispatch({ type: "select", payload: valueAsArray });
    }
  }, [selectionDispatch, isRefetching, valueAsArray]);

  const [noneSelectedEngaged, setNoneSelectedEngaged] = useState(
    noneSelectedValue === value
  );

  const { register, setValue } = useFormContext();

  useEffect(() => {
    setValue(
      prop,
      !noneSelectedEngaged && selection.length === 0
        ? null
        : noneSelectedEngaged
        ? ""
        : selection.join(","),
      {
        // mark as dirty when the selection has changed
        // and even when just the "noneSelected" element is unclicked
        shouldDirty:
          value !== selection.join(",") ||
          (typeof noneSelectedValue !== "undefined" &&
            !noneSelectedEngaged &&
            selection.length === 0),
      }
    );
  }, [
    noneSelectedEngaged,
    noneSelectedValue,
    prop,
    selection,
    setValue,
    value,
  ]);

  return (
    <div>
      <Label label={title} />
      <input type="hidden" {...register(prop)} />
      <SmallTableContainer style={{ width: 300, height: 300 }}>
        <Table
          density="compact"
          items={options || []}
          itemIdProp="key"
          status={status}
          error={error}
          columns={[
            {
              key: "title",
              title: titleTitle ?? "",
              width: "100%",
            },
            ...(additionalColumns ? additionalColumns : []),
          ]}
          selectionMode="multi"
          selection={selection}
          selectionDispatch={selectionDispatch}
          fullRowSelect={true}
          noFilteredItemMessage=""
          nonVirtual={true}
          disabledItems={
            noneSelectedEngaged ? options?.map((e) => e.key) : undefined
          }
          disableHeader={
            typeof noneSelectedValue !== "undefined" && !showTableHeader
          }
          disableSelectAll={true}
          disableAllItems={isRefetching}
          aboveHeaderRow={
            <EDSTable.Row>
              <EDSTable.Cell
                colSpan={2 + (additionalColumns ? additionalColumns.length : 0)}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Checkbox
                    disabled={isRefetching}
                    checked={noneSelectedEngaged}
                    label={noneSelectedString}
                    onChange={() => {
                      if (noneSelectedEngaged) {
                        setNoneSelectedEngaged(false);
                      } else {
                        selectionDispatch({ type: "reset" });
                        setNoneSelectedEngaged(true);
                      }
                    }}
                  />
                </div>
              </EDSTable.Cell>
            </EDSTable.Row>
          }
        />
      </SmallTableContainer>
    </div>
  );
}
