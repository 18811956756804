import { Button, Icon } from "@equinor/eds-core-react";
import { check } from "@equinor/eds-icons";
import useConfirm from "components/confirm/useConfirm";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import { FieldContext } from "components/form/FieldContext";
import {
  Fieldset,
  FieldsetContainer,
  FormRowBlock,
} from "components/form/Form";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSheet from "queries/useSheet";
import useVDSSubsegmentPropertiesCommand, {
  AffectedVDSItem,
} from "queries/useVDSSubsegmentPropertiesCommand";
import { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { confirmNavigation } from "utils/confirmNavigation";
import { closeEditSheet } from "../../commands";
import { sheetProperties } from "../../config/sheetConfig";
import {
  FormVDSSubsegmentProperties,
  SaveVDSSubsegmentProperties,
  VDSSubsegment,
} from "../../types";
import {
  PropertiesColumns,
  QueryStatusAction,
  statusesReducer,
  VDSValidators,
} from "../VDSCommonProperties";
import { successMessage } from "utils/successMessage";
import { AffectedVDS } from "./AffectedVDS";
import { Nullable } from "utils/types";
import { MessageModal } from "components/MessageModal";

export function VDSSubsegmentCharacteristics({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSSubsegment;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  const {
    data: vdsCharacteristicsData,
    status,
    error,
  } = useCodelist({
    codelist: "vds-characteristics",
  });

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: { "vds-characteristics": status },
    });
  }, [status, statusesDispatch]);

  const value = data.getVDSSubsegmentCharSum
    .map((e) => e.CharacteristicID)
    .join();

  const vdsCharacteristicsOptions = useMemo(
    () =>
      vdsCharacteristicsData?.map((e) => ({
        key: String(e.CharacteristicID),
        title: e.Description,
        inherited: data.getVDSSubsegmentCharInherited
          .map((i) => i.CharacteristicID)
          .includes(e.CharacteristicID) ? (
          <Icon data={check} size={16} />
        ) : (
          ""
        ),
      })),
    [data.getVDSSubsegmentCharInherited, vdsCharacteristicsData]
  );

  return (
    <MultiselectFieldFromCommaSeparated
      options={vdsCharacteristicsOptions}
      prop="VDSCharacteristicProperty"
      title="VDS Characteristics"
      value={value}
      noneSelectedString="None"
      noneSelectedValue=""
      status={status}
      error={error}
      additionalColumns={[{ key: "inherited", title: "Inherited" }]}
      showTableHeader={true}
      titleTitle="Characteristics"
      isRefetching={isRefetching}
    />
  );
}

export function VDSSubsegmentPropertiesFields({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSSubsegment;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  return (
    <PropertiesColumns>
      <VDSSubsegmentCharacteristics
        data={data}
        statusesDispatch={statusesDispatch}
        isRefetching={isRefetching}
      />
      <VDSValidators
        data={data.getVDSSubsegmentProperties[0]}
        statusesDispatch={statusesDispatch}
        isRefetching={isRefetching}
      />
    </PropertiesColumns>
  );
}

export function VDSSubsegmentPropertiesModal({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const open = useMemo(
    () => !!name && !!revision && tab === "properties",
    [name, revision, tab]
  );

  const [showAlertModal, setShowAlertModal] = useState(false);

  const { data, status, error, isRefetching, isRefetchError, refetch } =
    useSheet({
      sheetType: "vds-subsegments",
      name,
      revision,
    });

  const {
    mutate: vdsSubsegmentAffectedVDS,
    status: vdsSubsegmentAffectedVDSStatus,
    error: vdsSubsegmentAffectedVDSError,
    data: vdsSubsegmentAffectedVDSData,
    reset: vdsSubsegmentAffectedVDSReset,
  } = useVDSSubsegmentPropertiesCommand({ command: "affected-vds" });

  const {
    mutate: vdsSubsegmentProperties,
    status: vdsSubsegmentPropertiesStatus,
    error: vdsSubsegmentPropertiesError,
    data: vdsSubsegmentPropertiesData,
    reset: vdsSubsegmentPropertiesReset,
  } = useVDSSubsegmentPropertiesCommand({ command: "properties" });

  const processData = (
    formData: FormVDSSubsegmentProperties,
    data: VDSSubsegment
  ): SaveVDSSubsegmentProperties => ({
    IncludeCharProperty: formData.VDSCharacteristicProperty.split(",")
      .filter(
        (c) =>
          !data.getVDSSubsegmentCharInherited
            .map((i) => String(i.CharacteristicID))
            .includes(c)
      )
      .join(","),
    ExcludeCharProperty: data.getVDSSubsegmentCharInherited
      .map((i) => String(i.CharacteristicID))
      .filter((i) => !formData.VDSCharacteristicProperty.split(",").includes(i))
      .join(","),
    ...formData,
  });

  const submitData = useRef({} as SaveVDSSubsegmentProperties);
  const affectedVDSList = useRef([] as AffectedVDSItem[]);

  const onSubmit: SubmitHandler<Nullable<FormVDSSubsegmentProperties>> = (
    formData
  ) => {
    if (data) {
      if (
        Object.keys(formData)
          .map((e) => formData[e as keyof typeof formData])
          .includes(null)
      ) {
        setShowAlertModal(true);
      } else {
        submitData.current = processData(
          formData as FormVDSSubsegmentProperties,
          data
        );
        vdsSubsegmentAffectedVDS({
          name,
          revision,
          content: submitData.current,
        });
      }
    }
  };

  const methods = useForm<FormVDSSubsegmentProperties>({
    shouldUnregister: true,
    mode: "all",
  });
  const { reset, formState, handleSubmit } = methods;
  const { isDirty } = formState;

  const { isConfirmed } = useConfirm();

  const closeModal = async () => {
    (!isDirty || (await confirmNavigation({ isConfirmed }))) &&
      closeEditSheet({ history });
  };

  const [statuses, statusesDispatch] = useReducer(statusesReducer, {});

  const combinedStatuses = useMemo(
    () => combineQueryStatuses(...Object.values(statuses)),
    [statuses]
  );

  const [showAffectedVDSModal, setShowAffectedVDSModal] = useState(false);

  useEffect(() => {
    combinedStatuses === "success" &&
      !isRefetching &&
      !isRefetchError &&
      reset(undefined, { keepDirty: false });
  }, [isRefetching, isRefetchError, data, reset, combinedStatuses]);

  useEffect(() => {
    if (vdsSubsegmentAffectedVDSStatus === "success") {
      if (vdsSubsegmentAffectedVDSData.data.getVDSAffected.length === 0) {
        vdsSubsegmentProperties({
          name,
          revision,
          content: submitData.current,
        });
      } else {
        setShowAffectedVDSModal(true);
        affectedVDSList.current =
          vdsSubsegmentAffectedVDSData.data.getVDSAffected;
      }
    }
    // this should only be triggered when the affecetedVDSStatus is changes, so other dependencies are not needed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vdsSubsegmentAffectedVDSStatus]);

  return (
    <>
      <MessageModal
        isOpen={showAlertModal}
        onClose={() => setShowAlertModal(false)}
        title="Modify selection"
      >
        Either All/None or at least one element should be selected.
      </MessageModal>
      <ModalWindow
        isOpen={open}
        closeModal={closeModal}
        title={`Properties for ${sheetProperties["vds-subsegments"].name} ${name} rev. ${revision}`}
        shouldCloseOnOverlayClick={false}
        layer="default"
        status={status}
        error={error}
        refetch={refetch}
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <FieldContext.Provider value={{}}>
                <ModalSideMargin>
                  <ModalWindowContentDefault>
                    <FieldsetContainer>
                      <Fieldset>
                        <FormRowBlock>
                          <VDSSubsegmentPropertiesFields
                            data={data}
                            statusesDispatch={statusesDispatch}
                            isRefetching={isRefetching}
                          />
                        </FormRowBlock>
                      </Fieldset>
                    </FieldsetContainer>
                  </ModalWindowContentDefault>
                </ModalSideMargin>
                <ModalWindowButtonContainer>
                  <Button
                    type="submit"
                    disabled={combinedStatuses !== "success" || isRefetching}
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={closeModal}>
                    Close
                  </Button>
                </ModalWindowButtonContainer>
              </FieldContext.Provider>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      {showAffectedVDSModal && data && (
        <AffectedVDS
          data={data}
          affectedVDS={affectedVDSList.current}
          setShowAffectedVDSModal={setShowAffectedVDSModal}
        />
      )}
      <StatusModal
        status={vdsSubsegmentAffectedVDSStatus}
        error={vdsSubsegmentAffectedVDSError}
        onSettled={vdsSubsegmentAffectedVDSReset}
      />
      <StatusModal
        status={vdsSubsegmentPropertiesStatus}
        error={vdsSubsegmentPropertiesError}
        onSettledClose={vdsSubsegmentPropertiesReset}
        successMessage={successMessage(vdsSubsegmentPropertiesData)}
      />
    </>
  );
}
