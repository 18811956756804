import { Button } from "@equinor/eds-core-react";
import useConfirm from "components/confirm/useConfirm";
import { Field } from "components/form/Field";
import { FieldContext } from "components/form/FieldContext";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { SaveVDSTextBlock } from "features/sheets/types";
import useSaveVDSTextBlock from "queries/useSaveVDSTextBlock";
import useSheet from "queries/useSheet";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { successMessage } from "utils/successMessage";
import { closeEditSheet } from "../../commands";
import { EditVDSTextBlockTemperatures } from "./Temperatures";
import { EditVDSTextBlockSectionD } from "./SectionD";
import { EditVDSTextBlockSectionH1 } from "./SectionH1";
import { EditVDSTextBlockSectionH2 } from "./SectionH2";
import { EditVDSTextBlockSectionH3 } from "./SectionH3";
import { EditVDSTextBlockSectionH4 } from "./SectionH4";
import { EditVDSTextBlockSectionM } from "./SectionM";
import { EditVDSTextBlockSectionR } from "./SectionR";

const emptyVDSTextBlock: SaveVDSTextBlock = {
  ValveType: "",
  DesignCode: "",
  SizeRangeFrom: "",
  SizeRangeTo: "",
  SizeRangeComment: "",
  RatingClass: "",
  Unit: "",
  MaxDesignPressure1: "",
  MaxDesignPressure2: "",
  MaxDesignPressure3: "",
  MaxDesignPressure4: "",
  MaxDesignPressure5: "",
  MaxDesignPressure6: "",
  MaxDesignPressure7: "",
  MaxDesignPressure8: "",
  MaxDesignPressure9: "",
  MaxDesignPressure10: "",
  MaxDesignPressure11: "",
  MaxDesignPressure12: "",
  AtTemperature1: "",
  AtTemperature2: "",
  AtTemperature3: "",
  AtTemperature4: "",
  AtTemperature5: "",
  AtTemperature6: "",
  AtTemperature7: "",
  AtTemperature8: "",
  AtTemperature9: "",
  AtTemperature10: "",
  AtTemperature11: "",
  AtTemperature12: "",
  DesignRemark: "",
  MaterialSubItem: "",
  MaterialName: "",
  MaterialMDS: "",
  MaterialRemark: "",
  MiscDescription: "",
  MiscVSK: "",
  VDSDescription: "",
  TextBlockName: "",
  MinDesignTemperature: "",
  MaxDesignTemperature: "",
  MinOperatingTemperature: "",
  MaxOperatingTemperature: "",
};

export function EditVDSTextBlock({
  name,
  revision,
  tab,
}: {
  name: string;
  revision: string;
  tab: string;
}) {
  const history = useHistory();
  const { data, status, error, isLoading, isRefetching } = useSheet({
    name,
    revision,
    sheetType: "vds-textblocks",
  });
  const methods = useForm<SaveVDSTextBlock>({
    shouldUnregister: true,
    mode: "all",
  });
  const { reset, formState, handleSubmit } = methods;

  const { isConfirmed } = useConfirm();

  const confirmNavigation = async (): Promise<boolean> => {
    return Object.keys(formState.dirtyFields).length === 0
      ? true
      : await isConfirmed(
          <>
            Unsaved changes will be lost when navigating away. Are you sure you
            want to leave?
          </>
        );
  };

  const {
    mutate: saveVDSTextBlock,
    status: saveVDSTextBlockStatus,
    error: saveVDSTextBlockError,
    reset: saveVDSTextBlockReset,
    data: saveVDSTextBlockData,
  } = useSaveVDSTextBlock();

  const onSubmit: SubmitHandler<SaveVDSTextBlock> = (formData) => {
    saveVDSTextBlock({
      name,
      revision,
      content: { ...emptyVDSTextBlock, ...formData },
    });
  };

  const closeModal = async () => {
    (await confirmNavigation()) && closeEditSheet({ history });
  };

  const disabled = useMemo(() => !data || data.Status !== "W", [data]);

  const open = useMemo(
    () => !!name && !!revision && !tab,
    [name, revision, tab]
  );

  // Reset the form data & status after save by observing isRefetching.
  // Refetch is triggered by invalidateQueries in useSaveVDSTextBlock.
  useEffect(() => {
    !isRefetching && reset(data);
  }, [isRefetching, data, reset]);

  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <>
      <ModalWindow
        closeModal={closeModal}
        isOpen={open}
        title={`Edit VDS Text Block ${name} rev. ${revision}`}
        status={status}
        error={error}
        isLoading={isLoading}
        shouldCloseOnOverlayClick={false}
        layer="default"
      >
        {data && (
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalSideMargin>
                <ModalWindowContentDefault
                  style={{ minWidth: 500, width: "90vw" }}
                >
                  <FieldContext.Provider value={{ disabled }}>
                    <FieldsetContainer>
                      <Fieldset>
                        <Field
                          feature="sheets"
                          sheetType="vds-textblocks"
                          prop="TextBlockName"
                          title="Name"
                          type="text"
                          value={data.TextBlockName}
                        />
                        <Field
                          feature="sheets"
                          sheetType="vds-textblocks"
                          prop="VDSDescription"
                          title="Description"
                          type="text"
                          value={data.VDSDescription}
                        />
                      </Fieldset>
                      <Fieldset>
                        {data.ItemSection === "D" && (
                          <EditVDSTextBlockSectionD
                            data={data}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                        {data.ItemSection === "R" && (
                          <EditVDSTextBlockSectionR
                            data={data}
                            name={name}
                            revision={revision}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                        {data.ItemSection === "M" && (
                          <EditVDSTextBlockSectionM
                            data={data}
                            name={name}
                            revision={revision}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                        {data.ItemSection === "H" && data.ItemID === 1 && (
                          <EditVDSTextBlockSectionH1
                            data={data}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                        {data.ItemSection === "H" && data.ItemID === 2 && (
                          <EditVDSTextBlockSectionH2
                            data={data}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                        {data.ItemSection === "H" && data.ItemID === 3 && (
                          <EditVDSTextBlockSectionH3
                            data={data}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                        {data.ItemSection === "H" && data.ItemID === 4 && (
                          <EditVDSTextBlockSectionH4
                            data={data}
                            setIsLoaded={setIsLoaded}
                          />
                        )}
                      </Fieldset>
                      {!(data.ItemSection === "H" && data.ItemID === 4) && (
                        <EditVDSTextBlockTemperatures data={data} />
                      )}
                    </FieldsetContainer>
                  </FieldContext.Provider>
                </ModalWindowContentDefault>
              </ModalSideMargin>
              <ModalWindowButtonContainer>
                {!disabled && (
                  <Button type="submit" disabled={!isLoaded}>
                    Save
                  </Button>
                )}
                <Button variant="outlined" onClick={closeModal}>
                  Close
                </Button>
              </ModalWindowButtonContainer>
            </form>
          </FormProvider>
        )}
      </ModalWindow>
      <StatusModal
        status={saveVDSTextBlockStatus}
        error={saveVDSTextBlockError}
        onSettledClose={saveVDSTextBlockReset}
        successMessage={successMessage(saveVDSTextBlockData)}
      />
    </>
  );
}
