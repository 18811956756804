import { VDSItemSections } from "features/sheets/types";
import { ValveElementGroups } from "./../features/admin/valve-element-groups/valveElementGroupsConfig";
import { PipeElementGroups } from "./../features/admin/pipe-element-groups/pipeElementGroupsConfig";
import axios from "axios";
import { PCSStandardNotes } from "features/admin/pcs-standard-notes/pcsStandardNotesConfig";
import { useQuery } from "react-query";
import config from "../app/config";
import { PlantMapping } from "features/admin/plant-mapping/plantMappingConfig";
import { AdminRoles, UserRoles } from "app/userConfig";

export type CodelistSpecification = {
  "valve-types": {
    ValveTypeID: number;
    Description: string;
    VDSCode: string;
    LongDescription: string;
    FBRBIndicator: "N" | "Y";
    ValveCategoryID: number;
    MainValveType: string;
    SubValveType: string;
    Seat: string;
    Pattern: string;
    Miscellaneous: string;
    HDIndicator: "N" | "Y";
  };
  "end-connections": {
    EndConnectionID: number;
    Description: string;
    VDSCode: string;
  };
  "material-groups": {
    MaterialGroupID: number;
    MaterialGroup: string;
    Description: string;
    Density: number;
    VDSCode1: string;
    VDSCode2: string;
    Sequence: number;
    MaterialType: string;
    PEBEWthMM: number;
    MinDesignTemperature: number;
    AllowableStress: number;
    InUse: "Y" | "N";
  };
  "special-requirements": {
    SpecialRequirementID: number;
    Description: string;
  };
  "vds-subsegment-groups": {
    GroupID: number;
    Description: string;
    OptionalSubsegment: "N" | "Y";
    Sequence: number;
  };
  "vds-subsegment-groups-config": {
    ItemID: number;
    ItemDescription: string;
    ItemSection: VDSItemSections;
    GroupID: number;
    GroupDescription: string;
  };
  "rating-classes": { RatingClassID: number; Description: string };
  bores: {
    BoreID: number;
    Description: string;
  };
  "vds-sizes": { VDSSizeID: number; Description: string };
  "pcs-standard-notes": PCSStandardNotes;
  "nps-schedules": {
    NomSize: string;
    OuterDiameter: string;
    WallThickness: string;
    Schedule: string;
  };
  "under-tolerances": { ID: number; UnderToleranceValue: string };
  "pipe-element-groups": PipeElementGroups;
  "valve-element-groups": ValveElementGroups;
  "embedded-notes": { NoteID: string; NoteText: string };
  "pcs-embedded-note-template": { HTMLContent: string };
  "plant-categories": { CategoryID: number; Description: string };
  areas: {
    AreaID: number;
    Description: string;
  };
  "matrix-types": {
    MatrixTypeID: number;
    Description: string;
  };
  "vsk-groups": {
    GroupID: number;
    Description: string;
  };
  "product-form-level2": {
    ProductFormLevel2ID: number;
    Description: string;
  };
  "remote-systems": {
    RemoteSystemName: string;
  };
  "plant-mapping": PlantMapping;
  "vds-characteristics": {
    CharacteristicID: number;
    Description: string;
  };
  "vds-header": {
    ItemID: number;
    Description: string;
  };
  "vds-misc": {
    ItemID: number;
    Description: string;
    VDSKGroups: {
      GroupID: number;
      Description: string;
    }[];
  };
  "vds-material": {
    ItemID: number;
    Description: string;
  };
  "vds-design": {
    ItemID: number;
    Description: string;
  };
  "configuration-parameters": {
    ID: number;
    URL: string;
    Description: string;
  };
  "capability-role-matrix": {
    Capability: string;
    Role: UserRoles;
    AdminRole: AdminRoles;
    Description: string;
    Comment: string;
  };
};

export type Codelists = keyof CodelistSpecification;

export const codelistProperties = {
  "valve-types": {
    listProperty: "getValveTypes",
    idProp: "ValveTypeID",
    nameProp: "Description",
    titleSingular: "Valve Type",
    titlePlural: "Valve Types",
    property: "",
  },
  "end-connections": {
    listProperty: "getEndConnections",
    idProp: "EndConnectionID",
    nameProp: "Description",
    titleSingular: "End Connection",
    titlePlural: "End Connections",
    property: "",
  },
  "material-groups": {
    listProperty: "getMaterialGroup",
    idProp: "MaterialGroupID",
    nameProp: "MaterialGroup",
    titleSingular: "Material Group",
    titlePlural: "Material Groups",
    property: "MaterialGroup",
  },
  "special-requirements": {
    listProperty: "getSpecialRequirement",
    idProp: "SpecialRequirementID",
    nameProp: "Description",
    titleSingular: "Special Requirement",
    titlePlural: "Special Requirements",
    property: "SpecialReq",
  },
  "vds-subsegment-groups": {
    listProperty: "getVDSSubsegmentGroups",
    idProp: "GroupID",
    nameProp: "Description",
    titleSingular: "VDS Subsegment Group",
    titlePlural: "VDS Subsegment Groups",
    property: "",
  },
  "vds-subsegment-groups-config": {
    listProperty: "getVDSSubsegmentGroupConfig",
    idProp: "ItemID",
    nameProp: "ItemDescription",
    titleSingular: "VDS Subsegment Group Config",
    titlePlural: "",
    property: "",
  },
  "rating-classes": {
    listProperty: "getRatingClass",
    idProp: "RatingClassID",
    nameProp: "Description",
    titleSingular: "Rating Class",
    titlePlural: "Rating Classes",
    property: "RatingClass",
  },
  bores: {
    listProperty: "getBore",
    idProp: "BoreID",
    nameProp: "Description",
    titleSingular: "Bore",
    titlePlural: "Bores",
    property: "",
  },
  "vds-sizes": {
    listProperty: "getVDSSize",
    idProp: "VDSSizeID",
    nameProp: "Description",
    titleSingular: "VDS Size",
    titlePlural: "VDS Sizes",
    property: "",
  },
  "pcs-standard-notes": {
    listProperty: "getStandardNotes",
    idProp: "NoteID",
    nameProp: "NoteText",
    titleSingular: "PCS Standard Note",
    titlePlural: "PCS Standard Notes",
    property: "",
  },
  "nps-schedules": {
    listProperty: "getNPSSchedules",
    idProp: "",
    nameProp: "",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "under-tolerances": {
    listProperty: "getUnderTolerances",
    idProp: "",
    nameProp: "",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "pipe-element-groups": {
    listProperty: "getPipeElementGroups",
    idProp: "ElementGroupID",
    nameProp: "ElementGroup",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "valve-element-groups": {
    listProperty: "getValveElementGroups",
    idProp: "ValveGroupID",
    nameProp: "ValveGroup",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "embedded-notes": {
    listProperty: "getEmbeddedNotes",
    idProp: "NoteID",
    nameProp: "NoteText",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "pcs-embedded-note-template": {
    listProperty: "getEmbeddedNoteTemplate",
    idProp: "",
    nameProp: "",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "plant-categories": {
    listProperty: "getPlantCategory",
    idProp: "CategoryID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  areas: {
    listProperty: "getArea",
    idProp: "",
    nameProp: "",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "matrix-types": {
    listProperty: "getMatrixType",
    idProp: "MatrixTypeID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "vsk-groups": {
    listProperty: "getVSKGroups",
    idProp: "GroupID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "product-form-level2": {
    listProperty: "getProductFormLevel2",
    idProp: "ProductFormLevel2ID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "remote-systems": {
    listProperty: "getRemoteSystem",
    idProp: "RemoteSystemName",
    nameProp: "RemoteSystemName",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "plant-mapping": {
    listProperty: "getPlantMapping",
    idProp: "MappingID",
    nameProp: "ShortDescription",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "vds-characteristics": {
    listProperty: "getVDSCharacteristics",
    idProp: "CharacteristicID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "vds-header": {
    listProperty: "getVDSHeader",
    idProp: "ItemID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "vds-misc": {
    listProperty: "getVDSMisc",
    idProp: "ItemID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "vds-material": {
    listProperty: "getVDSMaterial",
    idProp: "ItemID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "vds-design": {
    listProperty: "getVDSDesign",
    idProp: "ItemID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "configuration-parameters": {
    listProperty: "getConfigParams",
    idProp: "ID",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
  "capability-role-matrix": {
    listProperty: "getCapabilityRoleMtarix",
    idProp: "Capability",
    nameProp: "Description",
    titleSingular: "",
    titlePlural: "",
    property: "",
  },
};

type ExtraParams = {
  [key: string]: string | number;
};

type Subpath = string;

type CodelistQueryKey = ["codelists", Codelists, Subpath, ExtraParams?];

export const codelistQueryFn = async <T extends Codelists>({
  queryKey,
}: {
  queryKey: CodelistQueryKey;
}): Promise<CodelistSpecification[T][]> => {
  const [urlPart, codelist, subpath, extraParams] = queryKey;
  const url =
    config.restApiBaseUrl +
    `${urlPart}/${codelist}${subpath ? `/${subpath}` : ""}${
      extraParams
        ? Object.keys(extraParams).reduce((acc, curr: string) => {
            return (acc += `${curr}=${extraParams[curr]}&`);
          }, "?")
        : ""
    }`;
  const { data } = await axios.get(url);
  return codelistProperties[codelist]
    ? data[codelistProperties[codelist].listProperty]
    : data;
};

export function useCodelist<T extends Codelists>({
  codelist,
  extraParams,
  subpath,
  enabled,
}: {
  codelist: T;
  extraParams?: ExtraParams;
  subpath?: string;
  enabled?: boolean;
}) {
  return useQuery<
    CodelistSpecification[T][],
    Error,
    CodelistSpecification[T][],
    CodelistQueryKey
  >(
    [
      "codelists",
      codelist,
      ...(subpath ? [subpath] : []),
      ...(extraParams ? [extraParams] : []),
    ] as CodelistQueryKey,
    codelistQueryFn,
    {
      enabled: enabled,
    }
  );
}
