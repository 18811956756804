import { useQuery } from "react-query";
import axios from "axios";
import config from "../app/config";
import { OperatorProps } from "../features/plant/types";

export default function useOperators() {
  return useQuery<OperatorProps[], Error>(["operators"], async () => {
    const { data } = await axios.get(`${config.restApiBaseUrl}operators`);
    return data["getOperator"];
  });
}
