import axios from "axios";
import { useQuery } from "react-query";
import { getApiUrl } from "../queryUtil";

type SettingsReply = { getUserSettings: { Key: string; Value: string }[] };

export function useOneSetting({ key }: { key: string }) {
  const fetchPost = async (key: string) => {
    const { data } = await axios.get<SettingsReply>(
      getApiUrl(`users/settings/key/${key}`)
    );
    return data.getUserSettings[0].Value;
  };

  return useQuery(["settings", key], () => fetchPost(key), {
    staleTime: Infinity,
  });
}
