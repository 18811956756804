import { Typography } from "@equinor/eds-core-react";
import React, { useContext } from "react";
import styled from "styled-components";
import { FlexContainer } from "../Components";
import { FieldContext } from "./FieldContext";

export function FieldsetContainer({
  slim,
  children,
}: {
  slim?: boolean;
  children?: React.ReactNode;
}) {
  const { slim: contextSlim } = useContext(FieldContext);
  return (
    <FieldsetContainerElement slim={slim || contextSlim}>
      {children}
    </FieldsetContainerElement>
  );
}

const FieldsetContainerElement = styled.div<{
  slim?: boolean;
}>(
  ({ slim }) => `
  margin: 0 ${slim ? "2px 0" : "2em 1em"};
  & > div {
    ${slim ? "padding-left: 0; padding-right: 0;" : ""}
  }
  & > div:first-child {
    ${slim ? "padding-top: 0;" : ""}
  }
  & > div:not(:first-child) {
    padding-top: 0.6em;
    border-top: 1px solid var(--borderDefault);
  } 
`
);

export const FormButtonContainer = styled.div`
  margin: 1em;
  display: flex;
  justify-content: space-around;
`;

export const FormRowBlock = styled.div`
  margin-bottom: 1em;
  margin-top: 1em;
`;

export const FormRow = styled.div.attrs({
  className: "FormRow",
})`
  display: flex;
  align-items: stretch;
  margin-bottom: 1em;
  margin-top: 1em;
  [disabled] {
    color: var(--textSemiLight);
    opacity: 1;
  }
`;

export const Fieldset = styled.div<{
  slim?: boolean;
}>(
  ({ slim }) => `
  padding: 0.5em 1em 0.75em;
  .FormRow {
    ${slim ? "margin: 0" : ""}
  }
`
);

export function FieldsetTitle({ children }: { children: React.ReactChild }) {
  return (
    <Typography variant="h6" style={{ marginTop: 6, marginBottom: 6 }}>
      {children}
    </Typography>
  );
}

export function FormOptionsContainer({
  children,
  style,
  alignLeft,
}: {
  children: React.ReactChild | React.ReactChild[];
  style?: React.CSSProperties;
  alignLeft?: boolean;
}) {
  return (
    <FlexContainer
      style={{
        justifyContent: "flex-start",
        gap: 12,
        marginLeft: alignLeft ? -14 : 0,
        ...style,
      }}
    >
      {children}
    </FlexContainer>
  );
}

export const InfoContainer = styled.div`
  margin: 4px 4px 16px;
`;
