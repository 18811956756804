import { FilterProps } from "components/table/Filters";
import { useEffect } from "react";
import { AllUsers } from "./usersConfig";
import { userRoles } from "app/userConfig";

export function UsersFilter({
  data,
  filtersDispatch,
  categoryFilter,
  setCategoryFilter,
}: {
  data?: AllUsers[];
  filtersDispatch: React.Dispatch<{
    type: "update" | "reset";
    payload?: FilterProps | undefined;
  }>;
  categoryFilter: string;
  setCategoryFilter: React.Dispatch<string>;
}) {
  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Role",
        prop: "Role",
        filterOptions: data
          ? (data as AllUsers[])
              .map((i) => ({
                key: i.Role,
                title: Object.keys(userRoles).includes(i.Role)
                  ? userRoles[i.Role as keyof typeof userRoles]
                  : i.Role,
              }))
              .reduce(
                (a, c) =>
                  a.map((e) => e.key).includes(c.key) ? a : a.concat(c),
                [] as { key: string; title: string }[]
              )
          : [],
        filterState: categoryFilter,
        defaultFilterState: "",
        setFilterState: setCategoryFilter,
        group: "",
      },
    });
    return () => filtersDispatch({ type: "reset" });
  }, [data, categoryFilter, setCategoryFilter, filtersDispatch]);
  return null;
}
