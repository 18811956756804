import {
  SheetTypes,
  nameProperties,
  sheetProperties,
} from "./config/sheetConfig";
import { SheetStatus } from "./config/statusConfig";
import ListMenu from "../../components/ListMenu";
import {
  more_vertical,
  edit,
  delete_forever,
  label,
  label_off,
  skip_next,
  visibility,
  external_link,
  check_circle_outlined,
  assignment,
  file,
  copy,
  file_description,
} from "@equinor/eds-icons";
import { Icon, Menu } from "@equinor/eds-core-react";
import { PlantProps } from "../plant/types";
import { cannotBeRevised } from "./util";
import useSheets, { sheetsExtraParams } from "../../queries/useSheets";
import { previewInPane, editSheet, previewInNewWindow } from "./commands";
import { useAppDispatch } from "../../app/hooks";
import { useHistory } from "react-router-dom";
import { setPaneMode, setPaneSheet } from "uiSlice";
import { SheetProps } from "./Sheets";
import { CopyVDSTextBlockQuery } from "queries/useCopyVDSTextBlock";
import { NewSheetRevisions } from "queries/useNewSheetRevisions";
import { SetMultipleSheetStatus } from "queries/useSetMultipleSheetStatus";
import React from "react";
import { NewEDSOperation } from "./modals/CreateEDS";
import { useUserConfig } from "UserConfigContext";

export default function ItemMenu({
  plant,
  item,
  sheetType,
  isCopyModal,
  deleteSingle,
  newSheetRevision,
  newSheetRevisions,
  copyVDSTextBlock,
  setSheetStatus,
  setMultipleSheetStatus,
  extraParams,
  hasName,
  sheetTypeName,
  nameProperty,
  mainPlant,
  deleteSheets,
  isConfirmed,
  setWantToSetStatus,
  setSelectedSheet,
  setNewEDSOperation,
  setSourceEDSName,
  setSourceEDSRevision,
}: {
  plant: PlantProps;
  item: any;
  sheetType: SheetTypes;
  isCopyModal: boolean;
  deleteSingle: Function;
  newSheetRevision: Function;
  newSheetRevisions: NewSheetRevisions["mutate"];
  copyVDSTextBlock: CopyVDSTextBlockQuery["mutate"];
  setSheetStatus: Function;
  setMultipleSheetStatus: SetMultipleSheetStatus["mutate"];
  extraParams: sheetsExtraParams;
  hasName: boolean;
  sheetTypeName: string;
  nameProperty: string;
  mainPlant: PlantProps;
  deleteSheets: Function;
  isConfirmed: Function;
  setWantToSetStatus: React.Dispatch<string>;
  setSelectedSheet: React.Dispatch<SheetProps>;
  setNewEDSOperation: React.Dispatch<NewEDSOperation>;
  setSourceEDSName: React.Dispatch<string>;
  setSourceEDSRevision: React.Dispatch<string>;
}) {
  const { data } = useSheets({ plant, sheetType, extraParams, disabled: true });

  const dispatch = useAppDispatch();
  const history = useHistory();
  const { previewInEdit } = sheetProperties[sheetType];

  const { caps, previewBaseUrl } = useUserConfig();

  const previewInPaneMenuItem = previewInEdit ? (
    <></>
  ) : (
    <Menu.Item
      key="mi-previewinpane"
      onClick={() => {
        previewInPane({
          location: isCopyModal ? "copyModal" : "main",
          dispatch,
          plant,
          sheetType,
          item,
        });
      }}
    >
      <Icon size={16} data={visibility} />
      Preview in pane
    </Menu.Item>
  );

  const previewMenuItem = previewInEdit ? (
    <></>
  ) : (
    <Menu.Item
      key="mi-preview"
      onClick={() => {
        previewInNewWindow({ item, sheetType, previewBaseUrl, plant });
      }}
    >
      <Icon size={16} data={external_link} />
      Preview in new window
    </Menu.Item>
  );

  const validationLogMenuItem =
    sheetType === "pcs" ? (
      <Menu.Section>
        <Menu.Item
          onClick={() => {
            dispatch(
              setPaneSheet({
                location: isCopyModal ? "copyModal" : "main",
                sheet: {
                  plant,
                  sheetType,
                  name: item.PCS,
                  revision: item.Revision,
                },
              })
            );
            dispatch(
              setPaneMode({
                location: isCopyModal ? "copyModal" : "main",
                mode: "validation-log",
              })
            );
          }}
        >
          <Icon size={16} data={check_circle_outlined} />
          Validation log
        </Menu.Item>
      </Menu.Section>
    ) : null;

  let statusSetterItems = [];

  async function setStatus(status: SheetStatus) {
    const name = item[nameProperties[sheetType]];
    setWantToSetStatus(status);
    setSelectedSheet({
      name,
      revision: item.Revision,
    });
  }

  if (
    ["vds-textblocks"].includes(sheetType) &&
    (item.Status === "W" || item.Status === "E")
  ) {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-o"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Official?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType: "vds-textblocks",
              status: "O",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Official (O)
      </Menu.Item>
    );
  }

  if (["vds-textblocks"].includes(sheetType) && item.Status === "O") {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-e"
        onClick={async () => {
          (await isConfirmed(
            <>
              Are you sure you want to set {sheetProperties[sheetType].name}{" "}
              {item[nameProperty]} rev. {item.Revision} to Expired?
            </>
          )) &&
            setMultipleSheetStatus({
              sheetType: "vds-textblocks",
              status: "E",
              sheets: [{ name: item[nameProperty], revision: item.Revision }],
            });
        }}
      >
        <Icon size={16} data={label} /> Set Expired (E)
      </Menu.Item>
    );
  }

  if (
    ["vsm", "sc", "eds"].includes(sheetType) &&
    item.Status === "W" &&
    (sheetType !== "eds" || caps.includes("sheets_eds_set_o_e"))
  ) {
    statusSetterItems.push(
      <Menu.Item key="mi-status-o" onClick={() => setStatus("O")}>
        <Icon size={16} data={label} /> Set Official (O)
      </Menu.Item>
    );
  }

  if (["general", "pcs"].includes(sheetType) && item.Status === "W") {
    statusSetterItems.push(
      <Menu.Item
        key="mi-status-i"
        onClick={() =>
          item.Revision.length > 1 ? setStatus("S") : setStatus("I")
        }
      >
        <Icon size={16} data={label} />
        {item.Revision.length > 1
          ? "Lock sub revision"
          : "Set Ready for Issue (I)"}
      </Menu.Item>
    );
  }

  if (
    ((["sc", "vsm", "eds"].includes(sheetType) && item.Status === "O") ||
      (sheetType === "pcs" && (item.Status === "O" || item.Status === "I"))) &&
    (sheetType !== "eds" || caps.includes("sheets_eds_set_o_e"))
  ) {
    statusSetterItems.push(
      <Menu.Item key="mi-status-e" onClick={() => setStatus("E")}>
        <Icon size={16} data={label_off} />
        Set Expired (E)
      </Menu.Item>
    );
  }

  if (isCopyModal) {
    return (
      <ListMenu buttonContent={<Icon data={more_vertical}></Icon>}>
        {previewInPaneMenuItem}
        {previewMenuItem}
        {validationLogMenuItem}
      </ListMenu>
    );
  }

  return (
    <ListMenu buttonContent={<Icon data={more_vertical}></Icon>}>
      <Menu.Item
        key="mi-edit"
        disabled={
          !(
            item.Status === "W" ||
            (item.Status === "I" && sheetType === "general") ||
            previewInEdit
          )
        }
        onClick={() => {
          editSheet({
            history,
            sheetType,
            item,
          });
        }}
      >
        {item.Status !== "W" && previewInEdit ? (
          <>
            <Icon size={16} data={file} />
            View
          </>
        ) : (
          <>
            <Icon size={16} data={edit} />
            Edit
          </>
        )}
      </Menu.Item>
      <Menu.Item
        key="mi-properties"
        onClick={() => {
          editSheet({
            history,
            sheetType,
            item,
            tab: "properties",
          });
        }}
      >
        <Icon size={16} data={assignment} />
        Properties
      </Menu.Item>
      {sheetType === "eds" && (
        <Menu.Item
          key="mi-copy"
          disabled={item.Status === "R"}
          onClick={() => {
            setNewEDSOperation("Copy");
            setSourceEDSName(item.EDS);
            setSourceEDSRevision(item.Revision);
          }}
        >
          <Icon size={16} data={copy} />
          Copy
        </Menu.Item>
      )}
      <Menu.Item
        key="mi-delete"
        disabled={item.Status !== "W"}
        onClick={() =>
          deleteSingle({
            sheetName: item[nameProperties[sheetType]],
            sheetRevision: item.Revision,
            hasName,
            sheetTypeName,
            nameProperty,
            mainPlant,
            deleteSheets,
            sheetType,
            isConfirmed,
          })
        }
      >
        <Icon size={16} data={delete_forever} />
        Delete
      </Menu.Item>
      <Menu.Item
        key="mi-newrev"
        disabled={cannotBeRevised(item, data && data.sheets, sheetType)}
        onClick={async () => {
          if (
            await isConfirmed(
              `Are you sure you want to create a new ${
                sheetType === "pcs" && item.Status === "I" ? "sub " : ""
              } revision?`
            )
          ) {
            sheetType === "vds-textblocks" ||
            sheetType === "vds-subsegments" ||
            sheetType === "vds"
              ? newSheetRevisions({
                  plant,
                  sheetType,
                  sheets: [
                    {
                      [nameProperty]: item[nameProperty],
                      Revision: item.Revision,
                    },
                  ],
                  command: "revision",
                })
              : newSheetRevision({
                  plant,
                  sheetType,
                  name: item[nameProperties[sheetType]],
                  revision: item.Revision,
                });
          }
        }}
      >
        <Icon size={16} data={skip_next} />
        {sheetType === "pcs" && item.Status === "I"
          ? "New sub revision"
          : "New revision"}
      </Menu.Item>
      {sheetType === "vds-textblocks" && (
        <Menu.Item
          disabled={item.Status !== "O" && item.Status !== "W"}
          onClick={() =>
            copyVDSTextBlock({
              textblocks: [
                {
                  revision: item.Revision,
                  textBlockId: item.TextBlockID,
                },
              ],
            })
          }
        >
          <Icon size={16} data={copy} />
          Copy and create new
        </Menu.Item>
      )}
      {sheetType === "vds-textblocks" && (
        <Menu.Item
          disabled={item.Status !== "O"}
          onClick={() => {
            editSheet({
              history,
              sheetType,
              item,
              tab: "description",
            });
          }}
        >
          <Icon size={16} data={file_description} />
          Update VDS Description
        </Menu.Item>
      )}
      {statusSetterItems.length > 0 && (
        <Menu.Section>{statusSetterItems}</Menu.Section>
      )}
      {!previewInEdit && (
        <Menu.Section>
          {previewInPaneMenuItem}
          {previewMenuItem}
        </Menu.Section>
      )}
      {validationLogMenuItem}
    </ListMenu>
  );
}
