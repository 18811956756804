import styled from "styled-components";

export const PanelContainer = styled.div`
  padding: 0 1em;
`;

export const PanelButtonContainer = styled.div`
  margin: 1em 0 0;
`;

export const PanelParagraph = styled.p`
  margin: 0 0 0.75em;
`;
