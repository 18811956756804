import {
  FlexColumnContainer,
  FlexContainer,
  FlexElement,
} from "components/Components";
import { Field } from "components/form/Field";
import { Fieldset } from "components/form/Form";
import { VDSTextBlock } from "features/sheets/types";

export function EditVDSTextBlockTemperatures({ data }: { data: VDSTextBlock }) {
  return (
    <Fieldset>
      <FlexColumnContainer style={{ justifyContent: "flex-start" }}>
        <FlexElement>
          <FlexContainer style={{ gap: 32 }}>
            <FlexElement>
              <Field
                title="Min. Design Temperature"
                feature="sheets"
                sheetType="vds-textblocks"
                prop="MinDesignTemperature"
                value={data.MinDesignTemperature}
                type="text"
                maxWidth={200}
              />
            </FlexElement>
            <FlexElement>
              <Field
                title="Max. Design Temperature"
                feature="sheets"
                sheetType="vds-textblocks"
                prop="MaxDesignTemperature"
                value={data.MaxDesignTemperature}
                type="text"
                maxWidth={200}
              />
            </FlexElement>
          </FlexContainer>
        </FlexElement>
        <FlexElement>
          <FlexContainer style={{ gap: 32 }}>
            <FlexElement>
              <Field
                title="Min. Operating Temperature"
                feature="sheets"
                sheetType="vds-textblocks"
                prop="MinOperatingTemperature"
                value={data.MinOperatingTemperature}
                type="text"
                maxWidth={200}
              />
            </FlexElement>
            <FlexElement>
              <Field
                title="Max. Operating Temperature"
                feature="sheets"
                sheetType="vds-textblocks"
                prop="MaxOperatingTemperature"
                value={data.MaxOperatingTemperature}
                type="text"
                maxWidth={200}
              />
            </FlexElement>
          </FlexContainer>
        </FlexElement>
      </FlexColumnContainer>
    </Fieldset>
  );
}
