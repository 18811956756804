import { getSheetState } from "../features/sheets/sheetSlice";
import useValidatePCS from "queries/useValidatePCS";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { getSheetApiUrl } from "./queryUtil";
import {
  plantDependentSheets,
  SheetTypes,
} from "../features/sheets/config/sheetConfig";
import { PlantProps } from "../features/plant/types";
import { useAppSelector } from "app/hooks";

export default function useSaveSheet() {
  const queryClient = useQueryClient();
  const { autoValidation } = useAppSelector(getSheetState);
  const { mutate: validatePCS } = useValidatePCS();

  async function saveSheet({
    plant,
    sheetType,
    name,
    revision,
    content,
    postfix,
  }: {
    plant: PlantProps;
    sheetType: SheetTypes;
    name: string;
    revision: string;
    content: any;
    postfix?: string;
  }) {
    return await axios(
      getSheetApiUrl({ plant, sheetType, name, revision, postfix }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(saveSheet, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheet",
        ...(plantDependentSheets.includes(variables.sheetType)
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
        variables.name,
        variables.revision,
        // The postfix is omitted here to invalidate the whole sheet
        // (incl. header with e.g. the last update datetime).
      ]);
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType)
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
      if (autoValidation) {
        validatePCS({
          plant: variables.plant,
          name: variables.name,
          revision: variables.revision,
        });
      }
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
