import { Button, EdsProvider, NativeSelect } from "@equinor/eds-core-react";
import { useAppSelector } from "app/hooks";
import { SimpleButtonContainer } from "components/Components";
import {
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFill,
} from "components/ModalWindow";
import { RevisionMarkWithPreviewButtons } from "components/table/Columns";
import Table, { ControlHeaderContainer } from "components/table/Table";
import useTable from "components/table/useTable";
import { SheetPane } from "features/sheets/SheetPane";
import { sheetDocumentWidth } from "features/sheets/config/sheetConfig";
import { VDSSubsegment } from "features/sheets/types";
import { splitter } from "features/sheets/util";
import { AffectedVDSItem } from "queries/useVDSSubsegmentPropertiesCommand";
import { useMemo, useState } from "react";
import * as Space from "react-spaces";
import { selectUIState } from "uiSlice";

type ActionTypes = "replace" | "delete" | "remove_filter" | "none";

const actionsTypesWithTitles = [
  { action: "none", title: "- No action -" },
  { action: "delete", title: "Delete" },
  { action: "replace", title: "Replace" },
  { action: "remove_filter", title: "Remove Filter" },
];

type Action = {
  itemId: string;
  action: ActionTypes;
  replacement: string;
};

type AffectedItemWithId = AffectedVDSItem & { itemId: string };

function VDSSubsegmentActionSelector({
  item,
  actions,
  setActions,
}: {
  item: AffectedItemWithId;
  actions: Action[];
  setActions: React.Dispatch<Action[]>;
}) {
  const currentAction = actions.find((e) => e.itemId === item.itemId);
  return (
    <div style={{ display: "flex", gap: 6, margin: "0 6px" }}>
      <div style={{ width: 200 }}>
        {currentAction?.action === "replace" && (
          <>
            <NativeSelect
              id={`VDSSubsegmentReplacemenetSelector-${item.itemId}`}
              label=""
            >
              <option>- Select Subsegment -</option>
            </NativeSelect>
          </>
        )}
      </div>
      <div>
        <NativeSelect
          id={`VDSSubsegmentActionSelector-${item.itemId}`}
          label=""
          value={currentAction?.action ?? "none"}
          style={{ minWidth: 160 }}
          onChange={(e) => {
            setActions([
              ...actions.filter((e) => e.itemId !== item.itemId),
              {
                itemId: item.itemId,
                action: e.target.value as ActionTypes,
                replacement: currentAction?.replacement ?? "",
              },
            ]);
          }}
        >
          {actionsTypesWithTitles.map((e) => (
            <option key={e.action} value={e.action}>
              {e.title}
            </option>
          ))}
        </NativeSelect>
      </div>
    </div>
  );
}

export function AffectedVDS({
  data,
  affectedVDS,
  setShowAffectedVDSModal,
}: {
  data: VDSSubsegment;
  affectedVDS: AffectedVDSItem[];
  setShowAffectedVDSModal: React.Dispatch<boolean>;
}) {
  const items = useMemo<AffectedItemWithId[]>(
    () =>
      affectedVDS.map((e) => ({ ...e, itemId: e.VDS + splitter + e.Revision })),
    [affectedVDS]
  );
  const { selection, selectionDispatch, selectionMode } = useTable({
    selectionMode: "multi",
  });
  const [actions, setActions] = useState<Action[]>(
    items.map((e) => ({
      itemId: e.itemId,
      action: "none",
      replacement: "",
    }))
  );
  const { paneModes } = useAppSelector(selectUIState);
  return (
    <ModalWindow
      title={`VDS affected by change in subsegment properties for (${data.getVDSSubsegmentHeader[0].SubsegmentID})`}
      closeModal={() => setShowAffectedVDSModal(false)}
      isOpen={true}
    >
      <ModalWindowContentFill style={{ width: "90vw", height: "75vh" }}>
        <Space.Fill>
          <Space.Fill>
            <Table
              nonVirtual={true}
              controlHeader={
                <EdsProvider density="comfortable">
                  <ControlHeaderContainer>
                    <SimpleButtonContainer>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          selectionDispatch({
                            type: "select",
                            payload: actions
                              .filter((action) => action.action === "none")
                              .map((action) => action.itemId),
                          });
                        }}
                      >
                        Select Items in Need of Action
                      </Button>
                    </SimpleButtonContainer>
                  </ControlHeaderContainer>
                </EdsProvider>
              }
              columns={[
                {
                  key: "VDS",
                  title: "VDS",
                  width: 90,
                },
                {
                  key: "Revision",
                  title: "Revision",
                  Component: RevisionMarkWithPreviewButtons,
                  type: "with-context",
                  componentProps: {
                    sheetType: "vds",
                    previewLocation: "affectedVDSModal",
                  },
                },
                {
                  key: "Status",
                  title: "Status",
                },
                {
                  key: "RevDate",
                  title: "Revision Date",
                  width: 180,
                },
                {
                  key: "LastUpdateBy",
                  title: "Last Update By",
                },
              ]}
              items={items}
              itemIdProp="itemId"
              density="compact"
              selection={selection}
              selectionDispatch={selectionDispatch}
              selectionMode={selectionMode}
              infoline={true}
              totalCount={items.length}
              RowMenu={VDSSubsegmentActionSelector}
              contextData={{ actions, setActions }}
              infoLineExtraInfo={[
                `Needs action: ${
                  actions.filter((e) => e.action === "none").length
                }`,
              ]}
              footer={
                <EdsProvider density="comfortable">
                  <div
                    style={{
                      width: "100%",
                      flexGrow: 1,
                      alignSelf: "flex-start",
                    }}
                  >
                    <SimpleButtonContainer style={{ alignItems: "center" }}>
                      <div>Set Action for selected:</div>
                      <Button
                        variant="outlined"
                        disabled={selection.length === 0}
                        onClick={() => {
                          setActions([
                            ...actions.filter(
                              (e) => !selection.includes(e.itemId)
                            ),
                            ...selection.map((selected) => ({
                              itemId: selected,
                              action: "delete" as ActionTypes,
                              replacement: "",
                            })),
                          ]);
                          selectionDispatch({ type: "reset" });
                        }}
                      >
                        Delete
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={selection.length === 0}
                      >
                        Replace With
                      </Button>
                      <Button
                        variant="outlined"
                        disabled={selection.length === 0}
                        onClick={() => {
                          setActions([
                            ...actions.filter(
                              (e) => !selection.includes(e.itemId)
                            ),
                            ...selection.map((selected) => ({
                              itemId: selected,
                              action: "remove_filter" as ActionTypes,
                              replacement: "",
                            })),
                          ]);
                          selectionDispatch({ type: "reset" });
                        }}
                      >
                        Remove Filter
                      </Button>
                    </SimpleButtonContainer>
                  </div>
                </EdsProvider>
              }
            />
          </Space.Fill>
          {paneModes.affectedVDSModal && (
            <Space.RightResizable size={sheetDocumentWidth}>
              <SheetPane location="affectedVDSModal" />
            </Space.RightResizable>
          )}
        </Space.Fill>
      </ModalWindowContentFill>

      <ModalWindowButtonContainer>
        <Button disabled>Save</Button>
        <Button
          variant="outlined"
          onClick={() => setShowAffectedVDSModal(false)}
        >
          Close
        </Button>
      </ModalWindowButtonContainer>
    </ModalWindow>
  );
}
