//import "./wdyr";
import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.scss";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import config from "./app/config";
import ErrorBoundary from "./ErrorBoundary";
import Modal from "react-modal";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./app/authConfig";
import axios from "axios";
import { acquireAccessToken } from "./app/auth";
import { appProps } from "./app/props";
import { QueryClient, QueryClientProvider } from "react-query";
import ConfirmContextProvider from "components/confirm/ConfirmContextProvider";
import ConfirmContextModal from "./components/confirm/ConfirmContextModal";
import { DndContext } from "@dnd-kit/core";
import { ReactQueryDevtools } from "react-query/devtools";

const msalInstance = new PublicClientApplication(msalConfig);

axios.interceptors.request.use(
  async function (axiosConfig) {
    const accessToken = await acquireAccessToken();
    if (
      axiosConfig.url?.startsWith(config.restApiBaseUrl) &&
      !!acquireAccessToken
    ) {
      if (!axiosConfig.headers) {
        axiosConfig.headers = {};
      }
      axiosConfig.headers["Authorization"] = `Bearer ${accessToken}`;
      if (config.extraHeaders) {
        axiosConfig.headers = {
          ...axiosConfig.headers,
          ...config.extraHeaders,
        };
      }
    }
    return axiosConfig;
  },
  function (error) {
    console.error(error);
    // Do something with request error
    return Promise.reject(error);
  }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

Modal.setAppElement("#root");

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <BrowserRouter basename={appProps.appBasePath as string}>
            <QueryClientProvider client={queryClient}>
              <ConfirmContextProvider>
                <DndContext>
                  <App />
                  <ReactQueryDevtools initialIsOpen={false} />
                </DndContext>
                <ConfirmContextModal />
              </ConfirmContextProvider>
            </QueryClientProvider>
          </BrowserRouter>
        </Provider>
      </MsalProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
