import { Field } from "components/form/Field";
import { Fieldset, FieldsetContainer } from "components/form/Form";
import { EditComponentProps } from "../ItemEditModal";
import { useCodelist } from "queries/useCodelist";
import { useEffect, useMemo } from "react";

export function PipeElementGroupsEdit({
  item,
  setEditLoading,
}: EditComponentProps<"pipe-element-groups">) {
  const { data: matrixTypes, status: matrixTypesStatus } = useCodelist({
    codelist: "matrix-types",
  });

  const matrixTypesOptions = useMemo(
    () =>
      matrixTypes
        ? matrixTypes.map((e) => ({
            id: String(e.MatrixTypeID),
            option: e.Description,
          }))
        : [],
    [matrixTypes]
  );

  useEffect(() => {
    setEditLoading && setEditLoading(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    matrixTypesStatus === "success" && setEditLoading && setEditLoading(false);
  }, [matrixTypesStatus, setEditLoading]);

  return (
    <>
      <FieldsetContainer>
        <Fieldset>
          {!item && (
            <Field
              area="pipe-element-groups"
              prop="ElementGroupID"
              value=""
              title="Element Group ID"
              type="text"
              style={{ width: 120 }}
              disabled={!!item}
            />
          )}
          <Field
            area="pipe-element-groups"
            prop="ElementGroup"
            value={item ? item.ElementGroup : ""}
            title="Element Group"
            type="text"
          />
          <Field
            area="pipe-element-groups"
            prop="MatrixTypeID"
            value={item ? item.MatrixTypeID : "0"}
            title="Matrix Type"
            type="option"
            optionsWithIds={matrixTypesOptions}
            status={matrixTypesStatus}
            notSetValue={"0"}
          />
        </Fieldset>
      </FieldsetContainer>
    </>
  );
}
