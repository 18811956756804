import { plantMappingConfig } from "../features/admin/plant-mapping/plantMappingConfig";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import config from "app/config";

export default function useUpdateRemoteSystems() {
  const queryClient = useQueryClient();

  async function updateRemoteSystems({
    remoteSystem,
    method,
  }: {
    remoteSystem: string;
    method: "post" | "delete";
  }) {
    return await axios({
      url: `${config.restApiBaseUrl}/${plantMappingConfig.queryKey.join("/")}${
        method === "delete" ? "/" + remoteSystem : ""
      }`,
      ...(method === "post"
        ? { data: JSON.stringify({ RemoteSystemName: remoteSystem }) }
        : {}),
      method,
    });
  }

  return useMutation(updateRemoteSystems, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["codelists", "remote-systems"]);
      queryClient.invalidateQueries(["codelists", "plant-mapping"]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type UpdateRemoteSystems = ReturnType<typeof useUpdateRemoteSystems>;
