import { Field } from "components/form/Field";
import { VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "queries/useVDSTextBlocksDistinctText";
import { useEffect } from "react";

export function EditVDSTextBlockSectionH1({
  data,
  setIsLoaded,
}: {
  data: VDSTextBlock;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const {
    data: valveTypes,
    isLoading,
    isSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "VT",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  useEffect(() => {
    isSuccess && setIsLoaded(true);
  }, [isSuccess, setIsLoaded]);

  return (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Valve Type"
        prop="ValveType"
        value={data.ValveType}
        options={valveTypes}
        type="autocomplete"
        isLoading={isLoading}
      />
    </>
  );
}
