import React, { useEffect } from "react";
import { Tabs, Button, Icon } from "@equinor/eds-core-react";
import { arrow_forward } from "@equinor/eds-icons";
import { useTabs } from "../../../components/tabs/useTabs";
import { useToggleButtons } from "../../../components/useToggleButtons";
import {
  PanelButtonContainer,
  PanelContainer,
} from "../../../components/Panels";
import useStandardNotes from "../hooks/useStandardNotes";
import usePCSCommandQuery from "../../../queries/usePCSCommandQuery";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectMainPlant } from "../../plant/plantSlice";
import { pcsCommandOnSelected } from "../commands";
import StatusModal from "../../../components/StatusModal";
import { successMessage } from "utils/successMessage";
import { PlantProps } from "../../plant/types";
import { FlexContainer, FlexElement } from "../../../components/Components";
import { reloadPreviewPane } from "../SheetPane";
import { setNoteFilter, setPipeElementFilter } from "../sheetSlice";
import usePipeElements from "../hooks/usePipeElements";
import useVDSSelector from "../hooks/useVDSSelector";

function StandardNoteEditor({
  location,
  selectedItems,
  pcsCommand,
  mainPlant,
}: {
  location:
    | "General"
    | "DesignCode"
    | "TempPress"
    | "ServiceCode"
    | "PIPE-SECTION"
    | "VALVE-SECTION";
  selectedItems: any[];
  pcsCommand: Function;
  mainPlant: PlantProps;
}) {
  const { activeButton, toggleButtonsContent } = useToggleButtons({
    buttons: [
      { key: "add", title: "Add" },
      { key: "remove", title: "Remove" },
      { key: "change", title: "Change" },
    ],
    id: "StandardNotesEditMode",
  });

  const { selection: pipeElementSelection, pipeElementsContent } =
    usePipeElements({
      existing: true,
    });
  const { selection: vdsSelection, vdsSelectorContent } = useVDSSelector({
    enabled: location === "VALVE-SECTION",
    plant: mainPlant,
    previewLocation: "main",
  });
  const { selection, standardNotesContent } = useStandardNotes({
    section: location === "VALVE-SECTION" ? "VDS" : "PCS",
    ...(["remove", "change"].includes(activeButton)
      ? { plant: mainPlant }
      : {}),
    ...(["remove", "change"].includes(activeButton) &&
    pipeElementSelection.length > 0
      ? { elementID: pipeElementSelection[0] }
      : {}),
  });
  const {
    selection: newSelection,
    standardNotesContent: newStandardNotesContent,
  } = useStandardNotes({
    section: location === "VALVE-SECTION" ? "VDS" : "PCS",
    enabled: activeButton === "change",
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    activeButton === "add"
      ? dispatch(setNoteFilter(0))
      : dispatch(setNoteFilter(selection[0]));
  }, [selection, dispatch, activeButton]);

  useEffect(() => {
    return () => {
      dispatch(setNoteFilter(0));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setPipeElementFilter(pipeElementSelection[0]));
  }, [pipeElementSelection, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPipeElementFilter(0));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <PanelContainer>
      {toggleButtonsContent}
      <FlexContainer>
        {location === "PIPE-SECTION" && (
          <FlexElement style={{ flexGrow: 1, marginRight: "2em" }}>
            {pipeElementsContent}
          </FlexElement>
        )}
        {location === "VALVE-SECTION" && (
          <FlexElement style={{ flexGrow: 1, marginRight: "2em" }}>
            {vdsSelectorContent}
          </FlexElement>
        )}
        <FlexElement style={{ flexGrow: 1, maxWidth: 1000 }}>
          {standardNotesContent}
        </FlexElement>
        {activeButton === "change" && (
          <>
            <FlexElement style={{ width: 70, textAlign: "center" }}>
              <Icon data={arrow_forward} color="secondary" />
            </FlexElement>
            <FlexElement style={{ flexGrow: 1, maxWidth: 1000 }}>
              {newStandardNotesContent}
            </FlexElement>
          </>
        )}
      </FlexContainer>
      <PanelButtonContainer>
        <Button
          onClick={() =>
            pcsCommandOnSelected({
              selectedItems,
              mainPlant,
              command:
                location === "PIPE-SECTION"
                  ? "standard-notes-pipe-elements"
                  : location === "VALVE-SECTION"
                  ? "standard-notes-valve-elements"
                  : "standard-notes-header",
              method:
                activeButton === "add"
                  ? "post"
                  : activeButton === "remove"
                  ? "delete"
                  : "put",
              pcsCommand,
              extraParams: {
                NoteID: selection[0],
                NoteLocation: location,
                NewNoteID: activeButton === "change" ? newSelection[0] : "",
                ...(["PIPE-SECTION", "VALVE-SECTION"].includes(location) && {
                  ElementID:
                    pipeElementSelection.length > 0
                      ? pipeElementSelection[0]
                      : "",
                  VDS: vdsSelection.length > 0 ? vdsSelection[0] : "",
                }),
              },
            })
          }
          disabled={
            selection.length === 0 ||
            selectedItems.length === 0 ||
            (activeButton === "change" && newSelection.length === 0)
          }
        >
          {activeButton === "add"
            ? "Add Standard Note"
            : activeButton === "remove"
            ? "Remove Standard Note"
            : "Change Standard Note"}
        </Button>
      </PanelButtonContainer>
    </PanelContainer>
  );
}

export const StandardNotesPanel = React.forwardRef(
  ({ selectedItems }: { selectedItems: any[] }, ref: React.Ref<any>) => {
    const mainPlant = useAppSelector(selectMainPlant);

    const {
      mutate: pcsCommand,
      status: pcsCommandStatus,
      reset: pcsCommandReset,
      error: pcsCommandError,
      data: pcsCommandData,
    } = usePCSCommandQuery();

    const { activeTab, handleChange } = useTabs({
      name: "StandardNotesPanelTabs",
    });

    return (
      <div ref={ref}>
        <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
          <Tabs.List>
            <Tabs.Tab>General</Tabs.Tab>
            <Tabs.Tab>Design Code</Tabs.Tab>
            <Tabs.Tab>Max. Design Pressure</Tabs.Tab>
            <Tabs.Tab>SC</Tabs.Tab>
            <Tabs.Tab>Pipe Section</Tabs.Tab>
            <Tabs.Tab>Valve Section</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              {activeTab === 0 && (
                <StandardNoteEditor
                  location="General"
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                  mainPlant={mainPlant}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 1 && (
                <StandardNoteEditor
                  location="DesignCode"
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                  mainPlant={mainPlant}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 2 && (
                <StandardNoteEditor
                  location="TempPress"
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                  mainPlant={mainPlant}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 3 && (
                <StandardNoteEditor
                  location="ServiceCode"
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                  mainPlant={mainPlant}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 4 && (
                <StandardNoteEditor
                  location="PIPE-SECTION"
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                  mainPlant={mainPlant}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 5 && (
                <StandardNoteEditor
                  location="VALVE-SECTION"
                  selectedItems={selectedItems}
                  pcsCommand={pcsCommand}
                  mainPlant={mainPlant}
                />
              )}
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
        <StatusModal
          status={pcsCommandStatus}
          successMessage={successMessage(pcsCommandData)}
          onSettledClose={pcsCommandReset}
          onSuccess={() => reloadPreviewPane("main")}
          error={pcsCommandError}
        />
      </div>
    );
  }
);

export default StandardNotesPanel;
