import { RevisionMark } from "../../../components/Components";
import { view_agenda } from "@equinor/eds-icons";
import {
  IconWithInput,
  DisplayAsFormElement,
  ElementNote,
} from "../hooks/useElementTable";

export const revmarkColumn = {
  key: "Revmark",
  title: "Rev.",
  longTitle: "Revision",
  Component: RevisionMark,
  width: 38,
};

export const pageBreakColumn = {
  key: "PageBreak",
  title: "PB",
  longTitle: "Page Break",
  Component: IconWithInput,
  type: "with-context",
  componentProps: {
    prop: "PageBreak",
    submitProp: "Pagebreak", // This has no uppercase B for a reason: the API accepts it only like this.
    icon: view_agenda,
    trueValue: "P",
    falseValue: "N",
    title: "Page Break",
  },
  width: 29,
};

export const fromSizeColumn = {
  key: "FromSize",
  title: "From",
  Component: DisplayAsFormElement,
  type: "with-context",
  componentProps: {
    prop: "FromSize",
  },
  width: 40,
};

export const toSizeColumn = {
  key: "ToSize",
  title: "To",
  Component: DisplayAsFormElement,
  type: "with-context",
  componentProps: {
    prop: "ToSize",
  },
  width: 40,
};

const noteColumnNoSection = {
  key: "NoteID",
  title: "Note",
  Component: ElementNote,
  type: "with-context",
  width: 43,
};

export const noteColumnPCS = {
  ...noteColumnNoSection,
  componentProps: {
    section: "PCS",
  },
};

export const noteColumnVDS = {
  ...noteColumnNoSection,
  componentProps: {
    section: "VDS",
  },
};

export const remarkColumn = {
  key: "Remark",
  title: "Remark",
  Component: DisplayAsFormElement,
  type: "with-context",
  componentProps: {
    prop: "Remark",
  },
  minWidth: 200,
};
