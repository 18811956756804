import { Icon, Menu } from "@equinor/eds-core-react";
import { ColumnsProps } from "../../components/table/Table";
import { border_all, border_horizontal, download } from "@equinor/eds-icons";
import ListMenu from "components/ListMenu";
import {
  SheetTypes,
  plantDependentSheets,
} from "features/sheets/config/sheetConfig";
import { downloadAsXLSX, downloadAsCSV } from "../../utils/download";
import { PlantProps } from "features/plant/types";

export function SheetDownloadButtons({
  columns,
  items,
  sheetType,
  plant,
  filteredItems,
  filename,
  hideDownloadAll,
}: {
  columns: ColumnsProps[];
  items?: any;
  sheetType: SheetTypes;
  plant: PlantProps;
  filteredItems?: any;
  filename: string;
  hideDownloadAll?: boolean;
}) {
  const finalFilename =
    (plantDependentSheets.includes(sheetType)
      ? plant?.ShortDescription + "-"
      : "") + filename;

  return (
    <>
      <ListMenu
        buttonContent={<Icon data={download} />}
        tooltipTitle="Download..."
      >
        <Menu.Item
          onClick={() => {
            downloadAsXLSX({
              columns,
              items: filteredItems,
              filename: finalFilename + "-filtered",
            });
          }}
          disabled={!filteredItems || filteredItems.length === 0}
          key="filtered-xlsx"
        >
          <Icon data={border_horizontal} /> Filtered data as XLSX
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            downloadAsCSV({
              columns,
              items: filteredItems,
              filename: finalFilename + "-filtered",
            });
          }}
          disabled={!filteredItems || filteredItems.length === 0}
          key="filtered-csv"
        >
          <Icon data={border_horizontal} /> Filtered data as CSV
        </Menu.Item>
        {!hideDownloadAll && (
          <Menu.Item
            onClick={() => {
              downloadAsXLSX({
                columns,
                items,
                filename: finalFilename + "-all",
              });
            }}
            disabled={!items || items.length === 0}
            key="all-xlsx"
          >
            <Icon data={border_all} /> All data as XLSX
          </Menu.Item>
        )}
        {!hideDownloadAll && (
          <Menu.Item
            onClick={() => {
              downloadAsCSV({
                columns,
                items,
                filename: finalFilename + "-all",
              });
            }}
            disabled={!items || items.length === 0}
            key="all-csv"
          >
            <Icon data={border_all} /> All data as CSV
          </Menu.Item>
        )}
      </ListMenu>
    </>
  );
}
