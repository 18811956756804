import { Icon, NativeSelect, Typography } from "@equinor/eds-core-react";
import { PCSSPEIssueMDSValidationData } from "./speIssueMDSValidationConfig";
import { useLocation } from "react-router-dom";
import usePlants from "queries/usePlants";
import { useMemo } from "react";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../ReportComponents";
import { ReportFormHeader } from "../ReportFormHeader";
import { reportsConfig } from "../Reports";
import { check_circle_outlined } from "@equinor/eds-icons";
import { combineQueryStatuses } from "queries/queryUtil";
import useOperators from "queries/useOperators";
import {
  GroupedPlantsOptions,
  groupPlants,
} from "features/plant/PlantSelector";

export const pcsSPEIssueMDSValidationReportColumns: {
  key: keyof PCSSPEIssueMDSValidationData[0];
  title: string;
}[] = [
  { key: "Plant", title: "Plant" },
  { key: "PCS", title: "PCS" },
  { key: "Revision", title: "Rev." },
  { key: "Status", title: "Status" },
  { key: "Item", title: "Item" },
  { key: "ElementGroup", title: "Element Group" },
  { key: "DimensionStandard", title: "Dim. Standard" },
  { key: "ProductForm", title: "Product Form" },
  { key: "Material", title: "Material" },
  { key: "ElementID", title: "Element ID" },
  { key: "ElementRevision", title: "Element Rev." },
  { key: "PCSMDS", title: "PCS MDS" },
  { key: "SPEMDS", title: "SPE MDS" },
  { key: "SPEMDSRevision", title: "SPE MDS Rev." },
  { key: "SPEMDSArea", title: "SPE MDS Area" },
  { key: "IssueMDS", title: "Issue MDS" },
  { key: "IssueMDSRevision", title: "Issue MDS Rev." },
  { key: "IssueMDSArea", title: "Issue MDS Area" },
];

export function PCSSPEIssueMDSValidationReport({
  data,
  filenameRef,
}: {
  data: PCSSPEIssueMDSValidationData | undefined;
  filenameRef: React.MutableRefObject<HTMLInputElement | null>;
}) {
  const { data: plants, status: plantsStatus } = usePlants();
  const { data: operators, status: operatorsStatus } = useOperators();
  const status = combineQueryStatuses(plantsStatus, operatorsStatus);
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const selectedPlant =
    plants &&
    plants.find(
      (e) => String(e.PlantID) === String(parsedSearch.get("plantID"))
    );

  return status === "loading" ? (
    <>Loading Plants...</>
  ) : status === "success" ? (
    <>
      <input
        type="hidden"
        value={
          selectedPlant?.ShortDescription +
          "-" +
          reportsConfig["pcs/spe-issuemds-validation"].title
        }
        ref={filenameRef}
      />
      <ReportFormHeader>
        <NativeSelect
          id="plantID"
          label="Plant"
          defaultValue={selectedPlant?.PlantID}
          style={{ maxWidth: 500 }}
        >
          {!selectedPlant && <option value="">Choose a plant...</option>}
          <GroupedPlantsOptions
            plantsGrouped={groupPlants(plants, operators)}
          />
        </NativeSelect>
      </ReportFormHeader>
      {typeof data !== "undefined" &&
        (data.length === 0 ? (
          <>
            <Typography variant="h4" style={{ display: "flex", gap: 8 }}>
              <Typography color="success">
                <Icon
                  data={check_circle_outlined}
                  style={{ marginTop: 3, marginLeft: 8 }}
                />
              </Typography>
              <div>
                {selectedPlant?.LongDescription}: Validation OK. Nothing to
                report.
              </div>
            </Typography>
          </>
        ) : (
          <ReportTable>
            <TableHead>
              <TableRow>
                {pcsSPEIssueMDSValidationReportColumns.map((e) => (
                  <HeadCell>{e.title}</HeadCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((d, i) => (
                <TableRow key={i}>
                  {pcsSPEIssueMDSValidationReportColumns.map((e) => (
                    <ContentCell>{d[e.key]}</ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </ReportTable>
        ))}
    </>
  ) : (
    <></>
  );
}
