import { EdsProvider, Icon } from "@equinor/eds-core-react";
import { IconType } from "@equinor/eds-core-react/dist/types/components/Icon/Icon.types";
import config from "app/config";
import {
  codelistProperties,
  Codelists,
  useCodelist,
} from "queries/useCodelist";
import styled from "styled-components";
import { TableCellContent } from "./Table";
import { RevisionMark } from "components/Components";
import { useAppSelector } from "app/hooks";
import { selectMainPlant } from "features/plant/plantSlice";
import { SheetTypes } from "features/sheets/config/sheetConfig";
import { PaneLocation } from "uiSlice";
import { PreviewButtons } from "features/sheets/SheetPane";

// extracted because of https://github.com/styled-components/vscode-styled-components/issues/292#issuecomment-1129477102
const tableIconAttrs = ({ color }: { color: string }) => ({
  size: 16,
  color: color ? color : "var(--textSemiLight)",
});

export const TableIcon = styled(Icon).attrs(tableIconAttrs)`
  padding-top: 3px;
`;

export function IconColumn<T>({
  item,
  prop,
  title,
  icon,
  trueValue,
  falseValue,
}: {
  item: T;
  prop: keyof T;
  title: string;
  icon: IconType;
  trueValue: string;
  falseValue: string;
}) {
  return (item[prop] as unknown) === trueValue ? (
    <TableIcon data={icon} title={title} />
  ) : (item[prop] as unknown) === falseValue ? (
    <></>
  ) : (
    <>{item[prop]}</>
  );
}

export function HTMLColumn<T>({ item, prop }: { item: T; prop: keyof T }) {
  return (
    <div
      className="HTMLColumn"
      dangerouslySetInnerHTML={{
        __html: (item[prop] as unknown as string).replaceAll(
          "../document_space/",
          config.documentBaseUrl
        ),
      }}
    />
  );
}

export function GroupPropertyWithCodelistColumn({
  item,
  groupProperty,
  property,
  codelist,
}: {
  item: any;
  groupProperty: string;
  property: string;
  codelist: Codelists;
}) {
  const { data, status } = useCodelist({ codelist });
  const content =
    status === "loading"
      ? ""
      : data
      ? item[groupProperty]
          .map((e: any) => e[property])
          .map((e: any) => {
            const codelistItem = data.find(
              (d: any) => d[codelistProperties[codelist].idProp] === e
            );
            return codelistItem
              ? (codelistItem as any)[codelistProperties[codelist].nameProp]
              : e;
          })
          .join(", ")
      : item[groupProperty].map((e: any) => e[property]).join(", ");

  return <TableCellContent title={content}>{content}</TableCellContent>;
}

export function RevisionMarkWithPreviewButtons({
  item,
  sheetType,
  revisionOverride,
  previewLocation,
}: {
  item: any;
  sheetType: SheetTypes;
  revisionOverride: string;
  previewLocation: PaneLocation;
}) {
  const plant = useAppSelector(selectMainPlant);
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <RevisionMark>{item.Revision}</RevisionMark>
      <EdsProvider density="compact">
        <div style={{ display: "flex" }}>
          <PreviewButtons
            sheetType={sheetType}
            item={{
              ...item,
              ...(revisionOverride ? { Revision: item[revisionOverride] } : {}),
              ...(sheetType === "vsm" ? { Format: "F" } : {}),
            }}
            plant={plant}
            previewLocation={previewLocation}
          />
        </div>
      </EdsProvider>
    </div>
  );
}
