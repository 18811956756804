import { useQuery } from "react-query";
import axios from "axios";
import config from "../app/config";

export interface PipeElementRev {
  ElementID: number;
  Revision: string;
  Status: string;
  DimensionStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  MDS: string;
  MDSRevision: string;
}

export default function usePipeElementRevList({
  elementId,
}: {
  elementId: number;
}) {
  return useQuery<PipeElementRev[], Error>(
    ["pipe-elements", elementId],
    async () => {
      const { data } = await axios.get(
        `${config.restApiBaseUrl}pmg/pipe-elements/elementid/${elementId}`
      );
      return data["getPipeElementAllRevList"];
    }
  );
}
