import { Button } from "@equinor/eds-core-react";
import useConfirm from "./useConfirm";
import {
  FatModal,
  modalStyle,
  ModalButtonContainer,
  ModalContainerFixed,
  ModalContainerAlways,
  ModalPadder,
} from "../Modal";

const ConfirmModal = () => {
  const { prompt = "", isOpen = false, proceed, cancel } = useConfirm();

  return (
    <FatModal isOpen={isOpen} style={modalStyle}>
      <ModalContainerFixed>
        <ModalPadder>{prompt}</ModalPadder>
      </ModalContainerFixed>
      <ModalContainerAlways>
        <ModalButtonContainer>
          <Button onClick={() => proceed && proceed()} disabled={!proceed}>
            OK
          </Button>
          <Button
            variant="outlined"
            onClick={() => cancel && cancel()}
            disabled={!cancel}
          >
            Cancel
          </Button>
        </ModalButtonContainer>
      </ModalContainerAlways>
    </FatModal>
  );
};

export default ConfirmModal;
