function escapeRegex(string: string) {
  return string.replace(/[/\-\\^$+?.()|[\]{}]/g, "\\$&");
}

export function filterItemsByName<T>({
  nameFilter,
  nameProperty,
  items,
  matchAnywhere,
  alwaysPresent,
}: {
  nameFilter: string;
  nameProperty: string | string[];
  items: T[];
  matchAnywhere?: boolean;
  alwaysPresent?: { idProp: string; ids: string[] };
}): T[] {
  if (!items) return [];
  const regexpFilter = new RegExp(
    `${matchAnywhere ? "" : "^"}${escapeRegex(nameFilter)
      .replaceAll("_", ".")
      .replaceAll("*", ".*")}`,
    "i"
  );
  return items.filter(
    (item: any) =>
      (alwaysPresent &&
        alwaysPresent.ids.includes(String(item[alwaysPresent.idProp]))) ||
      (Array.isArray(nameProperty)
        ? nameProperty.some((e) => String(item[e]).match(regexpFilter))
        : String(item[nameProperty]).match(regexpFilter))
  );
}
