import { Button } from "@equinor/eds-core-react";
import { useState } from "react";
import { ModalButtonContainer } from "components/Modal";
import {
  ModalWindow,
  ModalWindowPrimaryContainer,
  ModalWindowContentFillScrollable,
  ModalWindowContentFill,
  ModalWindowContentFixed,
  ModalWindowDefaultContainer,
} from "components/ModalWindow";
import styled from "styled-components";
import Table from "../../components/table/Table";

const ModalWindowButtons = styled.div``;

const table = false;
const multiTable = false;

export default function ModalWindowTestpage() {
  const [open, setOpen] = useState(false);
  const [openDefault, setOpenDefault] = useState(false);

  const tableContent = (
    <Table
      items={Array(200).fill({ Test: "Test" })}
      columns={[{ key: "Test", title: "Test" }]}
      selectionMode={false}
      itemIdProp="Test"
    />
  );

  return (
    <div>
      <div style={{ display: "flex", gap: 12, padding: 12 }}>
        <Button onClick={() => setOpen(true)}>Open primary ModalWindow</Button>
        <Button onClick={() => setOpenDefault(true)}>
          Open default ModalWindow
        </Button>
      </div>
      <ModalWindow
        isOpen={openDefault}
        closeModal={() => setOpenDefault(false)}
        title="default"
        layer="default"
      >
        <ModalWindowDefaultContainer>Test</ModalWindowDefaultContainer>
      </ModalWindow>
      <ModalWindow
        isOpen={open}
        closeModal={() => setOpen(false)}
        title="MW"
        layer="primary"
      >
        <ModalWindowPrimaryContainer>
          {table ? (
            multiTable ? (
              <ModalWindowContentFill>
                <ModalWindowPrimaryContainer>
                  <ModalWindowContentFixed>
                    Hello, this is my area.
                  </ModalWindowContentFixed>
                  <ModalWindowContentFill>
                    {tableContent}
                  </ModalWindowContentFill>
                </ModalWindowPrimaryContainer>
              </ModalWindowContentFill>
            ) : (
              <ModalWindowContentFill>{tableContent}</ModalWindowContentFill>
            )
          ) : (
            <ModalWindowContentFillScrollable>
              {Array(200)
                .fill("")
                .map((e, i) => (
                  <div>test {i}</div>
                ))}
            </ModalWindowContentFillScrollable>
          )}
          <ModalWindowContentFixed>
            <ModalWindowButtons>
              <ModalButtonContainer>
                <Button>Test</Button>
                <Button variant="outlined">Test</Button>
              </ModalButtonContainer>
            </ModalWindowButtons>
          </ModalWindowContentFixed>
        </ModalWindowPrimaryContainer>
      </ModalWindow>
    </div>
  );
}
