import { Typography } from "@equinor/eds-core-react";
import { RatingGroupsData } from "./ratingGroupsConfig";
import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableHead,
  TableRow,
} from "../ReportComponents";

export function RatingGroupsReport({ data }: { data: RatingGroupsData }) {
  const groups = data.reduce(
    (a, c) => (a.includes(c.GroupID) ? a : a.concat(c.GroupID)),
    [] as string[]
  );
  const ratingClasses = groups.map((group) =>
    data
      .filter((d) => d.GroupID === group)
      .reduce(
        (a, c) => (a.includes(c.RatingClass) ? a : a.concat(c.RatingClass)),
        [] as string[]
      )
  );
  const temperatures = groups.map((group) =>
    data
      .filter((d) => d.GroupID === group)
      .reduce(
        (a, c) => (a.includes(c.Temperature) ? a : a.concat(c.Temperature)),
        [] as number[]
      )
  );

  return (
    <>
      {groups.map((group, groupIndex) => (
        <div style={{ marginBottom: "2em" }}>
          <Typography variant="h4" style={{ marginBottom: "0.5em" }}>
            Rating Group {group}
          </Typography>
          <ReportTable>
            <TableHead>
              <HeadCell></HeadCell>
              {ratingClasses[groupIndex].map((ratingClass) => (
                <HeadCell>{ratingClass}</HeadCell>
              ))}
            </TableHead>
            <TableBody>
              {temperatures[groupIndex].map((temperature) => (
                <TableRow>
                  <HeadCell>{temperature}°C</HeadCell>
                  {ratingClasses[groupIndex].map((ratingClass) => (
                    <ContentCell>
                      {
                        data.find(
                          (d) =>
                            d.GroupID === group &&
                            d.RatingClass === ratingClass &&
                            d.Temperature === temperature
                        )?.Pressure
                      }
                    </ContentCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </ReportTable>
        </div>
      ))}
    </>
  );
}
