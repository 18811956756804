import {
  Button,
  CircularProgress,
  Icon,
  Search,
  Tooltip,
} from "@equinor/eds-core-react";
import { add, refresh } from "@equinor/eds-icons";
import {
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
} from "components/Components";
import { Download } from "components/Download";
import Filters, { FilterProps } from "components/table/Filters";
import {
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "components/table/Table";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ListAdminAreas,
  ListAdminProps,
  listAdminConfig,
} from "./listAdminConfig";
import { PFL2MappingFilter } from "./product-form-level2-mapping/PFL2MappingFilter";
import { PFL2Mapping } from "./product-form-level2-mapping/pfl2MappingConfig";

export function ListControlHeader<T extends ListAdminAreas>({
  area,
  data,
  isRefetching,
  refetch,
  nameFilter,
  setNameFilter,
  filters,
  filtersDispatch,
}: {
  area: T;
  data?: ListAdminProps<T>[];
  isRefetching: boolean;
  refetch: Function;
  nameFilter: string;
  setNameFilter: React.Dispatch<string>;
  filters: FilterProps[];
  filtersDispatch: React.Dispatch<{
    type: "update" | "reset";
    payload?: FilterProps | undefined;
  }>;
}) {
  const history = useHistory();

  const { title, singularTitle, columns } = listAdminConfig[area];
  const [categoryFilter, setCategoryFilter] = useState("");

  return (
    <ControlHeaderContainer>
      {area === "product-form-level2-mapping" && (
        <PFL2MappingFilter
          data={data as PFL2Mapping[]}
          filtersDispatch={filtersDispatch}
          categoryFilter={categoryFilter}
          setCategoryFilter={setCategoryFilter}
        />
      )}
      <FlexContainer style={{ justifyContent: "flex-start", gap: 12 }}>
        <FlexElement style={{ marginLeft: 16 }}>
          <Search
            placeholder="Search..."
            value={nameFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setNameFilter(e.target.value)
            }
          />
        </FlexElement>
        {filters.length > 0 && (
          <FlexElement>
            <Filters
              filters={filters}
              groupProperties={[{ group: "", col: 1 }]}
              maxWidth={500}
            />
          </FlexElement>
        )}
        <ControlHeaderTitle>{title}</ControlHeaderTitle>
        <FlexContainer style={{ marginLeft: "auto" }}>
          <HeaderButtonContainer>
            <FlexElement>
              <CircularProgress
                size={24}
                style={{
                  visibility: isRefetching ? "visible" : "hidden",
                  marginRight: "12px",
                  marginTop: "3px",
                }}
              />
            </FlexElement>
            <FlexElement>
              <Tooltip title="Reload">
                <Button variant="ghost_icon" onClick={() => refetch()}>
                  <Icon data={refresh} />
                </Button>
              </Tooltip>
            </FlexElement>
            <FlexElement>
              <Download items={data} columns={columns} filename={title} />
            </FlexElement>
            <FlexElement>
              <Tooltip title={`Create ${singularTitle}`}>
                <Button
                  variant="ghost_icon"
                  onClick={() => history.replace({ search: "createNew" })}
                >
                  <Icon data={add} />
                </Button>
              </Tooltip>
            </FlexElement>
          </HeaderButtonContainer>
        </FlexContainer>
      </FlexContainer>
    </ControlHeaderContainer>
  );
}
