import {
  Button,
  CircularProgress,
  Icon,
  Menu,
  Search,
  Switch,
  Tooltip,
} from "@equinor/eds-core-react";
import {
  add,
  copy,
  delete_multiple,
  label,
  more_horizontal,
  refresh,
  skip_next,
} from "@equinor/eds-icons";
import { useMemoOne as useMemo } from "use-memo-one";
import {
  Fade,
  FlexContainer,
  FlexElement,
  HeaderButtonContainer,
} from "../../components/Components";
import ListMenu from "../../components/ListMenu";
import {
  ColumnsProps,
  ControlHeaderContainer,
  ControlHeaderTitle,
} from "../../components/table/Table";
import Filters, { FilterProps } from "../../components/table/Filters";
import { PlantProps } from "../plant/types";
import { newRevisionsFromSelected } from "./commands";
import {
  getElementNamePlural,
  plantDependentSheets,
  sheetTypeNames,
  SheetTypes,
} from "./config/sheetConfig";
import { showSheetCopyModal } from "./sheetSlice";
import { VDSTextBlock } from "./types";
import { cannotBeRevised } from "./util";
import { CopyVDSTextBlockQuery } from "queries/useCopyVDSTextBlock";
import { NewSheetRevisions } from "queries/useNewSheetRevisions";
import { SetMultipleSheetStatus } from "queries/useSetMultipleSheetStatus";
import useSaveSetting from "queries/settings/useSaveSetting";
import { settingKey } from "utils/settings";
import { VDSTaskMonitorButton } from "./VDSTaskMonitor";
import { SheetDownloadButtons } from "features/sheets/ExportButtons";
import { NewEDSOperation } from "./modals/CreateEDS";

export function SheetControlHeader({
  plant,
  mainPlant,
  isCopyModal,
  sheetType,
  selectedItems,
  deleteSelected,
  deleteSheets,
  hasName,
  sheetTypeName,
  nameProperty,
  nameFilter,
  setNameFilter,
  isConfirmed,
  newSheetRevisions,
  setMultipleSheetStatus,
  filters,
  mainItemsSheets,
  filteredItems,
  columns,
  refetch,
  isRefetching,
  dispatch,
  filterPlaceholder,
  containerSize,
  basedOnSubsegmentFilter,
  setBasedOnSubsegmentFilter,
  setShowCreateNew,
  setNewEDSOperation,
  isWorkbenchCommand,
  copyVDSTextBlock,
  filterKey,
  filterKeySheetType,
}: {
  plant: PlantProps;
  mainPlant: PlantProps;
  isCopyModal: boolean;
  sheetType: SheetTypes;
  selectedItems: any[];
  deleteSelected: Function;
  deleteSheets: Function;
  hasName: boolean;
  sheetTypeName: string;
  nameProperty: string;
  nameFilter: string;
  setNameFilter: Function;
  isConfirmed: Function;
  newSheetRevisions: NewSheetRevisions["mutate"];
  setMultipleSheetStatus: SetMultipleSheetStatus["mutate"];
  filters: FilterProps[];
  mainItemsSheets: any[];
  filteredItems: any[];
  columns: ColumnsProps[];
  refetch: Function;
  isRefetching: boolean;
  dispatch: Function;
  filterPlaceholder: string;
  containerSize: any;
  basedOnSubsegmentFilter?: "N" | "Y";
  setBasedOnSubsegmentFilter?: Function;
  setShowCreateNew: React.Dispatch<boolean>;
  setNewEDSOperation: React.Dispatch<NewEDSOperation>;
  isWorkbenchCommand: boolean;
  copyVDSTextBlock: CopyVDSTextBlockQuery["mutate"];
  filterKey: string;
  filterKeySheetType: string;
}) {
  const filterGroupProperties = useMemo(
    () => [
      { group: "Valve Characteristics", col: 1 },
      { group: "Valve Types", col: 1 },
      { group: "Selections", col: 2 },
      { group: "Item", col: 3 },
      { group: "Statuses", col: 5 },
      { group: "Users", col: 6 },
      { group: "Priority", col: 6 },
    ],
    []
  );

  const elementName = getElementNamePlural(sheetType);

  const { mutate: saveSetting } = useSaveSetting();

  return (
    <ControlHeaderContainer>
      <FlexContainer>
        <FlexElement
          style={{
            textAlign: "right",
            paddingRight: "8px",
          }}
        >
          {isCopyModal ? (
            <></>
          ) : (
            <ListMenu
              buttonContent={<Icon data={more_horizontal}></Icon>}
              placement="bottom-start"
              disabled={selectedItems.length === 0}
            >
              <Menu.Item
                onClick={() =>
                  deleteSelected({
                    selectedItems,
                    hasName,
                    nameProperty,
                    mainPlant,
                    deleteSheets,
                    sheetType,
                    isConfirmed,
                  })
                }
                disabled={
                  selectedItems.length === 0 ||
                  JSON.stringify(
                    Array.from(new Set(selectedItems.map((v: any) => v.Status)))
                  ) !== JSON.stringify(["W"])
                }
              >
                <Icon size={16} data={delete_multiple} />
                Delete selected {elementName}
              </Menu.Item>
              {!isWorkbenchCommand ? (
                <Menu.Item
                  onClick={() =>
                    newRevisionsFromSelected({
                      selectedItems,
                      hasName,
                      nameProperty,
                      mainPlant,
                      newSheetRevisions,
                      sheetType,
                      isConfirmed,
                    })
                  }
                  disabled={
                    selectedItems.length === 0 ||
                    selectedItems
                      .map((item) =>
                        cannotBeRevised(item, mainItemsSheets, sheetType)
                      )
                      .reduce((acc, curr) => acc || curr)
                  }
                >
                  <Icon size={16} data={skip_next} />
                  {sheetType === "pcs"
                    ? `New (sub) revisions from selected data sheets`
                    : `New revisions from selected ${elementName}`}
                </Menu.Item>
              ) : (
                <></>
              )}
              {sheetType === "vds-textblocks" && (
                <Menu.Item
                  onClick={() =>
                    copyVDSTextBlock({
                      textblocks: selectedItems.map((e: VDSTextBlock) => ({
                        textBlockId: String(e.TextBlockID),
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) =>
                      a || (c.Status !== "O" && c.Status !== "W")
                        ? true
                        : false,
                    false
                  )}
                >
                  <Icon size={16} data={copy} />
                  Copy and create new from selected {elementName}
                </Menu.Item>
              )}
              {sheetType === "vds-textblocks" && (
                <Menu.Item
                  onClick={() =>
                    setMultipleSheetStatus({
                      sheetType: "vds-textblocks",
                      status: "O",
                      sheets: selectedItems.map((e: VDSTextBlock) => ({
                        name: String(e.TextBlockID),
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) =>
                      a || (c.Status !== "W" && c.Status !== "E")
                        ? true
                        : false,
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Official (O)
                </Menu.Item>
              )}
              {sheetType === "vds-textblocks" && (
                <Menu.Item
                  onClick={() =>
                    setMultipleSheetStatus({
                      sheetType: "vds-textblocks",
                      status: "E",
                      sheets: selectedItems.map((e: VDSTextBlock) => ({
                        name: String(e.TextBlockID),
                        revision: e.Revision,
                      })),
                    })
                  }
                  disabled={selectedItems.reduce(
                    (a, c) => (a || c.Status !== "O" ? true : false),
                    false
                  )}
                >
                  <Icon size={16} data={label} />
                  Set selected {elementName} Expired (E)
                </Menu.Item>
              )}
            </ListMenu>
          )}
        </FlexElement>
        <FlexElement style={{ flexGrow: 2 }}>
          <FlexContainer style={{ justifyContent: "flex-start", gap: 12 }}>
            <FlexElement>
              <Search
                onChange={(e) => {
                  setNameFilter(e.target.value);
                  if (e.target.value === "") {
                    saveSetting({
                      key: settingKey([filterKeySheetType, "nameFilter"]),
                      content: e.target.value,
                    });
                  }
                }}
                onBlur={() => {
                  saveSetting({
                    key: settingKey([filterKeySheetType, "nameFilter"]),
                    content: nameFilter,
                  });
                }}
                placeholder={filterPlaceholder}
                value={nameFilter}
                style={{ width: "199px" }}
              />
            </FlexElement>
            <FlexElement>
              <Filters
                filters={filters}
                groupProperties={filterGroupProperties}
                maxWidth={containerSize.width}
                filterKey={filterKey}
              />
            </FlexElement>
            <FlexElement>
              <ControlHeaderTitle>
                {sheetTypeName}
                {plantDependentSheets.includes(sheetType) ? (
                  <>
                    <Fade> in </Fade>
                    {plant.LongDescription}
                  </>
                ) : null}
              </ControlHeaderTitle>
            </FlexElement>
            {sheetType === "vds" && (
              <FlexElement style={{ paddingLeft: "1em", margin: "-4px 0" }}>
                <Switch
                  checked={basedOnSubsegmentFilter === "Y"}
                  label="Structured"
                  onChange={() =>
                    setBasedOnSubsegmentFilter &&
                    setBasedOnSubsegmentFilter(
                      basedOnSubsegmentFilter === "Y" ? "N" : "Y"
                    )
                  }
                />
              </FlexElement>
            )}
          </FlexContainer>
        </FlexElement>
        <HeaderButtonContainer>
          <div>
            <CircularProgress
              size={24}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                marginTop: "3px",
              }}
            />
          </div>
          <Tooltip title="Reload">
            <Button variant="ghost_icon" onClick={() => refetch()}>
              <Icon data={refresh} />
            </Button>
          </Tooltip>
          <SheetDownloadButtons
            columns={columns}
            sheetType={sheetType}
            plant={plant}
            items={mainItemsSheets}
            filteredItems={filteredItems}
            filename={`${sheetTypeNames[sheetType]}${
              sheetType === "vds"
                ? basedOnSubsegmentFilter === "Y"
                  ? "-structured"
                  : "-unstructured"
                : ""
            }`}
            hideDownloadAll={
              sheetType === "vds" || isCopyModal || isWorkbenchCommand
            }
          />
          {(
            ["vds", "vds-subsegments", "vds-textblocks"] as SheetTypes[]
          ).includes(sheetType) && <VDSTaskMonitorButton />}
          {isCopyModal || !plantDependentSheets.includes(sheetType) ? (
            <></>
          ) : (
            <Tooltip title="Copy data sheets to this plant">
              <Button
                variant="ghost_icon"
                onClick={() => dispatch(showSheetCopyModal())}
              >
                <Icon data={copy} />
              </Button>
            </Tooltip>
          )}
          {["vds-textblocks"].includes(sheetType) && (
            <Tooltip title={`Create new ${sheetTypeNames[sheetType]}`}>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  setShowCreateNew(true);
                }}
              >
                <Icon data={add} />
              </Button>
            </Tooltip>
          )}
          {["eds"].includes(sheetType) && (
            <Tooltip title={`Create new ${sheetTypeNames[sheetType]}`}>
              <Button
                variant="ghost_icon"
                onClick={() => {
                  setNewEDSOperation("Add");
                }}
              >
                <Icon data={add} />
              </Button>
            </Tooltip>
          )}
        </HeaderButtonContainer>
      </FlexContainer>
    </ControlHeaderContainer>
  );
}
