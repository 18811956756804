import { Button } from "@equinor/eds-core-react";
import { ErrorMessage } from "components/Components";
import useConfirm from "components/confirm/useConfirm";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  FieldValues,
  FormProvider,
  UseFieldArrayInsert,
  UseFieldArrayUpdate,
  UseFieldArrayMove,
  useForm,
} from "react-hook-form";
import { QueryStatus } from "react-query";
import { useHistory } from "react-router-dom";
import { FieldContext } from "../../components/form/FieldContext";
import { closeAdminModal } from "./Admin";
import {
  ListAdminAreas,
  listAdminConfig,
  ListAdminProps,
} from "./listAdminConfig";
import { MaterialGroupsEdit } from "./material-groups/MaterialGroupsEdit";
import { PFL2MappingEdit } from "./product-form-level2-mapping/PFL2MappingEdit";
import { VDSSubsegmentGroupsEdit } from "./vds-subsegment-groups/VDSSubsegmentGroupsEdit";

export function ListEditModal<T extends ListAdminAreas>({
  edit,
  createNew,
  area,
  data,
  status,
  update,
  insert,
  move,
  submit,
  isRefetching,
  saveAdminStatus,
  confirmPFL2MappingStatus,
  currentGroupId,
}: {
  edit: string | null;
  createNew: boolean;
  area: T;
  data?: ListAdminProps<T>[];
  status: QueryStatus;
  update: UseFieldArrayUpdate<FieldValues>;
  insert: UseFieldArrayInsert<FieldValues>;
  move: UseFieldArrayMove;
  submit: () => void;
  isRefetching: boolean;
  saveAdminStatus: QueryStatus;
  confirmPFL2MappingStatus: QueryStatus;
  currentGroupId: React.MutableRefObject<number>;
}) {
  const history = useHistory();
  const closeModal = useCallback(() => {
    closeAdminModal({ history });
  }, [history]);
  const { singularTitle, itemIdProp, groupIdProp } = listAdminConfig[area];

  const item = useMemo(
    () =>
      data &&
      edit &&
      data.find((e: ListAdminProps<T>) => String(e[itemIdProp]) === edit),
    [data, edit, itemIdProp]
  );
  const index = useMemo(
    () =>
      data && edit
        ? data.findIndex(
            (e: ListAdminProps<T>) => String(e[itemIdProp]) === String(edit)
          )
        : null,
    [data, edit, itemIdProp]
  );

  const [position, setPosition] = useState(0);
  const [editLoading, setEditLoading] = useState(false);

  useEffect(
    () =>
      data && setPosition(createNew ? data.length : index === null ? 0 : index),
    [data, index, createNew]
  );

  const methods = useForm<ListAdminProps<T>>({
    shouldUnregister: true,
    mode: "all",
  });
  const { handleSubmit, register, formState, reset } = methods;

  const onSubmit = handleSubmit((submittedItem) => {
    if (index === null) {
      insert(position, submittedItem);
      if (groupIdProp) {
        currentGroupId.current = Number(submittedItem[groupIdProp]);
      }
      submit();
    }
    if (typeof index === "number" && index >= -1) {
      update(index, submittedItem);
      move(index, position);
      if (groupIdProp) {
        currentGroupId.current = Number(submittedItem[groupIdProp]);
      }
      submit();
    }
  });

  useEffect(() => {
    item && reset(item);
  }, [item, reset]);

  useEffect(() => {
    createNew && reset();
  }, [createNew, reset]);

  const { isConfirmed } = useConfirm();

  const checkIfDirtyThenCloseModal = async () => {
    Object.keys(formState.dirtyFields).length > 0
      ? (await isConfirmed(
          <>
            Unsaved changes will be lost. Are you sure you want to close the
            window?
          </>
        )) && closeModal()
      : closeModal();
  };

  return (
    <ModalWindow
      closeModal={closeModal}
      isOpen={!!edit || !!createNew}
      title={edit ? `Edit ${singularTitle} ${edit}` : `New ${singularTitle}`}
    >
      {createNew || (edit && data && item) ? (
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <FieldContext.Provider
              value={{
                disabled:
                  isRefetching ||
                  saveAdminStatus === "loading" ||
                  confirmPFL2MappingStatus === "loading",
                slim: true,
              }}
            >
              <ModalSideMargin>
                <ModalWindowContentDefault style={{ minWidth: 500 }}>
                  <input
                    type="hidden"
                    // @ts-ignore
                    {...register(itemIdProp, {
                      valueAsNumber: true,
                      value: item ? (item[itemIdProp] as string | number) : "",
                    })}
                  />
                  <input
                    type="hidden"
                    // @ts-ignore
                    {...register("Action", {
                      value: createNew ? "N" : "",
                    })}
                  />
                  {area === "material-groups" && (
                    <MaterialGroupsEdit
                      item={item as ListAdminProps<"material-groups">}
                      data={data as ListAdminProps<"material-groups">[]}
                      position={position}
                      setPosition={setPosition}
                    />
                  )}
                  {area === "product-form-level2-mapping" && (
                    <PFL2MappingEdit
                      item={
                        item as ListAdminProps<"product-form-level2-mapping">
                      }
                      data={
                        data as ListAdminProps<"product-form-level2-mapping">[]
                      }
                      setEditLoading={setEditLoading}
                      status={status}
                      position={position}
                      setPosition={setPosition}
                    />
                  )}
                  {area === "vds-subsegment-groups" && (
                    <VDSSubsegmentGroupsEdit
                      item={item as ListAdminProps<"vds-subsegment-groups">}
                      data={data as ListAdminProps<"vds-subsegment-groups">[]}
                      position={position}
                      setPosition={setPosition}
                    />
                  )}
                </ModalWindowContentDefault>
              </ModalSideMargin>
              <ModalWindowButtonContainer>
                <Button
                  type="submit"
                  disabled={
                    editLoading ||
                    isRefetching ||
                    saveAdminStatus === "loading" ||
                    Object.keys(formState.errors).length > 0
                  }
                >
                  {createNew ? "Create" : "Update"}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => checkIfDirtyThenCloseModal()}
                >
                  Close
                </Button>
              </ModalWindowButtonContainer>
            </FieldContext.Provider>
          </form>
        </FormProvider>
      ) : (
        <ModalSideMargin>
          <ErrorMessage>No such item.</ErrorMessage>
        </ModalSideMargin>
      )}
    </ModalWindow>
  );
}
