import {
  Button,
  Checkbox,
  NativeSelect,
  Popover as EDSPopover,
  Typography,
} from "@equinor/eds-core-react";
import { filter_alt, filter_alt_active } from "@equinor/eds-icons";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { useMemo } from "use-memo-one";
import { Codelists } from "../../queries/useCodelist";
import Table, { SmallTableContainer } from "./Table";
import useTable from "./useTable";
import { portalContainer } from "../../utils/util";
import {
  ControlButtonContainer,
  FlexContainer,
  FlexElement,
  IconButton,
  InputContainer,
  UnstyledList,
} from "../Components";
import useSaveSetting from "queries/settings/useSaveSetting";
import { getSettingsObject } from "utils/settings";
import { useSomeSettings } from "queries/settings/useSomeSettings";

const Popover = styled(EDSPopover)`
  max-width: none;
  & > div {
    max-width: none;
  }
  & > button {
    right: 8px !important;
  }
`;

export interface FilterProps {
  type: "select" | "multiselect" | "text" | "checkboxes";
  group: string;
  title: string;
  prop: string;
  filterState: string | string[];
  setFilterState: Dispatch<SetStateAction<any>>;
  defaultFilterState: string | string[];
  commaSeparated?: boolean;
  filterOptions?: string[] | { key: string; title: string | JSX.Element }[];
  extraFilterOptions?: any;
  codelist?: Codelists;
  isArray?: boolean;
}

interface GroupProps {
  group: string;
  col: number;
}

const FilterColumn = styled(FlexElement)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  > div:not(:last-child) {
    margin-bottom: 1.5em;
  }
`;

export function filtersReducer(
  state: FilterProps[],
  action: { type: "update" | "reset"; payload?: FilterProps }
) {
  const groupSort = (a: FilterProps, b: FilterProps) =>
    a.group < b.group ? -1 : a.group > b.group ? 1 : 0;
  const { type, payload } = action;
  return type === "reset"
    ? []
    : !payload
    ? state
    : state.map((s) => s.prop).includes(payload.prop)
    ? state
        .filter((s) => s.prop !== payload.prop)
        .concat(payload)
        .sort(groupSort)
    : state.concat(payload).sort(groupSort);
}

function FilterPopover({
  filters,
  groupProperties,
  maxWidth,
  anchorRef,
  isOpen,
  closePopover,
}: {
  filters: FilterProps[];
  groupProperties: GroupProps[];
  maxWidth: number;
  anchorRef: React.RefObject<HTMLElement>;
  isOpen: boolean;
  closePopover: () => void;
}) {
  const filterCols = groupProperties
    .filter((gp) => filters.map((f) => f.group).includes(gp.group))
    .reduce((acc: number[], curr: any) => {
      return acc.includes(curr.col) ? acc : acc.concat(curr.col);
    }, []);

  const resetToDefaults = () => {
    filters.forEach((f) => {
      f.setFilterState(f.defaultFilterState);
    });
  };

  return ReactDOM.createPortal(
    <Popover anchorEl={anchorRef.current} open={isOpen} onClose={closePopover}>
      <Popover.Content
        style={{
          overflow: "auto",
          maxWidth: maxWidth - 50,
          maxHeight: "calc(80vh - 46px)",
        }}
      >
        <FlexContainer
          style={{
            alignItems: "flex-start",
            marginRight: "1.5em",
            justifyContent: "flex-start",
          }}
        >
          {filterCols.map((filterCol) => (
            <FilterColumn
              key={filterCol}
              style={{
                padding: ".5em .5em 0",
                margin: ".5em .75em",
              }}
            >
              {groupProperties
                .filter((gp) => gp.col === filterCol)
                .filter((gp) => filters.map((f) => f.group).includes(gp.group))
                .map((filterGroup) => (
                  <div key={filterGroup.group}>
                    {filterGroup.group && (
                      <Typography
                        color="primary"
                        variant="caption"
                        style={{
                          borderBottom: "1px solid var(--bordeDefault)",
                          paddingBottom: ".5em",
                          marginBottom: ".5em",
                        }}
                      >
                        {filterGroup.group}
                      </Typography>
                    )}
                    {filters
                      .filter(
                        (filter: FilterProps) =>
                          filter.group === filterGroup.group
                      )
                      .map((filter: FilterProps) => (
                        <InputContainer key={filter.prop}>
                          {filter.type === "checkboxes" ? (
                            <CheckboxesFilter
                              filterOptions={
                                filter?.filterOptions
                                  ? filter.filterOptions
                                  : []
                              }
                              filterState={filter.filterState as string[]}
                              setFilterState={filter.setFilterState}
                            />
                          ) : filter.type === "select" ? (
                            <SelectFilter
                              id={filter.prop}
                              title={filter.title}
                              filterOptions={
                                filter?.filterOptions
                                  ? filter.filterOptions
                                  : []
                              }
                              filterState={filter.filterState as string}
                              setFilterState={filter.setFilterState}
                            />
                          ) : filter.type === "multiselect" ? (
                            <MultiselectFilter
                              filterOptions={
                                filter?.filterOptions
                                  ? filter.filterOptions
                                  : []
                              }
                              filterState={filter.filterState as string[]}
                              setFilterState={filter.setFilterState}
                            />
                          ) : (
                            <></>
                          )}
                        </InputContainer>
                      ))}
                  </div>
                ))}
            </FilterColumn>
          ))}
        </FlexContainer>
        <ControlButtonContainer style={{ justifyContent: "center" }}>
          <Button onClick={() => closePopover()}>Close</Button>
          <Button variant="outlined" onClick={() => resetToDefaults()}>
            Reset to defaults
          </Button>
        </ControlButtonContainer>
      </Popover.Content>
    </Popover>,
    portalContainer
  );
}

export default function Filters({
  filters,
  groupProperties,
  maxWidth,
  filterKey,
}: {
  filters: FilterProps[];
  groupProperties: GroupProps[];
  maxWidth: number;
  filterKey?: string;
}) {
  return filters.length > 0 ? (
    <FiltersDefined
      filters={filters}
      groupProperties={groupProperties}
      maxWidth={maxWidth}
      filterKey={filterKey}
    />
  ) : null;
}

function FiltersDefined({
  filters,
  groupProperties,
  maxWidth,
  filterKey,
}: {
  filters: FilterProps[];
  groupProperties: GroupProps[];
  maxWidth: number;
  filterKey?: string;
}) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { mutate: saveSetting } = useSaveSetting();
  const settingsKeys = filters.map((filter) => [filterKey, filter.prop].join());
  const settingsResults = useSomeSettings({ keys: settingsKeys });
  const settings = getSettingsObject({ settingsKeys, settingsResults });

  useEffect(() => {
    filters.forEach((filter) => {
      const storedSetting = settings[[filterKey, filter.prop].join()];
      if (storedSetting !== null) {
        filter.setFilterState(
          filter.isArray
            ? storedSetting.split(",").filter((e) => !!e)
            : storedSetting
        );
      } else {
        filter.setFilterState(filter.defaultFilterState);
      }
    });

    // This effect needs to run when the filterKey changes,
    // and not when the filters change, hence filters are omitted.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterKey]);

  const closePopover = () => {
    setIsOpen(false);

    // Filters are only saved if filterKey is provided.
    if (filterKey) {
      filters.forEach((filter) => {
        const filterContent =
          typeof filter.filterState === "string"
            ? filter.filterState
            : filter.filterState.join();
        const settingKey = [filterKey, filter.prop].join();
        filterKey &&
          settings[[filterKey, filter.prop].join()] !== filterContent &&
          saveSetting({
            key: settingKey,
            content: filterContent,
          });
      });
    }
  };
  const togglePopover = () => (isOpen ? closePopover() : setIsOpen(true));

  const isDefault = useMemo(
    () =>
      filters.reduce(
        (a, c) =>
          a &&
          (Array.isArray(c.filterState) && Array.isArray(c.defaultFilterState)
            ? c.filterState.join() === c.defaultFilterState.join()
            : c.filterState === c.defaultFilterState)
            ? true
            : false,
        true
      ),
    [filters]
  );

  return filters.length > 0 ? (
    <>
      <IconButton
        title="Filters"
        iconData={isDefault ? filter_alt : filter_alt_active}
        ref={anchorRef}
        variant="outlined"
        onClick={togglePopover}
      />
      <FilterPopover
        filters={filters}
        groupProperties={groupProperties}
        maxWidth={maxWidth}
        anchorRef={anchorRef}
        isOpen={isOpen}
        closePopover={closePopover}
      />
    </>
  ) : null;
}

function CheckboxesFilter({
  filterOptions,
  filterState,
  setFilterState,
}: {
  filterOptions: string[] | { key: string; title: string | JSX.Element }[];
  filterState: string[];
  setFilterState: Dispatch<SetStateAction<string[]>>;
}) {
  return (
    <UnstyledList style={{ marginLeft: "-12px" }}>
      {filterOptions.map((option) => {
        const key = typeof option === "string" ? option : option.key;
        const title = typeof option === "string" ? option : option.title;
        return (
          <li key={key}>
            <Checkbox
              // ignore needed because label does not accept JSX by type definition, although it works
              // @ts-ignore
              label={title}
              value={key}
              checked={filterState.includes(key)}
              onChange={() =>
                filterState.includes(key)
                  ? setFilterState(filterState.filter((i: string) => i !== key))
                  : setFilterState(filterState.concat(key))
              }
            />
          </li>
        );
      })}
    </UnstyledList>
  );
}

function SelectFilter({
  filterOptions,
  filterState,
  setFilterState,
  id,
  title,
}: {
  filterOptions: string[] | { key: string; title: string | JSX.Element }[];
  filterState: string;
  setFilterState: Dispatch<SetStateAction<string>>;
  id: string;
  title: string;
}) {
  return (
    <NativeSelect
      id={id}
      label={title}
      value={filterState}
      onChange={(e) => {
        setFilterState(e.target.value);
      }}
      style={{ width: 200 }}
    >
      <option value="" key="">
        *
      </option>
      {filterOptions.map((option) => {
        const key = typeof option === "string" ? option : option.key;
        const title = typeof option === "string" ? option : option.title;
        return (
          <option key={key} value={key}>
            {title}
          </option>
        );
      })}
    </NativeSelect>
  );
}

const columns = [{ key: "title", title: "", width: "100%" }];

function MultiselectFilter({
  filterOptions,
  filterState,
  setFilterState,
}: {
  filterOptions: string[] | { key: string; title: string | JSX.Element }[];
  filterState: string[];
  setFilterState: Dispatch<SetStateAction<string[]>>;
}) {
  const { selectionDispatch, selection } = useTable({
    selectionMode: "multi",
    selection: filterState,
  });

  useEffect(() => setFilterState(selection), [setFilterState, selection]);

  return (
    <SmallTableContainer style={{ width: 300, height: 300 }}>
      <Table
        density="compact"
        items={filterOptions}
        itemIdProp="key"
        columns={columns}
        selectionMode="multi"
        selection={selection}
        selectionDispatch={selectionDispatch}
        fullRowSelect={true}
        headerRowSelectAll={true}
        noFilteredItemMessage=""
        nonVirtual={true}
      />
    </SmallTableContainer>
  );
}
