import { Button, Icon, Tooltip } from "@equinor/eds-core-react";
import { work, work_outline } from "@equinor/eds-icons";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Chip } from "components/Components";
import Table, { ColumnsProps } from "components/table/Table";
import { useOneSetting } from "queries/settings/useOneSettings";
import useSaveSetting from "queries/settings/useSaveSetting";

import useVDSTaskMonitor from "queries/useVDSTaskMonitor";
import React, { useEffect, useMemo, useRef } from "react";
import { selectUIState, setShowVDSTaskMonitor } from "uiSlice";

const vdsTaskMonitorSettingKey = "vds-task-monitor-open";

export function VDSTaskMonitorButton() {
  const dispatch = useAppDispatch();
  const { showVDSTaskMonitor } = useAppSelector(selectUIState);
  const { data } = useVDSTaskMonitor({ refetchInterval: 30000 });
  const hasRunning = useMemo(
    () => data?.map((e) => e.Status).includes("Running"),
    [data]
  );
  const { data: vdsTaskMonitorOpenStored } = useOneSetting({
    key: vdsTaskMonitorSettingKey,
  });

  useEffect(() => {
    vdsTaskMonitorOpenStored === "true" &&
      dispatch(setShowVDSTaskMonitor(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { mutate: saveSetting } = useSaveSetting();

  return (
    <Tooltip title="VDS Task Monitor">
      <Button
        variant={showVDSTaskMonitor ? "contained_icon" : "ghost_icon"}
        onClick={() => {
          dispatch(setShowVDSTaskMonitor(!showVDSTaskMonitor));
          saveSetting({
            key: vdsTaskMonitorSettingKey,
            content: String(!showVDSTaskMonitor),
          });
        }}
      >
        <Icon data={hasRunning ? work : work_outline} />
      </Button>
    </Tooltip>
  );
}

function TaskStatusChip({ children }: { children: React.ReactNode }) {
  return (
    <Chip variant={children === "Running" ? "active" : "default"}>
      {children}
    </Chip>
  );
}

export function VDSTaskMonitor() {
  const { current: columns } = useRef<ColumnsProps[]>([
    { key: "JobName", title: "Job Name" },
    { key: "StartDate", title: "Start Date" },
    { key: "TaskDescription", title: "Task Description", width: "50%" },
    { key: "RunDuration", title: "Run Duration" },
    { key: "Status", title: "Status", Component: TaskStatusChip },
  ]);
  const { data, status, error } = useVDSTaskMonitor({});
  return (
    <Table
      itemIdProp="JobName"
      columns={columns}
      items={data}
      status={status}
      error={error}
      loadingString="Loading VDS Task Monitor..."
    />
  );
}
