import { Button, Icon } from "@equinor/eds-core-react";
import { edit } from "@equinor/eds-icons";
import { useHistory } from "react-router-dom";
import { PlantMapping } from "./plantMappingConfig";

export function EditCommandButton({ item }: { item: PlantMapping }) {
  const history = useHistory();

  return (
    <Button
      variant="ghost_icon"
      onClick={() =>
        history.replace({
          search: `edit=${item.MappingID}`,
        })
      }
    >
      <Icon data={edit} />
    </Button>
  );
}
