import {
  itemAdminConfig,
  ItemAdminAreas,
  ItemAdminReturnType,
  ItemAdminSections,
} from "../features/admin/itemAdminConfig";
import axios from "axios";
import { useQuery } from "react-query";
import config from "../app/config";

export function useItemAdmin<T extends ItemAdminAreas>({
  area,
  subArea,
  section,
}: {
  area: T;
  subArea?: string;
  section?: ItemAdminSections<T>[number];
}) {
  return useQuery<ItemAdminReturnType<T>, Error>(
    [
      "codelists",
      area,
      ...(subArea ? [subArea] : []),
      ...(section ? [section] : []),
    ],
    async () => {
      const url =
        config.restApiBaseUrl +
        `${itemAdminConfig[area].url}${subArea ? "/" + subArea : ""}${
          section && itemAdminConfig[area].sectionFilter
            ? `?${itemAdminConfig[area].sectionFilter}=${section}`
            : ""
        }`;
      const { data }: { data: any } = await axios.get(url);
      return itemAdminConfig[area].apiListProp
        ? data[itemAdminConfig[area].apiListProp]
        : data;
    }
  );
}
