import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import { EditMethods, getSheetApiUrl } from "./queryUtil";
import { PlantProps } from "../features/plant/types";

export default function usePCSCommandQuery() {
  const queryClient = useQueryClient();

  async function pcsCommand({
    plant,
    sheets,
    command,
    method,
    extraParams,
  }: {
    plant: PlantProps;
    sheets: any[];
    command: string;
    method: EditMethods;
    extraParams?: object;
  }) {
    return await axios(
      getSheetApiUrl({
        plant,
        sheetType: "pcs",
        command: `-${command}`,
      }),
      {
        method: method,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: {
          sheets,
          ...extraParams,
        },
      }
    );
  }

  return useMutation(pcsCommand, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sheets", variables.plant.PlantID, "pcs"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
