import {
  Button,
  EdsProvider,
  Icon,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import { external_link } from "@equinor/eds-icons";
import {
  FlexContainer,
  FlexElement,
  Loader,
  PageContainer,
  PageContent,
  PageTitle,
} from "components/Components";
import { ColumnsProps } from "components/table/Table";
import useReport from "queries/useReports";
import { useMemo, useRef } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { ReportsDownload } from "./ReportsDownload";
import { AllowableStressMatrixReport } from "./allowable-stress-matrix/AllowableStressMatrixReport";
import {
  AllowableStressMatrixData,
  allowableStressMatrixConfig,
} from "./allowable-stress-matrix/allowableStressMatrixConfig";
import { MaterialRatingGroupsReport } from "./material-rating-groups/MaterialRatingGroupsReport";
import {
  MaterialRatingGroupsData,
  materialRatingGroupsConfig,
} from "./material-rating-groups/materialRatingGroupsConfig";
import { PCSSPEIssueMDSValidationReport } from "./pcs-spe-issuemds-validation/SPEIssueMDSValidationReport";
import {
  PCSSPEIssueMDSValidationData,
  pcsSPEIssueMDSValidationConfig,
} from "./pcs-spe-issuemds-validation/speIssueMDSValidationConfig";
import {
  EDSPCSSearchData,
  edsPCSSearchConfig,
} from "./eds-pcs-search/edsPCSSearchConfig";
import { EDSPCSSearchReport } from "./eds-pcs-search/EDSPCSSearchReport";
import {
  VDSPCSSearchData,
  vdsPCSSearchConfig,
} from "./vds-pcs-search/vdsPCSSearchConfig";
import { VDSPCSSearchReport } from "./vds-pcs-search/VDSPCSSearchReport";
import {
  MDSPCSSearchData,
  mdsPCSSearchConfig,
} from "./mds-pcs-search/mdsPCSSearchConfig";
import { MDSPCSSearchReport } from "./mds-pcs-search/MDSPCSSearchReport";
import { MDSVDSSearchReport } from "./mds-vds-search/MDSVDSSearchReport";
import {
  MDSVDSSearchData,
  mdsVDSSearchConfig,
} from "./mds-vds-search/mdsVDSSearchConfig";
import {
  VDSTextblockSearchData,
  vdsTextblockSearchConfig,
} from "./vds-textblock-search/vdsTextblockSearchConfig";
import { VDSTextblockSearchReport } from "./vds-textblock-search/VDSTextblockSearchReport";
import {
  RatingGroupsData,
  ratingGroupsConfig,
} from "./rating-groups/ratingGroupsConfig";
import { RatingGroupsReport } from "./rating-groups/RatingGroupsReport";
import useMeasure from "react-use-measure";
import {
  WallThicknessCalculationRatiosData,
  wallThicknessCalculationRatiosConfig,
} from "./wall-thickness-calculation-ratios/wallThicknessCalculationRatiosConfig";
import useSheet from "queries/useSheet";
import { WallThicknessCalculationRatiosReport } from "./wall-thickness-calculation-ratios/WallThicknessCalculationRatiosReport";
import { useAppSelector } from "app/hooks";
import { selectMainPlant } from "features/plant/plantSlice";

export const reportsConfig = {
  "misc/allowable-stress-matrix": allowableStressMatrixConfig,
  "misc/material-rating-groups": materialRatingGroupsConfig,
  "misc/rating-groups": ratingGroupsConfig,
  "pcs/spe-issuemds-validation": pcsSPEIssueMDSValidationConfig,
  "sst/pcs-search": edsPCSSearchConfig,
  "vds/pcs-search": vdsPCSSearchConfig,
  "vds/textblock-search": vdsTextblockSearchConfig,
  "mds/pcs-search": mdsPCSSearchConfig,
  "mds/vds-search": mdsVDSSearchConfig,
  "special/pcs/wall-thickness-calculation-ratios":
    wallThicknessCalculationRatiosConfig,
} as const;

export type ReportURIs = keyof typeof reportsConfig;

export type ReportsData = {
  "misc/allowable-stress-matrix": AllowableStressMatrixData;
  "misc/material-rating-groups": MaterialRatingGroupsData;
  "misc/rating-groups": RatingGroupsData;
  "pcs/spe-issuemds-validation": PCSSPEIssueMDSValidationData;
  "sst/pcs-search": EDSPCSSearchData;
  "vds/pcs-search": VDSPCSSearchData;
  "vds/textblock-search": VDSTextblockSearchData;
  "mds/pcs-search": MDSPCSSearchData;
  "mds/vds-search": MDSVDSSearchData;
  "special/pcs/wall-thickness-calculation-ratios": WallThicknessCalculationRatiosData;
};

export type ReportsConfig = {
  title: string;
  menuTitle: string;
  listProperty: string;
  disabled?: boolean;
  disableMenu?: boolean;
  exportFunction: (data: any) => { columns: ColumnsProps[]; items: any[] };
};

function ReportOpenInNew({
  report,
  search,
}: {
  report: ReportURIs;
  search?: string;
}) {
  return (
    <>
      <Link
        to={`/out/reports/${report}${search ? search : ""}`}
        target="_blank"
      >
        <Tooltip title="Open in new window">
          <Button variant="ghost_icon">
            <Icon data={external_link} />
          </Button>
        </Tooltip>
      </Link>
    </>
  );
}

export function ReportDisplay<T extends ReportURIs>({
  report,
  search,
  displayOut,
  displayIn,
  headerRight,
  headerWidth,
  name,
  revision,
}: {
  report: T;
  search?: string;
  displayOut?: boolean;
  displayIn?: boolean;
  headerRight?: React.ReactNode;
  headerWidth?: number | string;
  name?: string;
  revision?: string;
}) {
  const { title, disabled } = reportsConfig[report as ReportURIs];
  const parsedSearch = new URLSearchParams(search);

  const {
    data: reportData,
    status: reportStatus,
    error: reportError,
  } = useReport({
    report,
    search,
    disabled:
      (report === "pcs/spe-issuemds-validation" &&
        (parsedSearch.get("plantID") === null ||
          parsedSearch.get("plantID") === "")) ||
      (report === "sst/pcs-search" &&
        parsedSearch.get("namefilter") === null) ||
      (report === "vds/pcs-search" &&
        parsedSearch.get("namefilter") === null) ||
      (report === "mds/pcs-search" &&
        parsedSearch.get("namefilter") === null) ||
      (report === "mds/vds-search" &&
        parsedSearch.get("namefilter") === null) ||
      (report === "vds/textblock-search" &&
        parsedSearch.get("textBlockID") === null) ||
      report === "special/pcs/wall-thickness-calculation-ratios",
  });

  const isSpecialReport = useMemo(
    () => report.substring(0, 7) === "special",
    [report]
  );

  const plant = useAppSelector(selectMainPlant);

  const {
    data: sheetData,
    status: sheetStatus,
    error: sheetError,
  } = useSheet({
    sheetType: "pcs",
    name: name ?? "",
    revision: revision ?? "",
    disabled: report !== "special/pcs/wall-thickness-calculation-ratios",
    postfix: "wallthickness-calculation-ratios",
    plant,
  });

  const data = useMemo(
    () => (isSpecialReport ? sheetData : reportData),
    [isSpecialReport, reportData, sheetData]
  );
  const status = useMemo(
    () => (isSpecialReport ? sheetStatus : reportStatus),
    [isSpecialReport, reportStatus, sheetStatus]
  );
  const error = useMemo(
    () => (isSpecialReport ? sheetError : reportError),
    [isSpecialReport, reportError, sheetError]
  );

  const filenameRef = useRef(null as HTMLInputElement | null);

  const [headerRef, headerSize] = useMeasure();

  return disabled ? (
    <PageContainer>Report disabled.</PageContainer>
  ) : (
    <>
      <div
        style={{
          position: "relative",
          padding: 0,
          width: "max-content",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          minHeight: displayOut ? "calc(100vh - 60px)" : "100%",
        }}
      >
        <PageTitle
          variant={displayIn ? "h4" : "h3"}
          style={{
            position: "sticky",
            top: 0,
            left: 0,
            background: "var(--bg)",
            borderBottom: "1px solid var(--borderDefault)",
            zIndex: 4,
            width: displayOut ? "100vw" : headerWidth ? headerWidth : "100%",
          }}
          ref={headerRef}
        >
          <FlexContainer
            style={{ padding: displayIn ? "7px 16px" : "14px 24px" }}
          >
            <FlexElement>{title}</FlexElement>
            <FlexElement>
              <FlexContainer>
                <FlexElement>
                  <ReportsDownload
                    report={report}
                    data={data}
                    filenameRef={filenameRef}
                  />
                </FlexElement>
                {!displayOut && !isSpecialReport && (
                  <FlexElement>
                    <ReportOpenInNew
                      report={report as ReportURIs}
                      search={search}
                    />
                  </FlexElement>
                )}
                {headerRight && <FlexElement>{headerRight}</FlexElement>}
              </FlexContainer>
            </FlexElement>
          </FlexContainer>
        </PageTitle>
        <div
          style={{
            padding: "10px 24px 20px",
            display: "flex",
            flex: 1,
            flexDirection: "column",
          }}
        >
          <EdsProvider density="compact">
            {report === "pcs/spe-issuemds-validation" ? (
              <PCSSPEIssueMDSValidationReport
                data={data as PCSSPEIssueMDSValidationData | undefined}
                filenameRef={filenameRef}
              />
            ) : report === "sst/pcs-search" ? (
              <EDSPCSSearchReport data={data as EDSPCSSearchData | undefined} />
            ) : report === "vds/pcs-search" ? (
              <VDSPCSSearchReport data={data as VDSPCSSearchData | undefined} />
            ) : report === "mds/pcs-search" ? (
              <MDSPCSSearchReport data={data as MDSPCSSearchData | undefined} />
            ) : report === "mds/vds-search" ? (
              <MDSVDSSearchReport data={data as MDSVDSSearchData | undefined} />
            ) : report === "vds/textblock-search" ? (
              <VDSTextblockSearchReport
                data={data as VDSTextblockSearchData | undefined}
              />
            ) : (
              <></>
            )}
          </EdsProvider>
          {status === "loading" ? (
            <div style={{ flexGrow: 1, position: "relative" }}>
              <Loader label="Loading Report..." />
            </div>
          ) : status === "error" ? (
            <>
              <Typography
                variant="h3"
                style={{ marginBottom: 10 }}
                color="danger"
              >
                Error
              </Typography>
              {error && error.message}
            </>
          ) : status === "success" ? (
            <>
              <EdsProvider density="compact">
                <PageContent>
                  {report === "misc/allowable-stress-matrix" ? (
                    <AllowableStressMatrixReport
                      data={data as AllowableStressMatrixData}
                      headerHeight={headerSize.height}
                    />
                  ) : report === "misc/material-rating-groups" ? (
                    <MaterialRatingGroupsReport
                      data={data as MaterialRatingGroupsData}
                    />
                  ) : report === "misc/rating-groups" ? (
                    <RatingGroupsReport data={data as RatingGroupsData} />
                  ) : report ===
                    "special/pcs/wall-thickness-calculation-ratios" ? (
                    <WallThicknessCalculationRatiosReport
                      data={
                        data.getWallThicknessCalcFigures as WallThicknessCalculationRatiosData
                      }
                    />
                  ) : (
                    <></>
                  )}
                </PageContent>
              </EdsProvider>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
}

export function Reports({ headerWidth }: { headerWidth?: number }) {
  const { report } = useParams<{ report: ReportURIs | string }>();
  const { pathname, search } = useLocation();
  const pathnameParts = pathname.split("/");

  return Object.keys(reportsConfig).includes(report) ? (
    <ReportDisplay
      report={report as ReportURIs}
      search={search}
      displayOut={pathnameParts[1] === "out"}
      headerWidth={headerWidth}
    />
  ) : (
    <>No such report.</>
  );
}

export type ReportsProps = React.ComponentProps<typeof Reports>;
