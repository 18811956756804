import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./app/store";
import { unsetPlantState } from "./features/plant/plantSlice";
import { PlantProps } from "./features/plant/types";
import { SheetTypes } from "./features/sheets/config/sheetConfig";
import { AdminRoles, UserRoles } from "app/userConfig";

interface SheetData {
  plant?: PlantProps;
  sheetType: SheetTypes;
  name: string;
  revision: string;
  format?: string;
}

export type PaneMode = false | "preview" | "validation-log";

export enum PaneLocationEnum {
  main = "main",
  editModal = "editModal",
  copyModal = "copyModal",
  issueModal = "issueModal",
  affectedVDSModal = "affectedVDSModal",
}

export type PaneLocation = keyof typeof PaneLocationEnum;

type PaneSheets = { [key in PaneLocationEnum]: SheetData };

type PaneModes = { [key in PaneLocationEnum]: PaneMode };

interface UIState {
  darkMode: boolean;
  isMenuOpen: boolean;
  paneSheets: PaneSheets;
  paneModes: PaneModes;
  showBeta: boolean;
  showAlpha: boolean;
  showVDSTaskMonitor: boolean;
  showRoleTest: boolean;
  roleTest: UserRoles;
  adminRoleTest: AdminRoles;
}

export const initialSheetState: SheetData = {
  plant: unsetPlantState,
  sheetType: "general",
  name: "",
  revision: "",
  format: "",
};

const initialState: UIState = {
  darkMode: false,
  isMenuOpen: false,
  paneModes: Object.keys(PaneLocationEnum).reduce(
    (a, c) => ({ ...a, [c]: false }),
    {}
  ) as PaneModes,
  paneSheets: Object.keys(PaneLocationEnum).reduce(
    (a, c) => ({ ...a, [c]: initialSheetState }),
    {}
  ) as PaneSheets,
  showBeta: false,
  showAlpha: false,
  showVDSTaskMonitor: false,
  showRoleTest: false,
  roleTest: "N",
  adminRoleTest: "A",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    setDarkMode: (state, action) => {
      state.darkMode = action.payload;
    },
    setPaneMode: (
      state,
      action: { payload: { location: PaneLocation; mode: PaneMode } }
    ) => {
      state.paneModes[action.payload.location] = action.payload.mode;
    },
    setPaneSheet: (
      state,
      action: { payload: { location: PaneLocation; sheet: SheetData } }
    ) => {
      state.paneSheets[action.payload.location] = action.payload.sheet;
    },
    setShowBeta: (state, action: { payload: boolean }) => {
      state.showBeta = action.payload;
    },
    setShowAlpha: (state, action: { payload: boolean }) => {
      state.showAlpha = action.payload;
    },
    setShowVDSTaskMonitor: (state, action: { payload: boolean }) => {
      state.showVDSTaskMonitor = action.payload;
    },
    setShowRoleTest: (state, action: { payload: boolean }) => {
      state.showRoleTest = action.payload;
    },
    setRoleTest: (state, action: { payload: UserRoles }) => {
      state.roleTest = action.payload;
    },
    setAdminRoleTest: (state, action: { payload: AdminRoles }) => {
      state.adminRoleTest = action.payload;
    },
  },
});

export const {
  setMenuOpen,
  setDarkMode,
  setPaneSheet,
  setPaneMode,
  setShowAlpha,
  setShowBeta,
  setShowVDSTaskMonitor,
  setShowRoleTest,
  setRoleTest,
  setAdminRoleTest,
} = uiSlice.actions;

export const selectUIState = (state: RootState) => state.ui;

export default uiSlice.reducer;
