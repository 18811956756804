import {
  useReducer,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";

export type SelectionModeType = false | "single" | "multi";

export type SelectionReducerAction = {
  type: "select" | "deselect" | "reset";
  payload?: string | string[];
};

type SelectionReducer = (
  state: string[],
  action: SelectionReducerAction
) => string[];

export default function useTable({
  selectionMode,
  setState,
  selection,
}: {
  selectionMode: SelectionModeType;
  setState?: Dispatch<SetStateAction<string[]>>; // if provided, the selection state will be reflected by setting it with this setState
  selection?: string[];
}) {
  const selectionReducer = useCallback<SelectionReducer>(
    (state, { type, payload }) => {
      switch (type) {
        case "select":
          return selectionMode === "multi"
            ? payload
              ? Array.isArray(payload)
                ? payload
                : [...state, payload]
              : state
            : selectionMode === "single"
            ? payload
              ? Array.isArray(payload)
                ? payload
                : [payload]
              : []
            : [];
        case "deselect":
          return selectionMode === "multi"
            ? state.filter((i: string) => i !== payload)
            : [];
        case "reset":
          return [];
      }
    },
    [selectionMode]
  );

  const [selectionState, selectionDispatch] = useReducer(
    selectionReducer,
    selection ? selection : []
  );

  useEffect(() => {
    selectionDispatch({ type: "reset", payload: "" });
    selection && selectionDispatch({ type: "select", payload: selection });
  }, [selection]);

  useEffect(() => {
    setState && setState(selectionState);
  }, [selectionState, setState]);

  return {
    selectionMode,
    selection: selectionState,
    selectionDispatch,
  };
}
