import { FilterProps } from "components/table/Filters";
import { PlantProps } from "features/plant/types";
import { useEffect } from "react";

export function PlantsFilter({
  data,
  filtersDispatch,
  categoryFilter,
  setCategoryFilter,
}: {
  data?: PlantProps[];
  filtersDispatch: React.Dispatch<{
    type: "update" | "reset";
    payload?: FilterProps | undefined;
  }>;
  categoryFilter: string;
  setCategoryFilter: React.Dispatch<string>;
}) {
  useEffect(() => {
    filtersDispatch({
      type: "update",
      payload: {
        type: "select",
        title: "Operator",
        prop: "OperatorName",
        filterOptions: data
          ? Array.from(
              new Set((data as PlantProps[]).map((i) => i.OperatorName))
            )
          : [],
        filterState: categoryFilter,
        defaultFilterState: "",
        setFilterState: setCategoryFilter,
        group: "",
      },
    });
    return () => filtersDispatch({ type: "reset" });
  }, [data, categoryFilter, setCategoryFilter, filtersDispatch]);
  return null;
}
