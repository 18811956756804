export const sheetStatusConfig = {
  O: "Official",
  E: "Expired",
  W: "Working",
  U: "Unofficial",
  R: "Replaced",
  I: "Ready to Issue",
  S: "Sub-revision",
};

export type SheetStatus = keyof typeof sheetStatusConfig;
export type MaybeSheetStatus = SheetStatus | undefined;
