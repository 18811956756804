import { Access } from "Access";
import { Reports, ReportsProps } from "./Reports";

export function AccessReports({ headerWidth }: ReportsProps) {
  return (
    <Access cap="reports" module="reports">
      <Reports headerWidth={headerWidth} />
    </Access>
  );
}
