import { PlantProps } from "../features/plant/types";
import config from "../app/config";
import { useQuery, useQueryClient, useMutation } from "react-query";
import axios from "axios";
import { EditMethods, getSheetApiUrl } from "./queryUtil";

export function usePipeElementGroupsQuery() {
  return useQuery(["pipe-element-groups"], async () => {
    const url = config.restApiBaseUrl + "codelists/pipe-element-groups";
    const { data } = await axios.get(url);
    return data["getPipeElementGroups"];
  });
}

export function usePipeElementsQuery({
  plant,
  elementGroupID,
  existing,
  pcs,
  revision,
  validityFilter,
}: {
  plant: PlantProps;
  elementGroupID: number;
  existing?: boolean;
  pcs?: string;
  revision?: string;
  validityFilter?: boolean;
}) {
  return useQuery(
    [
      "pipe-elements",
      plant.PlantID,
      elementGroupID,
      existing,
      pcs,
      revision,
      validityFilter,
    ],
    async () => {
      let url = existing
        ? getSheetApiUrl({
            plant,
            sheetType: "pcs",
            command: `-pipe-elements`,
          }) + `?ElementGroupID=${elementGroupID}`
        : `${config.restApiBaseUrl}pmg/pipe-elements?PlantID=${plant.PlantID}&ElementGroupID=${elementGroupID}`;
      if (pcs) {
        url += "&pcs=" + pcs;
      }
      if (revision) {
        url += "&rev=" + revision;
      }
      if (!validityFilter) {
        url += "&validityfilter=N";
      }
      const { data } = await axios.get(url);
      return data["getPipeElementList"];
    },
    {
      enabled: elementGroupID > 0 && plant.PlantID > 0,
    }
  );
}

export function useModifyPipeElementInSheets() {
  const queryClient = useQueryClient();

  async function addPipeElements({
    plant,
    sheets,
    elementID,
    newElementID,
    method,
    fromSize,
    toSize,
  }: {
    plant: PlantProps;
    sheets: any[];
    elementID: string;
    newElementID?: string;
    method: EditMethods;
    fromSize?: string;
    toSize?: string;
  }) {
    return await axios(
      getSheetApiUrl({
        plant,
        sheetType: "pcs",
        command: `-pipe-elements`,
      }),
      {
        method,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        data: {
          sheets,
          ElementID: elementID,
          ...(newElementID && { NewElementID: newElementID }),
          ...(typeof fromSize !== "undefined" && {
            FromSize: fromSize ? fromSize : "",
          }),
          ...(typeof toSize !== "undefined" && {
            ToSize: toSize ? toSize : "",
          }),
        },
      }
    );
  }

  return useMutation(addPipeElements, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sheets", variables.plant.PlantID, "pcs"]);
      queryClient.invalidateQueries(["pipe-elements", variables.plant.PlantID]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
