import React, { useContext, useState, useEffect } from "react";
import { useMemoOne as useMemo } from "use-memo-one";
import { useHistory, useLocation } from "react-router-dom";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentFill,
  ModalWindowContentFixed,
  ModalWindowPrimaryContainer,
  ModalWindowTitle,
} from "components/ModalWindow";
import {
  Issue,
  IssuePipeElementsList,
  IssueReturnType,
} from "queries/useIssues";
import { closeIssue, editIssue } from "./Issues";
import { PlantProps } from "features/plant/types";
import { Fade, HrLine, RevisionMark, StatusChip } from "components/Components";
import {
  NativeSelect,
  Tabs,
  Button,
  Icon,
  EdsProvider,
} from "@equinor/eds-core-react";
import { useTabs } from "../../components/tabs/useTabs";
import {
  IssueSheetTypes,
  sheetDocumentWidth,
} from "../sheets/config/sheetConfig";
import { useAppSelector } from "app/hooks";
import { selectMainPlant } from "features/plant/plantSlice";
import { ColumnsProps, ItemsClassNames } from "components/table/Table";
import { change_history, edit } from "@equinor/eds-icons";
import { PreviewButtons, SheetPane } from "../sheets/SheetPane";
import * as Space from "react-spaces";
import { ModalPadder } from "components/Modal";
import styled from "styled-components";
import { getItemID } from "../sheets/util";
import { successMessage } from "../../utils/successMessage";
import useSheetSelector from "./hooks/useSheetSelector";
import useConfirm from "components/confirm/useConfirm";
import { IssueSetSheets, issueSetSheetTypes } from "queries/useIssueSetSheets";
import StatusModal from "components/StatusModal";
import usePipeElementSelector from "./hooks/usePipeElementSelector";
import { PaneLocation, selectUIState } from "uiSlice";
import { EditIssueContext, EditIssueContextProvider } from "./EditIssueContext";
import useIssueCommand from "queries/useIssueCommand";
import { IssueValidation } from "./IssueValidation";
import { useIssueSheets } from "./hooks/useIssueSheets";

export function DeltaIcon({ children }: { children: React.ReactNode }) {
  return children === "Y" ? (
    <div title="New since last revision" style={{ paddingLeft: 10 }}>
      <Icon size={16} data={change_history} color="var(--textSemiLight)" />
    </div>
  ) : (
    <div style={{ paddingLeft: 10, width: 16 }}></div>
  );
}

export function IssueSheetRevisionMarkWithPreviewButtons({
  item,
  column,
  issueSheetType,
  revisionOverride,
  previewLocation = "issueModal",
}: {
  item: any;
  column: ColumnsProps;
  issueSheetType: IssueSheetTypes;
  revisionOverride: string;
  previewLocation?: PaneLocation;
}) {
  const plant = useAppSelector(selectMainPlant);
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <RevisionMark>{item[column.key]}</RevisionMark>
      <EdsProvider density="compact">
        <div style={{ display: "flex" }}>
          <PreviewButtons
            sheetType={issueSheetType}
            item={{
              ...item,
              ...(revisionOverride ? { Revision: item[revisionOverride] } : {}),
              ...(issueSheetType === "vsm" ? { Format: "F" } : {}),
            }}
            plant={plant}
            previewLocation={previewLocation}
          />
        </div>
      </EdsProvider>
    </div>
  );
}

export const NativeSelectAutoWidth = styled(NativeSelect)`
  select {
    width: auto !important;
  }
`;

export function isIssueSetSheetType(
  issueSheetType: IssueSheetTypes
): issueSheetType is IssueSetSheets {
  return issueSetSheetTypes.includes(issueSheetType);
}

export function EditIssueSheetContent<T extends IssueSheetTypes>({
  issueSheetType,
  items,
  setItems,
  itemId,
}: {
  issueSheetType: T;
  items: IssueReturnType<T>;
  setItems: React.Dispatch<IssueReturnType<T>>;
  itemId: string;
}) {
  const plant = useAppSelector(selectMainPlant);
  const { editSheet, setEditSheetOpen, setItemsClassNames } =
    useContext(EditIssueContext);
  const currentItemId = useMemo(
    () => getItemID(editSheet.name, editSheet.revision),
    [editSheet]
  );
  const defaultSelection = useMemo(() => [currentItemId], [currentItemId]);

  const { sheetSelectorContent, selectedLines } = useSheetSelector({
    defaultSelection,
    nameFilter: editSheet.name,
    sheetType: issueSheetType,
    plant,
  });

  return (
    <>
      <ModalSideMargin>{sheetSelectorContent}</ModalSideMargin>
      <ModalWindowButtonContainer>
        <Button
          onClick={() => {
            // prettier-ignore
            // @ts-ignore
            const itemIndex = items.map(e => e[itemId]).indexOf(editSheet.name);
            items[itemIndex] = { ...items[itemIndex], ...selectedLines[0] };
            // prettier-ignore
            // @ts-ignore
            setItems([...items]);
            setItemsClassNames([
              {
                itemID: editSheet.name,
                className: "_modifiedData",
                element: "_line",
              },
            ]);
            setTimeout(() => setItemsClassNames([]), 3000);
            setEditSheetOpen(false);
          }}
        >
          Select
        </Button>
        <Button variant="outlined" onClick={() => setEditSheetOpen(false)}>
          Cancel
        </Button>
      </ModalWindowButtonContainer>
    </>
  );
}

export function EditIssuePipeElementsContent({
  items,
  setItems,
}: {
  items: IssuePipeElementsList;
  setItems: React.Dispatch<IssuePipeElementsList>;
}) {
  const { editSheet, setEditSheetOpen, setItemsClassNames } =
    useContext(EditIssueContext);

  const defaultSelection = useMemo(
    () => [editSheet.revision],
    [editSheet.revision]
  );

  const { pipeElementSelectorContent, selectedLines } = usePipeElementSelector({
    defaultSelection,
    elementId: Number(editSheet.name),
  });

  return (
    <>
      <ModalSideMargin>{pipeElementSelectorContent}</ModalSideMargin>
      <ModalWindowButtonContainer>
        <Button
          onClick={() => {
            const itemIndex = items
              .map((e) => e.ElementID)
              .indexOf(editSheet.name);
            items[itemIndex] = { ...items[itemIndex], ...selectedLines[0] };
            setItems([...items]);
            setItemsClassNames([
              {
                itemID: editSheet.name,
                className: "_modifiedData",
                element: "_line",
              },
            ]);
            setTimeout(() => setItemsClassNames([]), 3000);
            setEditSheetOpen(false);
          }}
        >
          Select
        </Button>
        <Button variant="outlined" onClick={() => setEditSheetOpen(false)}>
          Cancel
        </Button>
      </ModalWindowButtonContainer>
    </>
  );
}

type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export function IssueSheetRowMenu<T extends IssueSheetTypes>({
  item,
  issueSheetType,
  revision,
  itemId,
  itemTitle,
}: {
  issueSheetType: T;
  revision: string;
  item: ArrElement<IssueReturnType<T>>;
  itemId: keyof ArrElement<IssueReturnType<T>>;
  itemTitle: string;
}) {
  const { issue, setEditSheet, setEditSheetOpen, isEditable } =
    useContext(EditIssueContext);
  return issue && issue.Status === "W" && issueSheetType !== "pcs" ? (
    <>
      <Button
        variant="ghost_icon"
        onClick={() => {
          setEditSheet({
            // @ts-ignore
            name: item[itemId],
            revision: item.Revision,
          });
          setEditSheetOpen(true);
        }}
        disabled={!isEditable}
      >
        <Icon data={edit} />
      </Button>
    </>
  ) : (
    <></>
  );
}

const DataLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 10px;
`;

const DataContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const DataLabel = styled.div`
  min-width: 100px;
  font-weight: bold;
`;

const Data = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  min-height: 24px; ;
`;

function IssueOverviewLine({
  label,
  data,
  Component,
}: {
  label: string;
  data: string;
  Component?: React.ComponentType<any>;
}) {
  return (
    <DataContainer>
      <DataLabel>{label}</DataLabel>
      <Data>{Component ? <Component>{data}</Component> : <>{data}</>}</Data>
    </DataContainer>
  );
}

function IssueSheetLine({ sheet, issue }: { sheet: string; issue: Issue }) {
  return (
    <DataContainer>
      <DataLabel>{sheet}</DataLabel>
      <Data>
        <RevisionMark>
          {issue[(sheet + "Revision") as keyof Issue]}
        </RevisionMark>
        <div>{issue[(sheet + "RevDate") as keyof Issue]}</div>
      </Data>
    </DataContainer>
  );
}

function IssueOverview({ issue }: { issue?: Issue }) {
  return issue ? (
    <ModalPadder>
      <DataLines>
        <IssueOverviewLine label="Issue Rev." data={issue.IssueRevision} />
        <IssueOverviewLine label="Date" data={issue.RevDate} />
        <IssueOverviewLine
          label="Status"
          data={issue.Status}
          Component={StatusChip}
        />
      </DataLines>
      <HrLine style={{ margin: "16px 0" }} />
      <DataLines>
        <IssueSheetLine sheet="General" issue={issue} />
        <IssueSheetLine sheet="PCS" issue={issue} />
        <IssueSheetLine sheet="SC" issue={issue} />
        <IssueSheetLine sheet="VSM" issue={issue} />
        <IssueSheetLine sheet="EDS" issue={issue} />
        <IssueSheetLine sheet="VDS" issue={issue} />
        <IssueSheetLine sheet="VSK" issue={issue} />
        <IssueSheetLine sheet="MDS" issue={issue} />
      </DataLines>
    </ModalPadder>
  ) : (
    <></>
  );
}

export interface EditSheet {
  name: string;
  revision: string;
}

const issueSheetTypeTabs: { title: string; sheet: IssueSheetTypes }[] = [
  { title: "PCS", sheet: "pcs" },
  { title: "SC", sheet: "sc" },
  { title: "VSM", sheet: "vsm" },
  { title: "EDS", sheet: "eds" },
  { title: "VDS", sheet: "vds" },
  { title: "VSK", sheet: "vsk" },
  { title: "MDS", sheet: "mds" },
  { title: "SPE", sheet: "pipe-elements" },
];

export function EditIssue({
  issues,
  plant,
  isEditable,
  LockIndicator,
}: {
  issues?: Issue[];
  plant: PlantProps;
  isEditable?: boolean;
  LockIndicator: React.ReactNode;
}) {
  const { search } = useLocation();
  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const revision = parsedSearch.get("edit") as string;
  const issue = issues && issues.find((i) => i.IssueRevision === revision);
  if (issues && !issue) {
    throw new Error("Invalid issue revision.");
  }
  const [editSheet, setEditSheet] = useState({} as EditSheet);
  const [editSheetOpen, setEditSheetOpen] = useState(false);
  const [itemsClassNames, setItemsClassNames] = useState(
    [] as ItemsClassNames[]
  );
  const [showValidation, setShowValidation] = useState(false);
  const [wantToSetStatus, setWantToSetStatus] = useState("");

  return (
    <EditIssueContextProvider
      revision={revision}
      issue={issue}
      editSheet={editSheet}
      setEditSheet={setEditSheet}
      editSheetOpen={editSheetOpen}
      setEditSheetOpen={setEditSheetOpen}
      itemsClassNames={itemsClassNames}
      setItemsClassNames={setItemsClassNames}
      isEditable={isEditable}
      showValidation={showValidation}
      setShowValidation={setShowValidation}
      wantToSetStatus={wantToSetStatus}
      setWantToSetStatus={setWantToSetStatus}
    >
      <EditIssueContent
        issues={issues}
        plant={plant}
        LockIndicator={LockIndicator}
      />
    </EditIssueContextProvider>
  );
}

export function EditIssueContent({
  issues,
  plant,
  LockIndicator,
}: {
  issues?: Issue[];
  plant: PlantProps;
  LockIndicator: React.ReactNode;
}) {
  const { revision, issue, isEditable, setShowValidation, setWantToSetStatus } =
    useContext(EditIssueContext);

  // activeSheetTab is a copy of activeTab and
  // is needed becuase useTabs and useIssueSheets have circular dependencies
  const [activeSheetTab, setActiveSheetTab] = useState(0);
  const { issueSheetsContent, checkIfDirty } = useIssueSheets({
    issueSheetType:
      issueSheetTypeTabs[activeSheetTab > 0 ? activeSheetTab - 1 : 0].sheet,
    revision,
    disabled: activeSheetTab === 0,
  });
  const { activeTab, handleChange } = useTabs({
    name: "EditIssue",
    changeConfirm: checkIfDirty,
  });
  useEffect(() => setActiveSheetTab(activeTab), [activeTab]);

  const { paneModes } = useAppSelector(selectUIState);
  const history = useHistory();
  const closeModal = async () =>
    (await checkIfDirty()) && closeIssue({ history });
  const { isConfirmed } = useConfirm();

  const {
    mutate: issueCommand,
    status: issueCommandStatus,
    error: issueCommandError,
    reset: issueCommandReset,
    data: issueCommandData,
  } = useIssueCommand();

  return (
    <>
      <ModalWindow
        title={
          <div style={{ display: "flex", gap: 20 }}>
            <ModalWindowTitle layer="primary">
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  whiteSpace: "nowrap",
                  alignItems: "center",
                }}
              >
                <div>Issue rev.</div>
                <NativeSelect
                  id="revision"
                  label=""
                  value={revision}
                  onChange={(e) => {
                    const item =
                      issues &&
                      issues.find((i) => i.IssueRevision === e.target.value);
                    item &&
                      editIssue({
                        item,
                        history,
                      });
                  }}
                >
                  {issues &&
                    issues.map((issue) => (
                      <option key={issue.IssueRevision}>
                        {issue.IssueRevision}
                      </option>
                    ))}
                </NativeSelect>
                <StatusChip>{issue ? issue.Status : ""}</StatusChip>
                <div>
                  <Fade> in </Fade>
                  {plant.LongDescription}
                </div>
                {LockIndicator}
              </div>
            </ModalWindowTitle>
          </div>
        }
        closeModal={() => closeModal()}
        isOpen={!!revision}
        layer="primary"
      >
        <Space.Fill>
          <Space.Fill>
            <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
              <ModalWindowPrimaryContainer>
                <ModalWindowContentFixed>
                  <Tabs.List>
                    <Tabs.Tab>Overview</Tabs.Tab>
                    {issueSheetTypeTabs.map((issueSheetTab) => {
                      return (
                        <Tabs.Tab key={issueSheetTab.sheet}>
                          {issueSheetTab.title}
                        </Tabs.Tab>
                      );
                    })}
                  </Tabs.List>
                </ModalWindowContentFixed>
                <ModalWindowContentFill>
                  <Tabs.Panels>
                    <Tabs.Panel>
                      {activeTab === 0 && (
                        <IssueOverview
                          issue={
                            issues &&
                            issues.find((i) => i.IssueRevision === revision)
                          }
                        />
                      )}
                    </Tabs.Panel>
                    {issueSheetTypeTabs.map((issueSheetTab) => {
                      return (
                        <Tabs.Panel key={issueSheetTab.sheet}>
                          {issueSheetTypeTabs[
                            activeSheetTab > 0 ? activeSheetTab - 1 : 0
                          ].sheet === issueSheetTab.sheet ? (
                            issueSheetsContent
                          ) : (
                            <></>
                          )}
                        </Tabs.Panel>
                      );
                    })}
                  </Tabs.Panels>
                </ModalWindowContentFill>
                <ModalWindowContentFixed>
                  {issue && (
                    <ModalWindowButtonContainer>
                      <div style={{ display: "flex", gap: 20 }}>
                        <Button
                          variant="outlined"
                          onClick={() => setShowValidation(true)}
                        >
                          Validate
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!isEditable || issue.Status !== "W"}
                          onClick={async () => {
                            (await isConfirmed(
                              <>Are you sure you want to update revisions?</>
                            )) &&
                              issueCommand &&
                              issueCommand({
                                command: "datasheets",
                                revision,
                                plant,
                              });
                          }}
                        >
                          Update Revisions
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!isEditable || issue.Status !== "W"}
                          onClick={() => {
                            setWantToSetStatus("M");
                            setShowValidation(true);
                          }}
                        >
                          Set as Master
                        </Button>
                        <Button
                          variant="outlined"
                          disabled={!isEditable || issue.Status !== "M"}
                          onClick={() => {
                            setWantToSetStatus("O");
                            setShowValidation(true);
                          }}
                        >
                          Make Official
                        </Button>
                      </div>
                      <div>
                        <Button variant="outlined" onClick={() => closeModal()}>
                          Close
                        </Button>
                      </div>
                    </ModalWindowButtonContainer>
                  )}
                </ModalWindowContentFixed>
              </ModalWindowPrimaryContainer>
            </Tabs>
          </Space.Fill>
          {paneModes.issueModal && (
            <Space.RightResizable size={sheetDocumentWidth}>
              <SheetPane location="issueModal" />
            </Space.RightResizable>
          )}
        </Space.Fill>
      </ModalWindow>
      <IssueValidation />
      <StatusModal
        status={issueCommandStatus}
        error={issueCommandError}
        onSettledClose={issueCommandReset}
        successMessage={successMessage(issueCommandData)}
      />
    </>
  );
}
