import { useDraggable } from "@dnd-kit/core";
import {
  Button,
  Checkbox,
  Table as EDSTable,
  EdsProvider,
  Icon,
  Radio,
  Typography,
} from "@equinor/eds-core-react";
import { arrow_drop_down, arrow_drop_up } from "@equinor/eds-icons";
import { SignInButton } from "components/SignInButton";
import { combineQueryStatuses } from "queries/queryUtil";
import { Codelists, codelistProperties } from "queries/useCodelist";
import { useCodelists } from "queries/useCodelists";
import React, { useEffect, useRef, useState } from "react";
import { QueryStatus } from "react-query";
import { Element } from "react-scroll";
import useMeasure from "react-use-measure";
import styled from "styled-components";
import { useMemo } from "use-memo-one";
import { naturalSort } from "utils/util";
import {
  SortMode,
  defaultComponents,
  defaultSort,
  defaultWidths,
} from "../../features/sheets/config/sheetConfig";
import {
  AbsoluteFlexContainer,
  BottomBorderContainer,
  CenterChildren,
  ContentMessage,
  FlexContainer,
  FlexContentElement,
  FlexHeaderElement,
  FooterContainer,
  InfolineContainer,
  InlineBlock,
  Loader,
  SimpleButtonContainer,
  TotalCenter,
} from "../Components";
import { VirtualTable } from "./VirtualTable";
import { SelectionModeType, SelectionReducerAction } from "./useTable";
import { TypographyVariants } from "@equinor/eds-core-react/dist/types/components/Typography/Typography.tokens";

type CodelistData = {
  [index: string]: string;
};

export type TableItem<T extends { [index: string]: unknown }> = T & {
  _absoluteIndex: number;
  _absoluteLength: number;
  _groupIndex: number;
  _groupLength: number;
};

export interface TableCommonProps {
  RowMenu: React.ComponentType<any>;
  Commands: React.ComponentType<any> | React.ComponentType<any>[];
  itemIdProp: string;
  selectionMode: SelectionModeType;
  columns: ColumnsProps[];
  fullRowSelect: boolean;
  itemsClassNames: ItemsClassNames[];
  codelists: {
    [index: string]: CodelistData;
  };
  fillRest?: boolean;
  RowHiddenComponent: React.ComponentType<any>;
  nonVirtual?: boolean;
  groupIdProp: string;
  disabledItems?: string[];
  disableAllItems?: boolean;
}

interface TableDataProps extends TableCommonProps {
  Row: React.ComponentType<any>;
  selection: string[];
  selectionDispatch: Function;
}

export interface RowDataProps extends TableCommonProps {
  index: number;
  selected: boolean;
  selector: React.ReactFragment;
  selectRef: React.RefObject<any>;
}

export type ColumnsProps = {
  key: string;
  title: string | JSX.Element;
  type?: "comma-delimited" | "with-context";
  width?: string | number;
  minWidth?: number;
  Component?: React.ComponentType<any> | string;
  codelist?: string;
  display?: string;
  componentProps?: Object;
  longTitle?: string;
  align?: "center";
  sort?: SortMode;
  className?: string;
};

export interface SortProps {
  key?: string;
  order?: "asc" | "desc";
  mode?: SortMode;
}

export interface ItemsClassNames {
  itemID: string | number;
  element: string;
  className: string;
}

export const OrderIcon = styled(Icon)`
  margin-right: -8px;
`;

export const DefaultTableComponent = styled(EDSTable)`
  &.table-sortable {
    th {
      white-space: nowrap;
      > div {
        height: 100%;
      }
    }
    th.column-sortable {
      user-select: none;
      .HeadCellContent {
        margin: 0 -16px;
        padding: 0 16px;
        cursor: pointer;
        &:hover {
          background: var(--furtherFadedBg);
        }
      }
    }
  }
  &.table-oversize {
    .TableCell-Menu {
      box-shadow: -5px 0px 5px rgb(0 0 0 / 10%);
    }
    .TableCell-Select {
      box-shadow: 5px 0px 5px rgb(0 0 0 / 10%);
    }
  }
  &.table-compact {
    td,
    th {
      height: 32px;
    }
    .TableCell-Select {
      width: 32px;
      padding-right: 16px;
      padding-left: 10px;
    }
    th,
    td {
      padding: 0 8px;
    }
    th.column-sortable {
      .HeadCellContent {
        padding: 0 8px;
        margin: 0 -8px;
      }
    }
    &.table-num-cols-1 {
      .TableCell-Select {
        padding-right: 8px;
      }
    }
    input[readonly] {
      font-size: 14px;
      font-weight: 500;
    }
  }
  &.table-has-row-above-header {
    thead {
      // This is a workaround for Chromium "rendering specialities" made while observing real life behaviour.
      // The -1px (down at thead) fixes a thin transparent line appearing above the fixed table header on HiDPI screens,
      // whereas the 0 here overrides that, as that caused a wobbly thead with multiple lines of content.
      top: 0;
    }
  }
  width: 100%;
  background: transparent;
  border-color: var(--borderDefault) !important;
  tr {
    background: var(--bg);
    &.groupHeader {
      background: var(--fadedBg);
      &:hover {
        background: var(--furtherFadedBg);
      }
      td.titleContainer {
        padding-left: 12px;
      }
    }
  }
  td,
  th {
    height: 49px; // with border, 48px needed for ghost_icon Buttons to have enough space
    &.restFiller {
      width: 100%;
    }
  }
  td._newData,
  tr._newData {
    box-shadow: inset 0px 0px 100px rgba(115, 255, 110, 0.5);
    transition: box-shadow 0s;
  }
  td._modifiedData,
  tr._modifiedData {
    box-shadow: inset 0px 0px 100px rgba(255, 255, 118, 0.5);
    transition: box-shadow 0s;
  }
  tr._fading {
    animation-name: fadingBg;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    td:not(.TableCell-Menu) {
      animation-name: fading;
      animation-duration: 0.4s;
      animation-fill-mode: forwards;
    }
  }
  td {
    &.nameDisplay {
    }
  }
  @keyframes fadingBg {
    100% {
      background-color: var(--furtherFadedBg);
    }
  }
  @keyframes fading {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }
  tr._deleted {
    text-decoration: line-through;
  }
  thead {
    z-index: 4;
    position: sticky;
    top: -1px;
    @media (max-resolution: 1dppx) {
      top: 0;
    }
    th {
      white-space: nowrap;
    }
    tr.row-selectall {
      cursor: pointer;
      &:hover {
        background: var(--borderDefault);
        filter: var(--fadeLessLess);
      }
    }
  }
  tbody {
    td {
      // This is to break cell contents anywhere
      // which mitigates column resize when elements are
      // added/removed in a virtual list when scrolling.
      word-break: break-word;
      // For double-line cells to avoid crampedness.
      line-height: 1;
    }
    .TableCell-Select {
      &:not(.selected) {
        svg {
          opacity: 0.1;
          transition: opacity 0.15s;
        }
      }
    }
  }
  > tbody {
    > tr {
      transition: box-shadow 3s;
      td {
        transition: box-shadow 3s;
      }
      .TableCell-Menu button {
        opacity: 0.1;
        transition: opacity 0.15s;
        &:focus {
          opacity: 1;
          transition: opacity 0.05s;
        }
        // fix for EDS Button pseudo-element overflow issue
        &::after {
          margin-left: -4px;
        }
      }
      &:hover {
        background: var(--semiFadedBg);
        .TableCell-Menu {
          background: var(--semiFadedBg);
          button {
            opacity: 1;
            transition: opacity 0.05s;
          }
        }
        .TableCell-Select {
          background: var(--semiFadedBg);
          svg {
            opacity: 1;
            transition: opacity 0.05s;
          }
        }
      }
    }
  }
  .TableCell-Select {
    background: var(--bg);
    position: sticky;
    left: 0;
    z-index: 2;
    width: 48px;
    padding-left: 8px;
    padding-right: 0;
    transition: box-shadow 0s;
  }
  th.TableCell-Select {
    background: var(--semiFadedBg);
  }
  td.TableCell-Menu,
  th.TableCell-Menu {
    position: sticky;
    right: 0;
    z-index: 2;
    padding-left: 2px;
    padding-right: 4px;
    text-align: right;
    white-space: nowrap;
    transition: box-shadow 0s;
  }
  td.TableCell-Menu {
    background: var(--bg);
    padding-left: 2px;
    padding-right: 4px;
  }
  th.TableCell-Menu {
    z-index: 3;
  }
  input[type="text"]:not([readonly]) {
    // makes text inputs translucent for the line hover effect to be better
    background-color: var(--transparentBgFaded);
  }
`;

export const TableRowWithDefaultColor = styled(EDSTable.Row)`
  th,
  td {
    background: var(--bg);
    border-bottom: none;
  }
`;

export const ControlHeaderContainer = styled.div`
  padding: 12px;
`;

export const ControlHeaderTitle = styled(Typography).attrs({
  variant: "h3",
})`
  padding-top: 2px;
`;

export const HeadCellContent = styled.div.attrs({
  className: "HeadCellContent",
})`
  display: flex;
  align-items: center;
  height: 100%;
`;

export const Dragger = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 12px;
  cursor: ew-resize;
  &::after {
    position: absolute;
    content: "";
    top: 0;
    right: 2px;
    width: 2px;
    height: 100%;
    background: var(--borderDefault);
    filter: var(--fadeLess);
    z-index: 2;
    visibility: hidden;
  }
  &:hover {
    &::after {
      visibility: visible;
    }
  }
  &.dragging {
    &::after {
      filter: var(--fade);
      visibility: visible;
    }
  }
`;

export const SmallTableContainer = styled.div`
  min-height: 240px;
  height: 100%;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--borderDefault);
`;

export const MediumTableContainer = styled.div`
  min-height: 50vh;
  height: 100%;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--borderDefault);
`;

export const TableCellContentNoClamp = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`;

export const TableGroupCellContentNoClamp = styled.div`
  padding-top: 12px;
  padding-bottom: 12px;
`;

export const TableCellContent = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const HeadTitle = styled.div`
  display: flex;
  align-items: center;
`;

function reverseDateRepresentationOfProp(item: any, prop: string) {
  if (!item[prop]) {
    return item;
  }
  const dateAndTime = item[prop].split(" ");
  const parts = dateAndTime[0].split(".");
  return {
    ...item,
    [prop]: `${parts[2]}.${parts[1]}.${parts[0]} ${
      dateAndTime[1] ? dateAndTime[1] : ""
    }`,
  };
}

interface OrderItemsProps {
  items: any[] | undefined;
  sortBy: SortProps;
  selectedFirst?: boolean;
  selection?: string[];
  itemIdProp: string;
  groupBy?: string;
  groupTitle?: string;
  groupIdProp?: string;
}

function addGroupArrayInfo<T extends { [index: string]: unknown }>(arr: T[]) {
  return arr.map((e, i) => ({
    ...e,
    _groupIndex: i,
    _groupLength: arr.length,
  }));
}

function addAbsoluteArrayInfo<T extends { [index: string]: unknown }>(
  arr: T[]
) {
  let index = -1;
  let length = arr.filter((e) => !("_type" in e)).length;
  return arr.reduce((a, c) => {
    !("_type" in c) && index++;
    return [
      ...a,
      {
        ...c,
        _absoluteIndex: index,
        _absoluteLength: length,
      },
    ];
  }, [] as any);
}

function sortItemsAndAddArrayInfo(props: OrderItemsProps) {
  return addGroupArrayInfo(sortItems(props));
}

function sortItems(props: OrderItemsProps) {
  const { items, sortBy, selectedFirst, selection, itemIdProp } = props;
  const { key, order, mode } = sortBy;
  if (!items) {
    return [];
  }

  function selectedSort(a: any, b: any) {
    return selectedFirst && selection
      ? selection.includes(String(a[itemIdProp]))
        ? -1
        : selection.includes(String(b[itemIdProp]))
        ? 1
        : 0
      : 0;
  }
  if (!key || !order) {
    if (selectedFirst && selection) {
      return [...items].sort(selectedSort);
    }
    return items;
  }

  const sortedItems = [...items];

  function stringSort(a: any, b: any) {
    if (!key) {
      return 0;
    }
    const textA = order === "asc" ? a[key] : b[key];
    const textB = order === "asc" ? b[key] : a[key];
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  }

  if (mode === "date") {
    return sortedItems
      .map((i) => reverseDateRepresentationOfProp(i, key))
      .sort(stringSort)
      .map((i) => reverseDateRepresentationOfProp(i, key))
      .sort(selectedSort);
  }

  if (mode === "numeric") {
    return sortedItems
      .sort((a, b) => {
        const result = b[key].localeCompare(a[key], undefined, {
          numeric: true,
          sensitivity: "base",
        });
        return order === "asc" ? !result : result;
      })
      .sort(selectedSort);
  }

  if (mode === "natural") {
    const naturalSorted = sortedItems.sort((a: any, b: any) =>
      naturalSort.compare(a.id, b.id)
    );
    return order === "asc" ? naturalSorted : naturalSorted.reverse();
  }

  return sortedItems.sort(stringSort).sort(selectedSort);
}

function getOrderedItems(props: OrderItemsProps) {
  const { items, itemIdProp, groupBy, groupTitle, groupIdProp } = props;

  if (!items) {
    return [];
  }

  if (groupBy) {
    const groups = items.reduce((a, c) => {
      const groupProp = groupIdProp
        ? c[groupIdProp]
        : groupTitle
        ? c[groupTitle]
        : c[groupBy];

      return !a.find((e: any) => e[itemIdProp] === groupProp) &&
        (a.length === 0 || c[groupBy] !== a[a.length - 1][groupBy])
        ? a.concat([
            {
              _type: "groupHeader",
              title: groupTitle
                ? c[groupTitle]
                  ? c[groupTitle]
                  : c[groupBy]
                : c[groupBy],
              [itemIdProp]: groupProp,
            },
          ])
        : a;
    }, []);

    const itemGroups = items.reduce(
      (a, c) => ({
        ...a,
        [c[groupBy]]: [...(a[c[groupBy]] ? a[c[groupBy]] : []), c],
      }),
      {}
    );

    const result = groups.reduce(
      (a: any[], c: any) => [
        ...a,
        c,
        ...sortItemsAndAddArrayInfo({
          ...props,
          items: itemGroups[c[itemIdProp]],
        }),
      ],
      []
    );
    return addAbsoluteArrayInfo(result);
  }

  return addAbsoluteArrayInfo(sortItemsAndAddArrayInfo(props));
}

export default function Table({
  columns,
  items,
  itemIdProp,
  status = "success",
  selectionMode = false,
  selection,
  selectionDispatch,
  controlHeader,
  noItemsMessage,
  HeaderRow,
  aboveHeaderRow,
  Row,
  RowMenu,
  Commands,
  TableComponent,
  contextData,
  loadingString,
  sortable,
  fullRowSelect,
  headerRowSelectAll,
  resizable,
  totalCount,
  infoline,
  infoLineExtraInfo,
  itemsClassNames,
  error,
  message,
  density,
  noFilteredItemMessage,
  codelists,
  codelistsToQuery,
  refetch,
  fillRest,
  selectedFirst,
  nonVirtual,
  groupBy,
  groupTitle,
  groupIdProp,
  RowHiddenComponent,
  relativePositioning,
  containerId,
  autoSizeColumns,
  centerColumnHeaders,
  footer,
  footerTypographyVariant,
  initialSortBy,
  disabledItems,
  disableHeader,
  disableSelectAll,
  disableAllItems,
}: {
  columns: ColumnsProps[];
  items: any[] | undefined;
  itemIdProp: string;
  status?: QueryStatus;
  selectionMode?: SelectionModeType;
  selection?: string[];
  selectionDispatch?: React.Dispatch<SelectionReducerAction>;
  controlHeader?: React.ReactNode;
  noItemsMessage?: string | React.ReactFragment;
  HeaderRow?: React.ComponentType<any>;
  aboveHeaderRow?: React.ReactFragment;
  Row?: React.ComponentType<any>;
  RowMenu?: React.ComponentType<any>;
  Commands?: React.ComponentType<any> | React.ComponentType<any>[];
  TableComponent?: React.ComponentType<any>;
  contextData?: any;
  loadingString?: string;
  sortable?: boolean;
  fullRowSelect?: boolean;
  headerRowSelectAll?: boolean;
  resizable?: boolean;
  totalCount?: number;
  infoline?: boolean;
  infoLineExtraInfo?: string[];
  itemsClassNames?: ItemsClassNames[];
  error?: any;
  message?: string;
  density?: "compact" | "comfortable";
  noFilteredItemMessage?: string;
  codelists?: {
    [index: string]: CodelistData;
  };
  codelistsToQuery?: Codelists[];
  refetch?: Function;
  fillRest?: boolean;
  selectedFirst?: boolean;
  nonVirtual?: boolean;
  groupBy?: string;
  groupTitle?: string;
  groupIdProp?: string;
  RowHiddenComponent?: React.ComponentType<any>;
  relativePositioning?: boolean;
  containerId?: string;
  autoSizeColumns?: boolean;
  centerColumnHeaders?: boolean;
  footer?: React.ReactNode;
  footerTypographyVariant?: TypographyVariants;
  initialSortBy?: SortProps;
  disabledItems?: string[];
  disableHeader?: boolean;
  disableSelectAll?: boolean;
  disableAllItems?: boolean;
}) {
  const [wrapperRef, wrapperSize] = useMeasure();
  const [tableHeadRef, tableHeadSize] = useMeasure();
  const [sortBy, setSortBy] = useState(initialSortBy ? initialSortBy : {});

  const groups = !items
    ? []
    : !groupBy
    ? []
    : items
        .map((e) => e[groupBy])
        .reduce((a, c) => (a[a.length - 1] === c ? a : a.concat(c)), []);

  // selectionMemo is used to retain selection when items is (temporarily) empty
  // because of filtering or loading
  const [selectionMemo, setSelectionMemo] = useState(
    selection ? selection : ([] as string[])
  );

  const ThisHeaderRow = HeaderRow ? HeaderRow : TableHeaderRow;
  const ThisRow = Row ? Row : TableContentRow;
  const ThisTableComponent = TableComponent
    ? TableComponent
    : DefaultTableComponent;
  const thisDensity = density ? density : "comfortable";
  const ThisRowHiddenComponent = RowHiddenComponent ? RowHiddenComponent : null;

  useEffect(() => {
    setSortBy(initialSortBy ? initialSortBy : {});
  }, [columns, initialSortBy]);

  const codelistQueryResults = useCodelists({ codelists: codelistsToQuery });
  const queriedCodelists = useMemo(() => {
    return codelistsToQuery
      ? Object.fromEntries(
          codelistsToQuery.map((codelist, i) => [
            codelist,
            codelistQueryResults && codelistQueryResults[i].data
              ? Object.fromEntries(
                  // @ts-ignore
                  codelistQueryResults[i].data.map((e) => [
                    // @ts-ignore
                    e[codelistProperties[codelist].idProp],
                    // @ts-ignore
                    e[codelistProperties[codelist].nameProp],
                  ])
                )
              : {},
          ])
        )
      : {};
  }, [codelistsToQuery, codelistQueryResults]);

  const selectAll = () =>
    selectionDispatch &&
    selectionMode === "multi" &&
    (selection && selection?.length > 0
      ? selectionDispatch({ type: "select", payload: [] })
      : selectionDispatch({
          type: "select",
          payload: items?.map((i) => String(i[itemIdProp])),
        }));

  const selectorAll =
    selectionMode === "multi" ? (
      <>
        <Checkbox
          checked={items?.length === selection?.length}
          indeterminate={
            items &&
            selection &&
            selection?.length > 0 &&
            selection?.length < items?.length
          }
          onChange={() => {
            selectAll();
          }}
        />
      </>
    ) : (
      <></>
    );

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        status,
        ...codelistQueryResults.map(
          (codelistQueryResult) => codelistQueryResult.status
        )
      ),
    [status, codelistQueryResults]
  );

  useEffect(() => {
    selection && setSelectionMemo(selection);
  }, [selection]);

  useEffect(() => {
    if (
      selectionDispatch &&
      selection &&
      items &&
      combinedStatuses === "success"
    ) {
      selectionDispatch({ type: "reset" });
      selectionDispatch({
        type: "select",
        payload:
          selectionMemo &&
          selectionMemo.filter((s) =>
            items.map((i) => String(i[itemIdProp])).includes(s)
          ),
      });
    }
    // Keep selection when items change but some of the selection remains.
    // eslint-disable-next-line
  }, [items, combinedStatuses]);

  const [initialSelection, setInitialSelection] = useState(
    selection ? selection : []
  );

  useEffect(
    () => {
      selection && selection.length > 0 && setInitialSelection(selection);
    },
    // This effect updates the initialselection when sortBy changes
    // to make the actual selection appear on the top.
    // eslint-disable-next-line
    [sortBy]
  );

  const PositionContainer = relativePositioning
    ? FlexContainer
    : AbsoluteFlexContainer;

  const Centering = relativePositioning ? FlexContainer : TotalCenter;

  return (
    <EdsProvider density={thisDensity}>
      <PositionContainer>
        <FlexHeaderElement>{controlHeader}</FlexHeaderElement>
        <FlexContentElement>
          <PositionContainer>
            <FlexContentElement ref={wrapperRef} id={containerId}>
              {message ? (
                <Centering>{message}</Centering>
              ) : combinedStatuses === "idle" ? (
                <ContentMessage>Query is idle.</ContentMessage>
              ) : combinedStatuses === "loading" ? (
                <Loader label={loadingString ? loadingString : "Loading..."} />
              ) : combinedStatuses === "error" ? (
                <>
                  <ContentMessage color="danger">
                    Error.
                    {error && error instanceof Error && (
                      <Typography style={{ marginBottom: ".5em" }}>
                        {error.message}
                      </Typography>
                    )}
                    <SimpleButtonContainer>
                      {refetch && (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            refetch();
                            codelistQueryResults.forEach((codelistQuery) =>
                              codelistQuery.refetch()
                            );
                          }}
                        >
                          Retry?
                        </Button>
                      )}
                      {error &&
                        error instanceof Error &&
                        error.message === "Sign in needed." && <SignInButton />}
                    </SimpleButtonContainer>
                  </ContentMessage>
                </>
              ) : totalCount === 0 || (items?.length === 0 && !totalCount) ? (
                <Centering>
                  {noItemsMessage ? noItemsMessage : "No items."}
                </Centering>
              ) : items?.length === 0 ? (
                <Centering>
                  {typeof noFilteredItemMessage != "undefined"
                    ? noFilteredItemMessage
                    : "No item matches the filter criteria."}
                </Centering>
              ) : (
                <VirtualTable
                  nonVirtual={nonVirtual}
                  header={
                    <ThisHeaderRow
                      ref={tableHeadRef}
                      selectAll={selectAll}
                      selectorAll={selectorAll}
                      columns={columns}
                      sortable={!!sortable}
                      resizable={!!resizable}
                      sortBy={sortBy}
                      setSortBy={setSortBy}
                      hasRowMenu={!!RowMenu || !!Commands}
                      headerRowSelectAll={headerRowSelectAll}
                      selectionMode={selectionMode}
                      fillRest={fillRest}
                      aboveHeaderRow={aboveHeaderRow}
                      autoSizeColumns={autoSizeColumns}
                      centerColumnHeaders={centerColumnHeaders}
                      disableHeader={disableHeader}
                      disableSelectAll={disableSelectAll}
                    />
                  }
                  tableClassName={`${sortable ? "table-sortable" : ""} ${
                    tableHeadSize.width > wrapperSize.width
                      ? "table-oversize"
                      : ""
                  } ${
                    thisDensity === "compact" ? "table-compact" : ""
                  } table-num-cols-${columns.length} ${
                    aboveHeaderRow ? "table-has-row-above-header" : ""
                  }`}
                  TableComponent={ThisTableComponent}
                  TableBodyComponent={EDSTable.Body}
                  TableRowComponent={TableRowWrapper}
                  height={wrapperSize.height}
                  width="100%"
                  itemCount={items ? items.length + groups.length : 0}
                  itemSize={thisDensity === "comfortable" ? 49 : 33}
                  itemData={{
                    items: getOrderedItems({
                      items,
                      sortBy,
                      selectedFirst,
                      selection: initialSelection,
                      itemIdProp,
                      groupBy,
                      groupTitle,
                      groupIdProp,
                    }),
                    tableData: {
                      itemIdProp,
                      selectionMode,
                      selection,
                      selectionDispatch,
                      columns,
                      fullRowSelect,
                      RowMenu,
                      Commands,
                      itemsClassNames,
                      codelists: { ...codelists, ...queriedCodelists },
                      fillRest,
                      RowHiddenComponent: ThisRowHiddenComponent,
                      nonVirtual,
                      groupIdProp: groupIdProp ? groupIdProp : itemIdProp,
                      disabledItems,
                      disableAllItems,
                    },
                    contextData,
                    Row: ThisRow,
                  }}
                />
              )}
            </FlexContentElement>
            {infoline && combinedStatuses === "success" && totalCount ? (
              <FlexHeaderElement>
                <InfolineContainer>
                  {selection && selection.length > 0 && (
                    <div>Selected: {selection.length}</div>
                  )}
                  {totalCount && (
                    <>
                      {totalCount !== items?.length && (
                        <div>Filtered: {items ? items.length : 0}</div>
                      )}
                      <div>Total: {totalCount}</div>
                    </>
                  )}
                  {infoLineExtraInfo?.map((e) => (
                    <div>{e}</div>
                  ))}
                </InfolineContainer>
              </FlexHeaderElement>
            ) : (
              <></>
            )}
            {footer && combinedStatuses === "success" ? (
              <FlexHeaderElement>
                <BottomBorderContainer>
                  <InfolineContainer
                    typographyVariant={footerTypographyVariant ?? "caption"}
                  >
                    <FooterContainer>{footer}</FooterContainer>
                  </InfolineContainer>
                </BottomBorderContainer>
              </FlexHeaderElement>
            ) : (
              <></>
            )}
          </PositionContainer>
        </FlexContentElement>
      </PositionContainer>
    </EdsProvider>
  );
}

function TableRowWrapper({
  index,
  Row,
  item,
  contextData,
  tableData,
}: {
  index: number;
  Row: React.ComponentType<any>;
  item: any;
  contextData: any;
  tableData: TableDataProps;
}) {
  const selectRef = useRef(null);
  const {
    itemIdProp,
    selectionMode,
    selection,
    selectionDispatch,
    columns,
    fullRowSelect,
    RowMenu,
    Commands,
    itemsClassNames,
    codelists,
    fillRest,
    RowHiddenComponent,
    groupIdProp,
  } = tableData;
  const selected = selection?.includes(String(item[itemIdProp]));
  const selector =
    selectionMode === "multi" ? (
      <Checkbox
        ref={selectRef}
        value={item[itemIdProp]}
        checked={selected}
        onChange={(e) => {
          selectionDispatch({
            type: selection?.includes(e.target.value) ? "deselect" : "select",
            payload: e.target.value,
          });
        }}
      />
    ) : selectionMode === "single" ? (
      <Radio
        ref={selectRef}
        value={item[itemIdProp]}
        checked={selected}
        onChange={(e) =>
          selectionDispatch({ type: "select", payload: e.target.value })
        }
      />
    ) : (
      <></>
    );

  const ThisRowComponent =
    item["_type"] === "groupHeader" ? TableGroupHeaderRow : Row;

  return (
    <ThisRowComponent
      index={index}
      item={item}
      contextData={contextData}
      rowData={{
        index,
        selected,
        selector,
        selectRef,
        columns,
        fullRowSelect,
        RowMenu,
        Commands,
        itemsClassNames,
        itemIdProp,
        codelists,
        selectionMode,
        fillRest,
        RowHiddenComponent,
        groupIdProp,
      }}
      tableData={tableData}
    />
  );
}

export function TableHeaderRowCell({
  col,
  columns,
  sortable,
  resizable,
  sortBy,
  setSortBy,
  autoSizeColumns,
  centerColumnHeaders,
}: {
  col: ColumnsProps;
  columns: ColumnsProps[];
  sortable: boolean;
  resizable: boolean;
  sortBy: SortProps;
  setSortBy: Function;
  autoSizeColumns?: boolean;
  centerColumnHeaders?: boolean;
}) {
  const { transform, listeners, attributes, setNodeRef, isDragging } =
    useDraggable({
      id: col.key,
    });

  const defaultWidth = col.width
    ? col.width
    : defaultWidths.hasOwnProperty(col.key)
    ? defaultWidths[col.key]
    : autoSizeColumns
    ? String(100 / columns.length) + "%"
    : "";

  const [width, setWidth] = useState(0);
  const [startWidth, setStartWidth] = useState(0);

  const [titleRef, titleSize] = useMeasure();
  const [cellContentRef, cellContentSize] = useMeasure();

  React.useLayoutEffect(() => {
    if (transform) {
      const newWidth =
        startWidth + transform.x > titleSize.width
          ? startWidth + transform.x
          : titleSize.width;
      newWidth !== width && setWidth(newWidth);
    }
  }, [transform, width, titleSize, startWidth]);

  return (
    <EDSTable.Cell
      key={col.key}
      className={sortable ? "column-sortable" : ""}
      style={{
        width: width ? width : defaultWidth,
        minWidth: col.minWidth
          ? titleSize.width > col.minWidth
            ? titleSize.width
            : col.minWidth
          : titleSize.width,
      }}
      title={col?.longTitle}
    >
      <HeadCellContent
        style={{
          width: width
            ? width
            : String(defaultWidth).includes("%")
            ? "100%"
            : defaultWidth,
          minWidth: titleSize.width,
          ...(centerColumnHeaders
            ? { justifyContent: "center", marginLeft: 0 }
            : {}),
        }}
        ref={cellContentRef}
        onClick={() =>
          sortable &&
          setSortBy({
            key: col.key,
            order:
              sortBy.key === col.key && sortBy.order === "desc"
                ? "asc"
                : "desc",
            mode: col.hasOwnProperty("sort")
              ? col.sort
              : defaultSort.hasOwnProperty(col.key)
              ? defaultSort[col.key]
              : "string",
          })
        }
      >
        <HeadTitle ref={titleRef}>
          {col.title}
          <OrderIcon
            data={sortBy.order === "desc" ? arrow_drop_down : arrow_drop_up}
            style={{
              visibility: sortBy.key === col.key ? "visible" : "hidden",
            }}
          />
        </HeadTitle>
      </HeadCellContent>
      {resizable ? (
        <Dragger
          ref={setNodeRef}
          {...listeners}
          {...attributes}
          onMouseDown={() => {
            setStartWidth(cellContentSize.width - 32);
          }}
          onDoubleClick={() => {
            setWidth(String(defaultWidth).includes("%") ? 0 : titleSize.width);
          }}
          className={isDragging ? "dragging" : ""}
        />
      ) : (
        <></>
      )}
    </EDSTable.Cell>
  );
}

export const TableHeaderRow = React.forwardRef(
  (
    {
      selectAll,
      selectorAll,
      columns,
      sortable,
      resizable,
      sortBy,
      setSortBy,
      hasRowMenu,
      headerRowSelectAll,
      selectionMode,
      fillRest,
      aboveHeaderRow,
      autoSizeColumns,
      centerColumnHeaders,
      disableHeader,
      disableSelectAll,
    }: {
      selectAll: Function;
      selectorAll: React.ReactFragment;
      columns: ColumnsProps[];
      sortable: boolean;
      resizable: boolean;
      sortBy: SortProps;
      setSortBy: Function;
      hasRowMenu: boolean;
      headerRowSelectAll?: boolean;
      selectionMode: SelectionModeType;
      fillRest?: boolean;
      aboveHeaderRow?: React.ReactFragment;
      autoSizeColumns?: boolean;
      centerColumnHeaders?: boolean;
      disableHeader?: boolean;
      disableSelectAll?: boolean;
    },
    ref: React.Ref<any>
  ) => {
    return (
      <>
        <EDSTable.Head sticky ref={ref}>
          {aboveHeaderRow}
          {!disableHeader && (
            <EDSTable.Row
              className={!sortable && headerRowSelectAll ? "row-selectall" : ""}
              onClick={() => !sortable && headerRowSelectAll && selectAll()}
            >
              {selectionMode && (
                <EDSTable.Cell className="TableCell-Select">
                  {disableSelectAll ? "" : selectorAll}
                </EDSTable.Cell>
              )}
              {!sortable && headerRowSelectAll ? (
                <EDSTable.Cell colSpan={columns.length}>
                  Select all
                </EDSTable.Cell>
              ) : (
                columns.map((col) => (
                  <TableHeaderRowCell
                    key={col.key}
                    col={col}
                    columns={columns}
                    sortable={sortable}
                    resizable={resizable}
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                    autoSizeColumns={autoSizeColumns}
                    centerColumnHeaders={centerColumnHeaders}
                  />
                ))
              )}
              {fillRest && <EDSTable.Cell className="restFiller" />}
              {hasRowMenu ? (
                <EDSTable.Cell className="TableCell-Menu"></EDSTable.Cell>
              ) : (
                <></>
              )}
            </EDSTable.Row>
          )}
        </EDSTable.Head>
        {headerRowSelectAll ? (
          <EDSTable.Head style={{ visibility: "collapse" }}>
            <EDSTable.Row>
              <EDSTable.Cell className="TableCell-Select"></EDSTable.Cell>
              {columns.map((col) => (
                <TableHeaderRowCell
                  key={col.key}
                  col={col}
                  columns={columns}
                  sortable={sortable}
                  resizable={resizable}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                />
              ))}
              {fillRest && <EDSTable.Cell className="restFiller" />}
              {hasRowMenu ? (
                <EDSTable.Cell className="TableCell-Menu"></EDSTable.Cell>
              ) : (
                <></>
              )}
            </EDSTable.Row>
          </EDSTable.Head>
        ) : (
          <></>
        )}
      </>
    );
  }
);

const GroupHeaderStyle = styled.div`
  padding-left: 4px;
  font-weight: bold;
`;

export function TableGroupHeaderRow({
  index,
  item,
  contextData,
  rowData,
  tableData,
}: {
  index: number;
  item: any;
  contextData: any;
  rowData: RowDataProps;
  tableData: TableDataProps;
}) {
  const { columns } = rowData;
  const { nonVirtual, itemsClassNames } = tableData;
  const CellContent = nonVirtual
    ? TableGroupCellContentNoClamp
    : TableCellContent;
  const id = `_group_${item.itemID}`;
  const itemsClassName = itemsClassNames
    ? itemsClassNames.find((e) => e.itemID === id && e.element === "_line")
    : "";

  return (
    <EDSTable.Row
      className={`groupHeader ${itemsClassName && itemsClassName.className}`}
    >
      <EDSTable.Cell className="titleContainer" colSpan={columns.length + 2}>
        <Element
          name={id}
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CellContent>
            <GroupHeaderStyle>{item.title}</GroupHeaderStyle>
          </CellContent>
        </Element>
      </EDSTable.Cell>
    </EDSTable.Row>
  );
}

export function TableContentRow({
  index,
  item,
  contextData,
  rowData,
  tableData,
}: {
  index: number;
  item: any;
  contextData: any;
  rowData: RowDataProps;
  tableData: TableDataProps;
}) {
  const {
    selected,
    selector,
    selectRef,
    columns,
    fullRowSelect,
    RowMenu,
    Commands,
    itemsClassNames,
    itemIdProp,
    codelists,
    selectionMode,
    fillRest,
    RowHiddenComponent,
  } = rowData;
  const { nonVirtual, disabledItems, disableAllItems } = tableData;
  const disabled = useMemo(
    () => disableAllItems || disabledItems?.includes(item[itemIdProp]),
    [disabledItems, item, itemIdProp, disableAllItems]
  );

  return (
    <EDSTable.Row
      onClick={(e) => {
        if (!disabled && fullRowSelect) {
          const target = e.target as HTMLElement;
          target.tagName !== "INPUT" &&
            target.tagName !== "BUTTON" &&
            selectRef.current.click();
        }
      }}
      style={{ cursor: !disabled && fullRowSelect ? "pointer" : "default" }}
      className={`${
        itemsClassNames
          ? itemsClassNames
              ?.filter(
                (ic) => ic.itemID === item[itemIdProp] && ic.element === "_line"
              )
              .map((e) => e.className)
              .join(" ")
          : ""
      }${
        typeof item._classNames !== "undefined"
          ? item._classNames.join(" ")
          : ""
      }${disabled ? " faded" : ""}`}
    >
      {RowHiddenComponent && (
        <td style={{ display: "none" }}>
          <RowHiddenComponent item={item} {...contextData} />
        </td>
      )}
      {selectionMode && (
        <EDSTable.Cell
          className={`TableCell-Select ${selected ? "selected" : ""}`}
        >
          {disabled ? (
            <Checkbox disabled={true} checked={selected} />
          ) : (
            selector
          )}
        </EDSTable.Cell>
      )}
      {columns.map((column) => {
        const { codelist, key, componentProps, align } = column;
        const Component =
          column.Component && column.Component !== ""
            ? column.Component
            : defaultComponents.hasOwnProperty(key) && column.Component !== ""
            ? defaultComponents[key]
            : React.Fragment;
        const codes = codelist
          ? codelists && codelists.hasOwnProperty(codelist)
            ? codelists[codelist]
            : {}
          : false;
        const content =
          typeof item[key] === "undefined" ? (
            <i>?</i>
          ) : column?.type === "comma-delimited" ? (
            item[key] === "*" ? (
              "All"
            ) : codes ? (
              item[key]
                .split(",")
                .map((i: string) => (codes[i] ? codes[i] : `[${i}]`))
                .join(", ")
            ) : (
              item[key].split(",").join(", ")
            )
          ) : codes ? (
            item[key] === 0 || item[key] === "" ? (
              ""
            ) : codes[item[key]] ? (
              codes[item[key]]
            ) : (
              `[${item[key]}]`
            )
          ) : (
            item[key]
          );
        return (
          <EDSTable.Cell
            key={key}
            className={
              itemsClassNames
                ?.filter(
                  (ic) => ic.itemID === item[itemIdProp] && ic.element === key
                )
                .map((e) => e.className)
                .join(" ") + (column.className ? " " + column.className : "")
            }
            style={align === "center" ? { textAlign: "center" } : {}}
          >
            {column?.type === "with-context" ? (
              <Component
                {...contextData}
                {...componentProps}
                item={item}
                index={index}
                column={column}
                align={align}
              />
            ) : nonVirtual && !Component ? (
              <TableCellContentNoClamp>{content}</TableCellContentNoClamp>
            ) : (
              <TableCellContent title={content}>
                {align === "center" ? (
                  <CenterChildren>
                    <Component>{content}</Component>
                  </CenterChildren>
                ) : (
                  <Component>{content}</Component>
                )}
              </TableCellContent>
            )}
          </EDSTable.Cell>
        );
      })}
      {fillRest && <EDSTable.Cell className="restFiller" />}
      {!!RowMenu || !!Commands ? (
        <EDSTable.Cell className="TableCell-Menu">
          {!!Commands && (
            <InlineBlock>
              {Array.isArray(Commands) ? (
                Commands.map((Command, i) => (
                  <Command key={i} {...contextData} item={item} index={index} />
                ))
              ) : (
                <Commands {...contextData} item={item} index={index} />
              )}
            </InlineBlock>
          )}
          {!!RowMenu && (
            <InlineBlock>
              <RowMenu {...contextData} item={item} />
            </InlineBlock>
          )}
        </EDSTable.Cell>
      ) : (
        <></>
      )}
    </EDSTable.Row>
  );
}

export type TableProps = Parameters<typeof Table>[0];
