import {
  getElementNamePlural,
  nameProperties,
  plantDependentSheets,
  sheetProperties,
  SheetTypes,
} from "./config/sheetConfig";
import { PlantProps } from "../plant/types";
import { ModalInsideContainerScrollable } from "../../components/Modal";
import { MultiColumnList } from "../../components/Components";
import { setPaneSheet, setPaneMode, PaneLocation } from "uiSlice";
import { EditMethods } from "../../queries/queryUtil";
import { History } from "history";
import { getPreviewUrl } from "./util";
import { NewSheetRevisions } from "queries/useNewSheetRevisions";

export const copySelected = async ({
  selectedItems,
  newRevision,
  hasName,
  sheetTypeName,
  newNames,
  sourcePlant,
  mainPlant,
  copySheets,
  sheetType,
  isConfirmed,
}: {
  selectedItems: any[];
  newRevision: string | undefined;
  hasName: boolean;
  sheetTypeName: string;
  newNames: any[];
  sourcePlant: PlantProps;
  mainPlant: PlantProps;
  copySheets: Function;
  sheetType: SheetTypes;
  isConfirmed: Function;
}) => {
  if (!newRevision) {
    throw new Error("Copy error: No new revision defined.");
  }
  const sheetsToCopy = selectedItems.map((item: any) => {
    return {
      ...(hasName && { [sheetTypeName]: item[sheetTypeName] }),
      Revision: item.Revision,
      NewName: newNames[item.itemID],
    };
  });
  const confirmed = await isConfirmed(
    <>
      <p>
        Are you sure you want to copy the selected data sheets from{" "}
        <b>{sourcePlant.LongDescription}</b> to{" "}
        <b>{mainPlant.LongDescription}</b> with new revision{" "}
        <b>{newRevision}</b>?
      </p>
      <ModalInsideContainerScrollable>
        <MultiColumnList columns={Math.floor(sheetsToCopy.length / 3 + 1)}>
          {sheetsToCopy.map((v: any) => {
            const content = hasName
              ? `${v[sheetTypeName]} rev. ${v.Revision}${
                  v.NewName !== v[sheetTypeName]
                    ? ` (new name: ${v.NewName})`
                    : ``
                }`
              : v.Revision;
            return <li key={content}>{content}</li>;
          })}
        </MultiColumnList>
      </ModalInsideContainerScrollable>
    </>
  );
  if (confirmed) {
    copySheets({
      sourcePlant: sourcePlant,
      targetPlant: mainPlant,
      sheetType: sheetType,
      sheets: sheetsToCopy,
      newRevision: newRevision,
    });
  }
};

export const deleteSingle = async ({
  sheetName,
  sheetRevision,
  hasName,
  sheetTypeName,
  nameProperty,
  mainPlant,
  deleteSheets,
  sheetType,
  isConfirmed,
}: {
  sheetName: string;
  sheetRevision: string;
  hasName: boolean;
  sheetTypeName: string;
  nameProperty: string;
  mainPlant: PlantProps;
  deleteSheets: Function;
  sheetType: SheetTypes;
  isConfirmed: Function;
}) => {
  const confirmed = await isConfirmed(
    <p>
      Do you really want to delete the {sheetTypeName}{" "}
      <b>
        {sheetName}
        {hasName && <> rev. {sheetRevision}</>}
      </b>
      {plantDependentSheets.includes(sheetType) && (
        <>
          {" "}
          from <b>{mainPlant.LongDescription}</b>
        </>
      )}
      ?
    </p>
  );
  if (confirmed) {
    deleteSheets({
      plant: mainPlant,
      sheetType: sheetType,
      sheets: [
        {
          ...(hasName && { [nameProperty]: sheetName }),
          Revision: sheetRevision,
        },
      ],
    });
  }
};

export const deleteSelected = async ({
  selectedItems,
  hasName,
  nameProperty,
  mainPlant,
  deleteSheets,
  sheetType,
  isConfirmed,
}: {
  selectedItems: any[];
  hasName: boolean;
  nameProperty: string;
  mainPlant: PlantProps;
  deleteSheets: Function;
  sheetType: SheetTypes;
  isConfirmed: Function;
}) => {
  const sheetsToDelete = selectedItems.map((item: any) => {
    return {
      ...(hasName && { [nameProperty]: item[nameProperty] }),
      Revision: item.Revision,
    };
  });
  if (sheetsToDelete.length > 0) {
    const confirmed = await isConfirmed(
      <>
        <p>
          Do you really want to delete the selected{" "}
          {getElementNamePlural(sheetType)}
          {plantDependentSheets.includes(sheetType) && (
            <>
              from <b>{mainPlant.LongDescription}</b>
            </>
          )}
          ?
        </p>
        <ModalInsideContainerScrollable>
          <MultiColumnList columns={6}>
            {sheetsToDelete.map((v: any) => {
              const sheet = hasName
                ? `${v[nameProperty]} rev. ${v.Revision}`
                : v.Revision;
              return <li key={sheet}>{sheet}</li>;
            })}
          </MultiColumnList>
        </ModalInsideContainerScrollable>
      </>
    );
    if (confirmed) {
      deleteSheets({
        plant: mainPlant,
        sheetType: sheetType,
        sheets: sheetsToDelete,
      });
    }
  }
};

export const newRevisionsFromSelected = async ({
  selectedItems,
  hasName,
  nameProperty,
  mainPlant,
  newSheetRevisions,
  sheetType,
  isConfirmed,
}: {
  selectedItems: any[];
  hasName: boolean;
  nameProperty: string;
  mainPlant: PlantProps;
  newSheetRevisions: NewSheetRevisions["mutate"];
  sheetType: SheetTypes;
  isConfirmed: Function;
}) => {
  const sheetsToRevise = selectedItems.map((item: any) => {
    return {
      ...(hasName && { [nameProperty]: item[nameProperty] }),
      Revision: item.Revision,
    };
  });
  if (sheetsToRevise.length > 0) {
    const confirmed = await isConfirmed(
      <>
        <p>
          Do you really want to create new revisions from the selected data
          sheets
          {plantDependentSheets.includes(sheetType) && (
            <>
              {" "}
              in <b>{mainPlant.LongDescription}</b>
            </>
          )}
          ?
        </p>
        <ModalInsideContainerScrollable>
          <MultiColumnList>
            {sheetsToRevise.map((v: any) => {
              const sheet = hasName
                ? `${v[nameProperty]} rev. ${v.Revision}`
                : v.Revision;
              return <li key={sheet}>{sheet}</li>;
            })}
          </MultiColumnList>
        </ModalInsideContainerScrollable>
      </>
    );
    if (confirmed) {
      newSheetRevisions({
        plant: mainPlant,
        sheetType,
        sheets: sheetsToRevise,
        ...((sheetType === "vds-textblocks" ||
          sheetType === "vds-subsegments" ||
          sheetType === "vds") && {
          command: "revision",
          sheetsName: sheetProperties[sheetType].sheetListProperty ?? "sheets",
        }),
      });
    }
  }
};

export function previewInPane({
  location,
  dispatch,
  plant,
  sheetType,
  item,
}: {
  location: PaneLocation;
  dispatch: Function;
  sheetType: SheetTypes;
  item: any;
  plant?: PlantProps;
}) {
  dispatch(
    setPaneSheet({
      location,
      sheet: {
        plant,
        sheetType,
        name: item[nameProperties[sheetType]],
        revision: item.Revision,
        format: item?.Format,
      },
    })
  );
  dispatch(
    setPaneMode({
      location,
      mode: "preview",
    })
  );
}

export const previewInNewWindow = ({
  item,
  sheetType,
  previewBaseUrl,
  plant,
}: {
  item: any;
  sheetType: SheetTypes;
  previewBaseUrl: string;
  plant?: PlantProps;
}) => {
  window.open(
    getPreviewUrl({ item, sheetType, previewBaseUrl, plant }),
    "_blank"
  );
};

export const openUrlInNewWindow = (url: string) => {
  window.open(url, "_blank");
};

export const editSheet = ({
  item,
  history,
  sheetType,
  tab,
}: {
  item: any;
  history: History;
  sheetType: SheetTypes;
  tab?: string;
}) => {
  history.push({
    search: `?edit=${item[nameProperties[sheetType]]}${
      sheetType !== "general" ? `&rev=${item.Revision}` : ""
    }${tab ? `&tab=${tab}` : ""}`,
  });
};

export const closeEditSheet = ({ history }: { history: History }) => {
  history.push({ search: "" });
};

export const pcsCommandOnSelected = async ({
  selectedItems,
  mainPlant,
  command,
  method,
  pcsCommand,
  extraParams,
  isConfirmed,
  commandName,
}: {
  selectedItems: any[];
  mainPlant: PlantProps;
  command: string;
  method: EditMethods;
  pcsCommand: Function;
  extraParams?: object;
  isConfirmed?: Function;
  commandName?: string;
}) => {
  const sheets = selectedItems.map((item: any) => {
    return {
      PCS: item.PCS,
      Revision: item.Revision,
    };
  });
  let confirmed = false;
  if (sheets.length > 0) {
    if (isConfirmed) {
      confirmed = await isConfirmed(
        <>
          <p>
            {commandName ? (
              <>
                Do you really want to {commandName} in{" "}
                <b>{mainPlant.LongDescription}</b>?
              </>
            ) : (
              <>Are you sure?</>
            )}
          </p>
          <ModalInsideContainerScrollable>
            <MultiColumnList>
              {sheets.map((v: any) => {
                const sheet = `${v.PCS} Rev. ${v.Revision}`;
                return <li key={sheet}>{sheet}</li>;
              })}
            </MultiColumnList>
          </ModalInsideContainerScrollable>
        </>
      );
    }
    if (!isConfirmed || confirmed) {
      pcsCommand({
        plant: mainPlant,
        sheets,
        command,
        method,
        extraParams,
      });
    }
  }
};
