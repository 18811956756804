import axios, { AxiosResponse } from "axios";
import { StatusResponseWithInfo } from "components/StatusModal";
import { VDSItemSections } from "features/sheets/types";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "./queryUtil";

export default function useCreateVDSTextBlock() {
  const queryClient = useQueryClient();

  async function createVDSTextBlock({
    itemSection,
    itemId,
  }: {
    itemSection: VDSItemSections;
    itemId: string;
  }): Promise<AxiosResponse<StatusResponseWithInfo>> {
    return await axios(getSheetApiUrl({ sheetType: "vds-textblocks" }), {
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: {
        Operation: "Add",
        ItemID: itemId,
        ItemSection: itemSection,
      },
    });
  }

  return useMutation(createVDSTextBlock, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["sheets", "vds-textblocks"]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
