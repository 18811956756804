import {
  Button,
  Checkbox,
  EdsProvider,
  Icon,
  Input,
  Label,
  TextField,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import { delete_forever, edit } from "@equinor/eds-icons";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import { useAppSelector } from "app/hooks";
import {
  AbsoluteFlexContainer,
  FlexContentElement,
  Loader,
} from "components/Components";
import {
  Fieldset,
  FieldsetContainer,
  FieldsetTitle,
  FormOptionsContainer,
  FormRow,
  FormRowBlock,
} from "components/form/Form";
import { ModalButtonContainer } from "components/Modal";
import { ModalSideMargin, ModalWindow } from "components/ModalWindow";
import { selectMainPlant } from "features/plant/plantSlice";
import Table, { ItemsClassNames } from "components/table/Table";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import usePlant, { PlantDetailedProps } from "queries/usePlant";
import useSheet from "queries/useSheet";
import { useMemoOne as useMemo } from "use-memo-one";
import { Field } from "../../../../components/form/Field";
import { maxPCSNameLength } from "../../config/sheetConfig";

type HistoricalPCS = {
  HistoricalPCS: string;
  Description: string;
};

interface PCSPropertiesProps {
  PCS: string;
  Revision: string;
  RatingClass: string;
  MaterialGroupID: number;
  SpecialReqID: number;
  NewVDSSection: "Y" | "N";
  TubePCS: string;
  EDSMJMatrix: "Y" | "N";
  MJReductionFactor: number;
  Notepad: null | string;
  getPCSMapping: HistoricalPCS[];
  getPCSManufacturers: {
    ManufacturerID: number;
    ManufacturerName: string;
    Selected: number;
  }[];
}

interface PCSPropertiesSubmitProps {
  RatingClass: string;
  MaterialGroupID: number;
  SpecialReqID: number;
  NewVDSSection: "Y" | "N";
  TubePCS: string;
  EDSMJMatrix: "Y" | "N";
  MJReductionFactor: number;
  Notepad: null | string;
  pcsmappings: HistoricalPCS[];
  pcsmanufacturers: {
    ManufacturerID: number;
    Selected: number;
  }[];
}

const transformFormData = (data: PCSPropertiesProps | undefined): any => {
  return (
    data && {
      RatingClass: data.RatingClass,
      MaterialGroupID: data.MaterialGroupID,
      SpecialReqID: data.SpecialReqID,
      NewVDSSection: data.NewVDSSection,
      TubePCS: data.TubePCS,
      EDSMJMatrix: data.EDSMJMatrix,
      MJReductionFactor: data.MJReductionFactor,
      Notepad: data.Notepad,
      pcsmappings: JSON.stringify(data.getPCSMapping),
      pcsmanufacturers: data.getPCSManufacturers.map((manufacturer) => ({
        ManufacturerID: manufacturer.ManufacturerID,
        Selected: manufacturer.Selected,
      })),
    }
  );
};

export const preparePropertiesData = (data: any): PCSPropertiesSubmitProps => {
  return {
    ...data,
    pcsmappings: JSON.parse(data.pcsmappings),
  };
};

export function PCSProperties({
  name,
  revision,
  setIsLoaded,
  saveSheetStatus,
}: {
  name: string;
  revision: string;
  setIsLoaded: Function;
  saveSheetStatus: QueryStatus;
}) {
  const plant = useAppSelector(selectMainPlant);

  const { data: plantData, status: plantStatus } = usePlant({ plant });

  const {
    data,
    status,
    isRefetching,
  }: {
    data: undefined | PCSPropertiesProps;
    status: QueryStatus;
    isRefetching: boolean;
  } = useSheet({
    plant,
    sheetType: "pcs",
    name,
    revision,
    postfix: "properties",
    cacheTime: 0,
  });

  const { data: materialGroupsData, status: materialGroupsStatus } =
    useCodelist({
      codelist: "material-groups",
    });

  const { data: specialRequirementsData, status: specialRequirementsStatus } =
    useCodelist({
      codelist: "special-requirements",
    });

  const { reset, setValue } = useFormContext();

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        status,
        plantStatus,
        materialGroupsStatus,
        specialRequirementsStatus
      ),
    [status, plantStatus, materialGroupsStatus, specialRequirementsStatus]
  );

  useEffect(() => {
    status === "success" && reset(transformFormData(data));
    // Sets defaultValues of the form, needed because the data
    // is loaded later than useForm is called for the first time.
    // eslint-disable-next-line
  }, [status]);

  useEffect(() => {
    if (saveSheetStatus === "success" && !isRefetching) {
      const newData = transformFormData(data);
      if (typeof newData === "object") {
        // pcsmappings are handeled separately.
        delete newData.pcsmappings;
        reset(newData, { keepDirty: false });
        setTimeout(() => {
          // The following is needed becasue react-hook-form was unable to set Radio button state
          // with reset(), so setValue with timeout was necessary to show the updated state
          // of the form in case of a different reply from the server to always show
          // the actual server state. Note: setValue does not work without timeout after a reset().
          Object.keys(newData).forEach((dataProp: string) => {
            setValue(
              dataProp,
              newData[dataProp as keyof PCSPropertiesSubmitProps]
            );
          });
        });
      }
    }
    // Sets the form data after successful saving.
    // isRefetching should be taken into account,
    // because resetting the form before the refetch finishes
    // would reset the form to its previous state.
  }, [reset, saveSheetStatus, isRefetching, data, setValue]);

  useLayoutEffect(() => {
    setIsLoaded(combinedStatuses === "success" && !isRefetching);
  }, [combinedStatuses, setIsLoaded, isRefetching]);

  return status === "loading" ? (
    <Loader label="Loading Properties..." />
  ) : typeof data === "undefined" || typeof plantData === "undefined" ? (
    <>Missing data.</>
  ) : (
    <PCSPropertiesForm
      data={data}
      isRefetching={isRefetching}
      plantData={plantData}
      materialGroupsData={materialGroupsData}
      specialRequirementsData={specialRequirementsData}
      specialRequirementsStatus={specialRequirementsStatus}
      materialGroupsStatus={materialGroupsStatus}
    />
  );
}

function PCSPropertiesForm({
  data,
  isRefetching,
  plantData,
  materialGroupsData,
  specialRequirementsData,
  specialRequirementsStatus,
  materialGroupsStatus,
}: {
  data: PCSPropertiesProps;
  isRefetching: boolean;
  plantData: PlantDetailedProps;
  materialGroupsData: any;
  specialRequirementsData: any;
  specialRequirementsStatus: QueryStatus;
  materialGroupsStatus: QueryStatus;
}) {
  const { watch, register } = useFormContext();
  const EDSMJMatrix = watch("EDSMJMatrix");

  const specialRequirementsOptions = useMemo(
    () =>
      specialRequirementsData
        ? [
            { id: 0, option: "- None -" },
            ...specialRequirementsData.map((e: any) => ({
              id: e.SpecialRequirementID,
              option: e.Description,
            })),
          ]
        : [],
    [specialRequirementsData]
  );

  const materialGroupsOptions = useMemo(
    () =>
      materialGroupsData
        ? [
            { id: 0, option: "- None -" },
            ...materialGroupsData.map((e: any) => ({
              id: e.MaterialGroupID,
              option: e.MaterialGroup,
            })),
          ]
        : [],
    [materialGroupsData]
  );

  const tubePCSOptions = useMemo(
    () => [
      {
        id: "I",
        option: "Imperial",
      },
      {
        id: "M",
        option: "Metric",
      },
      {
        id: "",
        option: "Disabled",
      },
    ],
    []
  );

  const edsMJMatrixOptions = useMemo(
    () => [
      {
        id: "Y",
        option: "Enabled",
      },
      {
        id: "N",
        option: "Disabled",
      },
    ],
    []
  );

  const newVDSSectionOptions = useMemo(
    () => [
      {
        id: "Y",
        option: "New VDS Section",
      },
      {
        id: "N",
        option: "Old VDS Section",
      },
    ],
    []
  );

  return (
    <AbsoluteFlexContainer>
      <FlexContentElement>
        <FieldsetContainer>
          <Fieldset>
            <Field
              prop="Notepad"
              value={data.Notepad ? data.Notepad : ""}
              title="Note Information"
              type="text"
              rows={3}
              rowsMax={6}
              maxLength={4000}
            />
            <Field
              prop="NewVDSSection"
              value={data.NewVDSSection}
              title="VDS Layout"
              type="radio"
              optionsWithIds={newVDSSectionOptions}
            />
            <Field
              prop="MaterialGroupID"
              value={data.MaterialGroupID}
              title="Material Group"
              type="option"
              status={materialGroupsStatus}
              optionsWithIds={materialGroupsOptions}
            />
            <Field
              prop="RatingClass"
              value={data.RatingClass}
              title="PCS Rating Class"
              type="text"
              maxWidth={180}
              maxLength={10}
            />
            <Field
              prop="SpecialReqID"
              value={data.SpecialReqID}
              title="Special Requirement"
              type="option"
              status={specialRequirementsStatus}
              optionsWithIds={specialRequirementsOptions}
            />
            <Field
              prop="TubePCS"
              title="Tube PCS"
              type="radio"
              value={data.TubePCS}
              optionsWithIds={tubePCSOptions}
            />
          </Fieldset>
          <Fieldset>
            <FieldsetTitle>PCS-specific EDS for Mech. Joint</FieldsetTitle>
            <div
              style={{ display: plantData.EDSMJ === "Y" ? "block" : "none" }}
            >
              <Field
                type="radio"
                prop="EDSMJMatrix"
                value={data.EDSMJMatrix}
                title=""
                style={{ margin: 0 }}
                optionsWithIds={edsMJMatrixOptions}
              />
              <div
                style={{
                  display:
                    EDSMJMatrix === "Y" && plantData.EDSMJ === "Y"
                      ? "block"
                      : "none",
                }}
              >
                <FormRow style={{ display: "block" }}>
                  <Label label="Manufacturers" />
                  <FormOptionsContainer alignLeft>
                    {data.getPCSManufacturers.map((pcsManufacturer, index) => {
                      return (
                        <div key={pcsManufacturer.ManufacturerID}>
                          <input
                            type="hidden"
                            value={pcsManufacturer.ManufacturerID}
                            {...register(
                              `pcsmanufacturers.${index}.ManufacturerID`
                            )}
                          />
                          <Checkbox
                            id={`pcsmanufacturer${pcsManufacturer.ManufacturerID}`}
                            label={pcsManufacturer.ManufacturerName}
                            value="1"
                            defaultChecked={pcsManufacturer.Selected === 1}
                            {...register(`pcsmanufacturers.${index}.Selected`)}
                          />
                        </div>
                      );
                    })}
                  </FormOptionsContainer>
                  <Typography
                    variant="caption"
                    style={{ marginTop: 8, marginBottom: 24 }}
                  >
                    Note: manufacturers are revision-independent.
                  </Typography>
                </FormRow>
                <Field
                  prop="MJReductionFactor"
                  value={data.MJReductionFactor}
                  title="Reduction Factor"
                  type="text"
                  maxWidth={120}
                  maxLength={10}
                />
              </div>
            </div>
            {plantData.EDSMJ === "N" && <p>Disabled in Plant.</p>}
          </Fieldset>
          <Fieldset>
            <FieldsetTitle>PCS Mappings</FieldsetTitle>
            <HistoricalPCSTable
              pcsMappingData={data.getPCSMapping}
              isRefetching={isRefetching}
            />
            <Typography variant="caption" style={{ marginTop: 18 }}>
              Note: mappings are revision-independent.
            </Typography>
          </Fieldset>
        </FieldsetContainer>
      </FlexContentElement>
    </AbsoluteFlexContainer>
  );
}

function HistoricalPCSMenu({
  item,
  historicalPCS,
  setHistoricalPCS,
  setEditModal,
}: {
  item: HistoricalPCS;
  historicalPCS: HistoricalPCS[];
  setHistoricalPCS: React.Dispatch<HistoricalPCS[]>;
  setEditModal: React.Dispatch<string>;
}) {
  return (
    <>
      <Button
        variant="ghost_icon"
        title={`Edit`}
        onClick={() => {
          setEditModal(item.HistoricalPCS);
        }}
      >
        <Icon data={edit} />
      </Button>
      <Button
        variant="ghost_icon"
        title={`Remove`}
        onClick={() => {
          setHistoricalPCS(
            historicalPCS.filter((e) => e.HistoricalPCS !== item.HistoricalPCS)
          );
        }}
      >
        <Icon data={delete_forever} />
      </Button>
    </>
  );
}

function HistoricalPCSEditModal({
  editModal,
  setEditModal,
  historicalPCS,
  setHistoricalPCS,
  setItemsClassNames,
}: {
  editModal: string;
  setEditModal: React.Dispatch<string>;
  historicalPCS: HistoricalPCS[];
  setHistoricalPCS: React.Dispatch<HistoricalPCS[]>;
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
}) {
  const item = historicalPCS.find((e) => e.HistoricalPCS === editModal);
  const newMode = !!(editModal && editModal === "_new");
  const [pcsName, setPCSName] = useState(
    !newMode && item ? item.HistoricalPCS : ""
  );
  const [pcsComment, setPCSComment] = useState(
    !newMode && item ? item!.Description : ""
  );
  const [validName, setValidName] = useState(false);

  return (
    <ModalWindow
      closeModal={() => setEditModal("")}
      isOpen={!!editModal}
      title={
        newMode
          ? "Add new Historical PCS"
          : item
          ? `Edit ${item.HistoricalPCS} Historical PCS`
          : "Error"
      }
    >
      <ModalSideMargin>
        {newMode && (
          <FormRowBlock>
            <Tooltip
              title={`${
                !pcsName || validName
                  ? ""
                  : "The mapping list contains this PCS already."
              }${
                pcsName && pcsName.length === maxPCSNameLength
                  ? ` The length of the PCS name is max. ${maxPCSNameLength} characters.`
                  : ""
              }`}
            >
              <TextField
                id="pcsName"
                label="Historical PCS"
                value={pcsName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.target.value.length <= maxPCSNameLength &&
                    setPCSName(e.target.value.toUpperCase());
                  setValidName(
                    !historicalPCS
                      .map((e) => e.HistoricalPCS)
                      .includes(e.target.value)
                  );
                }}
                variant={pcsName && !validName ? "error" : undefined}
              />
            </Tooltip>
          </FormRowBlock>
        )}
        <FormRowBlock>
          <Tooltip
            title={`${
              pcsComment && pcsComment.length === 1000
                ? `The length of the Description is max. 1000 characters.`
                : ""
            }`}
          >
            <TextField
              cols={80}
              id="pcsComment"
              label="Description"
              multiline
              rows={2}
              rowsMax={8}
              value={pcsComment}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                e.target.value.length <= 1000 && setPCSComment(e.target.value)
              }
            />
          </Tooltip>
        </FormRowBlock>
        <ModalButtonContainer>
          <Button
            onClick={() => {
              setHistoricalPCS(
                [
                  ...(newMode
                    ? historicalPCS
                    : historicalPCS.filter(
                        (e) => e.HistoricalPCS !== editModal
                      )),
                  { HistoricalPCS: pcsName!, Description: pcsComment },
                ].sort((a, b) =>
                  a.HistoricalPCS > b.HistoricalPCS
                    ? 1
                    : a.HistoricalPCS < b.HistoricalPCS
                    ? -1
                    : 0
                )
              );
              setItemsClassNames([
                {
                  className: newMode ? "_newData" : "_modifiedData",
                  element: "_line",
                  itemID: pcsName,
                },
              ]);
              setTimeout(() => {
                setItemsClassNames([]);
              }, 2000);
              setEditModal("");
            }}
            disabled={pcsName === "" || (newMode && !validName)}
          >
            {newMode ? "Add new" : "Save"}
          </Button>
          <Button variant="outlined" onClick={() => setEditModal("")}>
            Close
          </Button>
        </ModalButtonContainer>
      </ModalSideMargin>
    </ModalWindow>
  );
}

function HistoricalPCSControlHeader({
  setEditModal,
}: {
  setEditModal: React.Dispatch<string>;
}) {
  return (
    <div style={{ margin: "6px 0 12px 0" }}>
      <EdsProvider density="comfortable">
        <Button variant="outlined" onClick={() => setEditModal("_new")}>
          Add new Historical PCS
        </Button>
      </EdsProvider>
    </div>
  );
}

function HistoricalPCSTable({
  pcsMappingData,
  isRefetching,
}: {
  pcsMappingData: HistoricalPCS[];
  isRefetching: boolean;
}) {
  const { register, setValue } = useFormContext();

  const [historicalPCS, setHistoricalPCS] = useState(pcsMappingData);
  const [inited, setInited] = useState(false);
  const [editModal, setEditModal] = useState("");
  const [itemsClassNames, setItemsClassNames] = useState(
    [] as ItemsClassNames[]
  );

  useEffect(() => {
    isRefetching && setInited(false);
  }, [isRefetching]);

  useEffect(() => {
    !isRefetching && setHistoricalPCS(pcsMappingData);
  }, [isRefetching, pcsMappingData]);

  useLayoutEffect(() => {
    setValue("pcsmappings", JSON.stringify(historicalPCS), {
      shouldDirty: inited,
    });
    setInited(true);
    // inited is omitted on purpose.
    // eslint-disable-next-line
  }, [historicalPCS, setValue]);

  return (
    <>
      <Table
        relativePositioning={true}
        density="compact"
        nonVirtual={true}
        items={historicalPCS}
        itemIdProp="HistoricalPCS"
        columns={[
          {
            key: "HistoricalPCS",
            title: "Historical PCS",
            width: 120,
          },
          {
            key: "Description",
            title: "Description",
          },
        ]}
        RowMenu={HistoricalPCSMenu}
        contextData={{
          historicalPCS,
          setHistoricalPCS,
          setEditModal,
        }}
        totalCount={historicalPCS.length}
        controlHeader={
          <HistoricalPCSControlHeader setEditModal={setEditModal} />
        }
        itemsClassNames={itemsClassNames}
      />
      {editModal && (
        <HistoricalPCSEditModal
          editModal={editModal}
          setEditModal={setEditModal}
          historicalPCS={historicalPCS}
          setHistoricalPCS={setHistoricalPCS}
          setItemsClassNames={setItemsClassNames}
        />
      )}
      <Input type="hidden" {...register("pcsmappings")} />
    </>
  );
}
