import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "../queryUtil";

export default function useSaveSetting() {
  const queryClient = useQueryClient();

  async function saveSetting({
    key,
    content,
  }: {
    key: string;
    content: string;
  }) {
    return await axios(getApiUrl(`users/settings/key/${key}`), {
      method: "put",
      data: content,
    });
  }

  // Settings are fetched once on application load and then optimistically updated
  // and never refetched, so no query is getting invalidated.
  //
  // We do not handle the case when saving a setting was not succesful.

  return useMutation(saveSetting, {
    onMutate: (data) => {
      queryClient.setQueryData(["settings", data.key], data.content);
    },
    onSuccess: (data, variables, context) => {},
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
