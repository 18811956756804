import { useLayoutEffect, useState } from "react";
import Table, { SmallTableContainer } from "../../../components/table/Table";
import {
  FlexContainer,
  FlexElement,
  InlineBlock,
  StatusChip,
} from "../../../components/Components";
import {
  CircularProgress,
  Search,
  Typography,
  Label,
} from "@equinor/eds-core-react";
import useTable from "../../../components/table/useTable";
import { filterItemsByName } from "../../../utils/filterItemsByName";
import useSheets from "../../../queries/useSheets";
import { PlantProps } from "../../plant/types";
import { PreviewButtons } from "../SheetPane";
import { PaneLocation } from "uiSlice";

const VDSColumns = [
  { key: "VDS", title: "VDS", width: 86 },
  { key: "Description", title: "Description", width: "100%" },
];

const extendedVDSColumns = [
  { key: "VDS", title: "VDS", width: 86 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "Status", title: "Status", Component: StatusChip },
  { key: "SizeRange", title: "Size Range" },
  { key: "Description", title: "Description", width: "100%" },
];

export default function useVDSSelector({
  enabled,
  plant,
  defaultSelection,
  valveGroup,
  extendedDisplay,
  previewLocation,
}: {
  enabled?: boolean;
  plant?: PlantProps;
  defaultSelection?: string[];
  valveGroup?: number;
  extendedDisplay?: boolean;
  previewLocation?: PaneLocation;
}): {
  selection: string[];
  selectionDispatch: Function;
  vdsSelectorContent: React.ReactFragment;
  selectedLines: any[];
  length: number;
  isLoading: boolean;
} {
  const isEnabled = enabled || typeof enabled === "undefined";
  const { data, status, isLoading, isRefetching, error, refetch } = useSheets({
    sheetType: "vds",
    extraParams: {
      statuses: ["O"],
      ...(plant && { plantFilter: plant.PlantID }),
      ...(valveGroup && { valveGroup }),
    },
    disabled: !isEnabled,
  });
  const [nameFilter, setNameFilter] = useState("");
  const [items, setItems] = useState([] as any[]);

  const { selectionMode, selection, selectionDispatch } = useTable({
    selectionMode: "single",
    selection: defaultSelection,
  });

  useLayoutEffect(() => {
    if (isEnabled && status === "success") {
      setItems(
        filterItemsByName({
          nameFilter,
          nameProperty: "VDS",
          items: data.sheets,
        })
      );
    }
  }, [nameFilter, data, status, isEnabled]);

  const vdsSelectorContent = isEnabled ? (
    <SmallTableContainer>
      <Table
        density="compact"
        items={items}
        itemIdProp="VDS"
        controlHeader={
          <ControlHeader
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            count={items.length}
            isLoading={status === "loading"}
            isRefetching={isRefetching}
          />
        }
        columns={extendedDisplay ? extendedVDSColumns : VDSColumns}
        contextData={{ previewLocation, sheetType: "vds" }}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={true}
        error={error}
        refetch={refetch}
        loadingString="Loading Data Sheets..."
        selectedFirst={!!defaultSelection}
        RowMenu={PreviewButtons}
      />
    </SmallTableContainer>
  ) : (
    <></>
  );

  return {
    vdsSelectorContent,
    selection,
    selectionDispatch,
    selectedLines: selection.map((s) => items.find((i) => i.VDS === s)),
    length: items.length,
    isLoading,
  };
}

function ControlHeader({
  nameFilter,
  setNameFilter,
  count,
  isLoading,
  isRefetching,
}: {
  nameFilter: string;
  setNameFilter: Function;
  count: number;
  isLoading: boolean;
  isRefetching: boolean;
}) {
  return (
    <div style={{ paddingBottom: "1em" }}>
      <FlexContainer style={{ justifyContent: "flex-start" }}>
        <FlexElement style={{ paddingRight: "1.5em" }}>
          <Label htmlFor="NameFilter" label="VDS filter" />
          <Search
            id="NameFilter"
            value={nameFilter}
            onChange={(e) => setNameFilter(e.target.value)}
          />
        </FlexElement>
        {!isLoading && (
          <FlexElement style={{ marginLeft: "auto", alignSelf: "flex-end" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                marginRight: "12px",
                verticalAlign: "middle",
              }}
            />
            <InlineBlock>
              <Typography variant="overline">
                {count} {count === 1 ? "item" : "items"}
              </Typography>
            </InlineBlock>
          </FlexElement>
        )}
      </FlexContainer>
    </div>
  );
}
