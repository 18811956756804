import React, { useEffect, useLayoutEffect, useState } from "react";
import ContentModal from "../../../components/ContentModal";
import { useAppSelector } from "../../../app/hooks";
import { selectMainPlant } from "../../plant/plantSlice";
import {
  plantDependentSheets,
  sheetTypeNames,
  SheetTypes,
} from "../config/sheetConfig";
import useSheet from "../../../queries/useSheet";
import {
  Fade,
  FlexContainer,
  FlexElement,
} from "../../../components/Components";
import HTMLEditor from "./HTMLEditor";
import {
  AbsoluteFlexContainer,
  FlexContentElement,
  FlexHeaderElement,
} from "../../../components/Components";
import { ModalButtonContainer } from "../../../components/Modal";
import { Button, Tabs, Typography } from "@equinor/eds-core-react";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import useSaveSheet from "../../../queries/useSaveSheet";
import StatusModal from "../../../components/StatusModal";
import { useHistory } from "react-router-dom";
import { closeEditSheet } from "../commands";
import { EditModalStatusDisplay, Infoline } from "./EditModalComponents";
import { useNamedTabs } from "components/tabs/useNamedTabs";
import useConfirm from "components/confirm/useConfirm";
import { Editor as TinyMCEEditor } from "tinymce";
import { prepareData, Properties } from "./Properties";
import { successMessage } from "../../../utils/successMessage";
import { PreviewButtons, reloadPreviewPane } from "../SheetPane";

export default function EditHTMLModall({
  name,
  revision,
  sheetType,
}: {
  name: string;
  revision: string;
  sheetType: SheetTypes;
}) {
  const mainPlant = useAppSelector(selectMainPlant);
  const editorRef = React.useRef<null | TinyMCEEditor>(null);
  const { status, data, isRefetching } = useSheet({
    plant: mainPlant,
    sheetType: sheetType,
    name: name,
    revision: sheetType === "general" ? name : revision,
  });
  const methods = useForm({ shouldUnregister: true, mode: "all" });
  const { formState, reset } = methods;
  const { dirtyFields } = formState;
  const {
    mutate: saveSheet,
    status: saveSheetStatus,
    reset: saveSheetReset,
    error: saveSheetError,
    data: saveSheetData,
  } = useSaveSheet();
  const [initialContent, setInitialContent] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);

  const item: any = data && status === "success" ? data : undefined;

  const history = useHistory();

  const { isConfirmed } = useConfirm();

  const confirmNavigation = async (): Promise<boolean> => {
    return !isLoaded ||
      (activeTabName === "content" &&
        editorRef.current &&
        initialContent === editorRef.current.getContent()) ||
      (activeTabName === "properties" && Object.keys(dirtyFields).length === 0)
      ? true
      : await isConfirmed(
          <>
            Unsaved changes will be lost when navigating away. Are you sure you
            want to leave?
          </>
        );
  };

  const tabs = [
    ...(item &&
    (item.Status === "W" || (item.Status === "I" && sheetType === "general"))
      ? ["content"]
      : []),
    "properties",
  ];

  const { activeTab, activeTabName, handleChange } = useNamedTabs({
    name: "Edit" + sheetType,
    changeConfirm: confirmNavigation,
    tabs,
  });

  const onSubmit: SubmitHandler<any> = (formData) => {
    if (activeTabName === "content" && editorRef.current) {
      saveSheet({
        plant: mainPlant,
        sheetType: sheetType,
        name: name,
        revision: sheetType === "general" ? name : revision,
        content: { HTMLContent: editorRef.current.getContent() },
      });
    }
    if (activeTabName === "properties") {
      saveSheet({
        plant: mainPlant,
        sheetType: sheetType,
        name: name,
        revision: sheetType === "general" ? name : revision,
        content: prepareData(formData),
        postfix: "properties",
      });
    }
  };

  useEffect(() => {
    if (saveSheetStatus === "success") {
      if (!isRefetching) {
        reset();
      }
      if (editorRef.current) {
        setInitialContent(editorRef.current.getContent());
      }
    }
  }, [isRefetching, reset, saveSheetStatus]);

  // This is useLayoutEffect, because with useEffect the setIsLoaded below run _after_
  // the setIsLoaded passed to Properties, so isLoaded was not set to true, and it is
  // best to issue setIsLoaded inside the Component itself.
  useLayoutEffect(() => {
    setInitialContent("");
    setIsLoaded(false);
  }, [activeTabName]);

  useLayoutEffect(() => {
    activeTabName === "content" && initialContent !== "" && setIsLoaded(true);
    activeTabName === "properties" && setIsLoaded(true);
  }, [initialContent, activeTabName]);

  const closeModal = async () => {
    (await confirmNavigation()) && closeEditSheet({ history });
  };

  return (
    <>
      <ContentModal
        isOpen={!!(name && sheetType)}
        closeModal={closeModal}
        header={
          <FlexContainer style={{ justifyContent: "flex-start" }}>
            <FlexElement style={{ marginRight: "1em" }}>
              <Typography variant="h3">
                Edit {sheetTypeNames[sheetType]} {name}{" "}
                {sheetType === "general" ? "" : "rev. " + revision}
                {plantDependentSheets.includes(sheetType) ? (
                  <>
                    <Fade> in </Fade>
                    {mainPlant.LongDescription}
                  </>
                ) : (
                  <></>
                )}
              </Typography>
            </FlexElement>
            {item && (
              <FlexElement style={{ marginTop: -11, marginBottom: -7 }}>
                <PreviewButtons
                  sheetType={sheetType}
                  item={item}
                  plant={mainPlant}
                  size={24}
                />
              </FlexElement>
            )}
          </FlexContainer>
        }
      >
        {status === "success" ? (
          <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <AbsoluteFlexContainer>
                  <FlexHeaderElement>
                    <Tabs.List>
                      {tabs.map((tab) => (
                        <Tabs.Tab key={tab}>
                          {tab === "content"
                            ? "Content"
                            : tab === "properties"
                            ? "Properties"
                            : ""}
                        </Tabs.Tab>
                      ))}
                    </Tabs.List>
                  </FlexHeaderElement>
                  <FlexContentElement>
                    <Tabs.Panels>
                      {tabs.map((tab) => (
                        <Tabs.Panel key={tab}>
                          {tab === "content" && activeTabName === "content" && (
                            <AbsoluteFlexContainer>
                              <FlexContentElement>
                                <HTMLEditor
                                  content={item.HTMLContent}
                                  editorRef={editorRef}
                                  setInitialContent={setInitialContent}
                                />
                              </FlexContentElement>
                            </AbsoluteFlexContainer>
                          )}
                          {tab === "properties" &&
                            activeTabName === "properties" && (
                              <Properties
                                setIsLoaded={setIsLoaded}
                                item={item}
                                sheetType={sheetType}
                              />
                            )}
                        </Tabs.Panel>
                      ))}
                    </Tabs.Panels>
                  </FlexContentElement>
                  <FlexHeaderElement>
                    <ModalButtonContainer style={{ margin: "15px 0" }}>
                      <Button
                        type="submit"
                        disabled={
                          !isLoaded ||
                          saveSheetStatus === "loading" ||
                          Object.keys(formState.errors).length > 0
                        }
                      >
                        Save
                      </Button>
                      <Button onClick={closeModal} variant="outlined">
                        Close
                      </Button>
                    </ModalButtonContainer>
                  </FlexHeaderElement>
                  <FlexHeaderElement>
                    <Infoline item={item} />
                  </FlexHeaderElement>
                </AbsoluteFlexContainer>
              </form>
            </FormProvider>
          </Tabs>
        ) : (
          <EditModalStatusDisplay status={status} />
        )}
      </ContentModal>
      <StatusModal
        status={saveSheetStatus}
        successMessage={successMessage(saveSheetData)}
        onSettledClose={saveSheetReset}
        error={saveSheetError}
        onSuccess={() => {
          reloadPreviewPane("main");
        }}
      />
    </>
  );
}
