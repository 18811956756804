import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";
import { userRoles, adminRoles, UserRoles, AdminRoles } from "app/userConfig";

type UserPlant = {
  PlantID: number;
  ShortDescription: string;
};

export type AllUsers = {
  UserNo: number;
  FullName: string;
  Role: UserRoles;
  AdminRole: AdminRoles;
  DocSpaceAdmin: "Y" | "N";
  PCSAdmin: "Y" | "N";
  UserID: string;
  RoleEndDate: null | string;
  OperatorID: number;
  Email: string;
  AID: string;
  Plants: UserPlant[];
};

export type UsersCreate = {
  FullName: string;
  Role: UserRoles;
  RoleEndDate: string;
  AdminRole: AdminRoles;
  DocumentSpaceAdmin: "Y" | "N";
  PCSAdmin: "Y" | "N";
  OperatorID: number;
  UserID: string;
  Email: string;
  plants: UserPlant[];
};

export type UsersUpdate = UsersCreate & CommonUpdateProps;

export const allUsersConfig: ItemAdminConfig<"users"> = {
  title: "Users",
  singularTitle: "User",
  url: "all-users",
  editUrl: "users",
  itemIdProp: "UserNo",
  apiListProp: "getUserList",
  nameProperty: ["UserID", "FullName", "Email"],
  codelists: {
    roles: userRoles,
    "admin-roles": adminRoles,
  },
  columns: [
    { key: "UserNo", title: "User ID" },
    { key: "UserID", title: "Username", width: 130 },
    { key: "FullName", title: "Full Name", width: 130 },
    { key: "Email", title: "E-mail", width: 200 },
    {
      key: "Role",
      title: "Role",
      codelist: "roles",
      width: 140,
    },
    { key: "RoleEndDate", title: "Role End Date" },
    { key: "AdminRole", title: "Admin Role", codelist: "admin-roles" },
  ],
  prepareData: (data) => {
    return {
      ...data,
      ...("plants" in data
        ? { plants: JSON.parse(data.plants) }
        : { plants: [] }),
    };
  },
};
