import { useQuery } from "react-query";
import axios, { AxiosError } from "axios";
import { getApiUrl } from "./queryUtil";
import {
  ReportURIs,
  ReportsData,
  reportsConfig,
} from "features/reports/Reports";

export default function useReport<T extends ReportURIs>({
  report,
  search,
  disabled,
}: {
  report: T;
  search?: string;
  disabled?: boolean;
}) {
  return useQuery<ReportsData[T], AxiosError<Error>>(
    ["reports", report, ...(search ? [search] : [])],
    async () => {
      const { listProperty } = reportsConfig[report];
      const { data } = await axios.get(
        getApiUrl(`reports/${report}${search ? search : ""}`)
      );
      return data[listProperty];
    },
    { enabled: !disabled }
  );
}
