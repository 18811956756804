import useTable from "../../components/table/useTable";
import Table from "../../components/table/Table";
import useSheets from "../../queries/useSheets";
import { unsetPlantState } from "../plant/plantSlice";

const cols = [
  { key: "MaterialGroup", title: "MaterialGroup", width: 80 },
  { key: "Revision", title: "Rev.", longTitle: "Revision" },
  { key: "RevDate", title: "Rev. Date", longTitle: "Revision Date" },
  { key: "Status", title: "Status" },
  { key: "LastUpdate", title: "Last Update" },
  { key: "LastUpdateBy", title: "Last Update By" },
  { key: "Notepad", title: "Note" },
];

function TestRowMenu({ testMenuData }: { testMenuData: string }) {
  return <>M: {testMenuData}</>;
}

export default function PMGList() {
  const { data, status } = useSheets({
    plant: unsetPlantState,
    sheetType: "pmg",
    extraParams: { materialGroupID: "*" },
  });

  const { selectionMode, selection, selectionDispatch } = useTable({
    selectionMode: "multi",
  });

  return (
    <>
      <Table
        items={data && data.sheets}
        itemIdProp="itemID"
        controlHeader={<>Pipe Meterial Group</>}
        columns={cols}
        contextData={{
          testInputData: "input",
          testMenuData: "menu",
        }}
        status={status}
        selectionMode={selectionMode}
        selection={selection}
        selectionDispatch={selectionDispatch}
        sortable={true}
        fullRowSelect={false}
        resizable={true}
        RowMenu={TestRowMenu}
      />
    </>
  );
}
