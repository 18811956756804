import { Button, CircularProgress, Typography } from "@equinor/eds-core-react";
import React from "react";
import useWindowDimensions from "utils/useWindowDimensions";
import styled from "styled-components";
import { breakpoints, breakpointsNumber, CloseButton } from "./Components";
import { Modal, ModalContentContainer } from "./Modal";
import { QueryStatus } from "react-query";

const CloseContainer = styled.div`
  position: absolute;
  top: 7px;
  right: 5px;
  z-index: 1001;
`;

const HeaderContainer = styled.div`
  padding: 20px 0 0;
  position: relative;
`;

export const ModalSideMargin = styled.div`
  padding: 0 15px;
  @media (min-width: ${breakpoints.lg}) {
    padding: 0 20px;
  }
`;

export const ModalWindowPrimaryContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
`;

export const ModalWindowDefaultContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ModalWindowContentFixed = styled.div`
  flex: 0 1;
`;

export const ModalWindowContentFill = styled.div`
  flex: 1 1;
  position: relative;
`;

export const ModalWindowContentFillScrollable = styled.div`
  flex: 1 1;
  overflow: auto;
  position: relative;
`;

export const ModalWindowContentDefault = styled.div`
  max-height: calc(100vh - 180px);
  max-width: 95vw;
  overflow-y: auto;
`;

export const ModalWindowButtonContainer = styled.div`
  margin: 20px 20px 0px;
  display: flex;
  gap: 20px;
  justify-content: space-evenly;
`;

export const ModalWindowProgressContainer = styled.div`
  margin: 10px 25px 15px;
`;

export type ModalLayers = "primary" | "secondary" | "default";

export function ModalWindowTitle({
  layer,
  children,
}: {
  layer?: ModalLayers;
  children: React.ReactNode;
}) {
  return (
    <Typography
      variant={layer === "primary" ? "h3" : "h5"}
      style={{
        marginTop: layer === "primary" ? -2 : -4,
        marginBottom: layer === "default" ? 16 : 22,
      }}
    >
      {children}
    </Typography>
  );
}

export function ModalWindow(
  props: {
    title: string | React.ReactNode;
    closeModal: () => void;
    hideCloseButton?: boolean;
    children?: React.ReactNode;
    zIndex?: number;
    layer?: ModalLayers;
    hide?: boolean;
    isLoading?: boolean;
    status?: QueryStatus;
    error?: Error | null;
    refetch?: Function;
  } & ReactModal.Props
) {
  const { width } = useWindowDimensions();
  const {
    title,
    closeModal,
    children,
    zIndex,
    layer,
    hide,
    isLoading,
    status,
    error,
    style,
    shouldCloseOnOverlayClick,
    hideCloseButton,
    refetch,
  } = props;
  let modalStyle = {
    content: {
      display: hide ? "none" : "block",
      position: "relatieve" as "relative",
      maxWidth: "98vw",
      maxHeight: "100%",
      background: "var(--bg)",
      color: "var(--text)",
      borderWidth: "0",
      borderRadius: "4px",
      boxShadow:
        "5px 5px 10px var(--overlayBg), 8px 8px 16px var(--overlayBgFaded)",
      margin: "0",
      padding: "0",
      paddingBottom: width >= breakpointsNumber.lg ? "20px" : "10px",
      inset: width >= breakpointsNumber.lg ? "40px" : "5px",
      ...(layer === "primary"
        ? {
            width: "95%",
            height: "90%",
          }
        : layer === "secondary"
        ? {
            width: "85%",
            height: "auto",
          }
        : layer === "default" && {
            width: "auto",
            height: "auto",
          }),
      ...style?.content,
    },
    overlay: {
      zIndex: zIndex ? zIndex : 310,
      background: "var(--overlayBg)",
      display: hide ? "none" : "flex",
      alignItems: "center",
      justifyContent: "center",
      ...style?.overlay,
    },
  };

  const ContainerElement =
    layer === "primary"
      ? ModalWindowPrimaryContainer
      : ModalWindowDefaultContainer;

  return (
    <Modal
      {...props}
      style={modalStyle}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {(isLoading || status === "loading") && (
        <ModalContentContainer style={{ margin: "40px 45px 15px" }}>
          <CircularProgress />
        </ModalContentContainer>
      )}
      <div
        style={{
          position: "relative",
          height: "100%",
          display: isLoading || status === "loading" ? "none" : "block",
        }}
      >
        <ContainerElement>
          <ModalWindowContentFixed>
            <div
              style={{
                position: "relative",
                paddingRight: hideCloseButton ? 0 : 44,
              }}
            >
              <ModalSideMargin>
                <HeaderContainer>
                  {typeof title === "string" ? (
                    <ModalWindowTitle layer={layer}>{title}</ModalWindowTitle>
                  ) : (
                    <>{title}</>
                  )}
                </HeaderContainer>
              </ModalSideMargin>
              {!hideCloseButton && (
                <CloseContainer>
                  <CloseButton
                    closeFn={closeModal}
                    size={layer === "primary" ? 24 : 16}
                  ></CloseButton>
                </CloseContainer>
              )}
            </div>
          </ModalWindowContentFixed>
          <ModalWindowContentFill
            style={{ display: status === "error" ? "none" : "initial" }}
          >
            {children}
          </ModalWindowContentFill>
          {status === "error" && (
            <ModalWindowContentFill>
              <>
                <ModalSideMargin>
                  <Typography color="danger">
                    {error ? error.message : "Error"}
                  </Typography>
                </ModalSideMargin>
                <ModalWindowButtonContainer>
                  {refetch ? (
                    <Button variant="outlined" onClick={() => refetch()}>
                      Retry
                    </Button>
                  ) : null}
                  <Button variant="outlined" onClick={closeModal}>
                    Close
                  </Button>
                </ModalWindowButtonContainer>
              </>
            </ModalWindowContentFill>
          )}
        </ContainerElement>
      </div>
    </Modal>
  );
}
