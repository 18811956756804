import {
  Button,
  Icon,
  Menu,
  Radio,
  NativeSelect,
  Typography,
  Switch,
} from "@equinor/eds-core-react";
import { QueryStatus } from "react-query";
import { ColumnsProps, ItemsClassNames } from "components/table/Table";
import { brush, external_link, visibility } from "@equinor/eds-icons";
import { useForm, useFormContext } from "react-hook-form";
import {
  ModalButtonContainer,
  ModalContainerAlways,
  ModalContainerScrollable,
} from "../../../../components/Modal";
import useElementTable, {
  getGroupName,
  DisplayAsFormElement,
  IconWithInput,
  GroupsData,
  useGroupSelector,
  usePositionSelector,
  ElementListGrouped,
  ElementItemData,
  IdProp,
} from "../../hooks/useElementTable";
import {
  revmarkColumn,
  pageBreakColumn,
  fromSizeColumn,
  toSizeColumn,
  noteColumnPCS,
  remarkColumn,
} from "../CommonColumns";
import {
  FlexElement,
  FlexFormContainer,
  LinearLoader,
} from "components/Components";
import {
  Fieldset,
  FieldsetContainer,
  FormRow,
} from "../../../../components/form/Form";
import { useMemoOne as useMemo } from "use-memo-one";
import { useState } from "react";
import usePipeElements, {
  PEProductFormLabel,
} from "../../hooks/usePipeElements";
import usePipeDimensions from "../../hooks/usePipeDimensions";
import {
  TextInputRow,
  Pagebreak,
  PagebreakFormElement,
} from "../../../../components/form/EditComponents";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectMainPlant } from "features/plant/plantSlice";
import useSheets from "queries/useSheets";
import { ModalSideMargin, ModalWindow } from "components/ModalWindow";
import { previewInNewWindow } from "../../commands";
import { setPaneMode, setPaneSheet } from "uiSlice";
import { MessageModal } from "components/MessageModal";
import { useUserConfig } from "UserConfigContext";

export interface PipeElementsAPIData {
  ElementGroupNo: number;
  LineNo: number;
  Element: string;
  DimStandard: string;
  FromSize: string;
  ToSize: string;
  ProductForm: string;
  Material: string;
  MDS: string;
  MDSVariant: string;
  MDSRevision: string;
  EDS: string;
  EDSRevision: string;
  ESK: string;
  Revmark: string;
  Remark: string;
  PageBreak: Pagebreak;
  ElementID: number;
  FreeText: "N" | "Y";
  NoteID: null | number;
  NewDeletedLine: "N" | "D";
  InitialInfo: string;
  InitialRevmark: string;
  Area: string;
}

interface PipeElementsFormData {
  ElementGroupID: number;
  ElementGroupText: string;
  LineNo: number;
  DimensionStandard: string;
  FromSize: string;
  ToSize: string;
  ProductForm: string;
  MaterialName: string;
  MDS: string;
  ESK: string;
  EDS: string;
  NoteID: number;
  Remark: string;
  ElementID: number;
  NewDeletedLine: "N" | "D";
  FreeText: "N" | "Y";
  Pagebreak: Pagebreak;
  InitialInfo: string;
  InitialRevmark: string;
}

interface PipeElementsPreparedData extends PipeElementsFormData {
  LineNo: number;
  ElementGroupText: string;
}

export interface PipeElementGroupsData {
  ElementGroupID: number;
  ElementGroup: string;
}

export function preparePipeElementsData(data: {
  pipeelements: { [index: IdProp]: PipeElementsFormData };
  listIds: string;
}): { pipeelements: PipeElementsPreparedData[] } | { BackEndAction: string } {
  let prevGroup = 0;
  let lineNo = 10;
  const preparedData = data.pipeelements
    ? data.listIds
        .split(",")
        .map((id) => data.pipeelements[id])
        .map((e) => {
          if (prevGroup !== e.ElementGroupID) {
            lineNo = 10;
          }
          const prepared = {
            ...e,
            LineNo: lineNo,
            ElementGroupText: lineNo === 10 ? e.ElementGroupText : "",
          };
          prevGroup = e.ElementGroupID;
          lineNo++;
          return prepared;
        })
    : [];
  return preparedData.length
    ? {
        pipeelements: preparedData,
      }
    : { BackEndAction: "DeleteWholeSet" };
}

function EditModal({
  setEditModal,
  editModal,
  groupsData,
  rows,
  rowsDispatch,
  group,
  position,
  defaultMaterialGroup,
  form,
  dataWithIds,
  setItemsClassNames,
  addedLines,
  setAddedLines,
  revision,
  name,
}: {
  setEditModal: Function;
  editModal: string;
  groupsData: GroupsData;
  rows: ElementListGrouped;
  rowsDispatch: Function;
  group?: number;
  position?: number;
  defaultMaterialGroup: string;
  form: { pipeelements: { [index: string]: PipeElementsFormData } };
  dataWithIds: ElementItemData[];
  setItemsClassNames: React.Dispatch<ItemsClassNames[]>;
  addedLines: ElementItemData[];
  setAddedLines: React.Dispatch<any[]>;
  revision: string;
  name: string;
}) {
  const editMode = editModal !== "_new";
  const item = useMemo(
    () =>
      editMode &&
      typeof form.pipeelements === "object" &&
      form.pipeelements.hasOwnProperty(editModal)
        ? form.pipeelements[editModal]
        : undefined,
    [form, editModal, editMode]
  );
  const title = editMode
    ? `Edit ${item!["ElementGroupText"]} element`
    : "Add new Pipe Element";
  const submitProp = "pipeelements";
  const { GroupSelector, selectedGroup } = useGroupSelector({
    groupsData,
    defaultGroup: group,
  });
  const { PositionSelector, selectedPosition } = usePositionSelector({
    rows,
    group: selectedGroup,
    defaultPosition: position,
  });
  const [isFreetext, setIsFreetext] = useState(
    editMode && item?.FreeText === "Y"
  );

  const defaultSelection = useMemo(
    () => (item?.ElementID ? [String(item!.ElementID)] : undefined),
    [item]
  );

  const [validityFilter, setValidityFilter] = useState(true);

  const { pipeElementsContent, selectedLines } = usePipeElements({
    existing: false,
    extendedDisplay: true,
    disableGroupSelector: true,
    defaultSelection,
    selectedGroupID: item ? item.ElementGroupID : selectedGroup,
    defaultMaterialGroup,
    pcs: name,
    revision,
    validityFilter,
  });

  const plant = useAppSelector(selectMainPlant);

  const { data: edsData, status: edsStatus } = useSheets({
    plant,
    sheetType: "eds",
    extraParams: {
      statuses: ["O"],
      pipeElementGroupFilter: item ? item.ElementGroupID : selectedGroup,
    },
  });

  const { setValue } = useFormContext();

  const setFormValue = (name: string, value: string) =>
    setValue(`${submitProp}.${editModal}.${name}`, value, {
      shouldDirty: true,
    });

  const { register, formState } = useForm({
    shouldUnregister: true,
    mode: "all",
  });

  const { fromSize, toSize, pipeDimensionsContent } = usePipeDimensions({
    register,
    formState,
    defaultFromSize: item ? item.FromSize : "",
    defaultToSize: item ? item.ToSize : "",
  });

  const [ftDimStandard, setFtDimStandard] = useState(
    editMode ? item!.DimensionStandard : ""
  );
  const [ftProductForm, setFtProductForm] = useState(
    editMode ? item!.ProductForm : ""
  );
  const [ftMaterialName, setFtMaterialName] = useState(
    editMode ? item!.MaterialName : ""
  );
  const [ftMDS, setFtMDS] = useState(editMode ? item!.MDS : "");
  const [ftESK, setFtESK] = useState(editMode ? item!.ESK : "");
  const [ftEDS, setFtEDS] = useState(editMode ? item!.EDS : "");
  const [ftRemark, setFtRemark] = useState(editMode ? item!.Remark : "");

  const [pagebreak, setPagebreak] = useState(
    editMode ? item!.Pagebreak : ("N" as Pagebreak)
  );
  const [eds, setEds] = useState(editMode ? item!.EDS : "");

  return (
    <ModalWindow
      isOpen={true}
      layer="secondary"
      closeModal={() => setEditModal("")}
      title={title}
    >
      <ModalSideMargin>
        <ModalContainerAlways>
          <FormRow>
            <FlexFormContainer
              style={{
                justifyContent: "space-between",
                alignItems: "flex-end",
                gap: 35,
              }}
            >
              {!editMode && (
                <FlexFormContainer>
                  <FlexElement>{GroupSelector}</FlexElement>
                  <FlexElement>{PositionSelector}</FlexElement>
                </FlexFormContainer>
              )}
              <FlexFormContainer style={{ marginBottom: -4 }}>
                <FlexElement>
                  <Radio
                    name="freetext"
                    label="Structured"
                    checked={!isFreetext}
                    onChange={() => setIsFreetext(false)}
                  />
                </FlexElement>
                <FlexElement>
                  <Radio
                    name="freetext"
                    label="Freetext"
                    checked={!!isFreetext}
                    onChange={() => setIsFreetext(true)}
                    disabled={
                      editMode &&
                      !addedLines.map((e) => e.id).includes(editModal) &&
                      dataWithIds.find((e) => e.id === editModal) &&
                      dataWithIds.find((e) => e.id === editModal)![
                        "FreeText"
                      ] === "N"
                    }
                  />
                </FlexElement>
              </FlexFormContainer>
              <FlexFormContainer>
                <FlexElement style={isFreetext ? { display: "none" } : {}}>
                  <Switch
                    checked={!!validityFilter}
                    onChange={() => {
                      setValidityFilter(!validityFilter);
                    }}
                    label="Validity Filter"
                  />
                </FlexElement>
              </FlexFormContainer>
            </FlexFormContainer>
          </FormRow>
        </ModalContainerAlways>
        <ModalContainerScrollable>
          <div style={{ minHeight: 586 }}>
            <FieldsetContainer slim>
              {isFreetext ? (
                <Fieldset>
                  <FlexFormContainer style={{ gap: "4em" }}>
                    <FlexElement>
                      <TextInputRow
                        label="Dimension Standard"
                        value={ftDimStandard}
                        setValue={setFtDimStandard}
                      />
                      <TextInputRow
                        label={PEProductFormLabel}
                        value={ftProductForm}
                        setValue={setFtProductForm}
                      />
                      <TextInputRow
                        label="Material Name"
                        value={ftMaterialName}
                        setValue={setFtMaterialName}
                      />
                    </FlexElement>
                    <FlexElement>
                      <TextInputRow
                        label="MDS"
                        value={ftMDS}
                        setValue={setFtMDS}
                        width={150}
                      />
                      <TextInputRow
                        label="ESK"
                        value={ftESK}
                        setValue={setFtESK}
                        width={150}
                      />
                      <TextInputRow
                        label="EDS"
                        value={ftEDS}
                        setValue={setFtEDS}
                        width={150}
                      />
                    </FlexElement>
                  </FlexFormContainer>
                  <TextInputRow
                    label="Remark"
                    value={ftRemark}
                    setValue={setFtRemark}
                    width="100%"
                  />
                  <FormRow>{pipeDimensionsContent}</FormRow>
                  <PagebreakFormElement
                    pagebreak={pagebreak}
                    setPagebreak={setPagebreak}
                  />
                </Fieldset>
              ) : (
                <Fieldset>
                  <FormRow>{pipeElementsContent}</FormRow>
                  <FormRow style={{ marginTop: "1.2em" }}>
                    {pipeDimensionsContent}
                  </FormRow>
                  <PagebreakFormElement
                    pagebreak={pagebreak}
                    setPagebreak={setPagebreak}
                  />
                  <FormRow>
                    {edsStatus === "error" ? (
                      <Typography color="error">
                        Error loading sheets.
                      </Typography>
                    ) : edsStatus === "loading" ? (
                      <div style={{ width: 300 }}>
                        <LinearLoader label="Loading EDSs..." />
                      </div>
                    ) : edsStatus === "success" &&
                      edsData &&
                      edsData.sheets.length === 0 ? (
                      <Typography>
                        No EDS for this Pipe Element Group
                      </Typography>
                    ) : (
                      <NativeSelect
                        id="EDSSelector"
                        label="EDS"
                        style={{ maxWidth: 300 }}
                        value={eds}
                        onChange={(e) => {
                          setEds(e.target.value);
                        }}
                      >
                        <option value="" key="_default">
                          Select an EDS...
                        </option>
                        {edsData &&
                          edsData.sheets.map((item: any) => {
                            return (
                              <option key={item.EDS} value={item.EDS}>
                                {item.EDS}
                              </option>
                            );
                          })}
                      </NativeSelect>
                    )}
                  </FormRow>
                </Fieldset>
              )}
            </FieldsetContainer>
          </div>
        </ModalContainerScrollable>
        <ModalContainerAlways>
          <ModalButtonContainer>
            <Button
              disabled={
                Object.keys(formState.errors).length > 0 ||
                (!isFreetext && selectedLines.length === 0)
              }
              onClick={() => {
                const elementID = isFreetext
                  ? 0
                  : selectedLines[0]["ElementID"];
                const dimStandard = isFreetext
                  ? ftDimStandard
                  : selectedLines[0]["DimensionStandard"];
                const productForm = isFreetext
                  ? ftProductForm
                  : selectedLines[0]["ProductForm"];
                const materialName = isFreetext
                  ? ftMaterialName
                  : selectedLines[0]["MaterialName"];
                const mds = isFreetext
                  ? ftMDS
                  : selectedLines[0]["MDS"].substring(0, 5);
                const esk = isFreetext ? ftESK : "";
                const mdsArea = isFreetext ? "" : selectedLines[0]["MDSArea"];
                const remark = isFreetext ? ftRemark : ""; // there's no remark for structured lines

                if (editMode) {
                  setItemsClassNames([
                    {
                      itemID: editModal,
                      element: "_line",
                      className: "_modifiedData",
                    },
                  ]);
                  setFormValue("ElementID", elementID);
                  setFormValue("DimensionStandard", dimStandard);
                  setFormValue("ProductForm", productForm);
                  setFormValue("MaterialName", materialName);
                  setFormValue("FromSize", fromSize);
                  setFormValue("ToSize", toSize);
                  setFormValue("MDS", mds);
                  setFormValue("ESK", esk);
                  setFormValue("EDS", isFreetext ? ftEDS : eds);
                  setFormValue("FreeText", isFreetext ? "Y" : "N");
                  setFormValue("MDSArea", mdsArea);
                  setFormValue("Remark", remark);
                  setFormValue("Pagebreak", pagebreak);
                  setTimeout(() => {
                    setItemsClassNames([]);
                  }, 3000);
                }

                if (!editMode) {
                  const lastId = [
                    ...dataWithIds.map((e) => e.id),
                    ...addedLines.map((e) => e.id),
                  ].at(-1);
                  const lastIdNum = lastId ? Number(lastId.substring(1)) : 0;
                  const newId = "e" + (lastIdNum + 1);
                  const groupTitle = groupsData.find(
                    (e) => e.id === selectedGroup
                  )!["name"];
                  setAddedLines([
                    ...addedLines,
                    {
                      Area: mdsArea,
                      DimStandard: dimStandard,
                      ESK: esk,
                      Element: groupTitle,
                      ElementGroupNo: selectedGroup,
                      ElementGroupTitle: groupTitle,
                      ElementID: elementID,
                      FreeText: isFreetext ? "Y" : "N",
                      InitialInfo: "",
                      InitialRevmark: "",
                      LineNo: 0,
                      MDS: mds,
                      MDSVariant: "",
                      Material: materialName,
                      NewDeletedLine: "N",
                      NoteID: null,
                      PageBreak: pagebreak, // This has uppercase B to replicate the GET request format so pageBreakColumn will pick up
                      ProductForm: productForm,
                      Remark: remark,
                      Revmark: "",
                      EDS: eds,
                      FromSize: fromSize,
                      ToSize: toSize,
                      id: newId,
                    },
                  ]);
                  rowsDispatch({
                    type: "add",
                    payload: {
                      toAdd: {
                        group: selectedGroup,
                        position: selectedPosition,
                        id: newId,
                      },
                    },
                  });
                  setItemsClassNames([
                    {
                      itemID: newId,
                      element: "_line",
                      className: "_newData",
                    },
                  ]);
                  setTimeout(() => {
                    setItemsClassNames([]);
                  }, 3000);
                }
                setEditModal("");
              }}
            >
              {editMode ? "Set" : "Add"}
            </Button>
            <Button variant="outlined" onClick={() => setEditModal("")}>
              Cancel
            </Button>
          </ModalButtonContainer>
        </ModalContainerAlways>
      </ModalSideMargin>
    </ModalWindow>
  );
}

function PipeElementRowMenu({ item }: { item: ElementItemData }) {
  const plant = useAppSelector(selectMainPlant);
  const dispatch = useAppDispatch();
  const { previewBaseUrl } = useUserConfig();

  return (
    <Menu.Section>
      <Menu.Item
        disabled={!item.EDS || !item.EDSRevision}
        onClick={() => {
          dispatch(
            setPaneSheet({
              location: "editModal",
              sheet: {
                sheetType: "eds",
                name: item.EDS,
                revision: item.EDSRevision,
              },
            })
          );
          dispatch(setPaneMode({ location: "editModal", mode: "preview" }));
        }}
      >
        <Icon size={16} data={visibility} />
        View EDS in pane
      </Menu.Item>
      <Menu.Item
        disabled={!item.EDS || !item.EDSRevision}
        onClick={() =>
          previewInNewWindow({
            item: { EDS: item.EDS, Revision: item.EDSRevision },
            sheetType: "eds",
            previewBaseUrl,
            plant,
          })
        }
      >
        <Icon size={16} data={external_link} />
        View EDS in new window
      </Menu.Item>
      <Menu.Item
        disabled={!item.MDSVariant || !item.MDSRevision}
        onClick={() => {
          dispatch(
            setPaneSheet({
              location: "editModal",
              sheet: {
                sheetType: "mds",
                name: item.MDSVariant,
                revision: item.MDSRevision,
              },
            })
          );
          dispatch(
            setPaneMode({
              location: "editModal",
              mode: "preview",
            })
          );
        }}
      >
        <Icon size={16} data={visibility} />
        View MDS in pane
      </Menu.Item>
      <Menu.Item
        disabled={!item.MDSVariant || !item.MDSRevision}
        onClick={() =>
          previewInNewWindow({
            item: { MDS: item.MDSVariant, Revision: item.MDSRevision },
            sheetType: "mds",
            previewBaseUrl,
            plant,
          })
        }
      >
        <Icon size={16} data={external_link} />
        View MDS in new window
      </Menu.Item>
    </Menu.Section>
  );
}

function PipeElementHiddenInputs({
  item,
  elementGroupsData,
}: {
  item: any;
  elementGroupsData: any[];
}) {
  const { register } = useFormContext();
  const registerName = `pipeelements.${item.id}.`;
  return (
    <>
      <input
        type="hidden"
        value={item.ElementGroupNo}
        {...register(`${registerName}ElementGroupID`)}
      />
      <input
        type="hidden"
        value={getGroupName({
          elementName: "pipe-elements",
          elementGroupsData,
          groupId: item.ElementGroupNo,
        })}
        {...register(`${registerName}ElementGroupText`)}
      />
      <input
        type="hidden"
        value={item.NewDeletedLine}
        {...register(`${registerName}NewDeletedLine`)}
      />
      <input
        type="hidden"
        value={item.InitialInfo}
        {...register(`${registerName}InitialInfo`)}
      />
      <input
        type="hidden"
        value={item.InitialRevmark}
        {...register(`${registerName}InitialRevmark`)}
      />
    </>
  );
}

function PipeElementControlHeader() {
  const [show, setShow] = useState(false);
  return (
    <>
      <Button variant="outlined" onClick={() => setShow(true)}>
        Generate EDS Matrix
      </Button>
      <MessageModal
        isOpen={show}
        title="Not implemented"
        onClose={() => setShow(false)}
      >
        This function is not implemented yet.
      </MessageModal>
    </>
  );
}

const pipeElementColumns = [
  revmarkColumn,
  pageBreakColumn,
  {
    key: "FreeText",
    title: "FT",
    longTitle: "Free Text",
    Component: IconWithInput,
    type: "with-context",
    componentProps: {
      prop: "FreeText",
      icon: brush,
      trueValue: "Y",
      falseValue: "N",
      title: "Free Text",
    },
    width: 29,
  },
  {
    key: "ElementID",
    title: "ID",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "ElementID",
      hideIf: ["0", 0],
    },
    width: 52,
  },
  {
    key: "DimStandard",
    title: "Dim. Standard",
    longTitle: "Dimension Standard",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "DimStandard",
      submitProp: "DimensionStandard",
    },
    width: 105,
  },
  fromSizeColumn,
  toSizeColumn,
  {
    key: "ProductForm",
    title: "Type",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "ProductForm",
    },
    width: 85,
  },
  {
    key: "Material",
    title: "Material Name",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "Material",
      submitProp: "MaterialName",
    },
  },
  {
    key: "MDS",
    title: "MDS",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "MDS",
    },
    minWidth: 70,
  },
  {
    key: "ESK",
    title: "ESK",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "ESK",
    },
  },
  {
    key: "EDS",
    title: "EDS",
    Component: DisplayAsFormElement,
    type: "with-context",
    componentProps: {
      prop: "EDS",
    },
  },
  remarkColumn,
  noteColumnPCS,
] as ColumnsProps[];

export function PCSPipeElements({
  setIsLoaded,
  name,
  revision,
  saveSheetStatus,
}: {
  setIsLoaded: Function;
  name: string;
  revision: string;
  saveSheetStatus: QueryStatus;
}) {
  const { elementTableContent } = useElementTable({
    elementName: "pipe-elements",
    setIsLoaded,
    saveSheetStatus,
    name,
    revision,
    columns: pipeElementColumns,
    title: "Pipe Elements",
    ElementHiddenInputs: PipeElementHiddenInputs,
    AdditionalRowMenu: PipeElementRowMenu,
    EditModal: EditModal,
    AdditionalControlHeader: PipeElementControlHeader,
  });

  return <>{elementTableContent}</>;
}
