import { Button, Icon } from "@equinor/eds-core-react";
import {
  arrow_drop_down,
  arrow_drop_up,
  delete_forever,
  edit,
} from "@equinor/eds-icons";
import useConfirm from "components/confirm/useConfirm";
import { TableItem } from "components/table/Table";
import { useHistory } from "react-router-dom";
import {
  ListAdminAreas,
  listAdminConfig,
  ListAdminProps,
} from "./listAdminConfig";

export function MoveUpCommandButton<T extends ListAdminAreas>({
  area,
  item,
  swap,
  submit,
  nameFilter,
  currentGroupId,
}: {
  area: T;
  item: TableItem<ListAdminProps<T>>;
  swap: Function;
  submit: Function;
  nameFilter: string;
  currentGroupId: React.MutableRefObject<number>;
}) {
  const { isConfirmed } = useConfirm();
  const { itemIdProp, groupIdProp } = listAdminConfig[area];

  return (
    <Button
      variant="ghost_icon"
      onClick={async () => {
        const confirmed = await isConfirmed(
          `Are you sure you want to move item ${item[itemIdProp]} up?`
        );
        if (confirmed) {
          swap(item._absoluteIndex, item._absoluteIndex - 1);
          if (groupIdProp) {
            currentGroupId.current = Number(item[groupIdProp]);
          }
          submit();
        }
      }}
      disabled={item._groupIndex <= 0 || !!nameFilter}
    >
      <Icon data={arrow_drop_up} />
    </Button>
  );
}

export function MoveDownCommandButton<T extends ListAdminAreas>({
  area,
  item,
  swap,
  submit,
  nameFilter,
  currentGroupId,
}: {
  area: T;
  item: TableItem<ListAdminProps<T>>;
  swap: Function;
  submit: Function;
  nameFilter: string;
  currentGroupId: React.MutableRefObject<number>;
}) {
  const { isConfirmed } = useConfirm();
  const { itemIdProp, groupIdProp } = listAdminConfig[area];

  return (
    <Button
      variant="ghost_icon"
      onClick={async () => {
        const confirmed = await isConfirmed(
          `Are you sure you want to move item ${item[itemIdProp]} down?`
        );
        if (confirmed) {
          swap(item._absoluteIndex, item._absoluteIndex + 1);
          if (groupIdProp) {
            currentGroupId.current = Number(item[groupIdProp]);
          }
          submit();
        }
      }}
      disabled={item._groupIndex >= item._groupLength - 1 || !!nameFilter}
    >
      <Icon data={arrow_drop_down} />
    </Button>
  );
}

export function RemoveCommandButton<T extends ListAdminAreas>({
  update,
  submit,
  item,
  area,
  disableDelete,
  currentGroupId,
}: {
  update: Function;
  submit: Function;
  item: TableItem<ListAdminProps<T>>;
  area: T;
  disableDelete?: (arg0: ListAdminProps<T>) => boolean;
  currentGroupId: React.MutableRefObject<number>;
}) {
  const { isConfirmed } = useConfirm();
  const { itemIdProp, groupIdProp } = listAdminConfig[area];

  return (
    <Button
      variant="ghost_icon"
      onClick={async () => {
        const confirmed = await isConfirmed(
          `Are you sure you want to remove item ${item[itemIdProp]}?`
        );
        if (confirmed) {
          update(item._absoluteIndex, { ...item, ...{ Action: "D" } });
          if (groupIdProp) {
            currentGroupId.current = Number(item[groupIdProp]);
          }
          submit();
        }
      }}
      disabled={disableDelete && disableDelete(item)}
    >
      <Icon data={delete_forever} />
    </Button>
  );
}

export function EditCommandButton<T extends ListAdminAreas>({
  item,
  area,
}: {
  item: ListAdminProps<T>;
  area: T;
}) {
  const { itemIdProp } = listAdminConfig[area];
  const history = useHistory();

  return (
    <Button
      variant="ghost_icon"
      onClick={() =>
        history.replace({
          search: `edit=${item[itemIdProp]}`,
        })
      }
    >
      <Icon data={edit} />
    </Button>
  );
}
