import { receipt } from "@equinor/eds-icons";
import { Category, MenuItem } from "components/MainMenuComponents";
import { ReportURIs, reportsConfig } from "./Reports";
import React from "react";

export function ReportsMenu() {
  return (
    <Category title="Reports">
      {Object.keys(reportsConfig).map((report) => {
        const { menuTitle, disabled, disableMenu } =
          reportsConfig[report as ReportURIs];
        return !disabled && !disableMenu ? (
          <MenuItem
            title={menuTitle}
            to={`/reports/${report}`}
            icon={receipt}
            key={report}
          />
        ) : (
          <React.Fragment key={report} />
        );
      })}
    </Category>
  );
}
