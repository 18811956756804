import { ValveTypeCodeContainer } from "components/Components";
import { MultiselectFieldFromCommaSeparated } from "components/form/EditComponents";
import {
  codelistProperties,
  Codelists,
  CodelistSpecification,
  useCodelist,
} from "queries/useCodelist";
import { useEffect, useMemo } from "react";
import { QueryStatus } from "react-query";
import styled from "styled-components";
import { VDSSubsegment, VDSTextBlock } from "../types";

const valveTypeOptionTitle = (
  valveType: CodelistSpecification["valve-types"]
) => (
  <>
    <ValveTypeCodeContainer>{valveType.VDSCode}</ValveTypeCodeContainer>{" "}
    {valveType.Description}
  </>
);

export function VDSValidator<T extends Codelists>({
  codelist,
  prop,
  value,
  statusesDispatch,
  titleFunc,
  isRefetching,
}: {
  codelist: T;
  prop: string;
  value: string;
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  titleFunc?: (arg0: CodelistSpecification[T]) => React.ReactFragment;
  isRefetching: boolean;
}) {
  const {
    data: codelistData,
    status,
    error,
  } = useCodelist({
    codelist,
  });

  const { idProp, nameProp, titleSingular } = codelistProperties[codelist];

  useEffect(() => {
    statusesDispatch({
      type: "set",
      payload: { [codelist]: status },
    });
  }, [status, statusesDispatch, codelist]);

  const options = useMemo(
    () =>
      codelistData?.map((e) => ({
        key: String(e[idProp as keyof typeof e]),
        title: titleFunc
          ? titleFunc(e)
          : (e[nameProp as keyof typeof e] as string),
      })),
    [codelistData, idProp, nameProp, titleFunc]
  );

  return (
    <MultiselectFieldFromCommaSeparated
      options={options}
      title={titleSingular}
      prop={prop}
      value={value}
      noneSelectedString="All"
      noneSelectedValue=""
      status={status}
      error={error}
      isRefetching={isRefetching}
    />
  );
}

export function VDSValidators({
  data,
  statusesDispatch,
  isRefetching,
}: {
  data: VDSTextBlock | VDSSubsegment["getVDSSubsegmentProperties"][0];
  statusesDispatch: React.Dispatch<QueryStatusAction>;
  isRefetching: boolean;
}) {
  return (
    <>
      <VDSValidator
        codelist="valve-types"
        statusesDispatch={statusesDispatch}
        prop="ValveTypeProperty"
        value={data.ValveTypeProperty}
        titleFunc={valveTypeOptionTitle}
        isRefetching={isRefetching}
      />
      <VDSValidator
        codelist="rating-classes"
        statusesDispatch={statusesDispatch}
        prop="RatingClassProperty"
        value={data.RatingClassProperty}
        isRefetching={isRefetching}
      />
      <VDSValidator
        codelist="material-groups"
        statusesDispatch={statusesDispatch}
        prop="MaterialGroupProperty"
        value={data.MaterialGroupProperty}
        isRefetching={isRefetching}
      />
      <VDSValidator
        codelist="end-connections"
        statusesDispatch={statusesDispatch}
        prop="EndConnectionProperty"
        value={data.EndConnectionProperty}
        isRefetching={isRefetching}
      />
      <VDSValidator
        codelist="special-requirements"
        statusesDispatch={statusesDispatch}
        prop="SpecialRequirementProperty"
        value={data.SpecialRequirementProperty}
        isRefetching={isRefetching}
      />
      <VDSValidator
        codelist="bores"
        statusesDispatch={statusesDispatch}
        prop="BoreProperty"
        value={data.BoreProperty}
        isRefetching={isRefetching}
      />
      <VDSValidator
        codelist="vds-sizes"
        statusesDispatch={statusesDispatch}
        prop="VDSSizeProperty"
        value={data.VDSSizeProperty}
        isRefetching={isRefetching}
      />
    </>
  );
}

export const PropertiesColumns = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
  gap: 30px;
`;

export type QueryStatusCollection = { [index: string]: QueryStatus };

export type QueryStatusAction = { type: "set"; payload: QueryStatusCollection };

export function statusesReducer(
  state: QueryStatusCollection,
  action: QueryStatusAction
) {
  return { ...state, ...action.payload };
}
