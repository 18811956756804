import { defaultConfig } from "config/config.default";
import { equinorProdConfig } from "config/config.equinor-prod";
import { equinorTestConfig } from "config/config.equinor-test";
import { presightProdTestConfig } from "config/config.presight-prod-test";

const appEnvConfig =
  process.env.REACT_APP_ENV === "equinor-prod"
    ? equinorProdConfig
    : process.env.REACT_APP_ENV === "equinor-test"
    ? equinorTestConfig
    : process.env.REACT_APP_ENV === "presight-prod-test"
    ? presightProdTestConfig
    : defaultConfig;

const config = {
  documentBaseUrl:
    "https://tr2000.presight.no/webapp-jsp/master/document_space/",
  externalValidationBaseUrl:
    "http://pentos.corp.presight.com/ibi_apps/WFServlet",
  commonLibPlantCodesInUrl: false,
  tinyApiKey: "t9pkdefzkedcdwbdo02nujib0qqygw1bbwcplp4lbt2177lt",
  ...appEnvConfig,
};

export default config;
