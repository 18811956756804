import { Config } from "./config.default";

export const equinorProdConfig: Config = {
  restApiBaseUrl: "https://tr2000api.equinor.com/",
  login: {
    clientId: "a4d5675c-35e3-41b2-b13d-577b4f95110b",
    authority:
      "https://login.microsoftonline.com/3aa4a235-b6e2-48d5-9195-7fcf05b459b0",
    scopes: ["api://6bae74f3-1e69-4ce1-9b1e-87cd4e1f1024/.default"],
  },
  modules: ["reports"],
};
