const baseTag = document.getElementsByTagName("base");

export const appProps = {
  appBasePath:
    baseTag && baseTag[0] && typeof baseTag[0].getAttribute !== "undefined"
      ? String(baseTag[0].getAttribute("href"))
      : "/",
  appBaseUrl: window.location.protocol + "//" + window.location.host,
  version:
    process.env.NODE_ENV === "development"
      ? `Development`
      : `${process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV + "_" : ""}${
          process.env.REACT_APP_VERSION
        }`,
};
