import React from "react";
import { default as ReactModal } from "react-modal";
import { Typography } from "@equinor/eds-core-react";
import styled from "styled-components";
import {
  FlexHeaderElement,
  FlexContentElement,
  AbsoluteFlexContainer,
  CloseButton,
  breakpointsNumber,
} from "./Components";
import useWindowDimensions from "../utils/useWindowDimensions";

const Modal = ReactModal;

export const ModalHeader = styled.div`
  padding: 20px 80px 0px 20px;
  min-height: 44px;
`;

const CloseContainer = styled.div`
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  z-index: 1001;
`;

const ModalContentContainer = styled.div`
  overflow: auto;
`;

export default function ContentModal(
  props: {
    header: string | React.ReactNode;
    children: React.ReactNode;
    closeModal: () => void;
    small?: boolean;
  } & ReactModal.Props
) {
  const { width } = useWindowDimensions();
  const {
    header,
    children,
    closeModal,
    small,
    style,
    shouldCloseOnOverlayClick,
  } = props;
  let modalStyle = {
    content: {
      background: "var(--bg)",
      color: "var(--text)",
      borderWidth: "0",
      borderRadius: "4px",
      boxShadow:
        "5px 5px 10px var(--overlayBg), 8px 8px 16px var(--overlayBgFaded)",
      margin: "0 auto",
      inset: width >= breakpointsNumber.lg ? 40 : 5,
      ...(small
        ? {
            width: "85%",
            height: "auto",
          }
        : {}),
      ...style?.content,
    },
    overlay: {
      zIndex: 100,
      background: "var(--overlayBg)",
      ...style?.overlay,
    },
  };

  return (
    <Modal
      {...props}
      style={modalStyle}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={
        typeof shouldCloseOnOverlayClick === "undefined"
          ? false
          : shouldCloseOnOverlayClick
      }
    >
      <AbsoluteFlexContainer>
        <FlexHeaderElement>
          <ModalHeader>
            {typeof header === "string" ? (
              <Typography variant={small ? "h4" : "h3"}>{header}</Typography>
            ) : (
              <>{header}</>
            )}
            <CloseContainer>
              <CloseButton
                closeFn={closeModal}
                size={small ? 16 : 24}
              ></CloseButton>
            </CloseContainer>
          </ModalHeader>
        </FlexHeaderElement>
        <FlexContentElement>
          <ModalContentContainer>{children}</ModalContentContainer>
        </FlexContentElement>
      </AbsoluteFlexContainer>
    </Modal>
  );
}
