import { Settings } from "queries/settings/useAllSettings";
import { UseSomeSettingsResult } from "queries/settings/useSomeSettings";

export function getSetting({
  settings,
  settingKey,
}: {
  settings?: Settings;
  settingKey?: string | null;
}) {
  return (settings && settingKey && settings[settingKey]) ?? null;
}

type SettingKeyElements =
  | string
  | { [index: string]: string | number | boolean | null };

export function settingKey(keyElements: SettingKeyElements[]) {
  return keyElements
    .map((e) => (typeof e === "string" ? e : Object.entries(e).join()))
    .join();
}

export function getSettingsObject({
  settingsKeys,
  settingsResults,
}: {
  settingsKeys: string[];
  settingsResults: UseSomeSettingsResult;
}) {
  return Object.fromEntries(
    settingsKeys.map((key, i) => [key, settingsResults[i].data ?? null])
  );
}
