import axios from "axios";
import { useQuery } from "react-query";
import config from "../app/config";

type PCSStandardNotesUsage = {
  Plant: string;
  PCS: string;
  Revision: string;
};

type PCSStandardNoteUsageList = {
  getStandardNotesUsage: PCSStandardNotesUsage[];
};

export function useNoteUsage({ noteId }: { noteId: string | false }) {
  const queryKey = ["codelists", "pcs-standard-notes-usage", noteId];
  return useQuery<PCSStandardNotesUsage[]>(
    queryKey,
    async () => {
      const url = config.restApiBaseUrl + queryKey.join("/");
      const { data } = await axios.get<PCSStandardNoteUsageList>(url);
      return data.getStandardNotesUsage;
    },
    { enabled: !!noteId }
  );
}
