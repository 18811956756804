import { useMutation, useQueryClient } from "react-query";
import { PlantProps } from "features/plant/types";
import axios from "axios";
import config from "app/config";

export default function useIssueSetPipeElements() {
  const queryClient = useQueryClient();

  async function issueSetPipeElements({
    method,
    pipeelements,
    plant,
    revision,
  }: {
    method: "put" | "delete";
    pipeelements: { ElementID: string | number; Revision: String }[];
    plant: PlantProps;
    revision: string;
  }) {
    return await axios({
      url:
        config.restApiBaseUrl +
        `plants/${plant.PlantID}/issues/rev/${revision}/pipe-elements`,
      method,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: { pipeelements },
    });
  }

  return useMutation(issueSetPipeElements, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["issues", variables.plant.PlantID]);
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
