import config from "app/config";
import axios from "axios";
import { ListAdminAreas, ListAdminProps } from "features/admin/listAdminConfig";
import { useMutation, useQueryClient } from "react-query";
import { listAdminConfig } from "./../features/admin/listAdminConfig";

export default function useSaveListAdmin<T extends ListAdminAreas>() {
  const queryClient = useQueryClient();

  async function saveAdmin({
    area,
    data,
  }: {
    area: T;
    data:
      | {
          ElementGroupID?: number | undefined;
        }
      | { [index: string]: ListAdminProps<T>[] };
  }) {
    const { url } = listAdminConfig[area];

    return await axios({
      url: `${config.restApiBaseUrl}${url}`,
      method: "put",
      data: JSON.stringify(data),
    });
  }
  return useMutation(saveAdmin, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries(["codelists", variables.area]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}

export type SaveListAdmin = ReturnType<typeof useSaveListAdmin>;
