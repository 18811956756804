import { Matrix } from "components/form/EditComponents";
import { Field } from "components/form/Field";
import { VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "queries/useVDSTextBlocksDistinctText";
import { useEffect } from "react";

export function EditVDSTextBlockSectionH4({
  data,
  setIsLoaded,
}: {
  data: VDSTextBlock;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const {
    data: ratingClasses,
    isLoading,
    isSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "RC",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  useEffect(() => {
    isSuccess && setIsLoaded(true);
  }, [isSuccess, setIsLoaded]);

  return data ? (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Rating"
        prop="RatingClass"
        value={data.RatingClass}
        options={ratingClasses}
        type="autocomplete"
        isLoading={isLoading}
        maxWidth={240}
      />
      <Matrix
        props={[
          [
            "MaxDesignPressure1",
            "MaxDesignPressure2",
            "MaxDesignPressure3",
            "MaxDesignPressure4",
            "MaxDesignPressure5",
            "MaxDesignPressure6",
            "MaxDesignPressure7",
            "MaxDesignPressure8",
            "MaxDesignPressure9",
            "MaxDesignPressure10",
            "MaxDesignPressure11",
            "MaxDesignPressure12",
          ],
          [
            "AtTemperature1",
            "AtTemperature2",
            "AtTemperature3",
            "AtTemperature4",
            "AtTemperature5",
            "AtTemperature6",
            "AtTemperature7",
            "AtTemperature8",
            "AtTemperature9",
            "AtTemperature10",
            "AtTemperature11",
            "AtTemperature12",
          ],
        ]}
        titles={["Max. Design Pressure (barg)", "At Temperature (°C)"]}
        values={[
          [
            data.MaxDesignPressure1,
            data.MaxDesignPressure2,
            data.MaxDesignPressure3,
            data.MaxDesignPressure4,
            data.MaxDesignPressure5,
            data.MaxDesignPressure6,
            data.MaxDesignPressure7,
            data.MaxDesignPressure8,
            data.MaxDesignPressure9,
            data.MaxDesignPressure10,
            data.MaxDesignPressure11,
            data.MaxDesignPressure12,
          ],
          [
            data.AtTemperature1,
            data.AtTemperature2,
            data.AtTemperature3,
            data.AtTemperature4,
            data.AtTemperature5,
            data.AtTemperature6,
            data.AtTemperature7,
            data.AtTemperature8,
            data.AtTemperature9,
            data.AtTemperature10,
            data.AtTemperature11,
            data.AtTemperature12,
          ],
        ]}
        maxWidth={900}
        min={[Array(12).fill(-1000), Array(12).fill(-500)]}
        max={[Array(12).fill(5000), Array(12).fill(1000)]}
      />
    </>
  ) : (
    <></>
  );
}
