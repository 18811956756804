import {
  ContentCell,
  HeadCell,
  ReportTable,
  TableBody,
  TableRow,
} from "../ReportComponents";
import { WallThicknessCalculationRatiosData } from "./wallThicknessCalculationRatiosConfig";

function Cell({
  property,
  item,
  bold,
}: {
  property: keyof WallThicknessCalculationRatiosData[0];
  item: WallThicknessCalculationRatiosData[0];
  bold?: boolean;
}) {
  return (
    <ContentCell
      style={{
        textDecoration:
          item.Selected === "Y" &&
          (property === "Pressure" ||
            property === "Temperature" ||
            property === "AllowableStress")
            ? "underline"
            : "none",
        fontWeight: bold ? "bold" : "normal",
      }}
    >
      {item[property]}
    </ContentCell>
  );
}

export function WallThicknessCalculationRatiosReport({
  data,
}: {
  data: WallThicknessCalculationRatiosData;
}) {
  return (
    <ReportTable>
      <TableBody>
        <TableRow>
          <HeadCell>Pressure Ratios</HeadCell>
          {data.map((item) => (
            <Cell item={item} property="PressureRatio" />
          ))}
        </TableRow>
        <TableRow>
          <HeadCell>Pressure</HeadCell>
          {data.map((item) => (
            <Cell item={item} property="Pressure" />
          ))}
        </TableRow>
        <TableRow>
          <HeadCell>Temperature</HeadCell>
          {data.map((item) => (
            <Cell item={item} property="Temperature" bold />
          ))}
        </TableRow>
        <TableRow>
          <HeadCell>Allowable Stress</HeadCell>
          {data.map((item) => (
            <Cell item={item} property="AllowableStress" />
          ))}
        </TableRow>
        <TableRow>
          <HeadCell>Allowable Stress Ratios</HeadCell>
          {data.map((item) => (
            <Cell item={item} property="AllowableStressRatio" />
          ))}
        </TableRow>
        <TableRow>
          <HeadCell>P/S</HeadCell>
          {data.map((item) => (
            <Cell item={item} property="PressureAllowableStress" />
          ))}
        </TableRow>
      </TableBody>
    </ReportTable>
  );
}
