import {
  Button,
  Label,
  Radio,
  TextField,
  Tooltip,
} from "@equinor/eds-core-react";
import { AxiosResponse } from "axios";
import React, { useEffect } from "react";
import { UseMutateFunction } from "react-query";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
} from "components/ModalWindow";
import styled from "styled-components";
import { FlexRowContainer } from "components/Components";
import { CreateEDSRequest } from "queries/useCreateEDS";

const ColumnContainer = styled.div`
  display: flex;
  gap: 1em;
`;

export type NewEDSOperation = "Add" | "Copy" | false;

export function CreateEDS({
  newEDSOperation,
  setNewEDSOperation,
  newEDSName,
  setNewEDSName,
  newEDSRevision,
  setNewEDSRevision,
  sourceEDSName,
  sourceEDSRevision,
  createEDS,
}: {
  newEDSOperation: NewEDSOperation;
  setNewEDSOperation: React.Dispatch<NewEDSOperation>;
  newEDSName: string;
  setNewEDSName: React.Dispatch<string>;
  newEDSRevision: string;
  setNewEDSRevision: React.Dispatch<string>;
  sourceEDSName: string;
  sourceEDSRevision: string;
  createEDS: UseMutateFunction<
    AxiosResponse<never>,
    unknown,
    CreateEDSRequest,
    unknown
  >;
}) {
  const closeModal = () => {
    setNewEDSOperation(false);
  };

  useEffect(() => {
    !!newEDSOperation && setNewEDSName("");
  }, [newEDSOperation, setNewEDSName]);

  const isNameValid = () =>
    !!(newEDSName.length < 6 && newEDSName.match(/^[A-Z0-9]*$/));
  const isNamePresent = () => newEDSName.length > 0;

  return (
    <>
      <ModalWindow
        closeModal={() => closeModal()}
        isOpen={!!newEDSOperation}
        title={
          newEDSOperation === "Add"
            ? "Create new EDS"
            : `Copy EDS from ${sourceEDSName} rev. ${sourceEDSRevision}`
        }
      >
        <ModalSideMargin>
          <FlexRowContainer>
            <Tooltip
              title={
                !isNameValid()
                  ? "Can only contain uppercase letters and numbers, maximum length is 5 characters"
                  : ""
              }
            >
              <TextField
                label={newEDSOperation === "Add" ? "EDS" : "New EDS name"}
                value={newEDSName}
                id="EDSname"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setNewEDSName(e.target.value)
                }
                variant={!isNameValid() ? "error" : undefined}
                autoFocus
                style={{ width: 150 }}
              />
            </Tooltip>
            <div>
              <Label label="Revision" />
              <ColumnContainer>
                <Radio
                  label="0"
                  checked={newEDSRevision === "0"}
                  onChange={() => setNewEDSRevision("0")}
                />
                <Radio
                  label="1"
                  checked={newEDSRevision === "1"}
                  onChange={() => setNewEDSRevision("1")}
                />
              </ColumnContainer>
            </div>
          </FlexRowContainer>
        </ModalSideMargin>
        <ModalWindowButtonContainer>
          <Button
            onClick={() => {
              if (newEDSOperation) {
                closeModal();
                createEDS({
                  operation: newEDSOperation,
                  newName: newEDSName,
                  newRevision: newEDSRevision,
                  name: sourceEDSName,
                  revision: sourceEDSRevision,
                });
              }
            }}
            disabled={!isNameValid() || !isNamePresent()}
          >
            Create
          </Button>
          <Button variant="outlined" onClick={() => closeModal()}>
            Close
          </Button>
        </ModalWindowButtonContainer>
      </ModalWindow>
    </>
  );
}
