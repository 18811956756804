import { Field } from "components/form/Field";
import { VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "queries/useVDSTextBlocksDistinctText";
import { useEffect } from "react";

export function EditVDSTextBlockSectionD({
  data,
  setIsLoaded,
}: {
  data: VDSTextBlock;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const {
    data: designRemarks,
    isLoading,
    isSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "DR",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  useEffect(() => {
    isSuccess && setIsLoaded(true);
  }, [isSuccess, setIsLoaded]);

  return data ? (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Design Remark"
        prop="DesignRemark"
        value={data.DesignRemark}
        options={designRemarks}
        type="autocomplete"
        isLoading={isLoading}
      />
    </>
  ) : (
    <></>
  );
}
