import { Button, NativeSelect } from "@equinor/eds-core-react";
import { FlexRowContainer } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { editSheet } from "features/sheets/commands";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useCreateVDSTextBlock from "queries/useCreateVDSTextBlock";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { successMessage } from "utils/successMessage";

export function NewVDSTextBlock({
  showCreateNew,
  setShowCreateNew,
  groupFilter,
}: {
  showCreateNew: boolean;
  setShowCreateNew: React.Dispatch<boolean>;
  groupFilter: string;
}) {
  const closeModal = () => {
    setShowCreateNew(false);
  };

  const { data: vdsSubsegmentGroups, status: vdsSubsegmentGroupsStatus } =
    useCodelist({ codelist: "vds-subsegment-groups" });
  const {
    data: vdsSubsegmentGroupsConfig,
    status: vdsSubsegmentGroupsConfigStatus,
  } = useCodelist({ codelist: "vds-subsegment-groups-config" });

  const combinedStatuses = useMemo(
    () =>
      combineQueryStatuses(
        vdsSubsegmentGroupsStatus,
        vdsSubsegmentGroupsConfigStatus
      ),
    [vdsSubsegmentGroupsStatus, vdsSubsegmentGroupsConfigStatus]
  );

  const [group, setGroup] = useState(groupFilter);
  const [item, setItem] = useState("");

  const configOptions = useMemo(
    () =>
      vdsSubsegmentGroupsConfig
        ? vdsSubsegmentGroupsConfig
            .filter((e) => String(e.GroupID) === group)
            .map((e) => ({
              value: String(e.ItemID),
              section: e.ItemSection,
              description: e.ItemDescription,
            }))
        : [],
    [group, vdsSubsegmentGroupsConfig]
  );

  useEffect(() => {
    groupFilter
      ? setGroup(groupFilter)
      : vdsSubsegmentGroups &&
        vdsSubsegmentGroups.length > 0 &&
        setGroup(String(vdsSubsegmentGroups[0].GroupID));
  }, [groupFilter, vdsSubsegmentGroups]);

  useEffect(() => {
    if (configOptions.length > 0) {
      setItem(configOptions[0].value);
    }
  }, [configOptions]);

  const selectedConfigOption = useMemo(
    () => configOptions.find((e) => e.value === item),
    [configOptions, item]
  );

  const {
    mutate: newVDSTextBlock,
    status: newVDSTextBlockStatus,
    error: newVDSTextBlockError,
    reset: newVDSTextBlockReset,
    data: newVDSTextBlockData,
  } = useCreateVDSTextBlock();

  const history = useHistory();

  return (
    <>
      <ModalWindow
        isOpen={showCreateNew}
        closeModal={closeModal}
        title="Create New VDS Text Block"
        isLoading={combinedStatuses === "loading"}
      >
        <>
          <ModalWindowContentDefault style={{ width: 400 }}>
            <ModalSideMargin>
              <FlexRowContainer>
                <div>
                  <NativeSelect
                    label="Group"
                    id="vds-subsegment-group"
                    value={group}
                    onChange={(e) => setGroup(e.target.value)}
                  >
                    {vdsSubsegmentGroups?.map((e) => (
                      <option key={e.GroupID} value={e.GroupID}>
                        {e.Description}
                      </option>
                    ))}
                  </NativeSelect>
                </div>
                <div>
                  <NativeSelect
                    label="Type"
                    value={item}
                    id="vds-subsegment-group-type"
                    onChange={(e) => setItem(e.target.value)}
                  >
                    {configOptions.map((e) => (
                      <option key={e.value} value={e.value}>
                        {e.section} - {e.description}
                      </option>
                    ))}
                  </NativeSelect>
                </div>
              </FlexRowContainer>
            </ModalSideMargin>
          </ModalWindowContentDefault>
          <ModalWindowButtonContainer>
            <Button
              disabled={!selectedConfigOption}
              onClick={() => {
                selectedConfigOption &&
                  newVDSTextBlock({
                    itemSection: selectedConfigOption?.section,
                    itemId: selectedConfigOption?.value,
                  });
              }}
            >
              Create
            </Button>
            <Button variant="outlined" onClick={closeModal}>
              Close
            </Button>
          </ModalWindowButtonContainer>
        </>
      </ModalWindow>
      <StatusModal
        status={newVDSTextBlockStatus}
        error={newVDSTextBlockError}
        onSettledClose={newVDSTextBlockReset}
        onSuccess={closeModal}
        onSuccessClose={() => {
          newVDSTextBlockData &&
            editSheet({
              item: {
                TextBlockID: newVDSTextBlockData?.data.AdditionalInfo,
                Revision: 0,
              },
              history,
              sheetType: "vds-textblocks",
            });
        }}
        successMessage={successMessage(newVDSTextBlockData)}
      />
    </>
  );
}
