import { ColumnsProps, TableProps } from "components/table/Table";
import {
  MaterialGroups,
  materialGroupsConfig,
} from "./material-groups/materialGroupsConfig";
import {
  PFL2Mapping,
  pfl2MappingConfig,
} from "./product-form-level2-mapping/pfl2MappingConfig";
import {
  VDSSubsegmentGroups,
  vdsSubsegmentGroupsConfig,
} from "./vds-subsegment-groups/vdsSubsegmentGroupsConfig";
import { Capabilities } from "queries/useUser";

export const listAdminAreas = [
  "material-groups",
  "product-form-level2-mapping",
  "vds-subsegment-groups",
] as const;

export const listAdminConfig: {
  [T in ListAdminAreas]: ListAdminConfig<T>;
} = {
  "material-groups": materialGroupsConfig,
  "product-form-level2-mapping": pfl2MappingConfig,
  "vds-subsegment-groups": vdsSubsegmentGroupsConfig,
};

export function isListAdminArea(area: any): area is ListAdminAreas {
  return listAdminAreas.includes(area);
}

export type ListAdminAreas = (typeof listAdminAreas)[number];

export type ListAdminCommonProps = {
  Action: "D" | "N";
};

// prettier-ignore
export type ListAdminProps<T extends ListAdminAreas> =
  T extends "material-groups" ? MaterialGroups :
  T extends "product-form-level2-mapping" ? PFL2Mapping :
  T extends "vds-subsegment-groups" ? VDSSubsegmentGroups
: never;

export type ListColumnKeyType<T extends ListAdminAreas> = {
  key: keyof ListAdminProps<T> & string;
};

export type ListAdminReturnType<T extends ListAdminAreas> = ListAdminProps<T>[];

export type ListAdminConfig<T extends ListAdminAreas> = Omit<
  TableProps,
  | "columns"
  | "itemIdProp"
  | "groupIdProp"
  | "selectionMode"
  | "status"
  | "items"
> & {
  title: string;
  singularTitle: string;
  url: string;
  confirmPFL2?: boolean;
  itemIdProp: keyof ListAdminProps<T> & string;
  groupIdProp?: keyof ListAdminProps<T> & string;
  itemNameProp: keyof ListAdminProps<T> & string;
  columns: (Omit<ColumnsProps, "key"> & ListColumnKeyType<T>)[];
  apiListProp: string;
  submitProp: string;
  nameProperty: keyof ListAdminProps<T> | (keyof ListAdminProps<T>)[];
  sectionFilter?: string;
  disableDelete?: (item: ListAdminProps<T>) => boolean;
  readonly?: (caps: Capabilities) => boolean;
};
