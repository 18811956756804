import { AppDisplayGlobalStyle } from "GlobalStyle";
import React from "react";
import * as Space from "react-spaces";
import useMeasure from "react-use-measure";
import { MenuToggle } from "./components/MainMenuComponents";
import SettingsMenu from "./SettingsMenu";
import UserMenu from "./UserMenu";
import { useAppSelector } from "./app/hooks";
import PlantSelector from "./features/plant/PlantSelector";
import { selectUIState } from "./uiSlice";
import {
  AppContainer,
  AppHeader,
  AppHeaderContainer,
  AppTitle,
  AppHeaderElement,
  AppWithSettingsLoaded,
} from "components/AppDisplayComponents";

export default function AppDisplay() {
  const [pageRef, { width: pageWidth }] = useMeasure();
  const { darkMode, showAlpha } = useAppSelector(selectUIState);

  React.useLayoutEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      darkMode ? "dark" : "light"
    );
  }, [darkMode]);

  return (
    <>
      <AppDisplayGlobalStyle />
      <Space.ViewPort style={{ overflow: "auto" }}>
        <AppContainer className="App" data-testid="App" id="App" ref={pageRef}>
          <Space.Top size="68px">
            <AppHeader color="medium">
              <AppHeaderContainer style={{ alignItems: "center" }}>
                <MenuToggle />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 3,
                  }}
                >
                  <AppTitle link />
                </div>
              </AppHeaderContainer>
              <AppHeaderContainer style={{ marginLeft: "auto" }}>
                <AppHeaderElement>
                  <PlantSelector kind="main" />
                </AppHeaderElement>
                <AppHeaderElement style={{ marginLeft: ".5em" }}>
                  {showAlpha && <SettingsMenu />}
                </AppHeaderElement>
                <AppHeaderElement>
                  <UserMenu />
                </AppHeaderElement>
              </AppHeaderContainer>
            </AppHeader>
          </Space.Top>
          <Space.Fill>
            <AppWithSettingsLoaded pageWidth={pageWidth} />
          </Space.Fill>
        </AppContainer>
      </Space.ViewPort>
    </>
  );
}
