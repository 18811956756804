import { useQuery } from "react-query";
import axios from "axios";
import config from "../app/config";
import { VDSItemSections } from "features/sheets/types";

export type AttributeNames =
  | "VT"
  | "CO"
  | "SF"
  | "ST"
  | "SC"
  | "UN"
  | "RC"
  | "DR"
  | "MS"
  | "MN"
  | "MM"
  | "MR"
  | "MD"
  | "MV";

export default function useVDSTextBlocksDistinctText({
  attributeName,
  sectionId,
  itemId,
}: {
  attributeName: AttributeNames;
  sectionId: VDSItemSections;
  itemId: number;
}) {
  return useQuery<string[], Error>(
    ["vds-textblocks/distinct-text", attributeName, sectionId, itemId],
    async () => {
      const { data } = await axios.get<{
        getDistinctAttributeText: {
          AttributeText: string;
        }[];
      }>(
        `${config.restApiBaseUrl}vds-textblocks/distinct-text?attributeName=${attributeName}&SectionID=${sectionId}&ItemID=${itemId}`
      );
      return data["getDistinctAttributeText"].map((e) => e.AttributeText);
    }
  );
}
