import { Access } from "Access";
import { useParams } from "react-router-dom";
import { Admin, ValidAdminAreas } from "./Admin";
import { ItemAdminAreas, ItemAdminSections } from "./itemAdminConfig";

export function AccessAdmin<T extends ValidAdminAreas>() {
  const { area, section } = useParams<{
    area?: T;
    section?: T extends keyof ItemAdminAreas
      ? ItemAdminSections<T>[number]
      : string;
  }>();

  return (
    <Access role="X" enabled={area !== "pcs-standard-notes"}>
      <Access cap="admin" module="admin">
        <Access
          cap={["admin_pcs_notes", "sheets"]}
          enabled={area === "pcs-standard-notes"}
        >
          <Admin area={area} section={section} key={`${area}${section}`} />
        </Access>
      </Access>
    </Access>
  );
}
