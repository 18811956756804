import { FilterProps } from "../components/table/Filters";

type Default = { [key in string]: any };

export function filterItemsByProps<T extends Default>({
  filters,
  items,
  alwaysPresent,
}: {
  filters: FilterProps[];
  items: T[];
  alwaysPresent?: { idProp: string; ids: string[] };
}) {
  return !items || !items?.length
    ? []
    : !filters || !filters?.length
    ? items
    : items.filter(
        (item: T) =>
          (alwaysPresent &&
            alwaysPresent.idProp in item &&
            alwaysPresent.ids.includes(String(item[alwaysPresent.idProp]))) ||
          filters
            .map((f) =>
              !f.filterState || f.filterState.length === 0
                ? true
                : f.commaSeparated
                ? item[f.prop]
                    .split(",")
                    .map(
                      (i: string) =>
                        i === "*" ||
                        (typeof f.filterState === "string"
                          ? i === f.filterState
                          : f.filterState.includes(i))
                    )
                    .reduce((acc: boolean, curr: boolean) => acc || curr)
                : typeof f.filterState === "string"
                ? String(item[f.prop]) === f.filterState
                : f.filterState.includes(item[f.prop])
            )
            .reduce((curr, acc) => acc && curr)
      );
}
