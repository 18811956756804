import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";
import { InUseIconColumn } from "./InUse";

export type PCSStandardNotes = {
  NoteID: number;
  NoteText: string;
  Category: string;
  InUse: "Y" | "N";
};

export type PCSStandardNotesCreate = {
  NoteSection: "PCS" | "VDS";
  NoteText: string;
  Category: string;
};

export type PCSStandardNotesUpdate = CommonUpdateProps & {
  NoteText: string;
  Category: string;
};

export const pcsStandardNotesConfig: ItemAdminConfig<"pcs-standard-notes"> = {
  title: "PCS Standard Notes",
  singularTitle: "PCS Standard Note",
  url: "codelists/pcs-standard-notes",
  itemIdProp: "NoteID",
  apiListProp: "getStandardNotes",
  groupBy: "Category",
  nameProperty: ["NoteID", "NoteText"],
  sectionFilter: "notefilter",
  sectionTitles: { VDS: " – Valve Section", PCS: " – Pipe Section" },
  disableDelete: (item: PCSStandardNotes) => item.InUse === "Y",
  columns: [
    {
      key: "Category",
      title: "Category",
      width: 100,
    },
    {
      key: "NoteID",
      title: "Note ID",
    },
    { key: "NoteText", title: "Note Text", width: "100%" },
    {
      key: "InUse",
      title: "In Use",
      type: "with-context",
      componentProps: {
        trueValue: "Y",
        falseValue: "N",
        title: "In Use",
      },
      Component: InUseIconColumn,
    },
  ],
};
