import { Button } from "@equinor/eds-core-react";
import React, { useEffect, useState } from "react";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
} from "./ModalWindow";

export function MessageModal({
  isOpen,
  title,
  children,
  onClose,
  closeButton,
}: {
  isOpen: boolean;
  title: string;
  children: React.ReactNode;
  onClose?: Function;
  closeButton?: string | false;
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => setOpen(isOpen), [isOpen]);

  const closeModal = () => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <ModalWindow
      closeModal={closeModal}
      isOpen={open}
      title={title ? title : ""}
      hideCloseButton={true}
      zIndex={330}
      shouldCloseOnOverlayClick={false}
    >
      <ModalSideMargin>{children}</ModalSideMargin>
      {closeButton !== false && (
        <ModalWindowButtonContainer>
          <Button variant="outlined" onClick={closeModal}>
            {closeButton ? closeButton : "OK"}
          </Button>
        </ModalWindowButtonContainer>
      )}
    </ModalWindow>
  );
}
