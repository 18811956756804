import { Button, Icon, Typography } from "@equinor/eds-core-react";
import { comment_chat } from "@equinor/eds-icons";
import React, { useContext, useState } from "react";
import { MultiColumnList } from "components/Components";
import {
  ModalSideMargin,
  ModalWindow,
  ModalWindowButtonContainer,
  ModalWindowContentDefault,
} from "components/ModalWindow";
import StatusModal from "components/StatusModal";
import { useNoteUsage } from "queries/useNoteUsage";
import { PCSStandardNotes } from "./pcsStandardNotesConfig";

interface InUseContextProps {
  showInUseModal: false | string;
  setShowInUseModal: React.Dispatch<false | string>;
}

export const InUseContext = React.createContext({} as InUseContextProps);

export function useInUse() {
  const [showInUseModal, setShowInUseModal] = useState(false as false | string);
  const { data, status, error } = useNoteUsage({ noteId: showInUseModal });
  const plants =
    data &&
    data.reduce<string[]>(
      (a, c) => (a.includes(c.Plant) ? a : [...a, c.Plant]),
      []
    );

  const inUseModalContent = showInUseModal && (
    <>
      {status === "success" && (
        <ModalWindow
          isOpen={!!showInUseModal}
          closeModal={() => setShowInUseModal(false)}
          title={`Note ${showInUseModal} usage`}
        >
          <ModalWindowContentDefault>
            <ModalSideMargin>
              {plants &&
                plants?.map((p) => (
                  <div key={p}>
                    <Typography variant="h6">{p}</Typography>
                    <MultiColumnList>
                      {data
                        .filter((e) => e.Plant === p)
                        .map((e) => (
                          <li key={[e.Plant, e.PCS, e.Revision].join()}>
                            {e.PCS} rev. {e.Revision}
                          </li>
                        ))}
                    </MultiColumnList>
                  </div>
                ))}
            </ModalSideMargin>
          </ModalWindowContentDefault>
          <ModalWindowButtonContainer>
            <Button onClick={() => setShowInUseModal(false)}>Close</Button>
          </ModalWindowButtonContainer>
        </ModalWindow>
      )}
      {status === "loading" && <StatusModal status={status} error={error} />}
    </>
  );
  return {
    inUseModalContent,
    showInUseModal,
    setShowInUseModal,
  };
}

export function InUseIconColumn({
  item,
  title,
  trueValue,
  falseValue,
}: {
  item: PCSStandardNotes;
  title: string;
  trueValue: string;
  falseValue: string;
}) {
  const { setShowInUseModal } = useContext(InUseContext);
  return item.InUse === trueValue ? (
    <Button
      variant="ghost_icon"
      onClick={() => setShowInUseModal(String(item.NoteID))}
    >
      <Icon data={comment_chat} size={16} title={title} />
    </Button>
  ) : (item.InUse as unknown) === falseValue ? (
    <></>
  ) : (
    <>{item.InUse}</>
  );
}
