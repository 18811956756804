import { download } from "@equinor/eds-icons";
import ListMenu from "./ListMenu";
import { Icon, Menu } from "@equinor/eds-core-react";
import { downloadAsCSV, downloadAsXLSX } from "utils/download";
import { ColumnsProps } from "./table/Table";

export function Download({
  columns,
  items,
  filename,
  disabled,
}: {
  columns: ColumnsProps[];
  items: any[] | undefined;
  filename: string;
  disabled?: boolean;
}) {
  return (
    <ListMenu
      buttonContent={<Icon data={download} />}
      tooltipTitle="Download as..."
      disabled={disabled}
    >
      {items && (
        <Menu.Item onClick={() => downloadAsXLSX({ columns, items, filename })}>
          Download as XLSX
        </Menu.Item>
      )}
      {items && (
        <Menu.Item onClick={() => downloadAsCSV({ columns, items, filename })}>
          Download as CSV
        </Menu.Item>
      )}
    </ListMenu>
  );
}
