import axios from "axios";
import { useQuery } from "react-query";
import { PlantProps } from "../features/plant/types";
import { getSheetApiUrl } from "./queryUtil";
import { splitter, ender } from "../features/sheets/util";

export function useArticleStackQuery({
  plant,
  lastRunOnlyFilter,
}: {
  plant: PlantProps;
  lastRunOnlyFilter: "Y" | "N";
}) {
  return useQuery(
    ["article-stack", plant.PlantID, lastRunOnlyFilter],
    async () => {
      const { data } = await axios.get(
        getSheetApiUrl({
          plant,
          sheetType: "pcs",
          command: `-articles-stack`,
        }) + `?lastrunonly=${lastRunOnlyFilter}`
      );
      // @ts-ignore
      return data["getPCS"].map((item: any) => ({
        ...item,
        ...{
          itemID: `${item.PCS}${splitter}${item.Revision}${ender}${item.AddStackDate}-${item.AddStackTime}`,
        },
        ...{
          AddStackDateTime: item.AddStackDate
            ? `${item.AddStackDate} ${item.AddStackTime}`
            : "",
        },
      }));
    }
  );
}
