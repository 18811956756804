import { sheetProperties } from "./../features/sheets/config/sheetConfig";
import { TypedSheetTypes } from "./../features/sheets/types";
import { useQuery } from "react-query";
import axios from "axios";
import {
  SheetTypes,
  contentProperty,
  listProperty,
  plantDependentSheets,
} from "../features/sheets/config/sheetConfig";
import { PlantProps } from "../features/plant/types";
import { getSheetApiUrl } from "./queryUtil";
import { SheetTypeData } from "features/sheets/types";

export type ValidationStatus = "W" | "S" | "I";

export interface ValLogDetail {
  Sequence: number;
  StepNo: number;
  LineNo: number;
  Description: string;
  Note: string;
  Approved: "Y" | "N";
  Status: ValidationStatus;
}

export interface ValLogStep {
  StepNo: number;
  Description: string;
  Status: "0" | "1";
}

interface ValidationCheck {
  RevValidationCheck: {
    ValidationCheckID: number;
    Description: string;
    Status: "1" | "0";
  }[];
}

type VDSTextBlockVSK = {
  getVDSTextBlockVSK: { VSK: string }[];
};

type VDSTextBlockMDS = {
  getVDSTextBlockMDS: { MDS: string }[];
};

type Postfixes =
  | "validation-logs/steps"
  | "validation-logs/details"
  | "vsk"
  | "mds"
  | string;

type ReturnType<T, S> = S extends "pcs"
  ? T extends "validation-logs/steps"
    ? {
        getValLogSteps: ValLogStep[];
        ApprovalDate: string;
        Approver: string;
        CaseHandler: string;
        LastUpdate: string;
        LockedBy: string;
        LockedDate: string;
        Status: string;
        ValidationDate: string;
      }
    : T extends "validation-logs/details"
    ? { getValLogDetails: ValLogDetail[] }
    : T extends "main-revision-validation-check"
    ? ValidationCheck
    : T extends "sub-revision-validation-check"
    ? ValidationCheck
    : any
  : S extends "general"
  ? SheetTypeData[S]
  : S extends TypedSheetTypes
  ? T extends "vsk"
    ? VDSTextBlockVSK
    : T extends "mds"
    ? VDSTextBlockMDS
    : SheetTypeData[S]
  : any;

export default function useSheet<T extends Postfixes, S extends SheetTypes>({
  plant,
  sheetType,
  name,
  revision,
  postfix,
  cacheTime,
  prefix,
  disabled,
}: {
  sheetType: S;
  name: string;
  revision: string;
  plant?: PlantProps;
  postfix?: T;
  cacheTime?: number;
  prefix?: string;
  disabled?: boolean;
}) {
  const postfixKey = postfix ? postfix.split("/") : [];
  return useQuery<ReturnType<T, S>, Error>(
    [
      prefix ? prefix : "sheet",
      ...(plantDependentSheets.includes(sheetType) && plant
        ? [plant.PlantID]
        : []),
      sheetType,
      name,
      revision,
      ...postfixKey,
    ],
    async () => {
      const { data } = await axios.get(
        getSheetApiUrl({ plant, sheetType, name, revision, postfix })
      );
      return postfix || sheetProperties[sheetType].multiPropertiesData
        ? data
        : data[
            sheetType in contentProperty
              ? contentProperty[sheetType as keyof typeof contentProperty]
              : listProperty[sheetType]
          ][0];
    },
    { cacheTime, enabled: !disabled }
  );
}
