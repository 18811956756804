import {
  Button,
  EdsProvider,
  Radio,
  Table,
  Typography,
} from "@equinor/eds-core-react";
import {
  Loader,
  SimpleButtonContainer,
  TotalCenter,
} from "components/Components";
import StatusModal from "components/StatusModal";
import { VDSItemSections } from "features/sheets/types";
import { combineQueryStatuses } from "queries/queryUtil";
import { useCodelist } from "queries/useCodelist";
import useSaveVDSSubsegmentGroupsConfig from "queries/useSaveVDSSubsegmentConfig";
import React from "react";
import { useMemo, useState } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { successMessage } from "utils/successMessage";

type Axis = {
  id: number;
  title: string;
}[];

type AxisWithGroup = {
  id: number;
  title: string;
  groupTitle: string;
  itemSection: VDSItemSections;
}[];

type RadioMatrixDataItem = {
  x: number;
  y: number;
  ySection: VDSItemSections;
};

type RadioMatrixData = RadioMatrixDataItem[];

const TableContainer = styled.div`
  background: var(--furtherFadedBg);
`;
const RotatedHeaderTable = styled(Table)`
  padding-top: 150px;
  padding-right: 153px;
  background: var(--furtherFadedBg);
`;
const Element = styled(Table.Cell)`
  padding: 5px 8px;
  background: var(--bg);
  border-right: 1px solid
    var(--eds_ui_background__medium, rgba(220, 220, 220, 1));
`;
const XHeader = styled.th`
  transform: rotate(-45deg);
  transform-origin: bottom left;
  z-index: 3;
  &.active {
    &:after {
      content: "";
      position: absolute;
      width: 216px;
      height: 34px;
      left: 17px;
      top: 3px;
      transform: skew(45deg);
      background: var(--transparentBg);
    }
  }
`;
const THead = styled(Table.Head)`
  background: var(--bg);
  position: relative;
  position: sticky;
  top: 150px;
  z-index: 3;
  &::before {
    content: "";
    border-bottom: 1px solid
      var(--eds_ui_background__medium, rgba(220, 220, 220, 1));
    top: -150px;
    position: absolute;
    display: block;
    height: 152px;
    width: 100%;
    background: var(--furtherFadedBg);
  }
`;
const XHeaderContainer = styled.div`
  padding-left: 35px;
`;
const XHeaderContent = styled.div`
  position: absolute;
  text-align: left;
  width: 214px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-top: 12px;
  padding-bottom: 5px;
  border-bottom: 1px solid
    var(--eds_ui_background__medium, rgba(220, 220, 220, 1));
`;
const YHeader = styled.th`
  text-align: left;
  border-right: 1px solid
    var(--eds_ui_background__medium, rgba(220, 220, 220, 1));
  border-bottom: 1px solid
    var(--eds_ui_background__medium, rgba(220, 220, 220, 1));
  background: var(--semiFadedBg);
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 7px;
  line-height: 1.3;
  min-width: 165px;
  &.active {
    box-shadow: inset 0 0 100px var(--transparentBg);
  }
`;
const YGroupHeader = styled.th`
  text-align: left;
  background: var(--furtherFadedBg);
  height: 40px;
  padding-left: 20px;
  padding-top: 7px;
  border-bottom: 1px solid
    var(--eds_ui_background__medium, rgba(220, 220, 220, 1));
`;

function RadioMatrix({
  xAxis,
  yAxis,
  data,
}: {
  xAxis: Axis;
  yAxis: AxisWithGroup;
  data: RadioMatrixData;
}) {
  const [active, setActive] = useState({} as RadioMatrixDataItem);
  let prevGroup = "";
  const { register } = useFormContext();
  return (
    <>
      <div style={{ overflow: "auto" }}>
        <TableContainer>
          <Typography variant="overline" as="div" style={{ fontSize: "12px" }}>
            <EdsProvider density="compact">
              <RotatedHeaderTable>
                <THead>
                  <tr>
                    <XHeader></XHeader>
                    {xAxis.map((x) => (
                      <XHeader
                        className={active.x === x.id ? "active" : ""}
                        key={x.id}
                      >
                        <XHeaderContainer>
                          <XHeaderContent>{x.title}</XHeaderContent>
                        </XHeaderContainer>
                      </XHeader>
                    ))}
                  </tr>
                </THead>
                <Table.Body>
                  {yAxis.map((y) => {
                    const row = (
                      <React.Fragment key={`${y.itemSection}${y.id}`}>
                        {prevGroup !== y.groupTitle && (
                          <tr>
                            <YGroupHeader colSpan={xAxis.length + 1}>
                              {y.groupTitle}
                            </YGroupHeader>
                          </tr>
                        )}
                        <tr>
                          <YHeader
                            className={
                              active.y === y.id &&
                              y.itemSection === active.ySection
                                ? "active"
                                : ""
                            }
                          >
                            {y.title}
                          </YHeader>
                          {xAxis.map((x) => (
                            <Element
                              key={x.id}
                              onMouseEnter={() => {
                                setActive({
                                  x: x.id,
                                  y: y.id,
                                  ySection: y.itemSection,
                                });
                              }}
                              onMouseLeave={() =>
                                setActive({ x: 0, y: 0, ySection: "H" })
                              }
                            >
                              <Radio
                                defaultChecked={
                                  data.filter(
                                    (e) =>
                                      e.x === x.id &&
                                      e.y === y.id &&
                                      e.ySection === y.itemSection
                                  ).length > 0
                                }
                                value={`${x.id}`}
                                {...register(`${y.id}${y.itemSection}`)}
                              />
                            </Element>
                          ))}
                        </tr>
                      </React.Fragment>
                    );
                    prevGroup = y.groupTitle;
                    return row;
                  })}
                </Table.Body>
              </RotatedHeaderTable>
            </EdsProvider>
          </Typography>
        </TableContainer>
      </div>
      <SimpleButtonContainer
        style={{
          position: "sticky",
          bottom: 0,
          background: "var(--bg)",
          justifyContent: "center",
          padding: "10px",
          zIndex: 3,
          borderTop: "1px solid var(--borderDefault)",
        }}
      >
        <Button type="submit">Save</Button>
      </SimpleButtonContainer>
    </>
  );
}

export function VDSSubsegmentGroupsConfig() {
  const { data: configData, status: configStatus } = useCodelist({
    codelist: "vds-subsegment-groups-config",
  });
  const { data: groupData, status: groupStatus } = useCodelist({
    codelist: "vds-subsegment-groups",
  });
  const { data: headerData, status: headerStatus } = useCodelist({
    codelist: "vds-header",
  });
  const { data: designData, status: designStatus } = useCodelist({
    codelist: "vds-design",
  });
  const { data: materialData, status: materialStatus } = useCodelist({
    codelist: "vds-material",
  });
  const { data: miscReqData, status: miscReqStatus } = useCodelist({
    codelist: "vds-misc",
  });

  const combinedStatuses = combineQueryStatuses(
    configStatus,
    groupStatus,
    headerStatus,
    designStatus,
    materialStatus,
    miscReqStatus
  );

  const xAxis = useMemo(
    () =>
      groupData
        ? groupData.map((e) => ({ id: e.GroupID, title: e.Description }))
        : [],
    [groupData]
  );

  const yAxis = useMemo(
    () => [
      ...(headerData
        ? headerData.map((e) => ({
            id: e.ItemID,
            title: e.Description,
            groupTitle: "Header",
            itemSection: "H" as VDSItemSections,
          }))
        : []),
      ...(designData
        ? designData.map((e) => ({
            id: e.ItemID,
            title: e.Description,
            groupTitle: "Design",
            itemSection: "D" as VDSItemSections,
          }))
        : []),
      ...(materialData
        ? materialData.map((e) => ({
            id: e.ItemID,
            title: e.Description,
            groupTitle: "Material",
            itemSection: "M" as VDSItemSections,
          }))
        : []),
      ...(miscReqData
        ? miscReqData.map((e) => ({
            id: e.ItemID,
            title: e.Description,
            groupTitle: "Misc",
            itemSection: "R" as VDSItemSections,
          }))
        : []),
    ],
    [headerData, designData, materialData, miscReqData]
  );

  const data = configData
    ? configData.map((e) => ({
        x: e.GroupID,
        y: e.ItemID,
        ySection: e.ItemSection,
      }))
    : [];

  const methods = useForm();

  const { handleSubmit } = methods;

  const {
    mutate,
    status: mutateStatus,
    error: mutateError,
    reset: mutateReset,
    data: mutateData,
  } = useSaveVDSSubsegmentGroupsConfig();

  const onSubmit = handleSubmit((data) => {
    const submitData = Object.keys(data)
    .map((key) => ({
      GroupID: data[key],
      ItemID: key.substring(0, key.length - 1),
      ItemSection: key.substring(key.length - 1, key.length),
    }));
    mutate({ data: submitData });
  });

  return combinedStatuses === "loading" ? (
    <TotalCenter>
      <Loader />
    </TotalCenter>
  ) : combinedStatuses === "error" ? (
    <TotalCenter>Error</TotalCenter>
  ) : combinedStatuses === "success" ? (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={onSubmit}
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <RadioMatrix xAxis={xAxis} yAxis={yAxis} data={data} />
        </form>
      </FormProvider>
      <StatusModal
        status={mutateStatus}
        error={mutateError}
        onSettledClose={mutateReset}
        successMessage={successMessage(mutateData)}
      />
    </>
  ) : null;
}
