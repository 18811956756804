import { QueryStatus } from "react-query";
import {
  ContentMessage,
  InfolineContainer,
  Loader,
} from "../../../components/Components";

export function EditModalStatusDisplay({
  status,
  loadingString,
}: {
  status: QueryStatus;
  loadingString?: string;
}) {
  return status === "idle" ? (
    <ContentMessage color="danger">Query is idle.</ContentMessage>
  ) : status === "error" ? (
    <ContentMessage color="danger">Error loading data sheet.</ContentMessage>
  ) : status === "loading" ? (
    <Loader label={loadingString ? loadingString : "Loading Data Sheet..."} />
  ) : null;
}

export function Infoline({
  item,
}: {
  item: { RevDate: string; LastUpdate: string; LastUpdateBy: string };
}) {
  return (
    <InfolineContainer>
      <div>Rev. Date: {item.RevDate}</div>
      <div>Last Update: {item.LastUpdate}</div>
      <div>Last Update By: {item.LastUpdateBy}</div>
    </InfolineContainer>
  );
}
