import {
  assignment,
  assignment_user,
  build_wrench,
  category,
  comment,
  craning,
  folder,
  gavel,
  grid_layers,
  grid_on,
  layers,
  library_books,
  pipe_support,
  platform,
  shuffle,
  swap_horizontal_circle,
  tag_relations,
  view_list,
  view_module,
} from "@equinor/eds-icons";
import { useUserConfig } from "UserConfigContext";
import { Access, hasAccess } from "Access";
import { ExamplesMenu } from "features/examples/Examples";
import { ReportsMenu } from "features/reports/ReportsMenu";
import React, { useMemo } from "react";
import { useAppSelector } from "./app/hooks";
import { getPlantURLID } from "./features/plant/PlantSelector";
import { selectMainPlant } from "./features/plant/plantSlice";
import TestpageMenu from "./features/testpages/TestpageMenu";
import { selectUIState } from "./uiSlice";
import {
  MenuWrapper,
  Category,
  MenuItem,
  ExternalLink,
} from "components/MainMenuComponents";

export function MainMenu() {
  const plant = useAppSelector(selectMainPlant);
  const plantURLID = useMemo(() => getPlantURLID(plant), [plant]);
  const plantPrefix = `/plant/${plantURLID}`;
  const { isMenuOpen, showAlpha } = useAppSelector(selectUIState);
  const { caps, userInfo, configParams } = useUserConfig();

  return (
    <MenuWrapper className={isMenuOpen ? "open" : ""}>
      <Access cap="sheets" role="A" module="sheets" hide>
        <Access role="X" hide>
          <Access cap={["sheets_eds", "sheets_vds"]} hide>
            <Category title="TR2000 Data Sheets">
              <MenuItem title="EDS" to="/eds" icon={folder} cap="sheets_eds" />
              <MenuItem
                title="VDS"
                to="/vds"
                icon={folder}
                cap="sheets_vds"
                hideSubmenu={
                  !hasAccess({
                    cap: [
                      "sheets_vds_replace_subsegment",
                      "sheets_vds_update_content",
                    ],
                    caps,
                    userInfo,
                  })
                }
              >
                <MenuItem
                  title="Replace Subsegment"
                  to={"/vds/replace-subsegment"}
                  icon={build_wrench}
                  cap="sheets_vds_replace_subsegment"
                  off
                />
                <MenuItem
                  title="Update Content"
                  to={"/vds/update-content"}
                  icon={build_wrench}
                  cap="sheets_vds_update_content"
                  off
                />
              </MenuItem>
            </Category>
          </Access>
        </Access>
        <Category title="Plant Data Sheets">
          <MenuItem
            title="General"
            to={`${plantPrefix}/general`}
            icon={folder}
            disabled={!plantURLID}
            role="X"
          />
          <MenuItem
            title="PCS"
            to={`${plantPrefix}/pcs`}
            icon={folder}
            disabled={!plantURLID}
          >
            <MenuItem
              title="References"
              to={`${plantPrefix}/pcs/references`}
              icon={shuffle}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Standard Notes"
              to={`${plantPrefix}/pcs/standard-notes`}
              icon={comment}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Pipe Elements"
              to={`${plantPrefix}/pcs/pipe-elements`}
              icon={pipe_support}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Properties"
              to={`${plantPrefix}/pcs/properties`}
              icon={assignment}
              disabled={!plantURLID}
            />
            <MenuItem
              title="Article Stack"
              to={`${plantPrefix}/pcs/article-stack`}
              icon={layers}
              disabled={!plantURLID}
            />
          </MenuItem>
          <MenuItem
            title="SC"
            to={`${plantPrefix}/sc`}
            icon={folder}
            disabled={!plantURLID}
            role="X"
          />
          <MenuItem
            title="VSM"
            to={`${plantPrefix}/vsm`}
            icon={folder}
            disabled={!plantURLID}
            role="X"
          />
          <MenuItem
            title="Issues"
            to={`${plantPrefix}/issues`}
            icon={grid_layers}
            disabled={!plantURLID}
          />
        </Category>
      </Access>
      <Access cap="admin" module="admin" hide>
        <Access cap={["admin", "admin_pcs_notes"]} hide>
          <Access cap={["admin", "sheets"]} hide>
            <Category title="Administration">
              <MenuItem
                title="Users"
                to="/admin/users"
                icon={assignment_user}
                role="X"
              />
              <MenuItem
                title="Plants"
                to="/admin/plants"
                icon={platform}
                role="X"
              />
              <MenuItem
                title="Plant Mapping"
                to="/admin/plant-mapping"
                icon={swap_horizontal_circle}
                role="X"
                cap="admin_plant_mapping"
                andOperator={true}
              />
              <MenuItem
                title="Document Space"
                to="/admin/document-space"
                icon={library_books}
                role="X"
                off
              />
              <MenuItem
                title="PCS Notes – Pipe Section"
                to="/admin/pcs-standard-notes/PCS"
                icon={comment}
                cap="sheets"
                role="A"
              />
              <MenuItem
                title="PCS Notes – Valve Section"
                to="/admin/pcs-standard-notes/VDS"
                icon={comment}
                cap="sheets"
                role="A"
              />
              <MenuItem
                title="Material Groups"
                to="/admin/material-groups"
                icon={category}
                role="X"
              />
              <MenuItem
                title="VDS Subsegment Config"
                to="/admin/vds-subsegment-groups-config"
                icon={grid_on}
                role="X"
              />
              <MenuItem
                title="Code Tables"
                to="/admin/codetables"
                icon={view_module}
                role="X"
              >
                <MenuItem
                  title="Pipe Element Groups"
                  to="/admin/pipe-element-groups"
                  icon={view_module}
                />
                <MenuItem
                  title="Valve Element Groups"
                  to="/admin/valve-element-groups"
                  icon={view_module}
                />
                <MenuItem
                  title="Valve Types"
                  to="/admin/valve-types"
                  icon={view_module}
                />
                <MenuItem
                  title="VDS Design"
                  to="/admin/vds-design"
                  icon={view_module}
                />
                <MenuItem
                  title="VDS Material"
                  to="/admin/vds-material"
                  icon={view_module}
                />
                <MenuItem
                  title="VDS Characteristics"
                  to="/admin/vds-characteristics"
                  icon={view_module}
                />
                <MenuItem
                  title="VDS Miscellaneous"
                  to="/admin/vds-misc"
                  icon={view_module}
                />
                <MenuItem
                  title="VDS Subsegment Groups"
                  to="/admin/vds-subsegment-groups"
                  icon={view_module}
                />
                <MenuItem
                  title="PMG Dimension Standards"
                  to="/admin/pmg-dimension-standards"
                  icon={view_module}
                />
                <MenuItem
                  title="PMG Product Forms"
                  to="/admin/pmg-product-forms"
                  icon={view_module}
                />
                <MenuItem
                  title="PMG PFL2 Mapping"
                  to="/admin/product-form-level2-mapping"
                  icon={view_module}
                />
              </MenuItem>
            </Category>
          </Access>
        </Access>
      </Access>
      <Access role="X" hide>
        <Access cap="sheets" module="sheets" hide>
          <Access cap="pmg" hide>
            <Category title="Pipe Elements">
              <MenuItem
                title="Pipe Material Group"
                to="/pmg/material-groups/"
                icon={view_list}
              />
              <MenuItem
                title="Structured Master Pipe Element"
                to="/pmg"
                icon={view_list}
                off
              />
              <MenuItem
                title="Structured Pipe Element"
                to="/pmg"
                icon={view_list}
                off
              />
              <MenuItem
                title="Pipe Element Article"
                to="/pmg"
                icon={view_list}
                off
              />
              <MenuItem title="Matrices" to="/pmg" icon={grid_on} off />
              <MenuItem title="Article Rule" to="/pmg" icon={gavel} off />
              <MenuItem title="Weight Rule" to="/pmg" icon={craning} off />
              <MenuItem
                title="Subelement Structure"
                to="/pmg"
                icon={tag_relations}
                off
              />
            </Category>
          </Access>
          <Access cap={["vds_subsegment", "vds_textblock"]} hide>
            <Category title="VDS Components">
              <MenuItem
                title="VDS Subsegment"
                to="/vds-subsegments"
                icon={folder}
                cap="vds_subsegment"
              />

              <MenuItem
                title="VDS Text Block"
                to="/vds-textblocks"
                icon={folder}
                cap="vds_textblock"
              />
            </Category>
          </Access>
        </Access>
      </Access>
      <Category title="Other Modules">
        <ExternalLink
          configParamId={1}
          configParams={configParams}
          title="Public Module"
        />
        <Access role="S" hide>
          <ExternalLink
            configParamId={27}
            configParams={configParams}
            title="EDS (Structured)"
            urlPostFix="/SST"
          />
          <ExternalLink
            configParamId={27}
            configParams={configParams}
            title="VSK Web Module"
            urlPostFix="/VSK"
          />
          <ExternalLink
            configParamId={27}
            configParams={configParams}
            title="MDS Web Module"
            urlPostFix="/MDS"
          />
          <ExternalLink
            configParamId={27}
            configParams={configParams}
            title="Bolt Tensions"
            urlPostFix="/bolt_tension"
          />
          <ExternalLink
            configParamId={27}
            configParams={configParams}
            title="Manufacturers"
          />
        </Access>
      </Category>
      <Access cap="reports" module="reports" hide>
        <ReportsMenu />
      </Access>
      {showAlpha && <TestpageMenu />}
      {showAlpha && <ExamplesMenu />}
    </MenuWrapper>
  );
}
