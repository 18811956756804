import { Typography } from "@equinor/eds-core-react";
import useConfirm from "components/confirm/useConfirm";
import { FilterProps, filtersReducer } from "components/table/Filters";
import StatusModal from "components/StatusModal";
import Table, { SmallTableContainer } from "components/table/Table";
import useConfirmPFL2Mapping, {
  PFL2MappingsConfirmationData,
} from "queries/useConfirmPFL2Mapping";
import { useListAdmin } from "queries/useListAdmin";
import useSaveListAdmin from "queries/useSaveListAdmin";
import {
  useMemo,
  useEffect,
  useState,
  useCallback,
  useReducer,
  useRef,
} from "react";
import { FieldValues, useFieldArray, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { filterItemsByName } from "utils/filterItemsByName";
import { filterItemsByProps } from "utils/filterItemsByProps";
import { successMessage } from "utils/successMessage";
import { closeAdminModal } from "./Admin";
import { ListAdminAreas, listAdminConfig } from "./listAdminConfig";
import { ListControlHeader } from "./ListControlHeader";
import { ListEditModal } from "./ListEditModal";
import {
  MoveDownCommandButton,
  MoveUpCommandButton,
  EditCommandButton,
  RemoveCommandButton,
} from "./ListRowMenu";
import { prepareMaterialGroupsData } from "./material-groups/materialGroupsConfig";
import { preparePFL2MappingData } from "./product-form-level2-mapping/pfl2MappingConfig";
import { prepareVDSSubsegmentGroupsData } from "./vds-subsegment-groups/vdsSubsegmentGroupsConfig";
import { useUserConfig } from "UserConfigContext";

export function ListAdmin<T extends ListAdminAreas>({ area }: { area: T }) {
  const {
    columns,
    itemIdProp,
    title,
    nameProperty,
    submitProp,
    singularTitle,
    disableDelete,
    confirmPFL2,
    readonly,
    ...rest
  } = listAdminConfig[area];

  const { data, status, error, failureCount, refetch, isRefetching } =
    useListAdmin({ area });

  const { search } = useLocation();

  const { reset, control, handleSubmit } = useForm();

  const { fields, swap, update, insert, move } = useFieldArray({
    control,
    name: submitProp,
  });

  const { caps } = useUserConfig();
  const isReadonly = !!readonly && readonly(caps);

  const resetToData = useCallback(() => {
    reset({ [submitProp]: data });
  }, [reset, data, submitProp]);

  useEffect(() => {
    resetToData();
  }, [resetToData, isRefetching]);

  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const createNew = useMemo(
    () => parsedSearch.get("createNew"),
    [parsedSearch]
  );
  const edit = useMemo(() => parsedSearch.get("edit"), [parsedSearch]);

  const {
    mutate: saveAdmin,
    status: saveAdminStatus,
    error: saveAdminError,
    data: saveAdminData,
    reset: saveAdminReset,
  } = useSaveListAdmin();

  const {
    mutate: confirmPFL2Mapping,
    status: confirmPFL2MappingStatus,
    error: confirmPFL2MappingError,
    data: confirmPFL2MappingData,
    reset: confirmPFL2MappingReset,
  } = useConfirmPFL2Mapping();

  const { isConfirmed } = useConfirm();

  const prepareData = useCallback(
    (
      area: ListAdminAreas,
      submitData: FieldValues,
      confirmElementGroup?: number
    ) => {
      return area === "material-groups"
        ? prepareMaterialGroupsData(submitData[submitProp])
        : area === "product-form-level2-mapping"
        ? preparePFL2MappingData(submitData[submitProp], confirmElementGroup)
        : area === "vds-subsegment-groups"
        ? prepareVDSSubsegmentGroupsData(submitData[submitProp])
        : submitData;
    },
    [submitProp]
  );

  const currentGroupId = useRef<number>(0);

  const submit = async () => {
    !isReadonly &&
      handleSubmit(async (submitData) => {
        const preparedData = prepareData(
          area,
          submitData,
          currentGroupId.current
        );
        if (confirmPFL2) {
          confirmPFL2Mapping({
            data: preparedData as PFL2MappingsConfirmationData,
          });
        } else {
          saveAdmin({ area, data: preparedData });
        }
      })();
  };

  useEffect(() => {
    const confirmAndMaybeSend = async () => {
      const needsConfirmationData =
        confirmPFL2MappingData?.data.getConfirmPFL2Mapping;
      if (needsConfirmationData && needsConfirmationData.length > 0) {
        const confirmed = await isConfirmed(
          <>
            <Typography variant="h5" style={{ marginBottom: "1em" }}>
              Confirm changes
            </Typography>
            <Typography style={{ marginBottom: "1em" }}>
              Changes in the mapping table will cause the following components
              in PMG to derive a new Product Form Level 2 value:
            </Typography>
            <SmallTableContainer style={{ minWidth: 680, minHeight: 200 }}>
              <Table
                items={needsConfirmationData}
                columns={[
                  { key: "ElementGroup", title: "Element Group" },
                  { key: "DimensionStandard", title: "Dimension Standard" },
                  { key: "ProductForm", title: "Product Form" },
                  { key: "MaterialGrade", title: "Material Grade" },
                  {
                    key: "ProductFormLevel2",
                    title: "New Product Form Level 2",
                  },
                ]}
                itemIdProp=""
                density="compact"
              />
            </SmallTableContainer>
          </>
        );
        if (confirmed) {
          saveAdmin({
            area,
            data: prepareData(
              area,
              prepareData(area, { [submitProp]: fields })
            ),
          });
        } else {
          resetToData();
        }
      } else {
        saveAdmin({ area, data: prepareData(area, { [submitProp]: fields }) });
      }
    };
    if (confirmPFL2MappingStatus === "success") {
      confirmAndMaybeSend();
    }
  }, [
    confirmPFL2MappingStatus,
    confirmPFL2MappingData,
    isConfirmed,
    saveAdmin,
    area,
    prepareData,
    fields,
    submitProp,
    resetToData,
  ]);

  const history = useHistory();
  const closeModal = useCallback(() => {
    closeAdminModal({ history });
  }, [history]);

  const closeModalIfAfterCreate = useCallback(() => {
    edit === null && closeModal();
  }, [closeModal, edit]);

  const [nameFilter, setNameFilter] = useState("");

  const [filters, filtersDispatch] = useReducer(
    filtersReducer,
    [] as FilterProps[]
  );

  return (
    <>
      <Table
        columns={columns}
        itemIdProp={itemIdProp}
        items={
          data &&
          filterItemsByProps({
            items: filterItemsByName({
              items: data,
              nameFilter,
              nameProperty: nameProperty as string | string[],
            }),
            filters,
          })
        }
        selectionMode={false}
        status={status}
        fillRest={true}
        loadingString={`${failureCount > 0 ? "Still " : ""}Loading ${title}...`}
        resizable={true}
        error={error}
        refetch={refetch}
        Commands={
          !isReadonly && [
            EditCommandButton,
            MoveUpCommandButton,
            MoveDownCommandButton,
            RemoveCommandButton,
          ]
        }
        contextData={{
          area,
          swap,
          submit,
          update,
          length: fields.length,
          nameFilter,
          disableDelete,
          currentGroupId,
        }}
        infoline={true}
        totalCount={data && data.length}
        controlHeader={
          <ListControlHeader
            area={area}
            data={data}
            refetch={refetch}
            isRefetching={isRefetching}
            nameFilter={nameFilter}
            setNameFilter={setNameFilter}
            filters={filters}
            filtersDispatch={filtersDispatch}
          />
        }
        {...rest}
      />
      {status === "success" && !isReadonly && (
        <ListEditModal
          edit={edit}
          createNew={!(createNew === null)}
          area={area}
          data={data}
          status={status}
          update={update}
          insert={insert}
          submit={submit}
          move={move}
          isRefetching={isRefetching}
          saveAdminStatus={saveAdminStatus}
          confirmPFL2MappingStatus={confirmPFL2MappingStatus}
          currentGroupId={currentGroupId}
        />
      )}
      <StatusModal
        status={saveAdminStatus}
        error={saveAdminError}
        successMessage={successMessage(saveAdminData)}
        onSettledClose={saveAdminReset}
        onError={resetToData}
        onSuccess={closeModalIfAfterCreate}
      />
      <StatusModal
        status={confirmPFL2MappingStatus}
        error={confirmPFL2MappingError}
        onSuccess={confirmPFL2MappingReset}
        onError={resetToData}
        onErrorClose={confirmPFL2MappingReset}
      />
    </>
  );
}
