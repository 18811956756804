import { sheetProperties } from "./../features/sheets/config/sheetConfig";
import { useMutation, useQueryClient } from "react-query";
import { PlantProps } from "../features/plant/types";
import {
  plantDependentSheets,
  SheetTypes,
} from "../features/sheets/config/sheetConfig";
import { getSheetApiUrl } from "./queryUtil";
import axios from "axios";
import { getSheetNameByType } from "features/sheets/util";

export default function useDeleteSheets() {
  const queryClient = useQueryClient();

  async function deleteSheets({
    plant,
    sheetType,
    sheets,
  }: {
    plant: PlantProps;
    sheetType: SheetTypes;
    sheets: any[];
  }) {
    const { sheetListProperty } = sheetProperties[sheetType];
    return await axios({
      method: "delete",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      url: getSheetApiUrl({ plant: plant, sheetType: sheetType }),
      data: { [sheetListProperty ? sheetListProperty : "sheets"]: sheets },
    });
  }

  return useMutation(deleteSheets, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheets",
        ...(plantDependentSheets.includes(variables.sheetType)
          ? [variables.plant.PlantID]
          : []),
        variables.sheetType,
      ]);
      variables.sheets.forEach((sheet) => {
        queryClient.removeQueries([
          "sheet",
          ...(plantDependentSheets.includes(variables.sheetType)
            ? [variables.plant.PlantID]
            : []),
          variables.sheetType,
          getSheetNameByType(sheet, variables.sheetType),
          sheet.Revision,
        ]);
      });
    },
    onError: (error, variables, context) => {
      console.error(`error in ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
