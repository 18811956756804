import axios from "axios";
import { useQueries } from "react-query";
import { getApiUrl } from "../queryUtil";

type SettingsReply = { getUserSettings: { Key: string; Value: string }[] };

export function useSomeSettings({ keys }: { keys: string[] }) {
  const fetchPost = async (key: string) => {
    const { data } = await axios.get<SettingsReply>(
      getApiUrl(`users/settings/key/${key}`)
    );
    return data.getUserSettings[0].Value;
  };

  return useQueries(
    keys.map((key) => ({
      queryKey: ["settings", key],
      queryFn: () => fetchPost(key),
      staleTime: Infinity,
    }))
  );
}

export type UseSomeSettingsResult = ReturnType<typeof useSomeSettings>;
