import config from "app/config";
import axios, { AxiosResponse } from "axios";
import { PFL2Mapping } from "features/admin/product-form-level2-mapping/pfl2MappingConfig";
import { useMutation } from "react-query";

type ConfirmPFL2Mapping = {
  ElementGroupID: number;
  ElementGroup: string;
  DimensionStandard: string;
  ProductForm: string;
  MaterialGrade: string;
  ProductFormLevel2ID: number;
  ProductFormLevel2: string;
};

type ConfirmPFL2Mappings = { getConfirmPFL2Mapping: ConfirmPFL2Mapping[] };

export type PFL2MappingsConfirmationData = {
  pfl2mappings: PFL2Mapping[];
  ElementGroupID: number;
};

export default function useConfirmPFL2Mapping() {
  async function saveAdmin({
    data,
  }: {
    data: PFL2MappingsConfirmationData;
  }): Promise<AxiosResponse<ConfirmPFL2Mappings>> {
    return await axios({
      url: `${config.restApiBaseUrl}codelists/product-form-level2-mapping-confirmation`,
      method: "put",
      data: JSON.stringify(data),
    });
  }
  return useMutation(saveAdmin, {
    onSuccess: (data, variables, context) => {},
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
