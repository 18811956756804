import { useLocation, withRouter } from "react-router-dom";
import AppDisplay from "AppDisplay";
import { AppOut } from "AppOut";
import { GlobalStyle } from "GlobalStyle";
import { UserAccessGate } from "UserConfigContext";
import { StatusBar } from "StatusBar";

function App() {
  const location = useLocation();
  const locationParts = location.pathname.split("/");
  const out = locationParts[1] === "out";

  return (
    <>
      <UserAccessGate>{out ? <AppOut /> : <AppDisplay />}</UserAccessGate>
      <StatusBar out={out} />
      <GlobalStyle />
    </>
  );
}

export default withRouter(App);
