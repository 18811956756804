import { Field } from "components/form/Field";
import { VDSTextBlock } from "features/sheets/types";
import useSheet from "queries/useSheet";
import useVDSTextBlocksDistinctText from "queries/useVDSTextBlocksDistinctText";
import { useEffect, useMemo } from "react";

export function EditVDSTextBlockSectionR({
  data,
  name,
  revision,
  setIsLoaded,
}: {
  data: VDSTextBlock;
  name: string;
  revision: string;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const {
    data: descriptions,
    isLoading: descriptionIsLoading,
    isSuccess: descriptionIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MD",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: vskData,
    isLoading: vskIsLoading,
    isSuccess: vskIsSuccess,
  } = useSheet({
    sheetType: "vds-textblocks",
    name,
    revision,
    postfix: "vsk",
  });

  const vskOptions = useMemo(
    () => (vskData ? vskData.getVDSTextBlockVSK.map((e) => e.VSK) : undefined),
    [vskData]
  );

  useEffect(() => {
    descriptionIsSuccess && vskIsSuccess && setIsLoaded(true);
  }, [descriptionIsSuccess, setIsLoaded, vskIsSuccess]);

  return (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Description"
        prop="MiscDescription"
        value={data.MiscDescription}
        options={descriptions}
        type="autocomplete"
        isLoading={descriptionIsLoading}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        prop="MiscVSK"
        value={data.MiscVSK}
        title="VSK"
        type="option"
        options={vskOptions}
        addNonExisting={true}
        disableNotSetOption={true}
        isLoading={vskIsLoading}
        maxWidth={180}
      />
    </>
  );
}
