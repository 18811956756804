import {
  Autocomplete,
  Checkbox,
  Input,
  Label,
  NativeSelect,
  Radio,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@equinor/eds-core-react";
import {
  ItemAdminAreas,
  ItemAdminSaveProps,
} from "features/admin/itemAdminConfig";
import React, { useContext, useLayoutEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { QueryStatus } from "react-query";
import { PreivewWithInputContainer } from "../../features/sheets/SheetPane";
import { FlexElement, LinearLoader, RevisionMark } from "../Components";
import { FormOptionsContainer, FormRow } from "./Form";
import {
  createId,
  InpCont,
  Note,
  NoteCont,
  RevCont,
  SeriesContainer,
} from "./EditComponents";
import { FieldContext } from "./FieldContext";
import { ListAdminAreas, ListAdminProps } from "features/admin/listAdminConfig";
import { SheetTypeData } from "features/sheets/types";

export type OptionsWithIds = { id: string | number; option: string }[];

export type Features = "sheets" | "admin";

export function Field<
  T extends ItemAdminAreas | ListAdminAreas,
  F extends Features,
  S extends keyof SheetTypeData
>({
  prop,
  title,
  type,
  value,
  options,
  optionsWithIds,
  meta,
  revMark,
  note,
  noteProp,
  noteTitle,
  isLoading,
  isError,
  status,
  maxWidth,
  rows,
  rowsMax,
  addNonExisting,
  nonExistingText,
  afterInputContent,
  style,
  maxLength,
  min,
  max,
  disabled,
  required,
  validate,
  disableNotSetOption,
  notSetValue,
  valueAsNumber,
}: {
  feature?: F;
  sheetType?: S;
  area?: T;
  prop: F extends "sheets"
    ? keyof SheetTypeData[S]
    : T extends ItemAdminAreas
    ? keyof ItemAdminSaveProps<T, "put"> | keyof ItemAdminSaveProps<T, "post">
    : T extends ListAdminAreas
    ? keyof ListAdminProps<T>
    : string | string[];
  title: string;
  type:
    | "text"
    | "option"
    | "series"
    | "matrix"
    | "checkbox"
    | "switch"
    | "radio"
    | "autocomplete";
  value: string | number | string[] | number[];
  options?: string[];
  optionsWithIds?: OptionsWithIds;
  meta?: string;
  revMark?: string;
  note?: string;
  noteProp?: string;
  noteTitle?: string;
  isLoading?: boolean;
  isError?: boolean;
  status?: QueryStatus;
  maxWidth?: number;
  rows?: number;
  rowsMax?: number;
  addNonExisting?: boolean;
  nonExistingText?: string;
  afterInputContent?: React.ReactFragment;
  style?: React.CSSProperties;
  maxLength?: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  required?: boolean | string;
  validate?: { [index: string]: (arg0: string) => boolean | string };
  disableNotSetOption?: boolean;
  notSetValue?: string | number;
  valueAsNumber?: boolean;
}) {
  const { register, formState, setValue, control } = useFormContext();
  required = required === true ? "Cannot be blank." : required;
  const { hasRev, disabled: contextDisabled } = useContext(FieldContext);
  const displayOptions = options
    ? options.map((e) => ({ id: e, option: e }))
    : optionsWithIds
    ? optionsWithIds
    : [];

  // This makes sure that the Field is  updated when the value changes as an extra precaution.
  // The server provided value will be shown in the Field after an update, even if the value differs from the input.
  useLayoutEffect(() => {
    setValue(
      String(prop),
      type === "checkbox" || type === "switch" ? value === "Y" : value
    );
  }, [prop, setValue, value, options, optionsWithIds, type]);

  return (
    <FormRow style={style}>
      {hasRev && (
        <RevCont>
          {typeof revMark !== "undefined" && (
            <RevisionMark>{revMark}</RevisionMark>
          )}
        </RevCont>
      )}
      <InpCont>
        {isLoading || status === "loading" ? (
          <div style={{ minHeight: 52, maxWidth: 300 }}>
            <LinearLoader label="Loading..." />
          </div>
        ) : isError || status === "error" ? (
          <div style={{ minHeight: 52, display: "flex", alignItems: "center" }}>
            <Typography color="danger">Error loading {title}.</Typography>
          </div>
        ) : (
          <>
            <PreivewWithInputContainer>
              {(typeof value === "string" || typeof value === "number") &&
              typeof prop === "string"
                ? (type === "text" && (
                    <div
                      style={{
                        maxWidth: maxWidth ? maxWidth : 500,
                        flexGrow: 1,
                      }}
                    >
                      <Tooltip
                        title={
                          prop && formState.errors && formState.errors[prop]
                            ? String(formState.errors[prop]!.message)
                            : ""
                        }
                      >
                        <TextField
                          label={title}
                          meta={meta}
                          id={createId(title)}
                          defaultValue={value}
                          multiline={!!rows}
                          rows={rows}
                          rowsMax={rowsMax}
                          disabled={contextDisabled || disabled}
                          variant={
                            formState.errors.hasOwnProperty(prop)
                              ? "error"
                              : undefined
                          }
                          {...register(prop, {
                            ...(maxLength
                              ? {
                                  maxLength: {
                                    value: maxLength,
                                    message: `Maximum length is ${maxLength} characters.`,
                                  },
                                  max,
                                  min,
                                }
                              : {}),
                            ...(validate ? { validate } : {}),
                            ...(required ? { required } : {}),
                            ...(valueAsNumber ? { valueAsNumber } : {}),
                          })}
                        />
                      </Tooltip>
                    </div>
                  )) ||
                  (type === "option" && (
                    <div
                      style={{
                        maxWidth: maxWidth ? maxWidth : 300,
                        flexGrow: 1,
                      }}
                    >
                      <Tooltip
                        title={
                          prop && formState.errors && formState.errors[prop]
                            ? String(formState.errors[prop]!.message)
                            : ""
                        }
                      >
                        <NativeSelect
                          defaultValue={String(value)}
                          id={prop}
                          label={title}
                          disabled={contextDisabled || disabled}
                          {...register(prop, {
                            ...(validate ? { validate } : {}),
                            ...(required ? { required } : {}),
                            ...(valueAsNumber ? { valueAsNumber } : {}),
                          })}
                          className={
                            prop && formState.errors && formState.errors[prop]
                              ? "inputError"
                              : ""
                          }
                        >
                          {(options || optionsWithIds) &&
                            !disableNotSetOption && (
                              <option
                                key=""
                                value={notSetValue ? notSetValue : ""}
                              >
                                - Not set -
                              </option>
                            )}
                          {(options || optionsWithIds) &&
                            addNonExisting &&
                            value &&
                            !displayOptions
                              .map((e) => e.id)
                              .includes(value) && (
                              <option key={value} value={value}>
                                {value}
                                {nonExistingText
                                  ? ` (${nonExistingText})`
                                  : " (missing item)"}
                              </option>
                            )}
                          {displayOptions.map((option) => (
                            <option key={option.id} value={String(option.id)}>
                              {option.option}
                            </option>
                          ))}
                        </NativeSelect>
                      </Tooltip>
                    </div>
                  )) ||
                  (type === "checkbox" && (
                    <Checkbox
                      id={prop}
                      label={title}
                      defaultChecked={value === "Y"}
                      disabled={contextDisabled || disabled}
                      {...register(prop, { ...(required ? { required } : {}) })}
                    />
                  )) ||
                  (type === "switch" && (
                    <Switch
                      id={prop}
                      label={title}
                      defaultChecked={value === "Y"}
                      disabled={contextDisabled || disabled}
                      {...register(prop)}
                    />
                  )) ||
                  (type === "radio" && optionsWithIds && (
                    <div>
                      <Label label={title} />
                      <FormOptionsContainer alignLeft>
                        {optionsWithIds.map((optionWithId) => (
                          <FlexElement key={optionWithId.id}>
                            <Radio
                              label={optionWithId.option}
                              defaultChecked={value === optionWithId.id}
                              value={optionWithId.id}
                              disabled={contextDisabled || disabled}
                              {...register(prop)}
                            />
                          </FlexElement>
                        ))}
                      </FormOptionsContainer>
                    </div>
                  )) ||
                  (type === "autocomplete" && (
                    <div
                      style={{
                        maxWidth: maxWidth ? maxWidth : "none",
                        flexGrow: 1,
                      }}
                    >
                      <Controller
                        control={control}
                        name={prop}
                        // @ts-ignore
                        defaultValue={value}
                        rules={{
                          ...(maxLength
                            ? {
                                maxLength: {
                                  value: maxLength,
                                  message: `Maximum length is ${maxLength} characters.`,
                                },
                                max,
                                min,
                              }
                            : {}),
                          ...(validate ? { validate } : {}),
                          ...(required ? { required } : {}),
                          ...(valueAsNumber ? { valueAsNumber } : {}),
                        }}
                        render={({ field: { onChange, onBlur, value } }) => {
                          return (
                            <Tooltip
                              title={
                                prop &&
                                formState.errors &&
                                formState.errors[prop]
                                  ? String(formState.errors[prop]!.message)
                                  : ""
                              }
                            >
                              <Autocomplete
                                onBlur={onBlur}
                                onOptionsChange={(e) => {
                                  onChange(e.selectedItems[0]);
                                }}
                                onInput={(
                                  e: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  onChange(e.target.value);
                                }}
                                selectedOptions={[value]}
                                label={title}
                                options={options ? options : []}
                                hideClearButton={true}
                                // @ts-ignore
                                multiline
                                style={{ flexGrow: 1 }}
                                disabled={contextDisabled || disabled}
                                className={
                                  prop &&
                                  formState.errors &&
                                  formState.errors[prop]
                                    ? "inputError"
                                    : ""
                                }
                                autoWidth={true}
                                clearSearchOnChange={false}
                              />
                            </Tooltip>
                          );
                        }}
                      />
                    </div>
                  ))
                : type === "series" &&
                  Array.isArray(value) &&
                  Array.isArray(prop) && (
                    <div
                      style={{
                        maxWidth: maxWidth ? maxWidth : "100%",
                        flexGrow: 1,
                      }}
                    >
                      <Label label={title} />
                      <SeriesContainer>
                        {prop.map((p, i) => {
                          return (
                            <FlexElement key={p}>
                              <Input
                                defaultValue={value[i]}
                                disabled={contextDisabled || disabled}
                                {...register(p, {
                                  ...(required ? { required } : {}),
                                  ...(validate ? { validate } : {}),
                                  ...(valueAsNumber ? { valueAsNumber } : {}),
                                })}
                              />
                            </FlexElement>
                          );
                        })}
                      </SeriesContainer>
                    </div>
                  )}
              {afterInputContent}
            </PreivewWithInputContainer>
          </>
        )}
      </InpCont>
      {typeof note !== "undefined" && typeof prop === "string" && (
        <NoteCont>
          <Note
            noteProp={noteProp ? noteProp : "NoteID" + prop}
            title={noteTitle ? noteTitle : title + " Note"}
            note={note}
          />
        </NoteCont>
      )}
    </FormRow>
  );
}
