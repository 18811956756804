import { useItemAdmin } from "queries/useItemAdmin";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import { FilterProps, filtersReducer } from "../../components/table/Filters";
import { filterItemsByName } from "../../utils/filterItemsByName";
import { filterItemsByProps } from "../../utils/filterItemsByProps";
import Table from "../../components/table/Table";
import {
  ItemAdminAreas,
  itemAdminConfig,
  ItemAdminSections,
} from "./itemAdminConfig";
import { ItemControlHeader } from "./ItemControlHeader";
import { ItemEditModal } from "./ItemEditModal";
import { InUseContext, useInUse } from "./pcs-standard-notes/InUse";
import { ItemRowMenu } from "./ItemRowMenu";

export function ItemAdmin<T extends ItemAdminAreas>({
  area,
  section,
}: {
  area: T;
  section?: ItemAdminSections<T>[number];
}) {
  const { columns, itemIdProp, title, nameProperty, ...rest } =
    itemAdminConfig[area];

  const [subArea, setSubArea] = useState("");

  useEffect(() => setSubArea(""), [area]);

  const { data, status, error, failureCount, refetch, isRefetching } =
    useItemAdmin({ area, section, subArea });

  const { search } = useLocation();

  const parsedSearch = useMemo(() => new URLSearchParams(search), [search]);
  const createNew = useMemo(
    () => parsedSearch.get("createNew"),
    [parsedSearch]
  );
  const edit = useMemo(() => parsedSearch.get("edit"), [parsedSearch]);
  const [nameFilter, setNameFilter] = useState("");
  const [filters, filtersDispatch] = useReducer(
    filtersReducer,
    [] as FilterProps[]
  );
  const { inUseModalContent, showInUseModal, setShowInUseModal } = useInUse();

  return (
    <>
      <InUseContext.Provider value={{ showInUseModal, setShowInUseModal }}>
        <Table
          columns={columns}
          itemIdProp={itemIdProp}
          items={
            data &&
            filterItemsByProps({
              items: filterItemsByName({
                nameFilter,
                nameProperty: nameProperty as string | string[],
                items: data,
                matchAnywhere: true,
              }),
              filters,
            })
          }
          totalCount={data && data.length}
          infoline={true}
          selectionMode={false}
          status={status}
          loadingString={`${
            failureCount > 0 ? "Still " : ""
          }Loading ${title}...`}
          sortable={true}
          resizable={true}
          fillRest={true}
          controlHeader={
            <ItemControlHeader
              area={area}
              section={section}
              nameFilter={nameFilter}
              setNameFilter={setNameFilter}
              data={data}
              filters={filters}
              filtersDispatch={filtersDispatch}
            />
          }
          RowMenu={ItemRowMenu}
          error={error}
          refetch={refetch}
          contextData={{
            area,
            disableDelete: itemAdminConfig[area].disableDelete,
          }}
          {...rest}
        />
        <ItemEditModal
          edit={edit}
          method={createNew === "" ? "post" : edit ? "put" : false}
          area={area}
          data={data}
          isRefetching={isRefetching}
          section={section}
        />
        {inUseModalContent}
      </InUseContext.Provider>
    </>
  );
}
