import { Field } from "components/form/Field";
import { VDSTextBlock } from "features/sheets/types";
import useSheet from "queries/useSheet";
import useVDSTextBlocksDistinctText from "queries/useVDSTextBlocksDistinctText";
import { useEffect, useMemo } from "react";

export function EditVDSTextBlockSectionM({
  data,
  name,
  revision,
  setIsLoaded,
}: {
  data: VDSTextBlock;
  name: string;
  revision: string;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const {
    data: remarks,
    isLoading: remarkIsLoading,
    isSuccess: remarkIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MR",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: subItems,
    isLoading: subItemIsLoading,
    isSuccess: subItemIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MS",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: materialNames,
    isLoading: materialNameIsLoading,
    isSuccess: materialNameIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "MN",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: mdsData,
    isLoading: mdsIsLoading,
    isSuccess: mdsIsSuccess,
  } = useSheet({
    sheetType: "vds-textblocks",
    name,
    revision,
    postfix: "mds",
  });

  const mdsOptions = useMemo(
    () => (mdsData ? mdsData.getVDSTextBlockMDS.map((e) => e.MDS) : undefined),
    [mdsData]
  );

  useEffect(() => {
    remarkIsSuccess &&
      subItemIsSuccess &&
      materialNameIsSuccess &&
      mdsIsSuccess &&
      setIsLoaded(true);
  }, [
    materialNameIsSuccess,
    mdsIsSuccess,
    remarkIsSuccess,
    setIsLoaded,
    subItemIsSuccess,
  ]);

  return (
    <>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Sub Item"
        prop="MaterialSubItem"
        value={data.MaterialSubItem}
        options={subItems}
        type="autocomplete"
        isLoading={subItemIsLoading}
        maxWidth={240}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Material"
        prop="MaterialName"
        value={data.MaterialName}
        options={materialNames}
        type="autocomplete"
        isLoading={materialNameIsLoading}
        maxWidth={240}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        prop="MaterialMDS"
        value={data.MaterialMDS}
        title="MDS"
        type="option"
        options={mdsOptions}
        addNonExisting={true}
        disableNotSetOption={true}
        isLoading={mdsIsLoading}
        maxWidth={180}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Description"
        prop="MaterialRemark"
        value={data.MaterialRemark}
        options={remarks}
        type="autocomplete"
        isLoading={remarkIsLoading}
      />
    </>
  );
}
