import { FlexContainer, FlexElement } from "components/Components";
import { Field } from "components/form/Field";
import { FormRow } from "components/form/Form";
import { VDSTextBlock } from "features/sheets/types";
import useVDSTextBlocksDistinctText from "queries/useVDSTextBlocksDistinctText";
import { useEffect } from "react";

export function EditVDSTextBlockSectionH3({
  data,
  setIsLoaded,
}: {
  data: VDSTextBlock;
  setIsLoaded: React.Dispatch<boolean>;
}) {
  const {
    data: sizeRangeFrom,
    isLoading: sizeRangeFromIsLoading,
    isSuccess: sizeRangeFromIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "SF",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: sizeRangeTo,
    isLoading: sizeRangeToIsLoading,
    isSuccess: sizeRangeToIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "ST",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: sizeComment,
    isLoading: sizeCommentIsLoading,
    isSuccess: sizeCommentIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "SC",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  const {
    data: unit,
    isLoading: unitIsLoading,
    isSuccess: unitIsSuccess,
  } = useVDSTextBlocksDistinctText({
    attributeName: "UN",
    sectionId: data.ItemSection,
    itemId: data.ItemID,
  });

  useEffect(() => {
    sizeRangeFromIsSuccess &&
      sizeRangeToIsSuccess &&
      sizeCommentIsSuccess &&
      unitIsSuccess &&
      setIsLoaded(true);
  }, [
    setIsLoaded,
    sizeRangeFromIsSuccess,
    sizeRangeToIsSuccess,
    sizeCommentIsSuccess,
    unitIsSuccess,
  ]);

  return (
    <>
      <FormRow>
        <FlexContainer style={{ justifyContent: "flex-start", columnGap: 32 }}>
          <FlexElement>
            <Field
              style={{ margin: 0 }}
              feature="sheets"
              sheetType="vds-textblocks"
              title="Size From"
              prop="SizeRangeFrom"
              value={data.SizeRangeFrom}
              options={sizeRangeFrom}
              type="autocomplete"
              isLoading={sizeRangeFromIsLoading}
              maxWidth={120}
            />
          </FlexElement>
          <FlexElement>
            <Field
              style={{ margin: 0 }}
              feature="sheets"
              sheetType="vds-textblocks"
              title="Size To"
              prop="SizeRangeTo"
              value={data.SizeRangeTo}
              options={sizeRangeTo}
              type="autocomplete"
              isLoading={sizeRangeToIsLoading}
              maxWidth={120}
            />
          </FlexElement>
        </FlexContainer>
      </FormRow>
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Unit"
        prop="Unit"
        value={data.Unit}
        options={unit}
        type="autocomplete"
        isLoading={unitIsLoading}
        maxWidth={120}
        maxLength={2}
      />
      <Field
        feature="sheets"
        sheetType="vds-textblocks"
        title="Comment"
        prop="SizeRangeComment"
        value={data.SizeRangeComment}
        options={sizeComment}
        type="autocomplete"
        isLoading={sizeCommentIsLoading}
      />
    </>
  );
}
