import { ItemAdminConfig, CommonUpdateProps } from "../itemAdminConfig";

export type PipeElementGroups = {
  ElementGroupID: number;
  ElementGroup: string;
  MatrixTypeID: number;
};

export type PipeElementGroupsCreate = {
  ElementGroupID: string;
  ElementGroup: string;
  MatrixTypeID: string;
};

export type PipeElementGroupsUpdate = CommonUpdateProps & {
  ElementGroup: string;
  MatrixTypeID: string;
};

export const pipeElementGroupsConfig: ItemAdminConfig<"pipe-element-groups"> = {
  title: "Pipe Element Groups",
  singularTitle: "Pipe Element Group",
  url: "codelists/pipe-element-groups",
  itemIdProp: "ElementGroupID",
  apiListProp: "getPipeElementGroups",
  nameProperty: ["ElementGroupID", "ElementGroup"],
  columns: [
    {
      key: "ElementGroupID",
      title: "ID",
    },
    { key: "ElementGroup", title: "Pipe Element Group", width: "60%" },
    {
      key: "MatrixTypeID",
      title: "Matrix Type",
      codelist: "matrix-types",
      width: "40%",
    },
  ],
  codelistsToQuery: ["matrix-types"],
};
