import { useEffect, useState } from "react";
import { getPreviewUrl, getSheetName } from "./util";
import { successMessage } from "../../utils/successMessage";
import styled from "styled-components";
import {
  nameProperties,
  plantDependentSheets,
  sheetTypeNames,
  SheetTypes,
} from "./config/sheetConfig";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { getSheetState, setAutoValidation } from "./sheetSlice";
import { PaneLocation, selectUIState, setPaneMode } from "uiSlice";
import { CloseButton, Fade } from "../../components/Components";
import {
  Button,
  Typography,
  Icon,
  IconProps,
  Switch,
  CircularProgress,
} from "@equinor/eds-core-react";
import {
  visibility,
  external_link,
  check_circle_outlined,
} from "@equinor/eds-icons";
import { previewInNewWindow, previewInPane } from "./commands";
import { PlantProps } from "features/plant/types";
import useValidatePCS from "queries/useValidatePCS";
import StatusModal from "components/StatusModal";
import { ValidationLog } from "./ValidationLog";
import { useMemoOne as useMemo } from "use-memo-one";
import useSheet from "queries/useSheet";
import { unsetPlantState } from "../plant/plantSlice";
import { useUserConfig } from "UserConfigContext";

const PaneContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MaxIframe = styled.iframe`
  width: calc(100% - 1px);
  flex-grow: 2;
  border: none;
  background: white;
`;

const PaneHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 11px 10px 5px;
  align-items: center;
`;

const PaneTitle = styled(Typography)`
  padding: 2px 6px;
`;

const ButtonContainer = styled.div`
  text-align: right;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export function reloadPreviewPane(location: PaneLocation) {
  const previewPane = document.getElementById(
    "PreviewPane_" + location
  ) as HTMLIFrameElement;
  if (previewPane) {
    previewPane.src += "";
  }
}

export function SheetPane({ location }: { location: PaneLocation }) {
  const { autoValidation } = useAppSelector(getSheetState);
  const { paneSheets, paneModes } = useAppSelector(selectUIState);
  const paneSheet = useMemo(() => paneSheets[location], [paneSheets, location]);
  const paneMode = paneModes[location];
  const { sheetType, revision, name, format, plant } = paneSheet;
  const { data: sheetData } = useSheet({
    plant: plant ? plant : unsetPlantState,
    sheetType,
    name,
    revision,
  });
  const dispatch = useAppDispatch();
  const { previewBaseUrl } = useUserConfig();
  const url = getPreviewUrl({
    item: {
      [nameProperties[sheetType]]: name,
      Revision: revision,
      Format: format,
    },
    sheetType: sheetType,
    previewBaseUrl,
    plant: plant,
  });

  const [resetIframe, setResetIframe] = useState(false);
  useEffect(() => {
    setResetIframe(true);
  }, [paneSheet]);
  useEffect(() => {
    resetIframe && setTimeout(() => setResetIframe(false));
  }, [resetIframe]);

  const {
    mutate: validatePCS,
    status: validatePCSStatus,
    error: validatePCSError,
    reset: validatePCSReset,
    data: validatePCSData,
  } = useValidatePCS();

  const { isRefetching } = useSheet({
    prefix: "sheet-validation",
    plant: plant ? plant : unsetPlantState,
    sheetType: "pcs",
    name,
    revision,
    postfix: "validation-logs/details",
  });

  return (
    <PaneContainer>
      <PaneHeaderContainer
        style={{
          minHeight:
            paneMode === "validation-log" || location === "main" ? 48 : 0,
        }}
      >
        {name && revision && (
          <PaneTitle
            variant="h4"
            style={{
              paddingTop: 0,
            }}
          >
            {paneMode === "preview" ? (
              <Fade>Preview of </Fade>
            ) : (
              paneMode === "validation-log" && <Fade>Validation log for </Fade>
            )}
            {revision ? (
              <>
                {sheetTypeNames[sheetType]}{" "}
                {getSheetName(
                  {
                    [nameProperties[sheetType]]: name,
                    Revision: revision,
                  },
                  sheetType
                )}
                {plantDependentSheets.includes(sheetType) && plant && (
                  <>
                    <Fade> in </Fade>
                    {plant.LongDescription}
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </PaneTitle>
        )}
        {paneMode === "validation-log" && (
          <div style={{ margin: "-8px 12px 0" }}>
            <CircularProgress
              size={16}
              style={{
                visibility: isRefetching ? "visible" : "hidden",
                verticalAlign: "middle",
              }}
            />
          </div>
        )}
        <ButtonContainer
          style={{ alignSelf: "flex-start", marginLeft: "auto", gap: 8 }}
        >
          {name &&
          revision &&
          sheetData &&
          sheetData.Status === "W" &&
          plant &&
          plant.PlantID &&
          paneMode === "validation-log" ? (
            <>
              <Button
                style={{ minWidth: 130 }}
                variant="outlined"
                onClick={() => {
                  validatePCS({
                    plant,
                    name,
                    revision,
                  });
                }}
              >
                Start validation
              </Button>
              {location === "editModal" && (
                <div title="Automatically start validation when the sheet is updated.">
                  <Switch
                    label="Autostart"
                    checked={autoValidation}
                    onChange={() =>
                      dispatch(setAutoValidation(!autoValidation))
                    }
                  />
                </div>
              )}
            </>
          ) : (
            <></>
          )}
          {sheetType === "pcs" && (
            <div>
              {paneMode === "preview" ? (
                <Button
                  variant="ghost_icon"
                  onClick={() =>
                    dispatch(setPaneMode({ location, mode: "validation-log" }))
                  }
                  title="Switch to Validation log"
                >
                  <Icon data={check_circle_outlined} />
                </Button>
              ) : paneMode === "validation-log" ? (
                <Button
                  variant="ghost_icon"
                  onClick={() =>
                    dispatch(setPaneMode({ location, mode: "preview" }))
                  }
                  title="Switch to Preview"
                >
                  <Icon data={visibility} />
                </Button>
              ) : (
                <></>
              )}
            </div>
          )}
          <div>
            <CloseButton
              size={location === "main" ? 24 : 18}
              closeFn={() =>
                dispatch(dispatch(setPaneMode({ location, mode: false })))
              }
            />
          </div>
        </ButtonContainer>
      </PaneHeaderContainer>
      {paneMode === "preview" ? (
        resetIframe ? (
          <></>
        ) : (
          <MaxIframe
            style={{
              borderTop: "1px solid var(--borderDefault)",
              borderLeft: "1px solid var(--borderDefault)",
            }}
            id={"PreviewPane_" + location}
            src={url}
            title="Data sheet"
          ></MaxIframe>
        )
      ) : paneMode === "validation-log" && plant ? (
        <ValidationLog
          name={name}
          revision={revision}
          plant={plant}
          paneLocation={location}
        />
      ) : (
        <>Pane disabled.</>
      )}
      <StatusModal
        status={validatePCSStatus}
        error={validatePCSError}
        onSettledClose={validatePCSReset}
        successMessage={successMessage(validatePCSData)}
      />
    </PaneContainer>
  );
}

export const PreivewWithInputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: flex-end;
`;

export const PreviewButtonsContainer = styled.div`
  margin-bottom: -2px;
  margin-top: -2px;
`;

export function PreviewButtons({
  plant,
  sheetType,
  item,
  previewLocation,
  size,
}: {
  sheetType?: SheetTypes;
  plant?: PlantProps;
  item?: any;
  previewLocation?: PaneLocation;
  size?: IconProps["size"];
}) {
  const dispatch = useAppDispatch();
  const { previewBaseUrl } = useUserConfig();

  return !sheetType ? (
    <></>
  ) : (
    <>
      {previewLocation && (
        <Button
          variant="ghost_icon"
          disabled={!item}
          onClick={() => {
            previewInPane({
              location: previewLocation,
              item,
              sheetType,
              plant,
              dispatch,
            });
          }}
          title="View in pane"
        >
          <Icon data={visibility} size={size ? size : 18} />
        </Button>
      )}
      <Button
        variant="ghost_icon"
        disabled={!item}
        onClick={() =>
          previewInNewWindow({ item, sheetType, previewBaseUrl, plant })
        }
        title="View in new window"
      >
        <Icon data={external_link} size={size ? size : 18} />
      </Button>
    </>
  );
}
