import React, { useEffect } from "react";
import { Button, NativeSelect, Typography } from "@equinor/eds-core-react";
import { PCSReferenceSheetTypes } from "../config/sheetConfig";
import usePipeDimensions from "../hooks/usePipeDimensions";
import { stripSheetArrayToIDs, addIndefiniteArticleToSheetType } from "../util";
import { successMessage } from "../../../utils/successMessage";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectMainPlant } from "../../plant/plantSlice";
import { useForm, SubmitHandler } from "react-hook-form";
import useSheets from "../../../queries/useSheets";
import { sheetTypeNames, nameProperties } from "../config/sheetConfig";
import {
  LinearLoader,
  TopPadder,
  Tabs,
  FlexContainer,
  FlexElement,
} from "../../../components/Components";
import useVDSSelector from "../hooks/useVDSSelector";
import {
  PanelContainer,
  PanelButtonContainer,
  PanelParagraph,
} from "../../../components/Panels";
import {
  PreivewWithInputContainer,
  PreviewButtons,
  PreviewButtonsContainer,
  reloadPreviewPane,
} from "../SheetPane";
import StatusModal from "../../../components/StatusModal";
import useChangePCSReference from "../../../queries/useChangePCSReference";
import { useTabs } from "../../../components/tabs/useTabs";
import usePipeElements from "../hooks/usePipeElements";
import { setPipeElementFilter } from "../sheetSlice";
import usePCSCommandQuery from "../../../queries/usePCSCommandQuery";
import { pcsCommandOnSelected } from "../commands";
import { useUserConfig } from "UserConfigContext";

function ChangeReference({
  referenceSheetType,
  selectedItems,
}: {
  referenceSheetType: PCSReferenceSheetTypes;
  selectedItems: any[];
}) {
  const mainPlant = useAppSelector(selectMainPlant);

  const name = sheetTypeNames[referenceSheetType];
  const nameProperty = nameProperties[referenceSheetType];

  const { data, status } = useSheets({
    plant: mainPlant,
    sheetType: referenceSheetType,
    extraParams: { statuses: ["O"] },
    disabled: referenceSheetType === "vds",
  });

  const {
    mutate: changePCSReference,
    status: changePCSReferenceStatus,
    reset: changePCSReferenceReset,
    error: changePCSReferenceError,
    data: changePCSReferenceData,
  } = useChangePCSReference();

  const { register, handleSubmit, watch, formState, setValue } = useForm({
    shouldUnregister: true,
    mode: "all",
  });

  const { selection, selectionDispatch, vdsSelectorContent } = useVDSSelector({
    enabled: referenceSheetType === "vds",
    extendedDisplay: true,
    previewLocation: "main",
  });

  const referenceWatch = watch("referenceSheet");

  const { pipeDimensionsContent, fromSize, toSize } = usePipeDimensions({
    formState,
    register,
    referenceSheetType,
  });

  const onSubmit: SubmitHandler<any> = (data) => {
    changePCSReference({
      plant: mainPlant,
      referenceSheetType,
      referenceSheet:
        referenceSheetType === "vds"
          ? selection[0]
          : data.referenceSheet === "command|remove"
          ? ""
          : data.referenceSheet,
      sheets: stripSheetArrayToIDs(selectedItems, "pcs"),
      fromSize,
      toSize,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PanelContainer>
        {status === "error" ? (
          <Typography color="error">Error loading sheets.</Typography>
        ) : referenceSheetType === "vds" ? (
          vdsSelectorContent
        ) : status === "loading" ? (
          <div style={{ maxWidth: 300 }}>
            <LinearLoader label="Loading Data Sheets..." />
          </div>
        ) : (
          status === "success" && (
            <PreivewWithInputContainer>
              <div>
                <NativeSelect
                  id="referenceSheet"
                  label=""
                  style={{ maxWidth: 300 }}
                  {...register("referenceSheet")}
                >
                  <option value="" key="_default">
                    Select {addIndefiniteArticleToSheetType(name)}...
                  </option>
                  <option value="command|remove" key="_command|remove">
                    - Remove {name} reference -
                  </option>
                  {data.sheets.map((item: any) => {
                    return (
                      <option
                        key={item[nameProperty]}
                        value={item[nameProperty]}
                      >
                        {item[nameProperty]}
                      </option>
                    );
                  })}
                </NativeSelect>
              </div>
              <PreviewButtonsContainer>
                <PreviewButtons
                  sheetType={referenceSheetType}
                  item={data.sheets.find(
                    (e: any) => e[nameProperty] === referenceWatch
                  )}
                  previewLocation="main"
                  plant={mainPlant}
                />
              </PreviewButtonsContainer>
            </PreivewWithInputContainer>
          )
        )}
        {referenceSheetType === "vds" ? (
          <TopPadder>{pipeDimensionsContent}</TopPadder>
        ) : (
          <></>
        )}
        <PanelButtonContainer>
          <Button
            type="submit"
            disabled={
              selectedItems.length === 0 ||
              !(referenceWatch || selection[0]) ||
              !!Object.keys(formState.errors).length
            }
          >
            {referenceSheetType === "vds" ? "Add" : "Change"} {name} Reference
          </Button>
        </PanelButtonContainer>
      </PanelContainer>
      <StatusModal
        status={changePCSReferenceStatus}
        successMessage={successMessage(changePCSReferenceData)}
        onSettledClose={changePCSReferenceReset}
        onSuccess={() => {
          reloadPreviewPane("main");
        }}
        onSuccessClose={() => {
          setValue("referenceSheet", "");
          selectionDispatch({ type: "reset" });
        }}
        error={changePCSReferenceError}
      />
    </form>
  );
}

function ChangeEDS({ selectedItems }: { selectedItems: any }) {
  const dispatch = useAppDispatch();
  const mainPlant = useAppSelector(selectMainPlant);
  const { handleSubmit, formState, register, setValue, watch } = useForm({
    shouldUnregister: true,
    mode: "all",
  });
  const { data, status } = useSheets({
    plant: mainPlant,
    sheetType: "eds",
    extraParams: { statuses: ["O"] },
  });
  const { pipeElementsContent, selection } = usePipeElements({
    existing: true,
  });
  const {
    mutate: changePCSReference,
    status: changePCSReferenceStatus,
    reset: changePCSReferenceReset,
    error: changePCSReferenceError,
    data: changePCSReferenceData,
  } = useChangePCSReference();

  const referenceWatch = watch("referenceEDS");

  const onSubmit: SubmitHandler<any> = (data) => {
    changePCSReference({
      plant: mainPlant,
      referenceSheetType: "eds",
      referenceSheet: data.referenceEDS,
      elementID: selection[0],
      sheets: stripSheetArrayToIDs(selectedItems, "pcs"),
    });
  };

  useEffect(() => {
    dispatch(setPipeElementFilter(selection[0]));
  }, [selection, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPipeElementFilter(0));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PanelContainer>
        <FlexContainer
          style={{ justifyContent: "flex-start", alignItems: "flex-start" }}
        >
          <FlexElement style={{ flexGrow: 1, maxWidth: 1000 }}>
            {pipeElementsContent}
          </FlexElement>
          <FlexElement
            style={{
              flexGrow: 1,
              maxWidth: 400,
              marginLeft: "40px",
            }}
          >
            {status === "error" ? (
              <Typography color="error">Error loading sheets.</Typography>
            ) : status === "loading" ? (
              <div style={{ maxWidth: 300 }}>
                <LinearLoader label="Loading Data Sheets..." />
              </div>
            ) : (
              status === "success" && (
                <PreivewWithInputContainer>
                  <div>
                    <NativeSelect
                      id="referenceEDS"
                      label="EDS to be added to the selected Pipe Element:"
                      style={{ maxWidth: 300 }}
                      {...register("referenceEDS")}
                    >
                      <option value="" key="_default">
                        Select an EDS...
                      </option>
                      {data.sheets.map((item: any) => {
                        return (
                          <option key={item.EDS} value={item.EDS}>
                            {item.EDS}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </div>
                  <PreviewButtonsContainer>
                    <PreviewButtons
                      sheetType="eds"
                      item={data.sheets.find(
                        (e: any) => e.EDS === referenceWatch
                      )}
                      previewLocation="main"
                    />
                  </PreviewButtonsContainer>
                </PreivewWithInputContainer>
              )
            )}
          </FlexElement>
        </FlexContainer>
        <PanelButtonContainer>
          <Button
            type="submit"
            disabled={
              selectedItems.length === 0 ||
              selection.length === 0 ||
              !referenceWatch ||
              !!Object.keys(formState.errors).length
            }
          >
            Change EDS Reference
          </Button>
        </PanelButtonContainer>
      </PanelContainer>
      <StatusModal
        status={changePCSReferenceStatus}
        successMessage={successMessage(changePCSReferenceData)}
        onSettledClose={changePCSReferenceReset}
        onSuccess={() => {
          reloadPreviewPane("main");
        }}
        onSuccessClose={() => {
          setValue("referenceEDS", "");
        }}
        error={changePCSReferenceError}
      />
    </form>
  );
}

function UpdateVDSDescription({ selectedItems }: { selectedItems: any[] }) {
  const mainPlant = useAppSelector(selectMainPlant);
  const {
    mutate: pcsCommand,
    status: pcsCommandStatus,
    reset: pcsCommandReset,
    error: pcsCommandError,
    data: pcsCommandData,
  } = usePCSCommandQuery();

  return (
    <>
      <PanelContainer>
        <PanelParagraph>
          Select sheets to update VDS Description on.
        </PanelParagraph>
        <PanelButtonContainer>
          <Button
            onClick={() =>
              pcsCommandOnSelected({
                selectedItems,
                mainPlant,
                command: "vds-descriptions",
                method: "put",
                pcsCommand,
              })
            }
            disabled={selectedItems.length === 0}
          >
            Update VDS Description
          </Button>
        </PanelButtonContainer>
      </PanelContainer>
      <StatusModal
        status={pcsCommandStatus}
        successMessage={successMessage(pcsCommandData)}
        onSettledClose={pcsCommandReset}
        error={pcsCommandError}
      />
    </>
  );
}

export const ReferencePanel = React.forwardRef(
  (
    {
      selectedItems,
    }: {
      selectedItems: any[];
    },
    ref: React.Ref<any>
  ) => {
    const { activeTab, handleChange } = useTabs({ name: "ReferencePanelTabs" });
    const { caps } = useUserConfig();

    return (
      <div ref={ref}>
        <Tabs activeTab={activeTab} onChange={handleChange} scrollable>
          <Tabs.List>
            <Tabs.Tab>Change SC</Tabs.Tab>
            <Tabs.Tab>Change VSM</Tabs.Tab>
            <Tabs.Tab>Change EDS</Tabs.Tab>
            <Tabs.Tab>Add VDS</Tabs.Tab>
            {caps.includes("sheets_pcs_ref_vds_desc") ? (
              <Tabs.Tab>Update VDS Description</Tabs.Tab>
            ) : (
              <></>
            )}
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              {activeTab === 0 && (
                <ChangeReference
                  referenceSheetType="sc"
                  selectedItems={selectedItems}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 1 && (
                <ChangeReference
                  referenceSheetType="vsm"
                  selectedItems={selectedItems}
                />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 2 && <ChangeEDS selectedItems={selectedItems} />}
            </Tabs.Panel>
            <Tabs.Panel>
              {activeTab === 3 && (
                <ChangeReference
                  referenceSheetType="vds"
                  selectedItems={selectedItems}
                />
              )}
            </Tabs.Panel>
            {caps.includes("sheets_pcs_ref_vds_desc") ? (
              <Tabs.Panel>
                {activeTab === 4 && (
                  <UpdateVDSDescription selectedItems={selectedItems} />
                )}
              </Tabs.Panel>
            ) : (
              <></>
            )}
          </Tabs.Panels>
        </Tabs>
      </div>
    );
  }
);

export default ReferencePanel;
