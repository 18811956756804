import { PlantProps, OperatorProps } from "../features/plant/types";
import config from "../app/config";
import { useQuery } from "react-query";
import axios from "axios";

export function useUserPlantsWithOperators() {
  return useQuery(
    ["codelists", "plants", "user-plants-with-operators"],
    async () => {
      return Promise.all([
        axios.get(`${config.restApiBaseUrl}user-plants`),
        axios.get(`${config.restApiBaseUrl}operators`),
      ]).then(function (results: any[]) {
        return {
          plants: results[0]["data"]["getPlant"] as PlantProps[],
          operators: results[1]["data"]["getOperator"] as OperatorProps[],
        };
      });
    }
  );
}

export type UserPlantsWithOperators = {
  plants: PlantProps[];
  operators: OperatorProps[];
};
