import { useParams } from "react-router-dom";
import MainSheets from "./MainSheets";
import { useAppSelector } from "../../app/hooks";
import { selectMainPlant } from "../plant/plantSlice";
import { Access } from "Access";
import { SheetTypes } from "features/sheets/config/sheetConfig";
import ArticleStack from "./ArticleStack";

export function AccessSheets() {
  const { sheetType } = useParams<{
    sheetType: SheetTypes;
  }>();
  const mainPlant = useAppSelector(selectMainPlant);

  return (
    <Access role="X" enabled={sheetType !== "pcs"}>
      <Access cap="sheets" role="A" module="sheets">
        <Access cap="sheets_eds" enabled={sheetType === "eds"}>
          <Access cap="sheets_vds" enabled={sheetType === "vds"}>
            <Access
              cap="vds_subsegment"
              enabled={sheetType === "vds-subsegments"}
            >
              <Access
                cap="vds_textblock"
                enabled={sheetType === "vds-textblocks"}
              >
                <MainSheets plant={mainPlant} displayType="main" />
              </Access>
            </Access>
          </Access>
        </Access>
      </Access>
    </Access>
  );
}

export function AccessArticleStack() {
  return (
    <Access cap="sheets">
      <ArticleStack />
    </Access>
  );
}
