import { Button, EdsProvider } from "@equinor/eds-core-react";
import { useHistory } from "react-router-dom";

export function ReportFormHeader({ children }: { children: React.ReactNode }) {
  const history = useHistory();
  return (
    <EdsProvider density="comfortable">
      <div style={{ marginBottom: "1.5em" }}>
        <form
          onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formKeys = Object.keys(event.currentTarget.elements).filter(
              (e) => isNaN(Number(e))
            );
            history.push({
              search: formKeys
                .filter((e) => event.currentTarget[e].value !== "")
                .map((e) => e + "=" + event.currentTarget[e].value)
                .join("&"),
            });
          }}
        >
          <div style={{ marginBottom: "1em" }}>{children}</div>
          <Button type="submit">Submit</Button>
        </form>
      </div>
    </EdsProvider>
  );
}
