import axios from "axios";
import { useMutation, useQueryClient } from "react-query";
import { getApiUrl } from "../queryUtil";

export default function useDeleteSettings() {
  const queryClient = useQueryClient();

  async function deleteSettings() {
    return await axios(getApiUrl(`users/settings/keys`), {
      method: "delete",
    });
  }

  // Settings are fetched once on application load and then optimistically updated
  // and never refetched, so no query is getting invalidated.
  //
  // We do not handle the case when saving a setting was not succesful.

  return useMutation(deleteSettings, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueryData(["settings"], {});
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
