import axios from "axios";
import { SaveVDSTextBlock } from "features/sheets/types";
import { useMutation, useQueryClient } from "react-query";
import { getSheetApiUrl } from "./queryUtil";

export default function useSaveVDSTextBlock() {
  const queryClient = useQueryClient();

  async function saveSheet({
    name,
    revision,
    content,
  }: {
    name: string;
    revision: string;
    content: SaveVDSTextBlock;
  }) {
    return await axios(
      getSheetApiUrl({
        sheetType: "vds-textblocks",
        name,
        revision,
      }),
      {
        method: "put",
        data: JSON.stringify(content),
      }
    );
  }

  return useMutation(saveSheet, {
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries([
        "sheet",
        "vds-textblocks",
        variables.name,
        variables.revision,
      ]);
      queryClient.invalidateQueries(["sheets", "vds-textblocks"]);
    },
    onError: (error, variables, context) => {
      console.error(`error ${context}`, error, variables);
    },
    onSettled: (data, error, variables, context) => {},
  });
}
