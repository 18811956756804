import Table from "../../components/table/Table";
import useTable from "../../components/table/useTable";

const columns = [
  { key: "d1", title: "data 1" },
  { key: "d2", title: "data 2" },
];

const data = [
  { d1: 0, d2: "b", g: "a", G: "AAAAAAA AAAA" },
  { d1: 1, d2: "b", g: "a" },
  { d1: 2, d2: "d", g: "a" },
  { d1: 3, d2: "d", g: "a" },
  { d1: 4, d2: "d", g: "a" },
  { d1: 5, d2: "d", g: "b", G: "BBB BBBBBBB" },
  { d1: 6, d2: "d", g: "b" },
  { d1: 7, d2: "d", g: "b" },
  { d1: 8, d2: "d", g: "a", G: "A2A2A2A2" },
  { d1: 9, d2: "d", g: "a" },
  { d1: 10, d2: "b", g: "c", G: "CCCCCCC" },
  { d1: 11, d2: "b", g: "c" },
  { d1: 12, d2: "d", g: "c" },
  { d1: 13, d2: "d", g: "e", G: "E EEEEEEEEEEE" },
  { d1: 14, d2: "d", g: "e" },
  { d1: 15, d2: "d", g: "d", G: "DDDDDDDD D" },
  { d1: 16, d2: "d", g: "d" },
  { d1: 17, d2: "d", g: "d" },
  { d1: 18, d2: "d", g: "d" },
  { d1: 19, d2: "d", g: "d" },
];

export default function TableTestpage() {
  const { selectionMode, selection, selectionDispatch } = useTable({
    selectionMode: "multi",
  });

  return (
    <Table
      selectionMode={selectionMode}
      selection={selection}
      selectionDispatch={selectionDispatch}
      items={data}
      columns={columns}
      itemIdProp="d1"
      groupBy="g"
      groupTitle="G"
      density="compact"
    />
  );
}
